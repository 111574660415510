import { HTMLAttributes, ReactNode } from 'react';

import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface ParagraphProps {
  children?: ReactNode;
  className?: string;
}

export const Paragraph = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement> & ParagraphProps) => (
  <p
    className={twMerge(
      clsx(
        'font-normal leading-5 text-base mb-4 text-grey-400',
        'light:font-body',
        'legacy:font-legacyBody legacy:tracking-tight',
        className,
      ),
    )}
    {...props}
  >
    {children}
  </p>
);

export const ParagraphBold = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement> & ParagraphProps) => (
  <Paragraph
    className={twMerge('font-bold legacy:font-black my-4', className)}
    {...props}
  >
    {children}
  </Paragraph>
);

export const ErrorParagraph = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement>) => (
  <p
    className={twMerge(
      clsx(
        'font-body my-4 text-base/[20px] text-danger-200',
        'light:font-medium',
        'legacy:font-legacyBody legacy:font-semibold legacy:leading-tight legacy:text-danger-300',
        className,
      ),
    )}
    {...props}
  >
    {children}
  </p>
);

export const NumberSmall = ({
  isCredit,
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
  isCredit?: boolean;
}) => (
  <span
    className={twMerge(
      clsx(
        'legacy:font-legacyNumbers light:font-number text-base/[18px] tracking-[-0.2px] font-semibold text-grey-400',
        isCredit && 'text-success-500',
        className,
      ),
    )}
    {...props}
  >
    {children}
  </span>
);

/////////////////////////////////////////
// Numbers
/////////////////////////////////////////
export const NumberLarge = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLSpanElement>) => (
  <span
    className={twMerge(
      'legacy:font-legacyNumbers light:font-number text-[32px] legacy:leading-[42px] legacy:tracking-[-2px] font-bold leading-10 text-grey-400 m-0 p-0',
      className,
    )}
    {...props}
  >
    {children}
  </span>
);

export const NumberExtraLarge = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLSpanElement>) => (
  <span
    className={twMerge(
      'legacy:font-legacyNumbers light:font-number legacy:text-[50px] legacy:leading-[42px] legacy:tracking-[-1.5px] leading-125',
      'font-bold text-3xl',
      className,
    )}
    {...props}
  >
    {children}
  </span>
);

export const ParagraphSmall = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <p
    className={twMerge(
      'font-body m-0 p-0 text-sm text-grey-300',
      'legacy:font-legacyBody legacy:text-sm/[18px] legacy:tracking-tight',
      className,
    )}
  >
    {children}
  </p>
);

export const BodyXs = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement> & ParagraphProps) => (
  <p
    className={twMerge(
      clsx(
        'font-normal text-sm/[18px] text-grey-300 m-0 p-0',
        'legacy:font-legacyBody legacy:leading-[-0.2px]',
        'light:text-grey-500 light:font-body light:text-xs/[18px]',
        className,
      ),
    )}
    {...props}
  >
    {children}
  </p>
);

export const BodySm = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement> & ParagraphProps) => (
  <p
    className={twMerge(
      clsx(
        'mb-4 text-sm',
        'light:font-body light:font-base',
        'legacy:font-normal legacy:leading-5 legacy:tracking-tight',
        className,
      ),
    )}
    {...props}
  >
    {children}
  </p>
);

export const LabelLg = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div
    className={twMerge(
      'legacy:text-xs/[18px] legacy:not-italic legacy:font-semibold legacy:tracking-[-0.2px] legacy:text-grey-400 legacy:m-0 legacy:p-0',
      'text-base/5 font-medium text-grey-800',
      'legacy:font-legacyBody light:font-body',
      className,
    )}
  >
    {children}
  </div>
);

/////////////////////////////////////////
// Subtitles
/////////////////////////////////////////
export const Subtitle = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
}) => (
  <div
    className={twMerge(
      'legacy:font-legacyBody light:font-body text-sm/[18px] tracking-[-0.2px] font-bold text-grey-300',
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

export const SubtitleMedium = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
}) => (
  <div
    className={twMerge(
      'm-0 p-0 text-grey-400 text-sm/[18px]',
      'light:font-body',
      'legacy:font-legacyBody legacy:font-bold legacy:tracking-tight',
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

export const SubtitleSmall = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => (
  <div
    className={twMerge(
      'font-semibold m-0 p-0 text-grey-400 text-xs/[18px]',
      'light:font-body',
      'legacy:font-legacyBody legacy:tracking-tight',
      className,
    )}
  >
    {children}
  </div>
);

/////////////////////////////////////////
// Titles
/////////////////////////////////////////
interface TitleLargeProps {
  children?: ReactNode;
  className?: string;
}

export const TitleExtraLarge = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLHeadingElement> & TitleLargeProps) => (
  <h1
    className={twMerge(
      clsx(
        'legacy:font-legacyHeading font-heading font-bold text-black text-5xl leading-tight',
        className,
      ),
    )}
    {...props}
  >
    {children}
  </h1>
);

export const TitleXLarge = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLHeadingElement> & TitleLargeProps) => (
  <h1
    className={twMerge(
      clsx(
        'font-body text-[40px]/[48px] font-bold tracking-[-0.2px] m-0 p-0',
        className,
      ),
    )}
    {...props}
  >
    {children}
  </h1>
);

export const TitleLarge = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLHeadingElement> & TitleLargeProps) => (
  <h1
    className={twMerge(
      clsx(
        'font-heading font-bold leading-10 mb-2 mt-0 text-3xl/7 text-grey-500',
        'light:uppercase light:tracking-[-0.12px]',
        'legacy:font-legacyHeading legacy:text-3xl legacy:font-black legacy:tracking-tight legacy:text-grey-400',
        className,
      ),
    )}
    {...props}
  >
    {children}
  </h1>
);

export const TitleMedium = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLHeadingElement>) => (
  <h2
    className={twMerge(
      'my-4 font-heading font-bold text-grey-500',
      'light:uppercase light:text-2xl/6',
      'legacy:font-black legacy:text-2xl legacy:font-legacyHeading legacy:tracking-tight',
      className,
    )}
    {...props}
  >
    {children}
  </h2>
);

export const TitleSmall = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLHeadingElement>) => (
  <h3
    className={twMerge(
      'my-4 font-heading font-bold text-grey-400',
      'light:uppercase',
      'legacy:text-xl legacy:text-grey-400 legacy:font-legacyHeading legacy:tracking-tight',
      className,
    )}
    {...props}
  >
    {children}
  </h3>
);

export const TitleSmaller = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLHeadingElement>) => (
  <h4
    className={twMerge(
      clsx(
        'font-heading font-bold text-grey-400 my-4',
        'light:uppercase',
        'legacy:text-base legacy:text-grey-400 legacy:font-legacyHeading legacy:tracking-tight legacy:font-black',
        className,
      ),
    )}
    {...props}
  >
    {children}
  </h4>
);
