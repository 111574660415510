/** @jsxImportSource theme-ui */
import { Fragment, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Box, IconButton } from '@material-ui/core';
import { navigate } from '@reach/router';
import { useGetCardsQuery } from 'apis/cards';
import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';
import ButtonUnstyledTw from 'components/ButtonUnstyledTw';
import CircularProgress from 'components/CircularProgress';
import { NumberExtraLarge, Paragraph } from 'components/TypographyTw';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { twMerge } from 'tailwind-merge';
import { Flex } from 'theme-ui';
import { TxFeatureFlags } from 'transactions/transactions-feature-flags';

import { selectAccountPersonalAccountGroupId } from '../../accounts/store/selectors';
import { track } from '../../analytics/analytics';
import {
  selectBalanceIsLoading,
  selectBalancePersonalSpendable,
} from '../../balance/store/selectors';
import { balanceActions } from '../../balance/store/slice';
import { LocaleContext } from '../../components';
import { Circle } from '../../components/icons/';
import { DeviceVerificationModal } from '../../deviceVerification/components';
import { selectDeviceVerificationOpen } from '../../deviceVerification/store/selectors';
import { deviceVerificationActions } from '../../deviceVerification/store/slice';

export const VirtualCardWithBalance = (
  props: Readonly<{ showBalance: Function }>,
) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const context = useContext(LocaleContext);
  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';

  const personalSpendableBalance = useSelector(selectBalancePersonalSpendable);
  const isBalanceLoading = useSelector(selectBalanceIsLoading);

  const {
    data: cardsResponse,
    isLoading: getCardsIsLoading,
    isUninitialized: cardsUninitialized,
  } = useGetCardsQuery();

  const personalAccountIdentifier = useSelector(
    selectAccountPersonalAccountGroupId,
  );

  const deviceVerificationOpen = useSelector(selectDeviceVerificationOpen);

  const [primaryCardImage, setPrimaryCardImage] = useState('');

  useEffect(() => {
    if (cardsResponse) {
      const primaryCard = cardsResponse.find(
        (card) => card.account_type === 'primary',
      );
      if (primaryCard) {
        setPrimaryCardImage(primaryCard.image_url);
      } else {
        setPrimaryCardImage(cardsResponse[0]?.image_url);
      }
    }
  }, [cardsResponse, setPrimaryCardImage]);

  useEffect(() => {
    if (personalAccountIdentifier) {
      dispatch(balanceActions.getBalanceRequest());
    }
  }, [dispatch, personalAccountIdentifier, context.apiLanguageHeader]);

  useEffect(() => {
    if (deviceVerificationOpen) {
      track({ event: 'Card Device Verification Opened' });
    }
  }, [deviceVerificationOpen]);

  return (
    <Fragment>
      <div className="flex flex-col justify-center items-center">
        <div className="relative">
          <div
            className={twMerge(
              'flex items-center justify-center h-full max-w-[320px] max-h-[202px] rounded-lg overflow-hidden max-md:w-full',
              '[&_img]:w-full [&_img]:h-full',
            )}
          >
            {getCardsIsLoading ? (
              <CircularProgress className="m-auto" />
            ) : (
              <>
                <img
                  src={primaryCardImage}
                  alt={intl.formatMessage({
                    id: 'VirtualCardWithBalance.VirtualCardPrivate',
                    defaultMessage: 'Virtual Card',
                  })}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center mt-3 xl:mt-6 legacy:xl:mt-0">
        <Paragraph className="legacy:font-bold legacy:mb-0 font-medium leading-normal mb-2">
          {isLight
            ? intl.formatMessage({
                id: 'VirtualCardWithBalance.SpendableBalance',
                defaultMessage: 'Spendable balance',
              })
            : intl.formatMessage({
                id: 'VirtualCardWithBalance.Balance',
                defaultMessage: 'Balance',
              })}
        </Paragraph>

        {isBalanceLoading ? (
          <Box display="flex">
            <NumberExtraLarge>&nbsp;</NumberExtraLarge>
            <CircularProgress />
          </Box>
        ) : (
          <BalanceIndicator
            balance={personalSpendableBalance}
            showBalance={props.showBalance}
          />
        )}

        <Flex sx={{ mt: 4, gap: 2 }}>
          <RoundButton
            light
            onClick={() => navigate('/load')}
            label={intl.formatMessage({
              id: 'VirtualCardWithBalance.AddMoneyButton',
              defaultMessage: 'Add money',
            })}
          >
            <KDSIcons.Icons.AddLegacy className="legacy:text-primary-300 text-white" />
          </RoundButton>

          <RoundButton
            light
            disabled={cardsUninitialized || getCardsIsLoading}
            onClick={() => navigate('/cards')}
            label={intl.formatMessage({
              id: 'VirtualCardWithBalance.MyCards.Button',
              defaultMessage: 'Cards',
            })}
          >
            <KDSIcons.Icons.CreditCard className="legacy:text-primary-300 text-white" />
          </RoundButton>
        </Flex>
      </div>

      <DeviceVerificationModal
        open={deviceVerificationOpen}
        onClose={() => {
          track({ event: 'Card Device Verification Closed' });
          dispatch(deviceVerificationActions.closeDeviceVerification());
        }}
      />
    </Fragment>
  );
};

type RoundButtonProps = {
  light?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
  label: string;
  hideLabel?: boolean;
};
const RoundButton = ({
  children,
  onClick,
  label,
  disabled = false,
  hideLabel = false,
  light,
}: Readonly<RoundButtonProps>) => {
  return (
    <ButtonUnstyledTw
      onClick={onClick}
      disabled={disabled}
      aria-label={label}
      className={twMerge(
        'min-w-[80px] flex flex-col items-center rounded-3xl',
        'hover:enabled:rounded-3xl focus:rounded-3xl disabled:opacity-40',
      )}
    >
      <Circle light={light}>{children}</Circle>
      {hideLabel || (
        <Paragraph className="my-2 legacy:font-normal legacy:text-base font-medium text-sm">
          {label}
        </Paragraph>
      )}
    </ButtonUnstyledTw>
  );
};

const BalanceIndicator = ({ balance, showBalance }) => {
  const formatMoney = useMoneyFormatter();
  const showBalanceSheet = useFlags()[TxFeatureFlags.ShowBalanceTooltip];

  if (!showBalanceSheet) {
    return (
      <NumberExtraLarge>{balance && formatMoney(balance)}</NumberExtraLarge>
    );
  }

  return (
    <div className="text-center flex relative left-[24px]">
      <NumberExtraLarge>{balance && formatMoney(balance)}</NumberExtraLarge>
      <IconButton className="ml-2 light:p-1" onClick={() => showBalance()}>
        <KDSIcons.Icons.Info />
      </IconButton>
    </div>
  );
};
