import * as Sentry from '@sentry/browser';

/**
 * For ignoreErrors and denyUrls
 * see:
 * - https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
 * - https://gist.github.com/impressiver/5092952
 */

const SAMPLE_RATE_DEFAULT = 0.05;

export const SentryInit = () => {
  if (import.meta.env.VITE_SENTRY_KEY) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_KEY,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      integrations: [Sentry.browserTracingIntegration()],
      release: `${import.meta.env.VITE_SENTRY_PROJECT}@${
        import.meta.env.VITE_VERSION
      }`,
      tracesSampler: () => {
        return SAMPLE_RATE_DEFAULT;
      },
      // Enable this again, after the web gateway starts sending the
      // `Access-Control-Expose-Headers: X-Correlation-Id` header
      // so that we can access it safely from the frontend
      // beforeBreadcrumb(breadcrumb, hint) {
      //   // since sentry does not automatically log the correlation-id header in xhr breadcrumbs,
      //   // we need to do it manually with this hook
      //   if (
      //     breadcrumb.category === 'xhr' &&
      //     breadcrumb.type === 'http' &&
      //     hint?.xhr
      //   ) {
      //     const xhr = hint?.xhr;

      //     if (xhr && xhr?.getResponseHeader) {
      //       const correlationId = xhr.getResponseHeader('X-Correlation-ID');
      //       if (correlationId) {
      //         breadcrumb.data = {
      //           ...breadcrumb.data,
      //           correlation_id: correlationId,
      //         };
      //       }
      //     }
      //   }

      //   return breadcrumb;
      // },
      allowUrls: [
        new RegExp(import.meta.env.VITE_SENTRY_ALLOW_URLS ?? '', 'i'),
      ],
      denyUrls: [
        /\.acsbapp\.com/i,
        /\.appsflyer\.com/i,
        /\.googlesyndication\.com/i,
        /app\.launchdarkly\.com/i,
        /\.launchdarkly\.com/i,
        /\.tiktok\.com/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],
      ignoreErrors: [
        'Error fetching flag settings',
        'Non-Error promise rejection captured with keys',
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        'Error: Request failed with status code 404',
        /TypeError/,
        'NetworkError:  A network error occurred.',
      ],
    });
    Sentry.configureScope((scope) => {
      const transaction = scope.getTransaction();

      // this is a temporary work around to get parameterized routes out of sentry
      // we should revisit this after migrating to react router v6 (instrumentation not currently supported in sentry)
      if (
        transaction &&
        transaction.op === 'pageload' &&
        transaction.name.startsWith('/referral/')
      ) {
        scope.setTransactionName('/referral');
      }
    });
  }
};

export default SentryInit;
