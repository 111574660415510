import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ButtonBase } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { useGetCurrentTierQuery, useGetTiersQuery } from 'apis/tiers';
import { KDSIcons } from 'assets/images/kds_icons';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import {
  ParagraphSmall,
  TitleLarge,
  TitleMedium,
} from 'components/TypographyTw';
import { TemplateTw } from 'layout/TemplateTw';
import { CancelAnytime } from 'tiers/components/CancelAnytime';
import { ChangePlan } from 'tiers/components/ChangePlan';
import { PlanCard } from 'tiers/components/PlanCard';
import { Tiers } from 'tiers/components/Tiers';
import { useSelectedTierPlan } from 'tiers/hooks/useSelectedTierPlan';
import { selectIsPaidTier } from 'tiers/store/selectors';
import { getNextHighestRankedTierKey } from 'tiers/utils/helpers';

import { selectLaunchDarklyFlagsIsLoading } from 'libs/launchdarkly/store/selectors';

const BackButton = () => (
  <ButtonBase
    className="mr-auto mb-6"
    onClick={() => navigate('/tiers/my-plan')}
    data-cy="tier-management-back-button"
  >
    <KDSIcons.Icons.ChevronLeft className="h-6 [&>path]:stroke-grey-400 [&>path]:transition-all [&>path]:ease-in-out [&>path]:duration-200 hover:[&>path]:stroke-grey-500" />
  </ButtonBase>
);

export const TierManagementPage = () => {
  const intl = useIntl();
  const isPaidTier = useSelector(selectIsPaidTier);

  const ldFlagsIsLoading = useSelector(selectLaunchDarklyFlagsIsLoading);

  const {
    data: tiers,
    isLoading: tiersIsLoading,
    isUninitialized: tiersIsUninitialized,
  } = useGetTiersQuery();
  const { data: currentTier, isLoading: currentTierIsLoading } =
    useGetCurrentTierQuery();

  const currentUsersTier = currentTier?.benefit_group?.key;

  const { setTier, currentTierSelected } = useSelectedTierPlan({
    tiers: tiers ?? [],
  });

  useEffect(() => {
    const nextHighestTier = getNextHighestRankedTierKey(currentUsersTier);

    setTier(nextHighestTier);
  }, [currentUsersTier, setTier]);

  if (
    tiersIsUninitialized ||
    tiersIsLoading ||
    currentTierIsLoading ||
    ldFlagsIsLoading
  ) {
    return <Skeleton variant="rect" />;
  }

  if (!isPaidTier) {
    navigate('/transactions', { state: { showWelcomeModal: false } });
    return null;
  }

  if (!tiers || tiers.length === 0) {
    navigate('/transactions', { state: { showWelcomeModal: false } });
    return null;
  }

  const selected = intl.formatMessage({
    id: 'TiersManage.PendingDowngrade.Selected',
    defaultMessage: 'selected',
  });

  const currentUsersTierPlan = currentTier?.benefit_group?.plans?.find(
    (plan) => plan.key === currentTier.frequency,
  );
  const selectedPlan = currentTierSelected?.plans?.find(
    (plan) => plan.key === currentTier?.frequency,
  );
  const tiersToShow = tiers.filter(
    (tier) => tier.key !== currentTier?.benefit_group?.key,
  );
  const delayedDowngradeTierKey =
    currentTier?.delayed_downgrade?.benefit_group?.key;
  const hasSelectedDowngradeTier =
    delayedDowngradeTierKey &&
    delayedDowngradeTierKey === currentTierSelected?.key;

  return (
    <TemplateTw name="Tiers - Manage">
      <BoxTw className="flex flex-col pb-12 xl:pb-20 items-center w-fit px-6 pt-8 xl:pt-0 mx-auto">
        <BackButton />
        <BoxTw className="flex flex-col justify-center items-start lg:items-center mx-4 w-full lg:w-[352px]">
          <TitleLarge
            className="text-center mb-4 mr-auto lg:mr-0 text-grey-500"
            data-cy="tier-manage-header"
          >
            {intl.formatMessage({
              id: 'TiersManage.header',
              defaultMessage: 'Your plan',
            })}
          </TitleLarge>
          {currentTier?.benefit_group && currentUsersTierPlan ? (
            <BoxTw className="flex mb-8 w-full h-auto">
              <PlanCard
                tier={currentTier?.benefit_group}
                plan={currentUsersTierPlan}
                isFree={false}
              />
            </BoxTw>
          ) : null}
          <TitleMedium className="mb-4 text-grey-300">
            {intl.formatMessage({
              id: 'TiersManage.plansHeader',
              defaultMessage: 'Available plans',
            })}
          </TitleMedium>
        </BoxTw>
        <Tiers
          boxClassName="mb-2 px-0"
          selectable
          tiers={tiersToShow}
          plan={selectedPlan?.key}
          selectedTier={currentTierSelected?.key}
          handleSelectTier={setTier}
          cardTrackName="tiers-change-select"
        />

        <BoxTw className="lg:max-w-[352px] max-w-[unset] mx-auto w-full">
          {hasSelectedDowngradeTier ? (
            <ParagraphSmall
              className="text-danger-300 font-bold lg:text-center mt-6"
              data-cy="selected-downgrade-tier-error"
            >
              {intl.formatMessage(
                {
                  id: 'TiersManage.PendingDowngrade',
                  defaultMessage:
                    'There is a pending downgrade for the {selected} plan.  Please select another plan.',
                },
                { selected: <b>{selected}</b> },
              )}
            </ParagraphSmall>
          ) : null}
          {currentTierSelected && currentTier?.benefit_group ? (
            <ChangePlan
              currentUserTier={currentTier}
              tier={currentTierSelected}
              selectedPlan={selectedPlan}
              trackEventName="tiers-change-confirm"
              onPaidTier
              disabled={hasSelectedDowngradeTier}
            />
          ) : null}
          <ButtonTw
            className="mt-4 mb-12 py-3 text-danger-300 font-black"
            data-cy="tiers-change-cancel-plan"
            trackName="tiers-change-cancel-plan"
            variant="clear"
            onClick={() => navigate('/cancel-plan')}
          >
            {intl.formatMessage({
              id: 'TiersManage.CancelPlan',
              defaultMessage: 'Cancel plan',
            })}
          </ButtonTw>
          <CancelAnytime />
        </BoxTw>
      </BoxTw>
    </TemplateTw>
  );
};
