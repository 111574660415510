import { baseTheme } from './base';

const greyScale = {
  50: '#FFFFFF',
  75: '#F1F5F8', // greyXLight
  100: '#EFEFEF', // tertiaryT100
  200: '#D8D8D8', // greyLight
  250: '#C3C5C9', // greyLavender
  300: '#74748B', // midGrey, tertiaryT300
  400: '#373E4D', // text, tertiary
  500: '#22262F', // textDark
};

const greenScale = {
  50: '#EEF7F4',
  75: '#B8DDD1',
  100: '#9BCFBE',
  200: '#70BAA2',
  300: '#53AC8F', // successful
  400: '#3A7864', // green400
  500: '#336957',
};

export const legacyTheme = {
  ...baseTheme,
  grey: greyScale,
  primary: {
    50: '#E6EEF5',
    // 75: '#96BAD6', // matches figma
    75: '#B2CCE1', // matches mobile; primaryDisabled
    100: '#6B9EC5',
    200: '#2B74AC',
    300: '#00579B', // primary
    400: '#003A67', // primaryActive
    500: '#00355F',
  },
  secondary: {
    50: '#FFEFE8',
    75: '#FACCC6',
    100: '#F8B7AE',
    200: '#F5988C', // secondaryS200
    300: '#F38374',
    400: '#D35848',
    500: '#86443C',
  },
  tertiary: greyScale,
  warning: {
    50: '#FFF4DB',
    75: '#FFE9BF',
    100: '#FFE0A4',
    200: '#FFD27D',
    300: '#FFC962',
    400: '#FDA947',
    500: '#FA7A31',
  },
  danger: {
    50: '#FEEDEE', // alertLight
    75: '#FAB4BB', // dangerD75
    100: '#F7949F',
    200: '#F46675',
    300: '#F24759', // danger
    400: '#A9323E',
    500: '#942B36', // alertDark
  },
  success: greenScale,
  'tiers-blue': {
    50: '#EBEEFF', // paleNavy
    75: '#CDD5FA',
    100: '#768CF1',
    300: '#2F3CCF', // blueNavy
    400: '#2834B2', // blueDark
  },
};
