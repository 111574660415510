import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useLocation, useNavigate } from '@reach/router';
import { trackInput } from 'analytics/analytics';
import { selectTheme } from 'appState/appState.slice';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

const SidebarChip = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <Box
    className={twMerge(
      'legacy:rounded-[10%] legacy:font-bold legacy:text-base legacy:text-grey-75 legacy:bg-primary-300',
      'rounded-[4px] font-bold text-xs border border-grey-75 px-2 py-1 bg-white ml-2',
      className,
    )}
  >
    {children}
  </Box>
);

export const PathItem = ({ page }) => {
  const Icon = page.icon;
  const themeName = useSelector(selectTheme);

  const location = useLocation();
  const intl = useIntl();

  const navigate = useNavigate();

  const clickhandler = () => {
    trackInput({
      type: 'Clicked',
      name: page.name,
      details: { to: page.path ? page.path : '' },
    });

    page.path && navigate(page.path);
    page.onClick && page.onClick();
  };

  return (
    <>
      {page.component}
      <ListItem
        button
        onClick={() => clickhandler()}
        key={page.name}
        data-cy={page.path}
        className={twMerge(
          clsx(
            'w-full border-none legacy:[border-bottom:1px_solid_#D8D8D8] [border-bottom:1px_solid_#CCCBD0] xl:[border-bottom:none] xl:flex xl:hover:bg-primary-75 light:py-3',
            location.pathname === page.path &&
              'legacy:[border-right:5px_solid_#00597B] [border-right:5px_solid_#6A22C9] bg-primary-75',
            page.path === '/settings' && 'xl:hidden',
          ),
        )}
      >
        <ListItemIcon className="light:h-6 light:w-6 light:min-w-6 light:mr-3">
          <Icon className="text-grey-400" />
        </ListItemIcon>
        <ListItemText
          primary={page.name}
          primaryTypographyProps={{
            style: {
              fontWeight:
                location.pathname === page.path || themeName === 'light'
                  ? 800
                  : 400,
            },
          }}
          className="light:text-grey-500"
        />
        {page.new && (
          <SidebarChip>
            {intl.formatMessage({
              id: 'Global.Label.New',
              defaultMessage: 'New',
            })}
          </SidebarChip>
        )}
        {page.chipMessage && (
          <SidebarChip className="text-xs/[18px] py-1">
            {page.chipMessage}
          </SidebarChip>
        )}
      </ListItem>
    </>
  );
};
