/** @jsxImportSource theme-ui */

/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from 'components/CircularProgress';
import { ThemeUICSSObject } from 'theme-ui';

import { track } from '../../analytics/analytics';
import { theme } from '../../theme';
import { TextMaskDate, TextMaskDateMMYY } from './TextMaskDate';
import { TextMaskPhone } from './TextMaskPhone';
import { AsyncValidationFn, ValidationFn } from './Validators';

export interface TextInputProps
  extends Partial<Omit<OutlinedTextFieldProps, 'css'>> {
  validators?: ValidationFn[];
  asyncValidators?: AsyncValidationFn[];
  onValidChange?: Function;
  controlledValue?: string;
  autoComplete?: string;
  helperText?: any;
  wrapperStyle?: ThemeUICSSObject;
  className?: string;
}

export const TextInput = ({
  name,
  onChange = () => {},
  onBlur = () => {},
  placeholder,
  id,
  label,
  fullWidth = true,
  required,
  type,
  validators,
  asyncValidators,
  onValidChange = () => {}, // needs better typing
  controlledValue = '',
  autoComplete = 'off',
  inputProps,
  InputProps,
  helperText,
  wrapperStyle = {},
  className,
  ...props
}: TextInputProps) => {
  const [value, setValue] = React.useState<string>('');
  const [errors, setErrors] = React.useState<
    Array<string | MessageDescriptor | null>
  >([]);
  const [asyncErrors, setAsyncErrors] = React.useState<
    Array<string | MessageDescriptor | null>
  >([]);
  const [allErrors, setAllErrors] = React.useState<
    Array<string | MessageDescriptor | null>
  >([]);
  const [touched, setTouched] = React.useState(!!controlledValue);
  const [isLoading, setIsLoading] = React.useState(false);
  const intl = useIntl();

  const validate = (inputValue: string) => {
    if (validators && validators.length) {
      const newErrors = validators
        .map((validator) => validator(inputValue))
        .filter(Boolean);

      setErrors(newErrors);
    }
  };

  // consider using useCallback to define this function so it doesn't redeclare
  const asyncValidate = (inputValue: string) => {
    if (asyncValidators && asyncValidators.length) {
      asyncValidators.forEach((validator, index) => {
        onValidChange(false);
        setIsLoading(true);
        validator(inputValue).then((errorMessage) => {
          setIsLoading(false);
          setAsyncErrors((previousErrors) => {
            previousErrors[index] = errorMessage
              ? intl.formatMessage(errorMessage)
              : null;
            return previousErrors.filter(Boolean);
          });
        });
      });
    }
  };

  React.useEffect(() => {
    // reset touched to avoid a flash of an error message
    if (touched && controlledValue) {
      setTouched(false);
    }
  }, []);

  React.useEffect(() => {
    setValue(controlledValue);
  }, [controlledValue]);

  React.useEffect(() => {
    validate(value);

    // debounce changes
    if (asyncValidators) {
      const handler = setTimeout(() => {
        asyncValidate(value);
      }, 200);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);

  React.useEffect(() => {
    setAllErrors([...errors, ...asyncErrors]);

    onValidChange(![...errors, ...asyncErrors].length);
  }, [errors, asyncErrors]);

  const change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onChange(event);
  };

  const blur = (event: React.FocusEvent<HTMLInputElement>) => {
    track({ event: `${name} Blurred` });
    setTouched(true);
    onBlur(event);
  };

  const focus = (event: React.FocusEvent<HTMLInputElement>) => {
    track({ event: `${name} Focused` });
  };

  const getInputPros = () => {
    if (InputProps) {
      return InputProps;
    }
    switch (type) {
      case 'phone':
        return { inputComponent: TextMaskPhone };
      case 'dateYYYYMMDD':
        return { inputComponent: TextMaskDate };
      case 'dateMMYY':
        return { inputComponent: TextMaskDateMMYY };
      default:
    }
  };

  const getHelperText = () => {
    if (helperText) {
      return helperText;
    }

    if (!touched) {
      return null;
    }

    if (!allErrors[0]) {
      return null;
    }

    if (typeof allErrors[0] === 'string') {
      return allErrors[0];
    }

    return intl.formatMessage(allErrors[0]);
  };

  const useStyles = () =>
    makeStyles(() => ({
      root: {
        '& .Mui-error': {
          color: theme.colors.danger,
        },
        '& .MuiOutlinedInput-root.Mui-error': {
          '& fieldset': {
            borderColor: theme.colors.danger,
          },
        },
      },
    }));

  const classes = useStyles()();

  return (
    <div sx={{ position: 'relative', ...wrapperStyle }} className={className}>
      {isLoading ? (
        <div className="absolute right-0 mx-2 z-[1] flex items-center h-full">
          <CircularProgress variant="large" className="my-auto" />
        </div>
      ) : null}
      <TextField
        variant="outlined"
        name={name}
        type={type}
        placeholder={placeholder}
        id={id}
        label={label}
        fullWidth={fullWidth}
        required={required}
        helperText={getHelperText()}
        error={!!(allErrors.length && touched)}
        onBlur={blur}
        onChange={change}
        onFocus={focus}
        InputProps={getInputPros()}
        inputProps={inputProps}
        value={value}
        autoComplete={autoComplete}
        className={classes.root}
        sx={{
          '.MuiInputBase-root': {
            backgroundColor: 'white',
          },
        }}
        {...props}
      />
    </div>
  );
};
