import { ReactNode } from 'react';

import { KDSIcons } from 'assets/images/kds_icons';
import { twMerge } from 'tailwind-merge';

export const ChecklistItem = ({
  checkClassName,
  children,
  className,
}: {
  checkClassName?: string;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <li
      className={twMerge(
        'list-none grid [grid-template-columns:18px_1fr] gap-x-4 [&:not(:last-of-type)]:mb-4',
        className,
      )}
    >
      <KDSIcons.Icons.CheckmarkFilled
        className={twMerge('text-success-300', checkClassName)}
      />
      <div className="flex items-center">{children}</div>
    </li>
  );
};
