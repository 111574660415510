import { authActions } from 'auth/store/slice';
import { showSnackbar } from 'components';
import { loginActions } from 'login/store/slice';

import { getDeviceID } from 'libs/axios';

import {
  FingerprintEventTypes,
  createFingerprints,
} from '../fingerprints/fingerprints';
import { MFAOption } from './auth';
import { rootApi } from './rootApi';

interface RegisterResponse {
  secret: string;
  url: string;
  recovery_code: string;
}

interface ValidateRequest {
  otp: string;
}

interface ResetRequest {
  recovery_code: string;
}

interface GetMfaOptionsResponse {
  type: string;
  restore_type: string;
}

interface UpdateMfaOptionsRequest {
  option: 'sms' | 'totp';
}

interface UpdateMfaOptionsResponse {
  type: 'totp' | 'sms';
  restore_type: string;
}

interface ValidateMfaRequest {
  mfa_option: MFAOption;
  mfa_token: string;
}

interface ValidateMFAResponse {
  login_type: string;
}

export const mfaApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    registerTotp: build.mutation<RegisterResponse, void>({
      query: () => ({
        url: `/1.0/auth/identities/totp`,
        method: 'POST',
      }),
      invalidatesTags: ['MFA'],
    }),
    validateTotp: build.mutation<void, ValidateRequest>({
      query: (body) => ({
        url: '/1.0/auth/identities/totp/validate',
        method: 'POST',
        body: {
          otp: body.otp,
        },
      }),
      invalidatesTags: ['MFA'],
    }),
    resetTotp: build.mutation<RegisterResponse, ResetRequest>({
      query: () => ({
        url: '/1.0/auth/identities/totp',
        method: 'PUT',
      }),
      invalidatesTags: ['MFA'],
    }),
    disableTotp: build.mutation<void, void>({
      query: () => ({
        url: '/1.0/auth/identities/totp',
        method: 'DELETE',
      }),
      invalidatesTags: ['MFA'],
    }),
    getMfaOptions: build.query<GetMfaOptionsResponse, void>({
      query: () => ({
        url: '/1.0/auth/identities/mfa/options',
        method: 'GET',
      }),
      providesTags: ['MFA'],
    }),
    updateMfaOptions: build.mutation<
      UpdateMfaOptionsResponse,
      UpdateMfaOptionsRequest
    >({
      query: (body) => ({
        url: '/1.0/auth/identities/mfa/options',
        method: 'PATCH',
        body: {
          option: body.option,
        },
      }),
      invalidatesTags: ['MFA'],
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          showSnackbar('Unexpected error occurred. Please try again.', {
            type: 'error',
          });
        }
      },
    }),
    validateMFA: build.mutation<ValidateMFAResponse, ValidateMfaRequest>({
      query: (args) => {
        const body: Record<string, string | boolean> = {
          mfa_option: args.mfa_option,
          mfa_token: args.mfa_token,
        };

        return {
          url: '/3.0/mfa/validate',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['MFA'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (arg.mfa_option === 'totp') {
            dispatch(loginActions.loginSuccessNoOTP());

            createFingerprints(
              getDeviceID(),
              FingerprintEventTypes.LoggedIn,
              data.login_type,
              arg.mfa_option,
            );
          }

          if (arg.mfa_option === 'sms') {
            dispatch(loginActions.smsOtpSuccess(true));
          }

          dispatch(authActions.setLoginType(data.login_type));
          dispatch(authActions.deleteSensitiveInfo());

          return;
        } catch (error) {
          return;
        }
      },
    }),
  }),
});

export const {
  useRegisterTotpMutation,
  useValidateTotpMutation,
  useResetTotpMutation,
  useDisableTotpMutation,
  useGetMfaOptionsQuery,
  useUpdateMfaOptionsMutation,
  useValidateMFAMutation,
} = mfaApi;
