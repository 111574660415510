import { ReactComponent as CreditBuilding } from './credit-building.svg';
import { ReactComponent as ForeignFees } from './foreign-fees.svg';
import { ReactComponent as Interest } from './interest.svg';
import { ReactComponent as Partners } from './partners.svg';
import { ReactComponent as Rent } from './rent.svg';
import { ReactComponent as Travel } from './travel.svg';

export const Plan = {
  CreditBuilding,
  ForeignFees,
  Interest,
  Partners,
  Rent,
  Travel,
};
