import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import Close from '@material-ui/icons/Close';
import { twMerge } from 'tailwind-merge';

import ButtonUnstyledTw from './ButtonUnstyledTw';

export const InPageNotification = ({
  children,
  closeButton = true,
}: {
  children: ReactNode;
  closeButton?: boolean;
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const intl = useIntl();

  if (isHidden) return null;
  return (
    <div
      className={twMerge(
        'text-grey-400 bg-grey-50 items-center justify-between rounded-xl gap-6 flex flex-row p-6',
        'legacy:bg-grey-75 legacy:rounded-5',
      )}
    >
      {children}
      {closeButton && (
        <ButtonUnstyledTw
          onClick={() => setIsHidden(true)}
          aria-label={intl.formatMessage({
            id: 'InPageNotification.CloseButton.AriaLabel',
            defaultMessage: 'Close notification',
          })}
        >
          <Close className="text-primary-300" />
        </ButtonUnstyledTw>
      )}
    </div>
  );
};
