import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useMediaQuery } from '@material-ui/core';
import { IBenefitGroup, IPlan } from 'apis/tiers';
import { selectTheme } from 'appState/appState.slice';
import { ReactComponent as TiersIconGradient } from 'assets/images/logos/everything-gradient-en.svg';
import { ReactComponent as TiersIconGradientFr } from 'assets/images/logos/everything-gradient-fr.svg';
import clsx from 'clsx';
import { LOCALE_EN, LOCALE_FR, LocaleContext } from 'components';
import { Paragraph, ParagraphSmall } from 'components/TypographyTw';
import { Money } from 'models';
import { twMerge } from 'tailwind-merge';
import { useTiersData } from 'tiers/hooks/useTiersData';
import { TierKey } from 'tiers/models';

const everythingLogoOptions = {
  [LOCALE_EN]: TiersIconGradient,
  [LOCALE_FR]: TiersIconGradientFr,
};

type Props = {
  isFree: boolean;
  plan: IPlan;
  tier: IBenefitGroup;
};

export const PlanCard = ({ isFree, plan, tier }: Props) => {
  const { locale } = useContext(LocaleContext);
  const intl = useIntl();

  const isEssential = tier.key === TierKey.Essential;
  const isEverything = tier.key === TierKey.Everything;
  const isExtra = tier.key === TierKey.Extra;

  const { getTierName } = useTiersData();
  const isSmallMobile = useMediaQuery('@media (max-width: 380px)');
  const themeName = useSelector(selectTheme);

  const tierName = getTierName(tier.name);

  const EverythingLogo = everythingLogoOptions[locale];
  const billed = {
    monthly: intl.formatMessage({
      id: 'TiersUpgrade.Modal.BilledMonthly',
      defaultMessage: 'Billed monthly',
    }),
    annual: intl.formatMessage({
      id: 'TiersUpgrade.Modal.BilledYearly',
      defaultMessage: 'Billed yearly',
    }),
  }[plan.key];

  return (
    <div
      data-cy={`plan-card-${tier.key}`}
      className={twMerge(
        clsx(
          'rounded-lg w-full',
          (isEssential || isExtra) && 'border border-solid border-grey-100',
          isEverything &&
            'legacy:bg-gradient-to-r legacy:from-tiers-blue-300 legacy:via-[#A36EFF] legacy:to-[#F36350]',
        ),
      )}
    >
      <div
        className={twMerge(
          clsx(
            'legacy:bg-tiers-blue-50 bg-primary-50 px-3 py-4 rounded-lg',
            isEverything &&
              'legacy:bg-[#F9F9F9] legacy:m-[1px] legacy:rounded-[7px]',
          ),
        )}
      >
        {isEverything && themeName === 'legacy' ? (
          <EverythingLogo
            height="34px"
            data-cy="tiers-upgrade-payment-tier-Everything"
          />
        ) : (
          <Paragraph
            data-cy={`tiers-upgrade-payment-tier-${tier.name}`}
            className="text-2xl legacy:font-black font-bold legacy:text-tiers-blue-400 text-primary-350 mb-1"
          >
            {tierName}
          </Paragraph>
        )}
        <div className="flex flex-row flex-wrap gap-1 items-center">
          <ParagraphSmall
            className={twMerge(
              clsx(
                'legacy:font-black font-bold legacy:text-grey-400 text-grey-500 whitespace-nowrap mb-0',
                isFree && 'line-through',
                isSmallMobile && 'text-xs',
              ),
            )}
          >
            {intl.formatMessage(
              {
                id: 'TiersUpgrade.Modal.CostPerMonth',
                defaultMessage: '{cost}/month',
              },
              {
                cost: new Money(
                  plan.key === 'monthly'
                    ? plan.amount_cents
                    : plan.amount_cents / 12,
                  locale,
                ).format('pretty'),
              },
            )}
          </ParagraphSmall>
          <div className="flex gap-1">
            {isFree && (
              <ParagraphSmall
                data-cy="tiers-upgrade-payment-free-trial"
                className={twMerge(
                  clsx(
                    'legacy:font-black font-bold legacy:text-tiers-blue-400 text-primary-350 whitespace-nowrap mb-0',
                    isSmallMobile && 'text-xs',
                  ),
                )}
              >
                {intl.formatMessage({
                  id: 'TiersUpgrade.Modal.FreeTrial',
                  defaultMessage: 'Free trial',
                })}
              </ParagraphSmall>
            )}
            <ParagraphSmall
              className={twMerge(
                clsx(
                  'text-grey-400 whitespace-nowrap',
                  isSmallMobile && 'text-xs',
                ),
              )}
              data-cy={`tiers-upgrade-payment-billed-${plan.key}`}
            >
              • {billed}
            </ParagraphSmall>
          </div>
        </div>
      </div>
    </div>
  );
};
