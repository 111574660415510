import { useEffect } from 'react';

import { useToggle } from 'hooks/useToggle';
import shajs from 'sha.js';

// Specifications for Meta Pixel Standard Events: https://www.facebook.com/business/help/402791146561655?id=1205376682832142

type TEvent =
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'CompleteRegistration'
  | 'Contact'
  | 'CustomizeProduct'
  | 'Donate'
  | 'FindLocation'
  | 'InitiateCheckout'
  | 'Lead'
  | 'Purchase'
  | 'Schedule'
  | 'Search'
  | 'StartTrial'
  | 'SubmitApplication'
  | 'Subscribe'
  | 'ViewContent';

type TPropertiesAdvancedMatching = {
  'ud[ct]'?: string;
  'ud[db]'?: string;
  'ud[em]'?: string;
  'ud[fn]'?: string;
  'ud[ge]'?: string;
  'ud[ln]'?: string;
  'ud[ph]'?: string;
  'ud[st]'?: string;
  'ud[zp]'?: string;
  'ud[external_id]'?: string;
};

// Sending advanced matching event using image tag:
// https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching
// https://stackoverflow.com/questions/39610897/how-to-initialize-facebook-pixel-with-data-that-will-be-populated-after-the-init
export const fbTrackEvent = (
  event: TEvent,
  properties?: TPropertiesAdvancedMatching,
): void => {
  let facebookUrlParamsString = '';
  const hashEmail = properties?.['ud[em]']
    ? shajs('sha256').update(properties['ud[em]']).digest('hex')
    : '';

  for (const prop in properties) {
    const hashProp = shajs('sha256').update(properties[prop]).digest('hex');
    facebookUrlParamsString = `${facebookUrlParamsString}&${prop}=${hashProp}`;
  }

  const imgTag = document.createElement('img');
  imgTag.height = 1;
  imgTag.width = 1;
  imgTag.setAttribute('style', 'display: none');
  imgTag.src = `https://www.facebook.com/tr?id=599633800219052&ev=${event}&noscript=1&eid=${hashEmail}${facebookUrlParamsString}`;

  document.body.appendChild(imgTag);
};

export const useFbTrackStartRegistration = () => {
  const { value: tracked, on: setTracked } = useToggle();
  const fbTrackingInitialized =
    typeof window !== 'undefined' && !!(window as any)?.fbq;

  useEffect(() => {
    if (!tracked && fbTrackingInitialized) {
      setTracked();
      fbTrackEvent('Lead');
    }
  }, [tracked, fbTrackingInitialized, setTracked]);
};
