/** @jsxImportSource theme-ui */
import { useEffect, useState } from 'react';

import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import { Logo } from 'components/Logo';

import { track } from '../../analytics/analytics';
import { ReactComponent as CancelMenuBar } from '../../assets/images/menu-bar-cancel.svg';
import { ReactComponent as Hamburger } from '../../assets/images/menubar.svg';
import ButtonUnstyled from '../ButtonUnstyled';

interface Props {
  paths: React.ReactNode[];
}

export const MobileNav = ({ paths }: Readonly<Props>) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    track({ event: 'Menu Closed' });
  };

  const handleClickHamburger = (event: any) => {
    setIsOpen(true);
    track({ event: 'Menu Opened' });
  };

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth > 1280 && handleClose();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <>
      <div
        id="menu-mobile"
        className="flex items-center justify-between h-[72px] w-[100vw] pl-6 border-b border-grey-200"
      >
        <div>
          <Logo />
        </div>
        <div className="flex items-center">
          <ButtonUnstyled
            onClick={handleClickHamburger}
            className="p-4 focus:border-grey-300 text-grey-400"
          >
            {isOpen ? <CancelMenuBar /> : <Hamburger />}
          </ButtonUnstyled>
        </div>
      </div>
      <Menu
        id="menu"
        open={isOpen}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorReference="none"
        style={{
          backgroundColor: 'rgba(0,0,0,0.3)',
          top: '72px',
        }}
        PaperProps={{
          style: {
            maxWidth: '100%',
            left: 0,
            padding: 0,
            borderRadius: 0,
            width: '100%',
          },
        }}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
      >
        <List component="nav" aria-label="navigation" className="w-full p-0">
          {paths}
        </List>
      </Menu>
    </>
  );
};
