import { rootApi } from './rootApi';

export interface IBillPayLoadData {
  account_group_id: string;
  payee_identifier: string;
}

const billPayLoadApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getBillPayLoadInfo: build.query<IBillPayLoadData, void>({
      query: () => ({
        url: `1.0/billpayload/info`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetBillPayLoadInfoQuery } = billPayLoadApi;
