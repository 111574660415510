import { useIntl } from 'react-intl';

import { Box, BoxProps, InputAdornment } from '@material-ui/core';
import { MoneyRequest } from 'apis/requestMoneyApi';
import { BoxTw } from 'components/BoxTw';
import { CopyButton } from 'components/CopyButton';
import {
  Paragraph,
  ParagraphSmall,
  TitleMedium,
} from 'components/TypographyTw';
import { KhInputTextTw } from 'components/inputs/KhInputTextTw';

export const RequestMoneyETransferDetails = ({
  request,
  className,
}: BoxProps & {
  request: MoneyRequest;
}) => {
  const intl = useIntl();

  return (
    <Box className={className}>
      <TitleMedium>
        {intl.formatMessage(
          {
            id: 'RequestMoneyETransferDetails.Title',
            defaultMessage: 'e-Transfer {name}',
          },
          { name: request.requester_given_name },
        )}
      </TitleMedium>
      <ParagraphSmall className="mb-6">
        {intl.formatMessage(
          {
            id: 'RequestMoneyETransferDetails.Subtitle',
            defaultMessage:
              'Interac e-Transfer from your bank to {name}’s KOHO account.',
          },
          { name: request.requester_given_name },
        )}
      </ParagraphSmall>
      <KhInputTextTw
        disabled={true}
        className="mb-0 [&_.MuiInputBase-input]:text-grey-400"
        defaultValue={request.etransfer_email}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CopyButton
                value={request.etransfer_email}
                trackingLabel="ETransfer Email"
              />
            </InputAdornment>
          ),
        }}
      />
      <BoxTw className="flex justify-between border-b border-grey-200">
        <Paragraph className="my-0">
          {intl.formatMessage({
            id: 'RequestMoneyETransferDetails.Question',
            defaultMessage: 'Security question',
          })}
          :
        </Paragraph>
        <BoxTw className="flex">
          <Paragraph className="my-0">
            {intl.formatMessage({
              id: 'RequestMoneyETransferDetails.QuestionValue',
              defaultMessage: 'KOHO Load code',
            })}
          </Paragraph>
          <CopyButton
            value={intl.formatMessage({
              id: 'RequestMoneyETransferDetails.QuestionValue',
              defaultMessage: 'KOHO Load code',
            })}
            trackingLabel="Security question"
            className="ml-2 px-4"
          />
        </BoxTw>
      </BoxTw>
      <BoxTw className="flex justify-between border-b border-grey-200">
        <Paragraph className="my-0">
          {intl.formatMessage({
            id: 'RequestMoneyETransferDetails.Answer',
            defaultMessage: 'Security answer',
          })}
          :
        </Paragraph>
        <BoxTw className="flex">
          <Paragraph className="my-0">{request.etransfer_answer}</Paragraph>
          <CopyButton
            value={request.etransfer_answer}
            trackingLabel="Security answer"
            className="ml-2 px-4"
          />
        </BoxTw>
      </BoxTw>
    </Box>
  );
};
