import { useContext, useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import List from '@material-ui/core/List';
import { navigate } from '@reach/router';
import { useCreateHisaAccountMutation } from 'apis/hisa';
import { LocaleContext } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { LinkTw } from 'components/LinkTw';
import { Paragraph, Subtitle, TitleLarge } from 'components/TypographyTw';
import { contactActions } from 'contact/store/contact.slice';
import { TemplateTw } from 'layout/TemplateTw';

import { BulletItem } from '../components/BulletItem';
import {
  HisaState,
  hisaActions,
  selectHisaOnboarding,
  transformatHisaOnboardingDataForApi,
} from '../store/slice';

const Assumptions = [
  defineMessage({
    id: 'HisaAgreementPage.Assumption.Residence',
    defaultMessage:
      'You are a Canadian resident and have attained the age of majority in the province you reside.',
  }),
  defineMessage({
    id: 'HisaAgreementPage.Assumption.Citizenshipe',
    defaultMessage:
      'You are not a US Person (Includes US or Dual Citizens), US Resident Alien, US Permanent Resident/Green Card Holder or a person born in the US.',
  }),
];

export const HisaAgreementPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { locale } = useContext(LocaleContext);
  const langToken = locale === 'fr' ? 'fr/' : '';

  const [hisaError, setHisaError] = useState<boolean | string>(false);

  const onboardingData: HisaState = useSelector(selectHisaOnboarding);

  const [createAccount, createRequestStatus] = useCreateHisaAccountMutation();

  const displayError = !!hisaError;

  const createAccountOnClick = async () => {
    let accountData = transformatHisaOnboardingDataForApi(onboardingData);

    try {
      await createAccount(accountData).unwrap();
      navigate('/savings');
      dispatch(hisaActions.setSuccessfullyCreated(true));
    } catch (error) {
      if (typeof error === 'object' && error != null && 'status' in error) {
        console.error('Error creating account:', error);
        setHisaError(JSON.stringify(error, null, 2));
      }
    }
  };

  return (
    <TemplateTw name="HISA Agreement" variant="center">
      <Subtitle>
        {intl.formatMessage({
          id: 'HisaSetupPage.EarnInterest',
          defaultMessage: 'Earn Interest',
        })}
      </Subtitle>
      <TitleLarge>
        {intl.formatMessage({
          id: 'HisaAgreementPage.Title',
          defaultMessage: 'Please review your agreement',
        })}
      </TitleLarge>

      <Paragraph>
        {intl.formatMessage(
          {
            id: 'HisaAgreementPage.SaveAgreementDescription',
            defaultMessage:
              'You are almost there! By proceeding, you acknowledge that you have read and agreed to the <linkTerms></linkTerms>.',
          },
          {
            linkTerms: () => (
              <LinkTw
                to={`https://www.koho.ca/${langToken}legal/#CardHolderAgreement`}
                target="_blank"
                trackName="KOHO Terms of Use"
                external={true}
              >
                {intl.formatMessage({
                  id: 'HisaAgreementPage.TermsAndConditions',
                  defaultMessage: 'terms and conditions here',
                })}
              </LinkTw>
            ),
          },
        )}
      </Paragraph>
      <Paragraph>
        {intl.formatMessage({
          id: 'HisaAgreementPage.SaveAgreementAssumptions',
          defaultMessage:
            'By clicking “I Agree and Continue”, you confirm that you meet the following eligibility conditions:',
        })}
      </Paragraph>
      <List className="pl-4 pb-12 list-disc">
        {Assumptions.map((assumption) => (
          <BulletItem key={assumption.id}>
            {intl.formatMessage(assumption)}
          </BulletItem>
        ))}
      </List>

      <ButtonTw
        className="mb-3"
        disabled={createRequestStatus.isLoading || displayError}
        trackName="HISA Agreement Agree"
        onClick={() => createAccountOnClick()}
      >
        {intl.formatMessage({
          id: 'HisaAgreementPage.Agree',
          defaultMessage: 'I agree & finish',
        })}
      </ButtonTw>

      {displayError && (
        <Paragraph className="text-danger-300 text-center mt-3">
          {intl.formatMessage({
            id: 'HisaAgreementPage.RequestError',
            defaultMessage:
              'Something went wrong. Please reach out to support to continue.',
          })}
        </Paragraph>
      )}

      {displayError && (
        <ButtonTw
          className="mt-3"
          variant="outline"
          trackName="HISA Agreement Support"
          onClick={() => dispatch(contactActions.intercomOpen())}
        >
          {intl.formatMessage({
            id: 'HisaAgreementPage.SupportButton',
            defaultMessage: 'Reach out to support',
          })}
        </ButtonTw>
      )}
    </TemplateTw>
  );
};
