import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Box, Typography, useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import * as Sentry from '@sentry/react';
import { useGetPersonalizedOnboardingQuery } from 'apis/personalizedOnboardingApi';
import { useGetCurrentTierQuery, useGetNbaEligibilityQuery } from 'apis/tiers';
import { BrazeHomeCards } from 'braze/BrazeHomeCards';
import { BrazeFeatureFlags } from 'braze/models/BrazeFeatureFlags';
import { InlineError } from 'components/InlineError/InlineError';
import { TitleMedium } from 'components/TypographyTw';
import { M1ContestBanner } from 'contest/components/M1ContestBanner';
import { useM1ContestStatus } from 'contest/hooks/useContestStatus';
import DownloadApp from 'features/modals/DownloadApp';
import { useToggle } from 'hooks/useToggle';
import { VerifyIdentityWidget } from 'kyc/components/VerifyIdentityWidget';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PersonalizedOnboardingWidget } from 'personalizedOnboarding/components/PersonalizedOnboardingWidget';
import { selectProfileVerified } from 'profile/store/selectors';
import { ManagePlanWidget } from 'tiers/components/ManagePlanWidget';
import UpgradePlanWidget from 'tiers/components/UpgradePlanWidget';
import { TiersFeatureFlags } from 'tiers/models/TiersFeatureFlags';
import NbaGiftCardWidget from 'tiers/nba-incentives/NbaGiftCardWidget';
import { selectIsPaidTier } from 'tiers/store/selectors';
import { selectTransactionsLoading } from 'transactions/store/selectors';

import { CashOut } from '../../components';
import { desktop } from '../../theme';
import { DownloadAppWidget } from './DownloadAppWidget';
import { VirtualCardWithBalance } from './VirtualCardWithBalance';

export const TransactionsSidebar = (
  props: Readonly<{ showBalance: Function }>,
) => {
  const isDesktop = useMediaQuery(desktop);

  const isNbaIncentivesEnabled = useFlags()['web-nba-incentive-enabled'];
  const isTiersSelectionEnabled =
    useFlags()[TiersFeatureFlags.EnableNonMemberTiersSelection];

  const isTransactionsLoading = useSelector(selectTransactionsLoading);

  const userVerified = useSelector(selectProfileVerified);

  const skipNbaIncentives = isNbaIncentivesEnabled ? undefined : true;
  const { data: nbaData } = useGetNbaEligibilityQuery(isNbaIncentivesEnabled, {
    skip: skipNbaIncentives,
  });
  const isNbaEligible = nbaData?.is_eligible;

  const [showM1Contest] = useM1ContestStatus();

  const {
    data: benefitData,
    isLoading: isTiersLoading,
    isUninitialized: isTiersUninitialized,
  } = useGetCurrentTierQuery();
  const isPaidTier = useSelector(selectIsPaidTier);

  const isBrazeHomeCardsEnabled =
    useFlags()[BrazeFeatureFlags.HomeCardsEnabled];

  const {
    value: isDownloadAppModalOpen,
    on: openDownloadAppModal,
    off: closeDownloadAppModal,
  } = useToggle();

  if (isTransactionsLoading) {
    return (
      <Box py={2}>
        <Skeleton variant="rect" height={60} className="mb-4" />
        <Skeleton variant="rect" height={180} className="mb-4" />
        <Skeleton variant="rect" height={100} />
      </Box>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {!isDesktop && (
        <Box className="mt-6 light:bg-white light:p-6 light:rounded-xl">
          <VirtualCardWithBalance showBalance={props.showBalance} />
        </Box>
      )}
      <Box className="flex flex-col gap-4">
        {showM1Contest && <M1ContestBanner onClick={openDownloadAppModal} />}

        <CallToActionWidget />

        {isBrazeHomeCardsEnabled && <BrazeHomeCards />}

        {!userVerified && (
          <Box className="mb-6">
            <VerifyIdentityWidget />
          </Box>
        )}

        <Box className="mb-6">
          {isTiersLoading || isTiersUninitialized ? (
            <Skeleton variant="rect" height={150} />
          ) : isPaidTier ? (
            <>
              {benefitData && <ManagePlanWidget benefit={benefitData} />}

              {isNbaEligible && (
                <Box className="mb-6">
                  <NbaGiftCardWidget nbaData={nbaData} />
                </Box>
              )}

              <DownloadAppWidget />
            </>
          ) : (
            <>
              {isNbaEligible && (
                <Box className="mb-6">
                  <NbaGiftCardWidget nbaData={nbaData} />
                </Box>
              )}
              {(!isTiersSelectionEnabled || userVerified) && (
                <UpgradePlanWidget />
              )}
            </>
          )}
        </Box>
      </Box>

      <DownloadApp
        open={isDownloadAppModalOpen}
        onClose={closeDownloadAppModal}
      />
    </div>
  );
};

const CallToActionWidget = () => {
  const intl = useIntl();
  const {
    data: personalizedOnboardingData,
    isLoading: isPersonalizedOnboardingLoading,
  } = useGetPersonalizedOnboardingQuery();

  const showPersonalizedOnboarding =
    !!personalizedOnboardingData && !personalizedOnboardingData.hidden;

  if (isPersonalizedOnboardingLoading)
    return <Skeleton variant="rect" height={60} className="mb-4" />;

  if (showPersonalizedOnboarding)
    return (
      <PersonalizedOnboardingWidget
        personalizedOnboardingData={personalizedOnboardingData}
      />
    );

  return (
    <div className="gap-4 flex flex-col">
      <div>
        <TitleMedium className="my-0">
          {intl.formatMessage({
            id: 'TransactionPage.PowerUps',
            defaultMessage: 'Cash back',
          })}
        </TitleMedium>
        <Typography className="text-base">
          {intl.formatMessage({
            id: 'TransactionPage.InstantCashBack',
            defaultMessage: 'Instant cash back on every purchase',
          })}
        </Typography>
      </div>
      <Sentry.ErrorBoundary fallback={<InlineError />}>
        <CashOut />
      </Sentry.ErrorBoundary>
    </div>
  );
};
