import { MouseEventHandler, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { RouteComponentProps, navigate, useParams } from '@reach/router';
import { track } from 'analytics/analytics';
import appStoreEn from 'assets/images/app-download/apple-en.svg';
import appStoreFr from 'assets/images/app-download/apple-fr.svg';
import googlePlayEn from 'assets/images/app-download/google-en.png';
import googlePlayFr from 'assets/images/app-download/google-fr.png';
import interac from 'assets/images/logos/interac-logo.png';
import { Card, LOCALE_EN, LayoutBasic, LocaleContext, UlTw } from 'components';
import { BoxTw } from 'components/BoxTw';
import ButtonUnstyled from 'components/ButtonUnstyledTw';
import CircularProgress from 'components/CircularProgress';
import { LinkTw } from 'components/LinkTw';
import { Paragraph, TitleLarge, TitleSmall } from 'components/TypographyTw';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getMobileOperatingSystem } from 'utility/getMobileOperatingSystem';

export const SelectPlatformPage = (props: RouteComponentProps) => {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const isInteracEnabled =
    useFlags()['web.enable-inbound-etransfer-platform-select'];

  const deepLinkUrl = useFlags()['web.inbound-etransfer-link-configuration'];
  const { transferId } = useParams();

  const os = getMobileOperatingSystem();
  const isMobile = os !== 'unknown';

  const mobileDeepLink = deepLinkUrl + transferId;

  let appDownloadLinks;
  if (os === 'ios') {
    appDownloadLinks = (
      <LinkTw
        trackName="IdentityVerification Apple Download"
        external
        to="https://apps.apple.com/ca/app/koho-financial/id1091010942"
        data-cy="btn-appleDownload"
        aria-label={intl.formatMessage({
          id: 'IdentityVerificationLandingPage.DownloadFromApple',
          defaultMessage: 'Download KOHO from the Apple app store',
        })}
      >
        <img
          src={locale === LOCALE_EN ? appStoreEn : appStoreFr}
          alt={intl.formatMessage({
            id: 'IdentityVerificationLandingPage.DownloadFromApple',
            defaultMessage: 'Download KOHO from the Apple app store',
          })}
          className="w-[110px] lg:w-[150px] mb-2 block"
        />
      </LinkTw>
    );
  } else if (os === 'android') {
    appDownloadLinks = (
      <LinkTw
        trackName="IdentityVerification Google Play Download"
        external
        to="https://play.google.com/store/apps/details?id=ca.koho"
        data-cy="btn-googleDownload"
        aria-label={intl.formatMessage({
          id: 'IdentityVerificationLandingPage.DownloadFromGoogle',
          defaultMessage: 'Download KOHO from Google Play',
        })}
      >
        <img
          src={locale === LOCALE_EN ? googlePlayEn : googlePlayFr}
          alt={intl.formatMessage({
            id: 'IdentityVerificationLandingPage.DownloadFromGoogle',
            defaultMessage: 'Download KOHO from Google Play',
          })}
          className="w-[110px] lg:w-[150px] mb-2 block"
        />
      </LinkTw>
    );
  }

  useEffect(() => {
    if ((!isInteracEnabled && isInteracEnabled !== undefined) || !isMobile) {
      navigate(`/e-transfer/deposit/${transferId}`);
    }
  }, [isInteracEnabled, transferId, isMobile]);

  const handleMobileClicked:
    | MouseEventHandler<HTMLAnchorElement>
    | undefined = () => {
    track({
      event: `Clicked`,
      properties: {
        element: 'button',
        type: 'button',
        name: ' e-Transfer Select Platform Mobile Button',
      },
    });
  };

  const listItems = (
    <UlTw className="my-4">
      <li className="border-grey-200 border h-[60px] rounded-lg flex justify-between items-center mt-2 w-full">
        <TitleSmall className="m-3 block w-full">
          {intl.formatMessage(
            {
              id: 'InboundEtransfer.SelectDepositPlatform.Mobile',
              defaultMessage: '<mobileLink>Mobile app</mobileLink>',
            },
            {
              mobileLink: (link) => (
                <a
                  href={mobileDeepLink}
                  onClick={handleMobileClicked}
                  className="no-underline text-grey-400 block w-full"
                >
                  {link}
                </a>
              ),
            },
          )}
        </TitleSmall>
      </li>
      <li className="border-grey-200 border h-[60px] rounded-lg flex justify-between items-center mt-2 w-full">
        <ButtonUnstyled
          className="w-full"
          onClick={() => {
            navigate(`/e-transfer/deposit/${transferId}`);
          }}
          trackName="e-Transfer Select Platform Web Button"
        >
          <TitleSmall className="m-3">
            {intl.formatMessage({
              id: 'InboundEtransfer.SelectDepositPlatform.Web',
              defaultMessage: 'Web browser',
            })}
          </TitleSmall>
        </ButtonUnstyled>
      </li>
    </UlTw>
  );

  return (
    <LayoutBasic
      pageName="Interac e-Transfer Select Platform"
      includeLanguageToggle={true}
    >
      <Card className="p-0 mb-16">
        <>
          <BoxTw className="flex rounded py-4 px-6 items-center bg-primary-50">
            <img
              className="w-7 h-7 mr-2"
              src={interac}
              alt={intl.formatMessage({
                id: 'InboundETransferPage.InteractETransferLogoAlt',
                defaultMessage: 'Interac e-Transfer Logo',
              })}
            />
            <Paragraph className="my-0">
              {intl.formatMessage({
                id: 'InboundEtransfer.SelectDepositPlatform.InteracTitle',
                defaultMessage: 'INTERAC e-Transfer®',
              })}
            </Paragraph>
          </BoxTw>
          <BoxTw className="p-6">
            <TitleLarge>
              {intl.formatMessage({
                id: 'InboundEtransfer.SelectDepositPlatform.Title',
                defaultMessage:
                  'How would you like to deposit your e-Transfer?',
              })}
            </TitleLarge>
            {!!deepLinkUrl && !!transferId ? (
              listItems
            ) : (
              <BoxTw className="flex justify-center">
                <CircularProgress />
              </BoxTw>
            )}
            {isMobile ? (
              <BoxTw className="flex flex-col flex-[1_0_50%] items-center pl-0 lg:pl-9">
                <Paragraph>
                  {intl.formatMessage({
                    id: 'InboundEtransfer.SelectDepositPlatform.AppCTA',
                    defaultMessage: `Don't have the app? Get it here: `,
                  })}
                </Paragraph>
                {appDownloadLinks}
              </BoxTw>
            ) : null}
          </BoxTw>
        </>
      </Card>
    </LayoutBasic>
  );
};
