import { useState } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { isXTMAffiliate, useGetAffiliatesQuery } from 'apis/affiliates';
import { ReferralRequest } from 'apis/registration';
import type { THEMES } from 'appState/appState.slice';
import { selectTheme } from 'appState/appState.slice';
import clsx from 'clsx';
import { SecurityAssurance } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import LoadingCentered from 'components/Loading/Centered';
import { ErrorParagraph, Paragraph, TitleLarge } from 'components/TypographyTw';
import { useValidationMsg } from 'components/forms/useValidationMsg';
import { KhInputMobileNumber } from 'components/inputs/KhInputMobileNumber';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RegistrationReferralCodeInput } from 'registration/components/RegistrationReferralCodeInput';
import { RegistrationFeatureFlags } from 'registration/models/RegistrationFeatureFlags';
import { MobileNumberSchema } from 'registration/zodForms/zodFormRegistrationMobileNumber';
import { twMerge } from 'tailwind-merge';
import {
  useTrackElementViewedOnce,
  useTrackPageViewedOnceDeprecated,
} from 'utility/analyticsHooks';

import referral from '../../assets/images/referral.png';

export const RegistrationMobileNumberPage = ({
  mobileNumberForm,
  submitForm,
  onReferralRequestUpdated,
  createUserLoading,
  referralCodePreSet,
  createUserError,
}: {
  submitForm: SubmitHandler<MobileNumberSchema>;
  mobileNumberForm: UseFormReturn<MobileNumberSchema, any>;
  onReferralRequestUpdated: (referralRequest: ReferralRequest | null) => void;
  createUserLoading: boolean;
  referralCodePreSet?: string;
  createUserError?: string;
}) => {
  const intl = useIntl();
  const {
    register,
    formState: { errors, isValid },

    getValues,
  } = mobileNumberForm;
  const [referralCodeValid, setReferralCodeValid] = useState(true);
  const themeName = useSelector<string, THEMES>(selectTheme);
  const { data: affiliatesData, isLoading: isAffiliatesDataLoading } =
    useGetAffiliatesQuery();
  const isAffiliateRegistrationWithAffiliateUserIdentifierEnabled =
    useFlags()[
      RegistrationFeatureFlags
        .EnableAffiliateRegistrationWithAffiliateUserIdentifier
    ];

  useTrackPageViewedOnceDeprecated({
    name: 'registration-mobile-number',
    overrideUrl: `registration/mobile-number`,
  });

  useTrackElementViewedOnce({
    element: errors?.mobileNumber,
    name: 'mobile-number-input-error',
  });

  const isLegacyTheme = themeName === 'legacy';

  const showReferralCodeInput =
    !isAffiliateRegistrationWithAffiliateUserIdentifierEnabled ||
    !isXTMAffiliate(affiliatesData?.affiliates);

  return (
    <>
      <TitleLarge
        className="legacy:mb-4 legacy:md:mb-8 light:mb-2"
        data-cy="mobile-number-title"
      >
        {intl.formatMessage({
          id: 'Registration.MobileNumberPage.Title',
          defaultMessage: "What's your mobile number?",
        })}
      </TitleLarge>
      <Paragraph className="legacy:mb-4 legacy:md:mb-8 light:mb-6">
        {intl.formatMessage({
          id: 'Registration.MobileNumberPage.Subtitle',
          defaultMessage: 'We’ll use this to keep your account secure.',
        })}
      </Paragraph>
      <KhInputMobileNumber
        {...register('mobileNumber')}
        label={intl.formatMessage({
          id: 'Registration.MobileNumberPage.MobileNumberLabel',
          defaultMessage: 'Mobile number',
        })}
        trackName="mobile-number"
        error={!!errors.mobileNumber}
        helperText={useValidationMsg(errors.mobileNumber?.message)}
        className="mb-4 light:mb-6"
        data-cy="mobile-input"
      />
      {isAffiliateRegistrationWithAffiliateUserIdentifierEnabled &&
      isAffiliatesDataLoading ? (
        <LoadingCentered />
      ) : (
        showReferralCodeInput && (
          <div
            className={twMerge(
              clsx(
                'bg-grey-75 mb-4 sm:mb-6 pb-6 sm:pb-8 px-4 rounded w-full',
                'light:sm:mb-12 light:p-4 light:bg-primary-50 light:rounded-xl',
                referralCodePreSet?.includes('_HIDDEN') && '!hidden',
              ),
            )}
          >
            <div className="flex pb-0 items-center justify-between">
              <Paragraph className="legacy:font-bold light:font-medium light:text-grey-500">
                {intl.formatMessage({
                  id: 'Registration.MobileNumberPage.ReferralCodeTitle',
                  defaultMessage: 'Have a referral code?',
                })}
              </Paragraph>
              {isLegacyTheme && (
                <img
                  alt={`${intl.formatMessage({
                    id: 'Registration.MobileNumberPage.ReferralAltTag',
                    defaultMessage: 'Refer code',
                  })}`}
                  width="120"
                  height="65"
                  src={referral}
                />
              )}
            </div>
            <RegistrationReferralCodeInput
              onValidChanged={setReferralCodeValid}
              onReferralRequestUpdated={onReferralRequestUpdated}
              referralCodePreSet={referralCodePreSet}
            />
          </div>
        )
      )}

      <ButtonTw
        onClick={() => submitForm(getValues())}
        trackName="move-mobile-number"
        disabled={!isValid || !referralCodeValid || createUserLoading}
        loading={createUserLoading}
        className="mb-6"
        data-cy="mobile-cta-button"
      >
        {intl.formatMessage({
          id: 'Registration.MobileNumberPage.CTA',
          defaultMessage: 'Next',
        })}
      </ButtonTw>

      {createUserError && (
        <ErrorParagraph className="mb-6 text-center">
          {createUserError}
        </ErrorParagraph>
      )}
      <SecurityAssurance />
    </>
  );
};
