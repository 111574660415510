import { useIntl } from 'react-intl';

// import { KDSIcons } from 'assets/images/kds_icons';
import { Modal } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { LinkTw } from 'components/LinkTw';
import { Paragraph } from 'components/TypographyTw';

export const RegistrationDuplicateUserModal = ({
  isOpen,
  onModalClosed,
}: {
  isOpen: boolean;
  onModalClosed: () => void;
}) => {
  const intl = useIntl();

  return (
    <Modal
      open={isOpen}
      onClose={onModalClosed}
      absolutePositioning={false}
      title={intl.formatMessage({
        id: 'RegistrationDuplicateUserModal.Title',
        defaultMessage: 'Have we met?',
      })}
    >
      <div className="px-1 md:px-3">
        {/* TODO: replace asset
        <KDSIcons.Spot.Contacts />
        */}
        <Paragraph className="light:mb-4">
          {intl.formatMessage({
            id: 'RegistrationDuplicateUserModal.Description',
            defaultMessage:
              'Looks like you’ve signed-up for KOHO before. Please login to continue.',
          })}
        </Paragraph>
        <LinkTw trackName="login" to="/login">
          <ButtonTw onClick={onModalClosed}>
            {intl.formatMessage({
              id: 'RegistrationDuplicateUserModal.Login',
              defaultMessage: 'Login',
            })}
          </ButtonTw>
        </LinkTw>
      </div>
    </Modal>
  );
};
