import { ButtonHTMLAttributes, MouseEventHandler } from 'react';

import { Link, LinkProps as ReachLinkProps } from '@reach/router';
import { trackInput } from 'analytics/analytics';
import clsx from 'clsx';
import CircularProgress from 'components/CircularProgress';
import { twMerge } from 'tailwind-merge';

type ButtonVariant =
  | 'cancel'
  | 'clear'
  | 'clearInverted'
  | 'coral'
  | 'ghost'
  | 'outline'
  | 'primary'
  | 'primaryInverted'
  | 'secondary'
  | 'secondaryInverted'
  | 'tertiary';

interface BaseProps {
  variant?: ButtonVariant;
  trackName?: string;
  trackMetadata?: Record<string, string | number | boolean>;
}

interface ButtonProps
  extends BaseProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

interface ButtonLinkProps extends BaseProps, ReachLinkProps<any> {
  to: string;
}

const InnerWrap = ({ children }) => {
  // span wrapper is to account for Safari bug in v14 that causes premature text wrapping
  return <span className="w-full relative">{children}</span>;
};

const buttonTypes = {
  cancel: clsx('bg-grey-75', 'text-grey-300'),
  clear: clsx('bg-transparent', 'border-none', 'p-2', 'text-primary-300'),
  clearInverted: clsx(
    'bg-transparent border border-secondary-75 p-2 light:text-secondary-75',
    'focus:outline-none focus:shadow-[0_0_0_2px] focus:shadow-blue-500/50',
    'hover:enabled:bg-secondary-100 light:hover:enabled:text-primary-500',
    'legacy:border-none legacy:text-white legacy:hover:enabled:bg-primary-400',
  ),
  coral: clsx(
    'bg-secondary-300',
    'disabled:opacity-50',
    'focus:opacity-100',
    'hover:enabled:opacity-100',
    'text-white',
  ),
  ghost: clsx(
    'disabled:bg-transparent disabled:text-primary-75 disabled:opacity-100',
    'focus:outline-none focus:shadow-[0_0_0_2px] focus:shadow-blue-500/50',
    'hover:enabled:text-primary-400',
    'bg-transparent border-none text-primary-300',
    'light:disabled:bg-transparent',
  ),
  outline: clsx(
    'bg-transparent',
    'border border-solid border-primary-300',
    'hover:bg-primary-400 hover:text-white',
    'text-primary-300',
  ),
  primary: clsx(
    'bg-primary-300',
    'disabled:bg-primary-100 light:disabled:text-grey-400',
    'focus:outline-border-focus outline-offset-2',
    'hover:bg-primary-400',
    'text-white',
  ),
  primaryInverted: clsx(
    'bg-white',
    'disabled:bg-primary-75',
    'focus:outline-none focus:shadow-[0_0_0_2px] focus:shadow-blue-500/50',
    'hover:enabled:text-white hover:enabled:bg-primary-400',
    'text-primary-300',
  ),
  secondary: clsx(
    'bg-white',
    'border border-primary-300 border-solid',
    'focus:bg-color-300 focus:text-primary-300',
    'hover:enabled:text-white hover:enabled:bg-primary-300',
    'text-primary-300',
  ),
  // only used in light theme
  secondaryInverted: clsx(
    'bg-secondary-75 text-primary-500',
    'hover:enabled:bg-secondary-100',
  ),
  tertiary: clsx(
    'bg-transparent',
    'border-none',
    'disabled:bg-transparent disabled:text-primary-75 disabled:opacity-100',
    'focus:outline-border-focus outline-offset-4',
    'hover:enabled:text-primary-400',
    'text-primary-300',
  ),
};

const getClassNames = (variant: ButtonVariant = 'primary') => {
  return twMerge(
    clsx(
      'disabled:saturate-50 disabled:opacity-40 disabled:cursor-not-allowed light:disabled:bg-grey-200',
      'font-black',
      'p-3 border-0 legacy:rounded light:rounded-full',
      'text-base text-center',
      'transition duration-33',
      'w-full',
      buttonTypes[variant],
    ),
  );
};

export const ButtonTw = ({
  children,
  type = 'submit',
  variant = 'primary',
  onClick,
  trackName,
  trackMetadata,
  loading,
  className = '',
  ...props
}: ButtonProps) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> | undefined = (
    event,
  ) => {
    if (trackName) {
      trackInput({
        type: 'Clicked',
        name: trackName,
        details: { type: type, element: 'button', ...trackMetadata },
      });
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      type={type}
      className={twMerge(getClassNames(variant), className)}
      onClick={handleClick}
      {...props}
    >
      <InnerWrap>
        {loading ? (
          <div className="relative">
            {/* Adding non breaking space to make sure the button has
                the height of regular text in it while loading */}
            &nbsp;
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <CircularProgress variant="small" className="text-white" />
            </div>
          </div>
        ) : (
          children
        )}
      </InnerWrap>
    </button>
  );
};

// TODO: Sunset the usage of ButtonLink in favor of Link (text)
// see: https://a11y-101.com/design/button-vs-link
export const ButtonLink = ({
  children,
  className,
  variant = 'primary',
  onClick,
  trackName,
  trackMetadata,
  ...props
}: ButtonLinkProps) => {
  const handleClick: MouseEventHandler<any> | undefined = (event) => {
    if (trackName) {
      trackInput({
        type: 'Clicked',
        name: trackName,
        details: { element: 'a', to: props.to, ...trackMetadata },
      });
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Link
      className={twMerge(getClassNames(variant), className)}
      onClick={handleClick}
      {...(props as any)}
    >
      <InnerWrap>{children}</InnerWrap>
    </Link>
  );
};

interface ButtonLinkExternalProps
  extends BaseProps,
    React.ComponentPropsWithoutRef<'a'> {}
export const ButtonLinkExternal = ({
  className,
  children,
  variant = 'primary',
  onClick,
  trackName,
  trackMetadata,
  ...props
}: ButtonLinkExternalProps) => {
  const handleClick: MouseEventHandler<HTMLAnchorElement> | undefined = (
    event,
  ) => {
    if (trackName) {
      trackInput({
        type: 'Clicked',
        name: trackName,
        details: { element: 'a', to: props.href || '', ...trackMetadata },
      });
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <a
      className={twMerge(
        'inline-flex w-full',
        getClassNames(variant),
        className,
      )}
      onClick={handleClick}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      <InnerWrap>{children}</InnerWrap>
    </a>
  );
};
