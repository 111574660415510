/** @jsxImportSource theme-ui */

/* eslint-disable react-hooks/exhaustive-deps*/
import {
  ChangeEvent,
  FocusEvent,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import clsx from 'clsx';
import CircularProgress from 'components/CircularProgress';
import { twMerge } from 'tailwind-merge';

import { track } from '../../analytics/analytics';
import { Money } from '../../models/Money';
import { LocaleContext } from '../I18NContextProvider';
import { TextInputProps } from './TextInput';

export const TextInputPaymentTw = ({
  name,
  onChange = () => {},
  onBlur = () => {},
  placeholder,
  label,
  fullWidth = true,
  type,
  validators,
  asyncValidators,
  onValidChange = () => {},
  controlledValue = '',
  autoComplete = 'off',
  inputProps,
  InputProps,
  helperText,
  className,
  ...props
}: TextInputProps) => {
  const [value, setValue] = useState<string>('');
  const [errors, setErrors] = useState<Array<string | null>>([]);
  const [asyncErrors, setAsyncErrors] = useState<Array<string | null>>([]);
  const [allErrors, setAllErrors] = useState<Array<string | null>>([]);
  const [showError, setShowError] = useState<Array<string | null>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const context = useContext(LocaleContext);
  const [touched, setTouched] = useState(
    !!controlledValue &&
      controlledValue !== context.intlFormatMoney('').format(),
  );

  const validate = (inputValue: Money) => {
    if (validators && validators.length) {
      const newErrors = validators
        .map((validator) => validator(inputValue.toNumber()))
        .map((descriptor) => {
          if (!descriptor) return null;
          return typeof descriptor === 'string'
            ? descriptor
            : intl.formatMessage(descriptor);
        })
        .filter(Boolean);

      setErrors(newErrors);
    }
  };

  // consider using useCallback to define this function so it doesn't redeclare
  const asyncValidate = (inputValue: Money) => {
    if (asyncValidators && asyncValidators.length) {
      asyncValidators.forEach((validator, index) => {
        onValidChange(false);
        setIsLoading(true);
        validator(inputValue.toNumber()).then((descriptor) => {
          setIsLoading(false);
          setAsyncErrors((previousErrors) => {
            previousErrors[index] = descriptor
              ? intl.formatMessage(descriptor)
              : null;
            return previousErrors.filter(Boolean);
          });
        });
      });
    }
  };

  useEffect(() => {
    setValue(controlledValue);
  }, [controlledValue]);

  useEffect(() => {
    validate(new Money(value));

    // debounce changes
    if (asyncValidators) {
      const handler = setTimeout(() => {
        asyncValidate(new Money(value));
      }, 200);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);

  useEffect(() => {
    setAllErrors([...errors, ...asyncErrors]);
    if (touched) {
      setShowError([...errors, ...asyncErrors]);
    }
    onValidChange(![...errors, ...asyncErrors].length);
  }, [errors, asyncErrors]);

  const change = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace('$', '');
    if (value === '0.00' || value === '0,00' || !value) {
      setValue('');
    } else {
      setValue(value);
      onChange(event);
      setTouched(true);
    }
  };

  const blur = (event: FocusEvent<HTMLInputElement>) => {
    track({ event: `${name} Blurred` });
    setTouched(true);
    onBlur(event);
  };

  const focus = (event: FocusEvent<HTMLInputElement>) => {
    track({ event: `${name} Focused` });
  };

  const onClick = () => {
    if (
      !value ||
      value === '$0.00' ||
      value === '0.00' ||
      value.slice(0, 4) === '0,00'
    ) {
      setValue('');
    }
  };

  const getInputProps = () => {
    if (InputProps) {
      return InputProps;
    }
  };

  const getHelperText = () => {
    if (helperText) {
      return helperText;
    } else {
      return touched ? allErrors[0] : null;
    }
  };

  //adds $ prefix if en and 0 before decimal if user inputs only cents
  const prefix = () => {
    if (context.locale === 'en') {
      if (value[0] === '.') return '$0';
      return '$';
    } else {
      if (value[0] === ',') return '0';
      return '';
    }
  };

  return (
    <div className={twMerge(clsx('relative'), className)}>
      {isLoading ? (
        <div className="absolute top-0 right-0 m-2">
          <CircularProgress className="text-primary-300" />
        </div>
      ) : null}
      <Fragment>
        <NumberFormat
          name={name}
          placeholder={context.locale === 'en' ? '$0.00' : '0,00$'}
          label={label}
          fullWidth={fullWidth}
          required={true}
          helperText={getHelperText()}
          error={!!(allErrors.length && touched)}
          onBlur={(event) => blur(event)}
          onChange={change}
          onFocus={focus}
          InputProps={getInputProps()}
          inputProps={inputProps}
          value={value}
          displayType={'input'}
          thousandSeparator={false}
          prefix={prefix()}
          suffix={context.locale === 'en' ? '' : '$'}
          decimalSeparator={context.locale === 'en' ? '.' : ','}
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
          onClick={onClick}
          className="bg-grey-75 py-4 pl-2 border-none rounded w-full mb-2 font-number font-semibold text-3xl text-primary-300"
        />
        <div className="text-danger-300">
          {/* We leave a nonbreaking space so the page doesn't reflow when the error displays */}
          {(allErrors && touched && showError[0]) || '\u00A0'}
        </div>
      </Fragment>
    </div>
  );
};
