import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Modal } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { Paragraph } from 'components/TypographyTw';

import { track } from '../../analytics/analytics';
import { selectAuthIsForcedLogout } from '../store/selectors';

export const ReLoginModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isForcedLogout = useSelector(selectAuthIsForcedLogout);

  const intl = useIntl();

  useEffect(() => {
    if (isForcedLogout) {
      track({
        event: 'Viewed',
        properties: { name: 'Relogin Modal Logout Forced' },
      });

      setIsModalOpen(true);
    }
  }, [isForcedLogout]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      maxWidth="xs"
      title={intl.formatMessage({
        id: 'ReloginModal.Title',
        defaultMessage: 'We logged you out due to inactivity.',
      })}
    >
      <Paragraph>
        {intl.formatMessage({
          id: 'ReloginModal.Content',
          defaultMessage:
            'For security purposes, you’ve been logged out because you were inactive. Log back in to continue using KOHO.',
        })}
      </Paragraph>
      <ButtonTw
        onClick={handleClose}
        trackName="Relogin Modal Logout Forced Acknowledged"
      >
        {intl.formatMessage({
          id: 'Global.Button.OkayGotIt',
          defaultMessage: 'Okay, got it',
        })}
      </ButtonTw>
    </Modal>
  );
};
