import { HttpResponse, bypass, http, passthrough } from 'msw';

const registrationTiersHandlers = [
  http.get(/\/benefits\/benefit-groups/, () => {
    return passthrough();

    // HttpResponse.json(tiersJsonData, { status: 200 });
  }),
  http.get(/\/benefits\/subscriptions\/current/, async ({ request }) => {
    const originalResponse = await fetch(bypass(request));
    const originalResponseData = await originalResponse.json();

    return HttpResponse.json(
      {
        ...originalResponseData,
        benefit_group: {
          ...originalResponseData.benefit_group,
          name: 'Everything',
        },
      },
      { status: 200 },
    );
  }),
];

export default registrationTiersHandlers;
