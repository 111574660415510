import { createSelector } from '@reduxjs/toolkit';
import { authApi } from 'apis/auth';
import { RootState } from 'store';

export const selectLoginResult = authApi.endpoints.login.select({
  requestId: 'login',
  fixedCacheKey: 'shared-login',
});

export const selectLoginInitialKycSkipped = createSelector(
  selectLoginResult,
  (loginResponse) => loginResponse.data?.profile?.initial_kyc_skipped,
);

export const selectLoginCanDoKyc = createSelector(
  selectLoginResult,
  (loginResponse) =>
    loginResponse.data?.profile?.cleared ||
    loginResponse.data?.profile?.can_retry_kyc,
);

export const selectLoginTelLast4Digits = createSelector(
  selectLoginResult,
  (loginResponse) => loginResponse.data?.masked_phone_number,
);

export const selectLoginState = (state: RootState) => state.login;

export const selectLoginOtpRememberDevice = createSelector(
  selectLoginState,
  (state) => state && state.rememberDevice,
);

export const selectLoginDestination = createSelector(
  selectLoginState,
  (state) => state?.destination,
);

export const selectLoginShowWelcomeBackModal = createSelector(
  selectLoginState,
  (state) => state?.showWelcomeBackModal,
);

export const selectLoginAffiliationURLParams = createSelector(
  selectLoginState,
  (state) => state?.affiliationURLParams,
);
