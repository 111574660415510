/** @jsxImportSource theme-ui */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { ButtonTw } from 'components/ButtonTw';

import { RecipientFormData } from '../../models/RecipientFormData';
import { TextInputEmail } from '../../sendMoney/components';
import { TextInputName } from '../../sendMoney/components/TextInputName';
import { TransferSecret } from './TransferSecret';

export const RecipientForm = ({
  onContinue,
}: {
  onContinue: (formData: RecipientFormData) => void;
}) => {
  const [formState, setFormState] = useState({
    name: {
      isValid: false,
      value: '',
    },
    email: {
      isValid: false,
      value: '',
    },
    secretQuestion: {
      isValid: false,
      value: '',
    },
    secretAnswer: {
      isValid: false,
      value: '',
    },
  });

  const intl = useIntl();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onContinue({
      name: formState.name.value,
      email: formState.email.value,
      secretQuestion: formState.secretQuestion.value,
      secretAnswer: formState.secretAnswer.value,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        value,
      },
    }));
  };

  const setFormStateValid = (fieldName: string, isValid: boolean) => {
    setFormState((prevState) => ({
      ...prevState,
      [fieldName]: {
        ...prevState[fieldName],
        isValid,
      },
    }));
  };

  const isFormValid =
    formState.name.isValid &&
    formState.email.isValid &&
    formState.secretAnswer.isValid &&
    formState.secretQuestion.isValid;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <TextInputName
        onChange={handleChange}
        onValidChange={(isValid) => setFormStateValid('name', isValid)}
        className="mb-6"
        controlledValue={formState.name.value}
      />
      <TextInputEmail
        onChange={handleChange}
        onValidChange={(isValid) => setFormStateValid('email', isValid)}
        className="mb-6"
        controlledValue={formState.email.value}
      />
      <TransferSecret
        onChange={handleChange}
        onValidChange={setFormStateValid}
        controlledValue={{
          question: formState.secretQuestion.value,
          answer: formState.secretAnswer.value,
        }}
      ></TransferSecret>
      <ButtonTw disabled={!isFormValid} type="submit">
        {intl.formatMessage({
          id: 'Global.Button.Continue',
          defaultMessage: 'Continue',
        })}
      </ButtonTw>
    </form>
  );
};
