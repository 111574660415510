import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Tooltip } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { selectTheme } from 'appState/appState.slice';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { BoxTw } from './BoxTw';
import ButtonUnstyled from './ButtonUnstyledTw';
import { Paragraph } from './TypographyTw';
import { CopyIconForCopier } from './ValueCopier';

type LineCopierProps = {
  label: string;
  value: string;
  className?: string;
  sx?: Record<string, string>;
};

export const LineCopier = ({
  label,
  value,
  className,
  ...props
}: Readonly<LineCopierProps>) => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';

  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <BoxTw
      className={twMerge(
        clsx(
          'flex items-center justify-between flex-row gap-4 mb-4 border-b-[1px_solid_rgba(0,0,0,0.12)]',
          className,
        ),
      )}
      {...props}
    >
      <BoxTw>
        <Paragraph className="my-0 font-bold">{label}</Paragraph>
      </BoxTw>
      <BoxTw className="flex items-center">
        <Paragraph className="my-0 text-grey-300">{value}</Paragraph>
        <ButtonUnstyled
          onClick={() => {
            setTooltipOpen(true);
            window.navigator.clipboard.writeText(value);
            setTimeout(() => setTooltipOpen(false), 2222);
          }}
        >
          <Tooltip
            PopperProps={{ disablePortal: true }}
            onClose={() => setTooltipOpen(false)}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={intl.formatMessage({
              id: 'ValueCopier.Copied',
              defaultMessage: 'Copied!',
            })}
            placement="top"
          >
            {isLight ? (
              <CopyIconForCopier className="ml-2 my-2" />
            ) : (
              <FileCopyOutlinedIcon className="cursor-pointer ml-2 my-2 text-grey-300" />
            )}
          </Tooltip>
        </ButtonUnstyled>
      </BoxTw>
    </BoxTw>
  );
};
