import { HttpResponse, http } from 'msw';

const registrationUserHandlers = [
  http.post(/\/users/, () => {
    // return HttpResponse.json({
    //   code: RegistrationUsersErrorCodes.OnboardingBlockedNameDOBLimit,
    //   name: 'Onboarding Blocked Name DOB Limit',
    // }, { status: 400 });
    return HttpResponse.json(
      {
        intercom_android_hmac:
          '8891d0f6acd8b9c60d163ff42d000cb198755f2cab8979e7f4310a9d261de3b4',
        intercom_ios_hmac:
          'bc8ad7446febe104134471ac282bcdfd6d2297e260ab5a8953bcc1fa31d9d9af',
        intercom_web_hmac:
          '0a7c127d3382cb064b09534a77ddde8b49ab7d1b33aa120bf7aed97c319bff5c',
        user: {
          id: 123456,
          reference_id: 'ABC123def',
          organization: 'koho',
          email: 'test+mocked@koho.ca',
          is_email_verified: false,
          etransfer_answer: null,
          terms_and_conditions_version: 1,
          password: null,
          access_code: null,
          initial_device_id: '4f045d5b-29ec-420e-9a45-c260df2b137c',
          initial_kyc_skipped: false,
          profile: {
            id: 123456,
            given_name: 'Test',
            additional_name: '',
            family_name: 'Test',
            preferred_given_name: 'Test',
            preferred_additional_name: '',
            preferred_family_name: 'Test',
            date_of_birth: '1999-02-02T00:00:00Z',
            telephone: '1672912127',
            address: {
              sub_building: '',
              building_number: '',
              building_name: '',
              secondary_street: '',
              street: '',
              block: '',
              neighbourhood: '',
              district: '',
              city: 'Hirtheberg',
              line1: '62524 Lyla Lake',
              line2: '',
              line3: '',
              line4: '',
              line5: '',
              province: 'Ontario',
              province_name: 'Ontario',
              province_code: 'ON',
              postal_code: 'C4A 4W7',
              country_name: 'Canada',
              country_iso2: 'CA',
              country_iso3: 'CAN',
              country_iso_number: 124,
              po_box_number: '30',
              created_at: '0001-01-01T00:00:00Z',
              is_temporary: null,
            },
            normalized_address: {
              sub_building: '',
              building_number: '',
              building_name: '',
              secondary_street: '',
              street: '',
              block: '',
              neighbourhood: '',
              district: '',
              city: 'Hirtheberg',
              line1: '62524 Lyla Lake',
              line2: '',
              line3: '',
              line4: '',
              line5: '',
              province: 'Ontario',
              province_name: 'Ontario',
              province_code: 'ON',
              postal_code: 'C4A 4W7',
              country_name: 'Canada',
              country_iso2: 'CA',
              country_iso3: 'CAN',
              country_iso_number: 124,
              po_box_number: '30',
              created_at: '0001-01-01T00:00:00Z',
              is_temporary: null,
            },
            shipping_address: null,
            occupation: {
              name: '',
              code: '',
            },
            is_third_party: false,
            is_foreign_tax_resident: false,
          },
          when_cleared: null,
          is_suspended: false,
          is_cancelled: false,
          flag_status: '',
          has_access_code: true,
          when_created: '2023-01-05T09:48:55.658498Z',
          language: '',
        },
      },
      { status: 200 },
    );
  }),
];

export default registrationUserHandlers;
