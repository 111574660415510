import { baseTheme } from './base';

const greyScale = {
  50: '#F8F8F9',
  75: '#E6E5E7',
  100: '#CCCBD0',
  200: '#ACAAB1',
  250: '#8C8A91',
  300: '#6B6970',
  350: '#545258',
  400: '#3F3D44',
  450: '#2C2A30',
  500: '#1B191D',
};

const greenScale = {
  50: '#EFFCF4',
  75: '#B9F4D1',
  100: '#4FE68C',
  200: '#1BC45F',
  300: '#179F4E',
  400: '#0E5E2E',
  500: '#0A4723',
  550: '#063419',
  600: '#031F0F',
};

export const lightTheme = {
  ...baseTheme,
  grey: greyScale,
  primary: {
    50: '#FAF7FF',
    75: '#EDDFFF',
    90: '#DCC1FF',
    100: '#C495FF',
    200: '#892CFF',
    300: '#6A22C9',
    350: '#4F1D98',
    400: '#36186B',
    500: '#1D123C',
  },
  secondary: {
    50: '#F3FFAD',
    75: '#D1F300',
    100: '#BBDA00',
    200: '#7F9400',
    300: '#627100',
    400: '#4B5700',
    500: '#282F00',
  },
  tertiary: greyScale,
  accent: {
    50: '#FFFAFF',
    75: '#FFDEFD',
    100: '#FAB6F7',
    200: '#E63FDF',
    300: '#BE00B7',
    400: '#73006F',
    500: '#540051',
  },
  // card: {
  //   babyBlue: '#B8D1DB',
  //   gold: '#B3A168',
  //   pink: '#F5B5C3',
  //   red: '#E43C2F',
  //   teal: '#00607F',
  //   yellow: '#F5EA47',
  // },
  danger: {
    50: '#FFFBF9',
    75: '#FFE1D7',
    100: '#FF8A65',
    200: '#FF470D',
    300: '#C63203',
    400: '#9C2601',
    500: '#771D00',
  },
  success: greenScale,
  warning: {
    50: '#FFF8E8',
    75: '#FFE1A5',
    100: '#FBC530',
    200: '#EF970B',
    300: '#D0730A',
    400: '#AC4F0A',
    500: '#672E0D',
  },
  icons: {
    container: greenScale[50],
    fill: greenScale[500],
  },
};
