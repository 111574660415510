import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as Sentry from '@sentry/browser';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { useCreateSetupIntentMutation } from 'apis/billing';
import { StatelessDrawerModal } from 'components/DrawerModal';
import LoadingCentered from 'components/Loading/Centered';

import { BillingMethodsAddCardForm } from './BillingMethodsAddCardForm';

// stripe publishable key
let stripePromise: Promise<Stripe | null>;

try {
  // fails in Storybook because we still use webpack and import.meta
  // is not supported in webpack
  stripePromise = loadStripe(import.meta.env.VITE_STRIPE_SDK_KEY, {
    stripeAccount: import.meta.env.VITE_STRIPE_BILLING_ACCOUNT_ID,
  }).catch((e) => {
    Sentry.captureException(
      new Error('Error initializing Stripe SDK', {
        cause: { error: e },
      }),
    );

    return Promise.resolve(null);
  });
} catch (e) {
  Sentry.captureException(
    new Error('Error initializing Stripe SDK', {
      cause: { error: e },
    }),
  );

  stripePromise = Promise.resolve(null);
}

export const BillingMethodsAddCardModal = ({
  cardAdded,
  ctaText,
  onClose,
  open,
}: {
  cardAdded: (billingMethodId) => void;
  ctaText?: string;
  onClose: () => void;
  open: boolean;
}) => {
  const intl = useIntl();

  const [createSetupIntent] = useCreateSetupIntentMutation();
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    if (open) {
      createSetupIntent()
        .unwrap()
        .then((res) => {
          setClientSecret(res.client_secret);
        });
    }
  }, [createSetupIntent, open]);

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#00579B',
        colorText: '#373E4D',
        colorTextSecondary: '#373E4D',
        fontFamily: 'Basis Grotesque Pro, system-ui, sans-serif',
      },
    },
  } as StripeElementsOptions;

  return (
    <StatelessDrawerModal
      isOpen={open}
      closeModal={onClose}
      modalProps={{ maxWidth: 'lg' }}
      title={intl.formatMessage({
        id: 'BillingMethodsAddCardModal.Title',
        defaultMessage: 'Add a credit/debit card',
      })}
      content={() => (
        <>
          {clientSecret ? (
            <Elements options={options} stripe={stripePromise}>
              <BillingMethodsAddCardForm
                cardAdded={(billingMethodId) => cardAdded(billingMethodId)}
                ctaText={ctaText}
              />
            </Elements>
          ) : (
            <div className="my-6">
              <LoadingCentered />
            </div>
          )}
        </>
      )}
    />
  );
};
