import { RegistrationIdentityErrorCodes } from 'apis/registration';
import { HttpResponse, http } from 'msw';

const registrationIdentitiesHandlers = [
  http.post(/\/identities/, () => {
    return HttpResponse.json(
      {
        errors: [
          {
            code: RegistrationIdentityErrorCodes.EmailDuplicate,
          },
        ],
      },
      { status: 400 },
    );
    // return HttpResponse.json(
    //   {
    //     existing_identity: 'false',
    //     session:
    //       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NzgwOTUyODIsImp0aSI6IjMyOTk3NmI5LTM2OTktNGJjNC1hMTNmLTMxYmVhZmJhNmIwMSIsImlhdCI6MTY3MjkxMTI4MiwiaXNzIjoiS09ITyIsInN1YiI6IjYxYzc5YmNiLTk2N2UtNGE1OC04NWFiLWJiYjQxYzNmZGRlNiIsIlVzZXJJRCI6Im5yUElicmM4IiwiT3JnYW5pemF0aW9uIjoia29obyIsIkdhdGV3YXkiOiJhcHAiLCJSb2xlIjoiZGVmYXVsdCIsIlBlcm1pc3Npb25zIjpbXSwiT25lVGltZSI6ZmFsc2V9.sE5aWgrNBGsR3yV0TGX94KH0edNuVC0ggoyebCUiLFw',
    //     user_identifier: 'ABC123def',
    //   },
    //   { status: 200 }
    // )
  }),
];

export default registrationIdentitiesHandlers;
