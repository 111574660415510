import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog } from '@material-ui/core';
import { navigate, useParams } from '@reach/router';
import { BoxTw } from 'components/BoxTw';
import CircularProgress from 'components/CircularProgress';
import { TitleMedium } from 'components/TypographyTw';
import { ConfirmDeclinePrompt } from 'inboundETransfer/components/confirmDeclinePrompt';
import { ErrorDialog } from 'inboundETransfer/components/errorAlert';
import {
  selectInboundETransfer,
  selectInboundETransferAcceptLoadable,
  selectInboundETransferAnswer,
  selectInboundETransferDeclineLoadable,
  selectInboundETransferRetrieveError,
  selectInboundETransferRetrieveLoadable,
  selectInboundETransferShowIncorrectAnswerError,
  selectInboundETransferSubmissionError,
  selectInboundETransferSubmissionIntent,
} from 'inboundETransfer/store/selectors';
import {
  InboundETransferActions,
  InboundETransferErrors,
  InboundETransferSubmissionIntent,
} from 'inboundETransfer/store/slice';
import { ContainerTw } from 'layout/ContainerTw';
import { TemplateTw } from 'layout/TemplateTw';
import { Loadable } from 'utility/loadable';

import { ReactComponent as TadaIcon } from '../../assets/images/transactions/tada.svg';
import LoadingCentered from '../../components/Loading/Centered';
import { InboundETransfer } from '../components/transfer';

enum InboundETransferIntent {
  'decline',
  'accept',
}

const RetrieveTransferLoading = ({ isLoading }: { isLoading: boolean }) => {
  return isLoading ? <LoadingCentered /> : null;
};

export const AwaitingRequest = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Dialog open={isOpen}>
      <BoxTw className="flex w-20 h-20 items-center justify-center">
        <CircularProgress />
      </BoxTw>
    </Dialog>
  );
};

export const TransferDeclined = () => {
  const intl = useIntl();
  return (
    <span data-cy="iet-decline-success">
      {intl.formatMessage({
        id: 'InboundETransfer.Success.DeclineHeading',
        defaultMessage: 'Transfer Declined',
      })}
    </span>
  );
};

export const TransferAccepted: React.FC = () => {
  const intl = useIntl();
  return (
    <span data-cy="iet-accept-success">
      {intl.formatMessage({
        id: 'InboundETransfer.Success.AcceptedHeading',
        defaultMessage: 'Transfer Accepted',
      })}
    </span>
  );
};

export const TransferAcceptDeclineSuccess = ({
  isOpen,
  intent,
}: {
  isOpen: boolean;
  intent: InboundETransferIntent | undefined;
}) => {
  return (
    <Dialog open={isOpen}>
      <BoxTw className="text-center p-4">
        <TadaIcon />

        <TitleMedium className="mt-0 mb-2">
          {intent === InboundETransferIntent.decline && <TransferDeclined />}
          {intent === InboundETransferIntent.accept && <TransferAccepted />}
        </TitleMedium>
      </BoxTw>
    </Dialog>
  );
};
export const InboundETransfersPage = () => {
  const { transferId } = useParams();

  const retrieveTransferLoadable: Loadable = useSelector(
    selectInboundETransferRetrieveLoadable,
  );
  const declineTransferLoadable: Loadable = useSelector(
    selectInboundETransferDeclineLoadable,
  );
  const acceptTransferLoadable: Loadable = useSelector(
    selectInboundETransferAcceptLoadable,
  );
  const transferDetails = useSelector(selectInboundETransfer);
  const formAnswer = useSelector(selectInboundETransferAnswer);
  const formShowIncorrectAnswerError = useSelector(
    selectInboundETransferShowIncorrectAnswerError,
  );
  const retrieveErrorType = useSelector(selectInboundETransferRetrieveError);
  const submissionError = useSelector(selectInboundETransferSubmissionError);
  const submissionIntent = useSelector(selectInboundETransferSubmissionIntent);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      InboundETransferActions.retrieveInboundETransferRequest({
        transferId,
      }),
    );
  }, [transferId, dispatch]);

  useEffect(() => {
    if (declineTransferLoadable.success || acceptTransferLoadable.success) {
      setAcceptDeclineSuccessOpen(true);

      const reset = setTimeout(() => {
        setAcceptDeclineSuccessOpen(false);
        navigate('/transactions');

        dispatch(InboundETransferActions.InboundETransferResetFeature());
      }, 1000);

      return () => {
        clearTimeout(reset);
      };
    }
  }, [declineTransferLoadable, acceptTransferLoadable, dispatch]);

  const [declinePromptOpen, setDeclinePromptOpen] = useState(false);
  const [acceptDeclineSuccessOpen, setAcceptDeclineSuccessOpen] =
    useState(false);

  const [intent, setIntent] = useState<undefined | InboundETransferIntent>();

  const handleRetrieveErrorAcknowledged = () => {
    // There is already a check before retrieving the transfer for KYC, so in theory a user should never get a kyc not cleared error.
    if (retrieveErrorType === InboundETransferErrors.RetrieveKycNotCleared) {
      navigate('/kyc/entry');
    } else {
      navigate('/transactions');
    }
  };

  const handleRetrieveErrorRetry = () => {
    dispatch(
      InboundETransferActions.retrieveInboundETransferRequest({
        transferId,
      }),
    );
  };

  const handleDeclinePromptClose = () => {
    setDeclinePromptOpen(false);
  };

  const handleDeclinePromptYes = () => {
    handleDeclinePromptClose();
    handleDecline();
  };

  const initiateHandleDecline = () => {
    setDeclinePromptOpen(true);
  };

  const handleDecline = () => {
    setIntent(InboundETransferIntent.decline);
    dispatch(
      InboundETransferActions.declineInboundETransferRequest({
        transferId,
        answer: formAnswer,
      }),
    );
  };

  const handleAccept = () => {
    setIntent(InboundETransferIntent.accept);
    dispatch(
      InboundETransferActions.acceptInboundETransferRequest({
        transferId,
        answer: formAnswer,
        // For security method, the options here are limited on web so hard coding.
        authenticationMethod: 'PASSWORD',
      }),
    );
  };

  const handleSubmissionErrorAcknowledged = () => {
    dispatch(
      InboundETransferActions.InboundETransferAcknowledgeSubmissionError(),
    );

    if (
      submissionError.error ===
      InboundETransferErrors.SubmissionTransferCanceled
    ) {
      navigate('/transactions');
    }
  };

  const handleSubmissionErrorRetry = () => {
    handleSubmissionErrorAcknowledged();

    switch (submissionIntent) {
      case InboundETransferSubmissionIntent.Accept:
        handleAccept();
        return;
      case InboundETransferSubmissionIntent.Decline:
        handleDecline();
        return;

      default:
        return;
    }
  };

  return (
    <TemplateTw name="Interac e-Transfer Deposit">
      <ContainerTw className="px-4 xl:px-0 py-0 w-full lg:w-[800px]">
        <RetrieveTransferLoading isLoading={retrieveTransferLoadable.loading} />
        {/* For retrieve transfer errors */}
        <ErrorDialog
          isOpen={retrieveTransferLoadable.error}
          errorType={retrieveErrorType}
          handleClose={handleRetrieveErrorAcknowledged}
          handleRetry={handleRetrieveErrorRetry}
        />
        {retrieveTransferLoadable.success && (
          <InboundETransfer
            fromName={transferDetails.fromName}
            amountCents={transferDetails.amountCadCents}
            question={transferDetails.securityQuestion}
            memo={transferDetails.memo}
            secretAnswerValue={formAnswer}
            showIncorrectAnswerError={formShowIncorrectAnswerError}
            declineHandler={initiateHandleDecline}
            declineLoadable={declineTransferLoadable}
            acceptHandler={handleAccept}
            acceptLoadable={acceptTransferLoadable}
          />
        )}
        <ConfirmDeclinePrompt
          isOpen={declinePromptOpen}
          handleNoAndClose={handleDeclinePromptClose}
          handleYesAndClose={handleDeclinePromptYes}
        />
        <AwaitingRequest
          isOpen={
            declineTransferLoadable.loading || acceptTransferLoadable.loading
          }
        />
        <TransferAcceptDeclineSuccess
          isOpen={acceptDeclineSuccessOpen}
          intent={intent}
        />
        {/* For accept/decline errors */}
        <ErrorDialog
          isOpen={submissionError.unAcknowledgedError}
          errorType={submissionError.error}
          handleClose={handleSubmissionErrorAcknowledged}
          handleRetry={handleSubmissionErrorRetry}
        />
      </ContainerTw>
    </TemplateTw>
  );
};
