import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { KDSIcons } from 'assets/images/kds_icons';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { ButtonTw } from './ButtonTw';
import { DrawerModal } from './DrawerModal';
import { Paragraph, TitleLarge } from './TypographyTw';

const Info = () => (
  <KDSIcons.Icons.QuestionBold className="legacy:text-tiers-blue-400 text-primary-350 ml-1 mb-[2px]" />
);

export type ModalProps = {
  content: ReactNode;
  icon?: ReactNode;
  title: string;
};

const ModalContent = ({
  content,
  icon,
  title,
  close,
}: ModalProps & { close: () => void }) => {
  const intl = useIntl();
  return (
    <>
      {icon}
      <TitleLarge
        className={twMerge(
          clsx(
            'mb-2 legacy:mt-4 mt-6 text-3xl legacy:text-grey-400 mr-9',
            icon && 'mt-0',
          ),
        )}
      >
        {title}
      </TitleLarge>
      <Paragraph className="block text-grey-400 [&_ul]:my-0 mb-4">
        {content}
      </Paragraph>
      <ButtonTw onClick={close}>
        {intl.formatMessage({
          id: 'InfoModal.Tooltip.Done',
          defaultMessage: 'Got it',
        })}
      </ButtonTw>
    </>
  );
};

export const InfoModal = (props: ModalProps) => {
  return (
    <DrawerModal
      content={({ closeModal }) => (
        <ModalContent {...props} close={closeModal} />
      )}
    >
      <Info />
    </DrawerModal>
  );
};
