import { useState } from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from '@material-ui/core';
import {
  ReferralReward,
  ReferralRewardsResponse,
  RewardTypes,
  useClaimRewardMutation,
} from 'apis/referralApi';
import { KDSIcons } from 'assets/images/kds_icons';
import clsx from 'clsx';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import { LinkTw } from 'components/LinkTw';
import { Paragraph, TitleLarge, TitleSmall } from 'components/TypographyTw';
import { KhChip } from 'components/kds/KhChip';

import { RewardItemInfoModal } from './RewardItemInfoModal';

export const ReferAndEarnUnlockRewards = ({
  referralsRewardsData,
  canRedeem,
}: {
  referralsRewardsData: ReferralRewardsResponse;
  canRedeem: boolean;
}) => {
  const intl = useIntl();

  const [infoModalReward, setInfoModalReward] = useState<ReferralReward | null>(
    null,
  );

  const [claimReward] = useClaimRewardMutation();

  const claimRewardClicked = (rewardId: string) => {
    claimReward(rewardId)
      .unwrap()
      .then(() => {
        // open success modal
      })
      .catch(() => {
        // open error modal
      });
  };

  return (
    <>
      <TitleLarge>
        {intl.formatMessage({
          id: 'ReferAndEarnUnlockRewards.Title',
          defaultMessage: 'Unlock Rewards',
        })}
      </TitleLarge>
      {referralsRewardsData?.rewards && (
        <BoxTw className="flex flex-col gap-4">
          {referralsRewardsData.rewards.map((reward) => (
            <BoxTw
              key={reward.id}
              className="shadow-[3px_6px_15px_0px_rgba(0,0,0,0.10)] rounded-lg overflow-hidden relative"
            >
              <BoxTw
                className={clsx(
                  'absolute left-0 top-0 w-full h-full bg-gradient-to-r from-tiers-blue-300 to-[#f36350]',
                  !reward?.auto_redeem ? 'hidden' : 'block',
                )}
              >
                <BoxTw className="absolute left-0.5 top-0.5 w-[calc(100%-4px)] h-[calc(100%-4px)] rounded-md bg-white z-0" />
              </BoxTw>
              <BoxTw className="z-10 flex flex-col gap-4 p-4 relative">
                <BoxTw className="flex justify-between items-center">
                  <BoxTw className="flex items-center">
                    <TitleSmall className="my-0">{reward.name}</TitleSmall>
                    {reward.new ? (
                      <KhChip
                        chipDecoration="stars"
                        chipType="light"
                        className="ml-2"
                      >
                        {intl.formatMessage({
                          id: 'ReferAndEarnUnlockRewards.Reward.New',
                          defaultMessage: 'New!',
                        })}
                      </KhChip>
                    ) : null}
                  </BoxTw>
                  {reward.limited_time_offer ? (
                    <KhChip
                      chipType="light"
                      chipIcon={
                        <KDSIcons.Icons.TimeBold className="text-primary-300 mr-1" />
                      }
                    >
                      {intl.formatMessage({
                        id: 'ReferAndEarnUnlockRewards.Reward.LimitedOffer',
                        defaultMessage: 'Limited offer!',
                      })}
                    </KhChip>
                  ) : reward.reward_type !== RewardTypes.Cash ? (
                    <IconButton onClick={() => setInfoModalReward(reward)}>
                      <KDSIcons.Icons.QuestionBold className="text-primary-300" />
                    </IconButton>
                  ) : null}
                </BoxTw>
                {reward.value &&
                reward.discounted_value &&
                reward.reward_type !== RewardTypes.Cash ? (
                  <BoxTw className="flex">
                    <Paragraph className="my-0 mr-1 text-grey-300 line-through inline-block">
                      {reward.value}
                    </Paragraph>
                    <Paragraph className="my-0 font-black inline-block text-green-300">
                      {reward.discounted_value}
                    </Paragraph>
                  </BoxTw>
                ) : null}
                <Paragraph className="my-0">{reward.description}</Paragraph>
                {reward.cta_button ? (
                  <LinkTw
                    to={reward.cta_button.cta_link}
                    external={true}
                    trackName="referral-cta-button"
                    trackMetadata={{ rewardId: reward.id }}
                    className="w-full text-center p-3 border border-solid border-primary-300 hover:bg-primary-400 hover:text-white text-primary-300 legacy:rounded light:rounded-full"
                  >
                    {reward.cta_button.cta_copy}
                  </LinkTw>
                ) : (
                  <ButtonTw
                    disabled={!canRedeem}
                    onClick={() => claimRewardClicked(reward.id)}
                    trackName="referral-cta-button"
                    trackMetadata={{ rewardId: reward.id }}
                  >
                    {canRedeem ? (
                      intl.formatMessage({
                        id: 'ReferAndEarnUnlockRewards.Reward.CTA',
                        defaultMessage: 'Redeem',
                      })
                    ) : (
                      <div className="flex items-center">
                        <KDSIcons.Icons.LockDetailed className="w-4 h-4 text-white mr-[10px]" />
                        {intl.formatMessage({
                          id: 'ReferAndEarnUnlockRewards.Reward.CTA.Disabled',
                          defaultMessage: 'Refer to redeem',
                        })}
                      </div>
                    )}
                  </ButtonTw>
                )}
              </BoxTw>
            </BoxTw>
          ))}
        </BoxTw>
      )}
      <RewardItemInfoModal
        open={infoModalReward !== null}
        onClose={() => setInfoModalReward(null)}
        canRedeem={canRedeem}
        onRedeemClicked={claimRewardClicked}
        reward={infoModalReward}
      />
    </>
  );
};
