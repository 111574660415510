import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { track } from 'analytics/analytics';
import { IBenefitGroup, ICurrentBenefit, IPlan } from 'apis/tiers';
import { ButtonTw } from 'components/ButtonTw';
import { useToggle } from 'hooks/useToggle';
import { Paragraph } from 'theme-ui';
import { isDowngradingTier } from 'tiers/utils/helpers';

import { DowngradeConfirmationModal } from './downgradeConfirmationModal';
import { UpgradeConfirmationModal } from './upgradeConfirmationModal/index';

const useGetButtonText = ({
  isFree,
  onPaidTier,
}: {
  isFree: boolean;
  onPaidTier: boolean;
}) => {
  const intl = useIntl();
  if (isFree)
    return intl.formatMessage({
      id: 'TierCard.TryForFree',
      defaultMessage: 'Try for free',
    });

  if (onPaidTier)
    return intl.formatMessage({
      id: 'TierCard.ChangePlan',
      defaultMessage: 'Change my plan',
    });

  return intl.formatMessage({
    id: 'TierCard.UpgradePlan',
    defaultMessage: 'Upgrade my plan',
  });
};

type Props = {
  askForConfirmation?: boolean;
  currentUserTier?: ICurrentBenefit;
  disabled?: boolean;
  isFree?: boolean;
  onPaidTier?: boolean;
  onPlanChange?: (tier: IBenefitGroup, plan: IPlan | undefined) => void;
  selectedPlan: IPlan | undefined;
  tier: IBenefitGroup;
  trackEventName?: string;
};

export const ChangePlan = ({
  askForConfirmation = true,
  currentUserTier,
  disabled = false,
  isFree = false,
  onPaidTier = false,
  onPlanChange,
  selectedPlan,
  tier,
  trackEventName,
}: Props) => {
  const {
    value: confimationModalOpen,
    on: openModal,
    off: closeModal,
  } = useToggle();

  const onPlanChangeComplete = useCallback(() => {
    onPlanChange && onPlanChange(tier, selectedPlan);
  }, [onPlanChange, tier, selectedPlan]);

  const buttonText = useGetButtonText({ onPaidTier, isFree });

  const isDowngrade = currentUserTier?.benefit_group?.key
    ? isDowngradingTier(currentUserTier.benefit_group.key, tier.key)
    : false;

  return (
    <>
      <ButtonTw
        disabled={!selectedPlan || !tier || disabled}
        onClick={() => {
          trackEventName &&
            track({ event: 'Clicked', properties: { name: trackEventName } });
          askForConfirmation ? openModal() : onPlanChangeComplete();
        }}
        className="mt-4 legacy:bg-tiers-blue-300"
        variant="primary"
        data-cy="change-tier-button"
      >
        <Paragraph className="font-bold leading-normal">{buttonText}</Paragraph>
      </ButtonTw>
      {selectedPlan && !isDowngrade && (
        <UpgradeConfirmationModal
          open={confimationModalOpen && !isDowngrade}
          onClose={closeModal}
          onConfirm={onPlanChangeComplete}
          selectedTier={tier}
          selectedPlan={selectedPlan}
          isFree={isFree}
        />
      )}
      {selectedPlan && currentUserTier && isDowngrade && (
        <DowngradeConfirmationModal
          open={confimationModalOpen && isDowngrade}
          onClose={closeModal}
          onConfirm={onPlanChangeComplete}
          oldTier={currentUserTier}
          newTier={tier}
          plan={selectedPlan}
        />
      )}
    </>
  );
};
