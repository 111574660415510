import { navigate } from '@reach/router';
import { track } from 'analytics/analytics';
import { Affiliate, affiliatesApi } from 'apis/affiliates';
import { authActions } from 'auth/store/slice';
import { profileActions } from 'profile/store/slice';
import { Epic, ofType } from 'redux-observable';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { RootState } from 'store';

import { selectLoginAffiliationURLParams } from './selectors';
import { loginActions } from './slice';

const loginSuccessNoOTPEpic: Epic<any, any, RootState> = (action$, store) => {
  return action$.pipe(
    ofType(loginActions.loginSuccessNoOTP),
    switchMap(() => {
      return [authActions.setSession(), loginActions.loginSuccess()];
    }),
    tap(() => {
      if (!store.value.auth.isNewLogin) {
        navigate('/sms-verify');
      }
    }),
  );
};

const loginSmsOtpSuccessEpic: Epic<any, any, RootState> = (action$, store) => {
  return action$.pipe(
    ofType(loginActions.smsOtpSuccess),
    switchMap(({ payload }) => {
      return [
        authActions.setOtpTimeout(),
        authActions.setSession(),
        loginActions.navigateToLoginDestination(),
        profileActions.getProfileRequestWithEvents(payload),
        loginActions.loginSuccess(),
      ];
    }),
  );
};

const loginRememberDeviceEpic = (action$) => {
  return action$.pipe(
    ofType(loginActions.rememberDeviceSelected),
    tap((payload) =>
      track({
        event: 'Login Remember Device Toggled',
        properties: { payload },
      }),
    ),
    map(() => loginActions.noop()),
  );
};

const loginNavigateToDestinationEpic: Epic<any, any, RootState> = (
  actions$,
  store,
) => {
  return actions$.pipe(
    ofType(loginActions.navigateToLoginDestination),
    tap(() => {
      navigate(store.value.login.destination);
    }),
    map(() => loginActions.noop()),
  );
};

const onLoginSuccessWithXTMURLParams: Epic<any, any, RootState> = (
  action$,
  store,
) => {
  return action$.pipe(
    ofType(loginActions.loginSuccess),
    filter(
      () =>
        selectLoginAffiliationURLParams(store.value)?.affiliate ===
        Affiliate.XTM,
    ),
    map(() => {
      const affiliateURLParams = selectLoginAffiliationURLParams(store.value);
      return affiliatesApi.endpoints.postXTMAffiliation.initiate(
        Number(affiliateURLParams!.affiliateUserIdentifier),
      );
    }),
  );
};

const exportedArray = [
  loginSuccessNoOTPEpic,
  loginRememberDeviceEpic,
  loginSmsOtpSuccessEpic,
  loginNavigateToDestinationEpic,
  onLoginSuccessWithXTMURLParams,
];
export default exportedArray;
