import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button as MuiButton } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { AddFundsShadowBox } from 'addFunds/components/AddFundsShadowBox';
import { AddFundsFeatureFlags } from 'addFunds/feature-flags';
import { track } from 'analytics/analytics';
import { KDSIcons } from 'assets/images/kds_icons';
import { LocaleContext, Modal, ValueCopier } from 'components';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import {
  Paragraph,
  ParagraphSmall,
  TitleLarge,
  TitleMedium,
  TitleSmaller,
} from 'components/TypographyTw';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TemplateTw } from 'layout/TemplateTw';
import {
  selectProfileEmail,
  selectProfileEtransferAnswer,
  selectProfileEtransferEmail,
} from 'profile/store/selectors';

import { AutodepositFormCard } from '../components/AutodepositForm';

export const LoadViaETransfer = () => {
  const intl = useIntl();

  const accountEmail = useSelector(selectProfileEmail);
  const eTransferEmail = useSelector(selectProfileEtransferEmail);
  const eTransferAnswer = useSelector(selectProfileEtransferAnswer);

  const [showInstantModal, setInstantModal] = useState(false);
  const [showPersonalModal, setPersonalModal] = useState(false);

  const autodepositEnabled =
    useFlags()[AddFundsFeatureFlags.AutodepositCardEnabled];

  function handleShowInstantModal() {
    track({
      event: 'Clicked',
      properties: {
        name: 'eTransferInstantModal',
        element: 'button',
        type: 'button',
      },
    });
    setInstantModal(true);
  }

  function handleShowPersonalModal() {
    track({
      event: 'Clicked',
      properties: {
        name: 'eTransferPersonalModal',
        element: 'button',
        type: 'button',
      },
    });
    setPersonalModal(true);
  }

  return (
    <TemplateTw name="LoadViaETransfer" variant="center">
      <TitleLarge className="my-4">
        {intl.formatMessage({
          id: 'LoadViaETransfer.Title',
          defaultMessage: 'Load via e-Transfer',
        })}
      </TitleLarge>

      <AddFundsShadowBox>
        <BoxTw className="flex justify-between">
          <TitleMedium className="mb-1">
            {intl.formatMessage({
              id: 'LoadViaETransfer.KohoInstantTitle',
              defaultMessage: 'KOHO (Instant)',
            })}
          </TitleMedium>
          <MuiButton onClick={() => handleShowInstantModal()} className="-mr-4">
            <KDSIcons.Icons.Info className="text-primary-300" />
          </MuiButton>
        </BoxTw>
        <Paragraph className="mt-1">
          {intl.formatMessage({
            id: 'LoadViaETransfer.KohoInstantDescription',
            defaultMessage:
              'e-Transfer money directly to your account using your unique KOHO email.',
          })}
        </Paragraph>

        <ParagraphSmall>
          {intl.formatMessage({
            id: 'LoadViaETransfer.CopyPasteEmail',
            defaultMessage: 'Copy & paste this email',
          })}
        </ParagraphSmall>
        <ValueCopier value={eTransferEmail || ''} />
        <Accordion
          classes={{
            root: 'p-0 shadow-none mt-3 before:content-[""] light:bg-grey-50',
          }}
        >
          <AccordionSummary
            onClick={() =>
              track({
                event: 'Clicked',
                properties: {
                  name: 'security-qa',
                  element: 'accordion',
                  type: 'button',
                },
              })
            }
            expandIcon={<ArrowDropDownIcon className="text-black" />}
            id="security-qa-header"
            aria-controls="security-qa-content"
            classes={{
              root: 'p-0 m-0',
              content: 'p-0 m-0',
              expanded: 'p-0 m-0',
            }}
          >
            <TitleSmaller>
              {intl.formatMessage({
                id: 'LoadInstructions.SecurityTitle',
                defaultMessage: 'Security question & answer',
              })}
            </TitleSmaller>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'block p-0' }}>
            <Paragraph>
              {intl.formatMessage({
                id: 'LoadViaETransfer.SecurityQuestionDescription',
                defaultMessage:
                  'If your bank requires a security question and answer for e-Transfers, copy and paste the the following information:',
              })}
            </Paragraph>
            <ParagraphSmall className="mt-4">
              {intl.formatMessage({
                id: 'LoadInstructions.SecurityQuestion',
                defaultMessage: 'Security question (if your bank requires one)',
              })}
            </ParagraphSmall>
            <ValueCopier
              value={intl.formatMessage({
                id: 'LoadInstructions.KohoLoadCode',
                defaultMessage: 'KOHO Load Code',
              })}
            />
            <ParagraphSmall className="mt-4">
              {intl.formatMessage({
                id: 'LoadInstructions.SecurityAnswer',
                defaultMessage:
                  'Security answer (must match the below to work)',
              })}
            </ParagraphSmall>
            <ValueCopier
              className="font-['Roboto_Mono']"
              value={eTransferAnswer || ''}
            />
          </AccordionDetails>
        </Accordion>
      </AddFundsShadowBox>

      <AddFundsShadowBox>
        <BoxTw className="flex justify-between">
          <TitleMedium className="mb-1">
            {intl.formatMessage({
              id: 'LoadViaETransfer.PersonalTitle',
              defaultMessage: 'Personal',
            })}
          </TitleMedium>
          <MuiButton
            onClick={() => handleShowPersonalModal()}
            className="-mr-4"
          >
            <KDSIcons.Icons.Info className="text-primary-300" />
          </MuiButton>
        </BoxTw>
        <Paragraph className="mt-1">
          {intl.formatMessage({
            id: 'LoadViaETransfer.PersonalDescription',
            defaultMessage:
              'You can use your personal email to add money to KOHO easily just as you would with any e-Transfer.',
          })}
        </Paragraph>

        <ParagraphSmall>
          {intl.formatMessage({
            id: 'LoadViaETransfer.CopyPasteEmail',
            defaultMessage: 'Copy & paste this email',
          })}
        </ParagraphSmall>
        <ValueCopier value={accountEmail || ''} />

        <Paragraph className="mt-6 mb-4">
          {intl.formatMessage({
            id: 'LoadViaETransfer.AutodepositDescription',
            defaultMessage:
              'If your email is setup for auto-deposit with another bank you will need to enable autodeposit below.',
          })}
        </Paragraph>
      </AddFundsShadowBox>

      {autodepositEnabled && <AutodepositFormCard />}

      <InstantTransferModal
        open={showInstantModal}
        onClose={() => setInstantModal(false)}
      />

      <PersonalTransferModal
        open={showPersonalModal}
        onClose={() => setPersonalModal(false)}
      />
    </TemplateTw>
  );
};

/**
 * Used in intl.formatMessage to bold text
 */
const boldText = (text: string) => <strong>{text}</strong>;

/**
 * Component used in each of the modals to provide a link to the help center
 */
function HelpButton() {
  const intl = useIntl();
  const context = useContext(LocaleContext);

  const href = `https://help.koho.ca/${context.locale}/articles/1610518-e-transfer-time-to-load`;

  return (
    <ButtonTw
      variant="secondary"
      className="mt-4"
      trackName="eTransferHelp"
      onClick={() => {
        window.open(href, '_blank', 'noopener,noreferrer');
      }}
    >
      {intl.formatMessage({
        id: 'LoadViaETransfer.InstantTransferModal.Questions',
        defaultMessage: 'Still got questions?',
      })}
    </ButtonTw>
  );
}

function BoxIcon({ children }) {
  return (
    <BoxTw className="flex items-center">
      <BoxTw className="w-10 h-10 bg-primary-300 flex justify-center items-center rounded-lg">
        {children}
      </BoxTw>
    </BoxTw>
  );
}

const InstantTransferModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const intl = useIntl();

  return (
    <Modal
      absolutePositioning={false}
      open={open}
      onClose={onClose}
      classes={{ paperWidthXs: 'max-w-[500px]', paper: 'rounded-lg py-0 px-2' }}
    >
      <TitleMedium className="mt-0">
        {intl.formatMessage({
          id: 'LoadViaETransfer.InstantTransferModal.Title',
          defaultMessage: 'How to e-Transfer to your KOHO email',
        })}
      </TitleMedium>
      <BoxTw className="flex gap-4">
        <BoxIcon>
          <KDSIcons.Icons.SendLegacy className="w-6 h-6 text-white" />
        </BoxIcon>
        <Paragraph>
          {intl.formatMessage({
            id: 'LoadViaETransfer.InstantTransferModal.StepOne',
            defaultMessage:
              'Open your banking app, and tap on "Send Money" or "Send Interac e-Transfer"',
          })}
        </Paragraph>
      </BoxTw>
      <BoxTw className="flex gap-4">
        <BoxIcon>
          <KDSIcons.Icons.Copy className="w-6 h-6 text-white" />
        </BoxIcon>
        <Paragraph>
          {intl.formatMessage({
            id: 'LoadViaETransfer.InstantTransferModal.StepTwo',
            defaultMessage:
              'Tap to copy and paste the exact information to send an e-Transfer',
          })}
        </Paragraph>
      </BoxTw>
      <BoxTw className="flex gap-4">
        <BoxIcon>
          <KDSIcons.Icons.Rich className="w-6 h-6 text-white" />
        </BoxIcon>
        <Paragraph>
          {intl.formatMessage({
            id: 'LoadViaETransfer.InstantTransferModal.StepThree',
            defaultMessage:
              "That's it!  Your money will be added to your KOHO account",
          })}
        </Paragraph>
      </BoxTw>
      <HelpButton />
    </Modal>
  );
};

const PersonalTransferModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const intl = useIntl();

  const email = useSelector(selectProfileEmail);

  return (
    <Modal
      absolutePositioning={false}
      open={open}
      onClose={onClose}
      classes={{ paperWidthXs: 'max-w-[500px]', paper: 'rounded-lg py-0 px-2' }}
    >
      <TitleMedium className="mt-0">
        {intl.formatMessage({
          id: 'LoadViaETransfer.PersonalTransferModal.Title',
          defaultMessage: 'How to e-Transfer to your KOHO email',
        })}
      </TitleMedium>
      <BoxTw className="flex gap-4">
        <BoxIcon>
          <KDSIcons.Icons.SendLegacy className="w-6 h-6 text-white" />
        </BoxIcon>
        <Paragraph>
          {intl.formatMessage(
            {
              id: 'LoadViaETransfer.PersonalTransferModal.StepOne',
              defaultMessage: `Open your banking app, and set up an e-Transfer to <boldText>{email}</boldText>`,
            },
            { boldText: (text: string) => boldText(text), email },
          )}
        </Paragraph>
      </BoxTw>
      <BoxTw className="flex gap-4">
        <BoxIcon>
          <KDSIcons.Icons.Email className="w-6 h-6 text-white" />
        </BoxIcon>
        <Paragraph>
          {intl.formatMessage(
            {
              id: 'LoadViaETransfer.PersonalTransferModal.StepTwo',
              defaultMessage:
                'Open your email and search for <boldText>"KOHO Powered by Peoples Trust"</boldText>',
            },
            { boldText: (text: string) => boldText(text) },
          )}
        </Paragraph>
      </BoxTw>
      <BoxTw className="flex gap-4">
        <BoxIcon>
          <KDSIcons.Icons.Rich className="w-6 h-6 text-white" />
        </BoxIcon>
        <Paragraph>
          {intl.formatMessage({
            id: 'LoadViaETransfer.PersonalTransferModal.StepThree',
            defaultMessage:
              'Accept your transfer, and the money will be added to your KOHO account',
          })}
        </Paragraph>
      </BoxTw>
      <HelpButton />
    </Modal>
  );
};
