import Bank from './Bank.png';
import Earn from './Earn.png';
import PiggyBank from './PiggyBank.png';
import Save from './Save.png';
import Shield from './Shield.png';
import Stop from './Stop.png';
import Success from './Success.png';
import Vault from './Vault.png';

export const Illustrations = {
  Bank,
  Earn,
  PiggyBank,
  Save,
  Shield,
  Vault,
  Success,
  Stop,
};
