import CircularProgress from 'components/CircularProgress';

const LoadingCentered = (): JSX.Element => {
  return (
    <div className="text-center m-2" data-testid="loading-ui--centered">
      <CircularProgress className="text-primary-300" />
    </div>
  );
};

export default LoadingCentered;
