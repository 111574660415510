import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { MoneyRequest } from 'apis/requestMoneyApi';
import { KDSIcons } from 'assets/images/kds_icons';
import clsx from 'clsx';
import { ButtonLinkExternal, LocaleContext } from 'components';
import { BoxTw } from 'components/BoxTw';
import { Paragraph, ParagraphSmall, TitleLarge } from 'components/TypographyTw';

import { RequestMoneyEmojiBackground } from './RequestMoneyEmojiBackground';
import { RequestMoneyInitialsAvatar } from './RequestMoneyInitialsAvatar';

export const RequestMoneyInfoMobile = ({
  request,
}: {
  request: MoneyRequest;
}) => {
  const intl = useIntl();
  const context = useContext(LocaleContext);

  return (
    <BoxTw>
      <TitleLarge>
        {request.amount
          ? intl.formatMessage(
              {
                id: 'RequestMoneyInfoMobile.Title',
                defaultMessage: 'Send {requesterName} {amount}',
              },
              {
                requesterName: request.requester_given_name,
                amount: context.intlFormatMoney(request.amount).format(),
              },
            )
          : intl.formatMessage(
              {
                id: 'RequestMoneyInfoMobile.TitleNoAmount',
                defaultMessage: 'Send {requesterName} money',
              },
              {
                requesterName: request.requester_given_name,
              },
            )}
      </TitleLarge>
      <BoxTw className="relative flex justify-center">
        <RequestMoneyEmojiBackground
          emoji={request.emoji}
          className="w-full h-[115px]"
        />
        <RequestMoneyInitialsAvatar
          initials={request.requester_initials}
          position="absolute"
          className={clsx(
            'z-[11] absolute',
            request.message ? 'top-[17px]' : 'top-[34px]',
          )}
        />
        {request.message && (
          <Paragraph className="rounded bg-grey-75 text-center p-2 mb-6 mt-0 absolute z-10 top-[55px]">
            {intl.formatMessage(
              {
                id: 'RequestMoneyInfoMobile.Message',
                defaultMessage: '“{message}”',
              },
              {
                message: request.message,
              },
            )}
          </Paragraph>
        )}
      </BoxTw>
      <BoxTw className="relative">
        <BoxTw className="flex justify-center items-center mt-4">
          <KDSIcons.Icons.QuestionBold className="mr-2" />
          <ParagraphSmall>
            {intl.formatMessage(
              {
                id: 'RequestMoneyExpiry.ExpiresAt',
                defaultMessage: 'This request will expire on {date}',
              },
              {
                date: intl.formatDate(request.expires_at, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  timeZone: 'utc',
                }),
              },
            )}
          </ParagraphSmall>
        </BoxTw>
        <ButtonLinkExternal
          href={request.payment_deep_link}
          variant="coral"
          className="my-4"
          trackName="request-money-pay-with-koho"
        >
          {intl.formatMessage({
            id: 'RequestMoneyInfoMobile.OpenApp',
            defaultMessage: 'Pay with KOHO',
          })}
        </ButtonLinkExternal>
      </BoxTw>
    </BoxTw>
  );
};
