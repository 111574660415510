/** @jsxImportSource theme-ui */
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { KDSIcons } from 'assets/images/kds_icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SendMoneyFeatureFlags } from 'sendMoney/send-money-flags';

import { Money } from '../models/Money';
import { BoxTw } from './BoxTw';
import { ButtonTw } from './ButtonTw';
import { LocaleContext } from './I18NContextProvider';
import {
  Paragraph,
  SubtitleSmall,
  TitleLarge,
  TitleSmall,
} from './TypographyTw';
import { Validators } from './forms';
import { TextInputPaymentTw } from './forms/TextInputPaymentTw';

export const TransferAmount = ({
  type,
  min,
  max,
  initial,
  showTotalAvailable,
  onContinue,
  feeAmount,
}: {
  type: 'in' | 'out';
  min?: Money;
  max: Money;
  initial: string;
  showTotalAvailable: boolean;
  onContinue: (amount: string) => void;
  feeAmount: string;
}) => {
  const intl = useIntl();
  const context = useContext(LocaleContext);
  const [amount, setAmount] = useState<Money>(context.intlFormatMoney(initial));
  const [valid, setValid] = useState(false);

  const outboundETranfersEnabled =
    useFlags()[SendMoneyFeatureFlags.OutboundETransferKillswitch];

  return (
    <>
      <div className="flex flex-col h-full">
        {!outboundETranfersEnabled ? (
          <BoxTw className="rounded-xl bg-danger-75 p-4 mb-5 flex items-start">
            <div className="mr-3">
              <KDSIcons.Icons.Warning />
            </div>

            <div>
              <TitleSmall className="my-0 mb-1">
                {intl.formatMessage({
                  id: 'TransferAmount.OutboundETransferDisabledTitle',
                  defaultMessage: 'Outgoing e-Transfers are disabled',
                })}
              </TitleSmall>

              <Paragraph className="my-0">
                {intl.formatMessage({
                  id: 'TransferAmount.OutboundETransferDisabledMessage',
                  defaultMessage:
                    "We're having some issues on our end. Please try again later.",
                })}
              </Paragraph>
            </div>
          </BoxTw>
        ) : null}

        <TitleLarge className="my-0 pb-4">
          {type === 'in'
            ? intl.formatMessage({
                id: 'TransferAmount.HowMuchToWithdraw',
                defaultMessage: 'How much would you like to withdraw?',
              })
            : intl.formatMessage({
                id: 'TransferAmount.HowMuchToSend',
                defaultMessage: 'How much do you want to send?',
              })}
        </TitleLarge>
        {showTotalAvailable ? (
          <Paragraph className="mt-0">
            {intl.formatMessage({
              id: 'TransferAmount.AvailableFunds',
              defaultMessage: `Available funds:`,
            })}{' '}
            {max && max.format()}
          </Paragraph>
        ) : null}
        <TextInputPaymentTw
          className="[&_input]:text-primary-300 [&_input]:bg-grey-75 [&_input]:rounded-lg [&_.MuiOutlinedInput-notchedOutline]:border-none"
          name="amount"
          controlledValue={context.intlFormatMoney(amount).format()}
          onChange={(event) =>
            setAmount(context.intlFormatMoney(event.currentTarget.value))
          }
          onValidChange={(event) => setValid(event)}
          type="amount"
          validators={[
            Validators.required,
            Validators.min(
              min?.toNumber() || 0,
              intl,
              intl.formatMessage(
                {
                  id: 'TransferAmount.Validation.MinAmount',
                  defaultMessage:
                    'Please enter an amount that’s more than {amount}',
                },
                { amount: feeAmount },
              ),
            ),
            Validators.max(
              max.toNumber(),
              intl,
              intl.formatMessage({
                id: 'TransferAmount.Validation.Max',
                defaultMessage:
                  'You do not have enough funds in your available balance.',
              }),
            ),
          ]}
        />
        <ButtonTw
          className="mt-2"
          onClick={() => onContinue(amount.format())}
          disabled={!valid || !outboundETranfersEnabled}
        >
          {intl.formatMessage({
            id: 'Global.Button.Continue',
            defaultMessage: 'Continue',
          })}
        </ButtonTw>

        <div className="my-8 text-center">
          <SubtitleSmall>
            {intl.formatMessage({
              id: 'TransferAmount.LegalFootnote.One',
              defaultMessage:
                '® Trademark of Interac Corp. KOHO Financial authorized user of the trademark.',
            })}
          </SubtitleSmall>

          <SubtitleSmall>
            {intl.formatMessage({
              id: 'TransferAmount.LegalFootnote.Two',
              defaultMessage:
                '® Trademark of Interac Corp. Used under license.',
            })}
          </SubtitleSmall>
        </div>
      </div>
    </>
  );
};
