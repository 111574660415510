import { rootApi } from './rootApi';

export enum MoneyMovementType {
  BillPay = 'bill_pay',
  CashLoad = 'cashload',
  AutodepositETransfer = 'autodeposit_etransfer',
  QAETransfer = 'q_a_etransfer',
  OutboundETransfer = 'outbound_etransfer',
  K2KInbound = 'k2k_inbound',
  K2KOutbound = 'k2k_outbound',
  EasyLoad = 'easyload',
  PAC = 'pac',
}

export interface IVelocityData {
  money_movement: IMoneyMovementData[];
}

export interface IMoneyMovementData {
  type: MoneyMovementType;
  daily_amount?: string;
  daily_count?: string;
  monthly_amount?: string;
  monthly_count?: string;
  max_transaction_amount: string;
}

export const velocityApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getVelocity: build.query<IVelocityData, void>({
      query: () => ({
        url: '1.0/velocity',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetVelocityQuery } = velocityApi;
