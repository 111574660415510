import React from 'react';
import { useIntl } from 'react-intl';

import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import { KDSIcons } from 'assets/images/kds_icons';
import { theme } from 'theme';

const useStyles = makeStyles<Theme>({
  timelineContainer: {
    position: 'relative',
    paddingBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '&::before': {
      content: '""', // Note the double quotes inside the single quotes.
      borderRadius: '9999px',
      position: 'absolute',
      width: '24px',
      top: 0,
      zIndex: 11,
      backgroundColor: '#C8102E',
      bottom: '20px',
    },
  },
  timelineItem: {
    gap: '16px',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    '&:last-child::before': {
      content: '""',
      position: 'absolute',
      zIndex: 10,
      backgroundImage: `linear-gradient(to bottom, #d8d8d8, #fff 80% )`,
      width: '24px',
      top: '24px',
      bottom: 0,
      minHeight: '90px',
      borderRadius: '9999px',
    },
  },
  itemTextBox: {
    flexGrow: 1,
  },
  itemIconBox: {
    flexShrink: 0,
    zIndex: 20,
    position: 'relative',
    borderRadius: '9999px',
    width: '24px',
    height: '24px',
    paddingTop: '4px',
    paddingLeft: '4px',
    backgroundColor: '#C8102E',
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
});

const Timeline: React.FC = ({ children }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.timelineContainer}>
      <Box className={classes.timelineItem}>
        <div className={classes.itemIconBox}>
          <KDSIcons.Icons.TiersLegacy color={theme.colors.white} />
        </div>
        <div className={classes.itemTextBox}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '16px',
              color: theme.colors.text,
            }}
          >
            {intl.formatMessage({
              defaultMessage: 'Sign up for a paid KOHO plan',
              id: 'NbaTimeline.Step1',
            })}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: theme.colors.midGrey,
              '& span': {
                color: '#C8102E',
                fontWeight: 800,
              },
            }}
          >
            {intl.formatMessage(
              {
                defaultMessage: 'Choose {essential}, {extra}, or {everything}',
                id: 'NbaTimeline.Step1.Subtitle',
              },
              {
                essential: <span>Essential</span>,
                extra: <span>Extra</span>,
                everything: <span>Everything</span>,
              },
            )}
          </Typography>
        </div>
      </Box>
      <Box className={classes.timelineItem}>
        <div className={classes.itemIconBox}>
          <KDSIcons.Icons.CalendarTimeBold color={theme.colors.white} />
        </div>
        <div className={classes.itemTextBox}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '16px',
              color: theme.colors.text,
            }}
          >
            {intl.formatMessage({
              defaultMessage: 'Complete a 30-day free trial',
              id: 'NbaTimeline.Step2',
            })}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: theme.colors.midGrey,
            }}
          >
            {intl.formatMessage({
              defaultMessage: 'Stay on a paid plan after your trial is done',
              id: 'NbaTimeline.Step2.Subtitle',
            })}
          </Typography>
        </div>
      </Box>
      <Box className={classes.timelineItem}>
        <div className={classes.itemIconBox}>
          <KDSIcons.Icons.GiftBold color={theme.colors.white} />
        </div>
        <div className={classes.itemTextBox}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '16px',
              color: theme.colors.text,
            }}
          >
            {intl.formatMessage({
              defaultMessage: 'Get your $75 NBAStore.ca e-Gift card',
              id: 'NbaTimeline.Step3',
            })}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: theme.colors.midGrey,
            }}
          >
            {intl.formatMessage({
              defaultMessage:
                'Come back to this page and you’ll get the link to access your card',
              id: 'NbaTimeline.Step3.Subtitle',
            })}
          </Typography>
        </div>
      </Box>
    </Box>
  );
};

export default Timeline;
