import { rootApi } from './rootApi';

export interface IAddMoneyMethodsOptions {
  direct_deposit: boolean;
  billpayload: boolean;
  easyload: boolean;
  etransfer: boolean;
  cashload: boolean;
}

export const addMoneyMethodsApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getAddMoneyMethodOptions: build.query<IAddMoneyMethodsOptions, void>({
      query: () => ({
        url: '1.0/load-methods',
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetAddMoneyMethodOptionsQuery } = addMoneyMethodsApi;
