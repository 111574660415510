import { BoxProps } from '@material-ui/core';
import { BoxTw } from 'components/BoxTw';
import { twMerge } from 'tailwind-merge';

export const RequestMoneyInitialsAvatar = ({
  initials,
  className,
}: BoxProps & {
  initials: string;
}) => {
  return (
    <BoxTw
      className={twMerge(
        'font-bold flex h-12 w-12 items-center justify-center rounded-full text-white border border-white bg-[#A01861]',
        className,
      )}
    >
      {initials}
    </BoxTw>
  );
};
