import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { KDSIcons } from 'assets/images/kds_icons';
import { Button } from 'components';
import { theme } from 'theme';

export const BillingMethodsAddCardForm = ({
  cardAdded,
  ctaText,
}: {
  cardAdded: (billingMethodId: string) => void;
  ctaText?: string;
}) => {
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      Sentry.captureException(new Error('Stripe.js not loaded'));
      return;
    }

    setIsLoading(true);

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    if (error) {
      if (
        (error?.type === 'card_error' || error?.type === 'validation_error') &&
        error?.message
      ) {
        setMessage(error.message);
      } else {
        setMessage('An unexpected error occurred.');
      }
    }

    if (setupIntent?.status === 'succeeded') {
      cardAdded(setupIntent.payment_method as string);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  } as StripePaymentElementOptions;

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />

      {!isLoading && message && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            p: 16,
            mt: 16,
            backgroundColor: theme.colors.danger,
            color: theme.colors.white,
            borderRadius: theme.borderRadius.small,
          }}
        >
          <KDSIcons.Icons.WarningLegacy sx={{ mr: 2 }} />
          <Typography>{message}</Typography>
        </Box>
      )}

      <Button
        id="submit"
        disabled={isLoading || !stripe || !elements}
        loading={isLoading}
        sx={{ mt: 16, bg: theme.colors.blueNavy }}
        variant="primary"
        trackName="add-billing-card-form-submit"
        data-cy={'add-billing-card-form-submit'}
      >
        <Typography sx={{ fontWeight: 900 }}>
          {ctaText
            ? ctaText
            : intl.formatMessage({
                id: 'BillingMethodsAddCardForm.Submit',
                defaultMessage: 'Save',
              })}
        </Typography>
      </Button>
    </form>
  );
};
