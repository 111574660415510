import { useGetContestStatusQuery } from 'apis/contests';
import { M1ContestFeatureFlags } from 'contest/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function useM1ContestStatus() {
  const { data: m1ContestData } = useGetContestStatusQuery();
  const isContestRunning = useFlags()[M1ContestFeatureFlags.KillSwitch];

  const showM1Contest =
    isContestRunning && m1ContestData?.status === 'initiated';

  return [showM1Contest, m1ContestData];
}
