import { ReactNode } from 'react';

import { navigate } from '@reach/router';
import { ButtonTw } from 'components/ButtonTw';

type Props = {
  children: ReactNode;
  dataCy: string;
  trackName: string;
};

export const BackToTransactions = ({ children, dataCy, trackName }: Props) => {
  return (
    <ButtonTw
      variant="ghost"
      className="mt-4 mb-12 py-3 legacy:text-tiers-blue-400 font-bold"
      onClick={() =>
        navigate('/transactions', { state: { showWelcomeModal: false } })
      }
      data-cy={dataCy}
      trackName={trackName}
    >
      {children}
    </ButtonTw>
  );
};
