import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectTheme } from 'appState/appState.slice';
import headerImg from 'assets/images/sideContent.jpg';
import { Widget } from 'components';
import DownloadAppButton from 'features/buttons/DownloadApp';

export const DownloadAppWidget = () => {
  const intl = useIntl();

  const themeName = useSelector(selectTheme);
  const isLegacyTheme = themeName === 'legacy';

  const img = {
    url: isLegacyTheme
      ? `${headerImg}`
      : `${import.meta.env.VITE_STATIC_URL}/widgets/unlock_even_more.png`,
  };

  return (
    <Widget
      bodyClassName="legacy:text-grey-400"
      contentClassName="legacy:bg-white"
      description={intl.formatMessage({
        id: 'DownloadAppWidget.Description',
        defaultMessage:
          'Build your credit history, effortlessly save, and more with the KOHO app.',
      })}
      image={img}
      title={intl.formatMessage({
        id: 'DownloadAppWidget.Title',
        defaultMessage: 'Unlock even more',
      })}
      titleClassName="legacy:text-grey-400"
    >
      <DownloadAppButton />
    </Widget>
  );
};
