import { ChangeEvent, FocusEvent, FormEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { RouteComponentProps } from '@reach/router';
import { Paragraph, TitleLarge } from 'components/TypographyTw';
import { PasswordValidationRules } from 'components/inputs/PasswordValidationRules';
import { useTypedSelector } from 'hooks/redux';
import {
  selectAuthenticationSuccess,
  selectPasswordResetError,
} from 'passwordReset/store/selectors';
import {
  isPasswordRequirementsMet,
  validatePasswordComplexity,
} from 'utility/validatePassword';

import { trackInput } from '../analytics/analytics';
import { SecurityAssurance } from '../components';
import { ButtonTw } from '../components/ButtonTw';
import CircularProgress from '../components/CircularProgress';
import {
  InputValidationMsg,
  useValidationMsg,
} from '../components/forms/useValidationMsg';
import { KhInputPasswordTw } from '../components/inputs/KhInputPasswordTw';
import { KhInputTextTw } from '../components/inputs/KhInputTextTw';
import { PasswordResetUserType, passwordResetActions } from './store/slice';

export const PasswordResetCreate = (_: RouteComponentProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [passwordValid, setPasswordValid] = useState<boolean | undefined>(
    undefined,
  );
  const [confirmInput, setConfirmInput] = useState<string>('');
  const [confirmValid, setConfirmValid] = useState<boolean>(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    hasMinLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
  });

  const state = useTypedSelector((state) => state);
  const { userType } = state.passwordReset;
  const { password } = state.passwordReset.request;
  const requestError = useSelector(selectPasswordResetError);
  const authenticationChecked = useSelector(selectAuthenticationSuccess);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Added new track event to override legacy event with the wrong name
    trackInput({
      type: 'Clicked',
      name: 'Update password CTA',
      details: { type: 'submit', element: 'button' },
    });

    if (userType === PasswordResetUserType.Identity) {
      dispatch(passwordResetActions.submitForIdentityUser());
    } else {
      dispatch(passwordResetActions.submitWithSmsOtp());
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isPasswordMatch = value === confirmInput;

    const passwordRequirement = validatePasswordComplexity(value);
    setPasswordRequirements(passwordRequirement);
    setPasswordValid(isPasswordRequirementsMet(passwordRequirement));
    setConfirmValid(isPasswordMatch);
    dispatch(passwordResetActions.updateResetError(null));
    dispatch(passwordResetActions.requestValue({ password: value }));
  };

  const onConfirmChange = (event: FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const valid = password === value;

    setConfirmValid(valid);
    setConfirmInput(value);
    dispatch(passwordResetActions.updateResetError(null));
  };

  const passwordMismatchedErrorMsg = useValidationMsg(
    !confirmValid && confirmInput ? InputValidationMsg.EmailMisMatched : null,
  );

  if (userType === 'identity' || authenticationChecked) {
    return (
      <form className="space-y-4" onSubmit={onSubmit}>
        <TitleLarge>
          {intl.formatMessage({
            id: 'UpdatePassword.Title',
            defaultMessage: 'Create new password',
          })}
        </TitleLarge>

        <Paragraph className="mt-4 pb-2">
          {intl.formatMessage({
            id: 'UpdatePassword.ReusedPasswordMessage',
            defaultMessage:
              'Remember to choose a password that is different from your old passwords.',
          })}
        </Paragraph>

        <KhInputPasswordTw
          autoFocus
          label={`${intl.formatMessage({
            id: 'PasswordReset.InputPasswordField',
            defaultMessage: 'Password',
          })}`}
          onChange={onChange}
        />

        <KhInputTextTw
          className="pb-1"
          variant="outlined"
          name="Confirm Password Input"
          type={'password'}
          value={confirmInput}
          label={`${intl.formatMessage({
            id: 'PasswordReset.InputConfirmPasswordField',
            defaultMessage: 'Confirm Password',
          })}`}
          helperText={
            passwordMismatchedErrorMsg ||
            (requestError && intl.formatMessage(requestError))
          }
          error={(!confirmValid && confirmInput.length !== 0) || !!requestError}
          onChange={onConfirmChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {confirmValid && (
                  <CheckCircleIcon className="mr-2 text-success-300" />
                )}
              </InputAdornment>
            ),
          }}
          id="confirm-password"
          autoComplete="confirm-password"
        />

        <PasswordValidationRules
          className="mt-8"
          passwordRequirements={passwordRequirements}
        />

        <ButtonTw
          type="submit"
          trackName="Resend device verification email"
          disabled={!passwordValid || !confirmValid || !!requestError}
        >
          {intl.formatMessage({
            id: 'UpdatePassword.Button',
            defaultMessage: 'Update password',
          })}
        </ButtonTw>

        <SecurityAssurance />
      </form>
    );
  } else {
    return (
      <div className="flex justify-center">
        <CircularProgress />
      </div>
    );
  }
};
