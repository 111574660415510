import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

type ContainerProps = {
  children: ReactNode;
  className?: string;
};

export const ContainerTw = ({ children, className }: ContainerProps) => {
  return (
    <div
      className={twMerge(
        'mt-16 mx-auto pt-2 px-4 xl:px-0  w-full xl:w-[1000px]',
        className,
      )}
    >
      {children}
    </div>
  );
};
