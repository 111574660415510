import { Skeleton } from '@material-ui/lab';
import { twMerge } from 'tailwind-merge';

const LoadingOverlay = ({ className }: { className?: string }): JSX.Element => {
  return (
    <div
      className={twMerge(
        'flex items-center justify-center w-screen h-screen',
        className,
      )}
      data-testid="loading-ui"
    >
      <Skeleton variant="rect" width="90%" height="90%" />
    </div>
  );
};

export default LoadingOverlay;
