import { Fragment, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useNavigate } from '@reach/router';
import { AddFundsFeatureFlags } from 'addFunds/feature-flags';
import { track } from 'analytics/analytics';
import {
  IVelocityData,
  MoneyMovementType,
  useGetVelocityQuery,
} from 'apis/velocity';
import transferIconV2 from 'assets/images/kds_icons/icons/debit-card.svg';
import dollarIconV2 from 'assets/images/kds_icons/icons/dollar-v2.svg';
import idIconV2 from 'assets/images/kds_icons/icons/spending-v2.svg';
import interacV2 from 'assets/images/kds_icons/icons/transfer-v2.svg';
import clsx from 'clsx';
import { LocaleContext } from 'components';
import { BoxTw } from 'components/BoxTw';
import ButtonUnstyled from 'components/ButtonUnstyledTw';
import CircularProgress from 'components/CircularProgress';
import { Paragraph, TitleLarge, TitleMedium } from 'components/TypographyTw';
import DownloadApp from 'features/modals/DownloadApp';
import { useToggle } from 'hooks/useToggle';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TemplateTw } from 'layout/TemplateTw';
import { selectProfileVerified } from 'profile/store/selectors';
import { twMerge } from 'tailwind-merge';
import { methodsMaxVelocityUtil } from 'utility/addMoneyMethodsVelocity';

function Card({ children, className = '' }) {
  return (
    <BoxTw
      variant="shadow"
      className={twMerge(
        clsx('p-4 mb-4 light:py-3 light:px-0 light:my-0', className),
      )}
    >
      {children}
    </BoxTw>
  );
}

function CardHeader({ children }) {
  return (
    <TitleMedium className="legacy:text-lg mb-1 mt-0 light:normal-case light:font-body light:text-base light:font-medium">
      {children}
    </TitleMedium>
  );
}

function CardContent({ children }) {
  return <Paragraph className="m-0">{children}</Paragraph>;
}

export const AddMoneyMethods = () => {
  const { data: velocityData, isLoading } = useGetVelocityQuery();

  const items = useGetItems(
    {
      etransfer: () => handleETransferClick('/load/e-transfer'),
      directDeposit: () => handleDDClick('/load/direct-deposit'),
      debitCard: () => handleDebitClick('/load/debit-card'),
      cashLoads: () => openDownloadAppModal(),
    },
    velocityData,
  );

  const intl = useIntl();
  const navigate = useNavigate();

  const {
    value: isDownloadAppModalOpen,
    on: openDownloadAppModal,
    off: closeDownloadAppModal,
  } = useToggle();

  const userIsVerified = useSelector(selectProfileVerified);

  const debitCardEnabled =
    useFlags()[AddFundsFeatureFlags.DebitCardLoadingEnabled];

  function handleETransferClick(to: string) {
    track({
      event: 'Clicked',
      properties: { name: 'eTransfer', to, element: 'button', type: 'button' },
    });
    navigate(to);
  }

  function handleDDClick(to: string) {
    track({
      event: 'Clicked',
      properties: {
        name: 'direct-deposit',
        to,
        element: 'button',
        type: 'button',
      },
    });

    if (!userIsVerified) {
      navigate('/kyc/entry');
    } else {
      navigate(to);
    }
  }

  function handleDebitClick(to: string) {
    track({
      event: 'Clicked',
      properties: {
        name: 'debit-card-load',
        to,
        element: 'button',
        type: 'button',
      },
    });

    if (debitCardEnabled) {
      if (!userIsVerified) {
        navigate('/kyc/entry');
      } else {
        navigate(to);
      }
    }
  }

  return (
    <TemplateTw name="AddMoneyMethods">
      <div className="light:bg-white lg:light:min-h-[638px] light:py-14 light:rounded-xl light:px-6">
        <div className="light:max-w-[450px] light:mx-auto">
          <TitleLarge className="light:mb-6">
            {intl.formatMessage({
              id: 'AddMoneyMethods.AddMoney.Title',
              defaultMessage: 'Add money',
            })}
          </TitleLarge>
          {isLoading ? (
            <div className="flex justify-center kh-mt-6">
              <CircularProgress />
            </div>
          ) : (
            items.map((item, index) => (
              <Fragment key={item.title}>
                {index === 2
                  ? debitCardEnabled || (
                      <TitleLarge className="mt-6">
                        {intl.formatMessage({
                          id: 'AddMoneyMethods.ComingSoon.Title',
                          defaultMessage: 'Coming soon...',
                        })}
                      </TitleLarge>
                    )
                  : null}
                <ButtonUnstyled onClick={item.onClick} className="w-full">
                  <Card
                    className={clsx(
                      index !== items.length - 1 && 'border-b border-grey-75',
                    )}
                  >
                    <BoxTw className="flex flex-row light:grid light:grid-cols-[20px_1fr] light:gap-4">
                      <img
                        src={item.image}
                        alt={item.title + ' logo'}
                        className="w-[33px] h-[33px]"
                      />
                      <BoxTw className="pl-4 light:pl-0">
                        <CardHeader>{item.title}</CardHeader>
                        <CardContent>{item.description}</CardContent>
                        <CardContent>{item.timeline}</CardContent>
                      </BoxTw>
                    </BoxTw>
                  </Card>
                </ButtonUnstyled>
              </Fragment>
            ))
          )}
        </div>
      </div>
      <DownloadApp
        open={isDownloadAppModalOpen}
        description={intl.formatMessage({
          id: 'AddMoneyMethods.DownloadApp.Description',
          defaultMessage: 'Deposit your cash with the app.',
        })}
        onClose={closeDownloadAppModal}
      />
    </TemplateTw>
  );
};

const useGetItems = (
  clickEvent: {
    etransfer: () => void;
    directDeposit: () => void;
    debitCard: () => void;
    cashLoads: () => void;
  },
  velocityData?: IVelocityData,
): {
  image: string;
  title: string;
  description: string;
  timeline?: string;
  loading?: boolean;
  onClick: () => void;
}[] => {
  const intl = useIntl();
  const localeContext = useContext(LocaleContext);

  const velocityEnabled =
    useFlags()[AddFundsFeatureFlags.VelocityDescriptionEnabled];

  return [
    {
      image: interacV2,
      title: intl.formatMessage({
        id: 'AddMoneyMethods.eTransfer.Title',
        defaultMessage: 'e-Transfer from your bank',
      }),
      description:
        velocityData && velocityEnabled
          ? intl.formatMessage(
              {
                id: 'AddMoneyMethods.eTransfer.VelocityDescription',
                defaultMessage: 'Up to {amount} at once',
              },
              {
                amount: localeContext
                  .intlFormatMoney(
                    methodsMaxVelocityUtil(velocityData)[
                      MoneyMovementType.AutodepositETransfer
                    ],
                  )
                  .format('pretty'),
              },
            )
          : intl.formatMessage({
              id: 'AddMoneyMethods.eTransfer.Description',
              defaultMessage: 'From another bank account',
            }),
      timeline: intl.formatMessage({
        id: 'AddMoneyMethods.eTransfer.TimelineDescription',
        defaultMessage: 'Arrives within 5 minutes',
      }),
      onClick: clickEvent.etransfer,
    },
    {
      image: transferIconV2,
      title: intl.formatMessage({
        id: 'AddMoneyMethods.DirectDeposit.Title',
        defaultMessage: 'Direct deposit',
      }),
      description: intl.formatMessage({
        id: 'AddMoneyMethods.DirectDeposit.Description',
        defaultMessage: 'Your paycheque, or government benefits.',
      }),
      timeline: intl.formatMessage({
        id: 'AddMoneyMethods.DirectDeposit.TimelineDescription',
        defaultMessage: 'Generally a day early',
      }),
      onClick: clickEvent.directDeposit,
    },
    {
      image: idIconV2,
      title: intl.formatMessage({
        id: 'AddMoneyMethods.DebitCard.Title',
        defaultMessage: 'Debit card',
      }),
      description:
        velocityData && velocityEnabled
          ? intl.formatMessage(
              {
                id: 'AddMoneyMethods.DebitCard.VelocityDescription',
                defaultMessage: 'Up to {amount} at once',
              },
              {
                amount: localeContext
                  .intlFormatMoney(
                    methodsMaxVelocityUtil(velocityData)[
                      MoneyMovementType.EasyLoad
                    ],
                  )
                  .format('pretty'),
              },
            )
          : intl.formatMessage({
              id: 'AddMoneyMethods.DebitCard.Description',
              defaultMessage: 'With a Mastercard or Visa debit.',
            }),
      timeline: intl.formatMessage({
        id: 'AddMoneyMethods.DebitCard.TimelineDescription',
        defaultMessage: 'Arrives instantly',
      }),
      onClick: clickEvent.debitCard,
    },
    {
      image: dollarIconV2,
      title: intl.formatMessage({
        id: 'AddMoneyMethods.Cash.Title',
        defaultMessage: 'Cash deposit',
      }),
      description:
        velocityData && velocityEnabled
          ? intl.formatMessage(
              {
                id: 'AddMoneyMethods.Cash.VelocityDescription',
                defaultMessage: 'Up to {amount} at once',
              },
              {
                amount: localeContext
                  .intlFormatMoney(
                    methodsMaxVelocityUtil(velocityData)[
                      MoneyMovementType.CashLoad
                    ],
                  )
                  .format('pretty'),
              },
            )
          : intl.formatMessage({
              id: 'AddMoneyMethods.Cash.Description',
              defaultMessage: 'At a Canada Post location. ',
            }),
      timeline: intl.formatMessage({
        id: 'AddMoneyMethods.Cash.TimelineDescription',
        defaultMessage: 'Arrives within 10 minutes',
      }),
      onClick: clickEvent.cashLoads,
    },
  ];
};
