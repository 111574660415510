import { useIntl } from 'react-intl';

import { BoxProps } from '@material-ui/core';
import { PutMoneyRequestError } from 'apis/requestMoneyApi';
import { ReactComponent as CheckMark } from 'assets/images/checkmark-success-filled.svg';
import { KDSIcons } from 'assets/images/kds_icons';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import { Paragraph, TitleLarge, TitleSmall } from 'components/TypographyTw';
import { twMerge } from 'tailwind-merge';

export const RequestMoneyErrorCard = ({
  error,
  className,
}: BoxProps & {
  error: PutMoneyRequestError;
}) => {
  const intl = useIntl();
  switch (error) {
    case PutMoneyRequestError.RequestFulfilled:
      return (
        <BoxTw
          className={twMerge(
            'rounded-lg p-4 lg:py-6 lg:px-10 w-full bg-success-50',
            className,
          )}
        >
          <TitleLarge className="text-center">
            {intl.formatMessage({
              id: 'RequestMoneyErrorCard.Fulfilled.Title',
              defaultMessage: 'This request was paid',
            })}
          </TitleLarge>
          <BoxTw className="flex justify-center">
            <CheckMark />
          </BoxTw>
        </BoxTw>
      );
    case PutMoneyRequestError.RequestExpired:
      return (
        <BoxTw
          className={twMerge(
            'rounded-lg p-4 lg:py-6 lg:px-10 bg-grey-75',
            className,
          )}
        >
          <TitleLarge className="text-center">
            {intl.formatMessage({
              id: 'RequestMoneyErrorCard.Expired.Title',
              defaultMessage: 'This request expired',
            })}
          </TitleLarge>
          <Paragraph className="text-center">
            {intl.formatMessage({
              id: 'RequestMoneyErrorCard.Expired.Body',
              defaultMessage: 'Contact the original requester to settle-up.',
            })}
          </Paragraph>
          {/* TODO: replace asset
          <BoxTw className="flex justify-center">
            <KDSIcons.Spot.Stop />
          </BoxTw> */}
        </BoxTw>
      );
    default:
      return (
        <BoxTw className={className}>
          <BoxTw className="flex flex-col rounded-lg items-center mb-4 p-4 lg:py-6 lg:px-10 bg-grey-100">
            <KDSIcons.Icons.WarningLegacy className="text-danger-300" />
            <TitleSmall className="mb-0">
              {intl.formatMessage({
                id: 'RequestMoneyErrorCard.Unknown.Title',
                defaultMessage: 'Something went wrong',
              })}
            </TitleSmall>
            <Paragraph className="text-center">
              {intl.formatMessage({
                id: 'RequestMoneyErrorCard.Unknown.Body',
                defaultMessage: 'Please try again or contact support',
              })}
            </Paragraph>
          </BoxTw>
          <ButtonTw
            onClick={() => {
              window.location.reload();
            }}
          >
            {intl.formatMessage({
              id: 'RequestMoneyErrorCard.Unknown.Button',
              defaultMessage: 'Retry',
            })}
          </ButtonTw>
        </BoxTw>
      );
  }
};
