import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import { BoxTw } from 'components/BoxTw';
import ButtonUnstyled from 'components/ButtonUnstyledTw';
import { Paragraph } from 'components/TypographyTw';
import { CardProductData, CardProductPackage } from 'models/CardProductData';
import { twMerge } from 'tailwind-merge';

import checkmark from '../../assets/images/green-checkmark.svg';

type CardSelectorProps = {
  products?: CardProductData[];
  selected: CardProductData | null;
  onSelect: (arg: CardProductData) => void;
};

export const CardSelector = (props: Readonly<CardSelectorProps>) => {
  const { products, onSelect } = props;

  let value = props.selected?.card_design;

  return !products ? (
    <BoxTw className="flex flex-col items-center h-full">
      <Skeleton className="aspect-[185/290] h-full max-h-[290px] w-full max-w-[185px] transform-none" />
      <Skeleton className="w-40 h-32 block" />
    </BoxTw>
  ) : (
    <>
      <BoxTw className="mx-6 mb-2 flex flex-col items-center h-fit">
        <BoxTw className="flex items-center justify-center">
          {products.map((product) => (
            <img
              key={product.name}
              alt={product.name}
              className={twMerge(
                'max-h-[290px] rounded-[7px] mb-4 transition',
                product.card_design === value
                  ? 'opacity-100'
                  : 'opacity-0 h-0 w-0',
              )}
              src={
                products.find((product) => product.card_design === value)
                  ?.image_url
              }
            />
          ))}
        </BoxTw>
        <Paragraph>
          {products.find((product) => product.card_design === value)?.name}
        </Paragraph>
      </BoxTw>

      <Grid container justifyContent="center">
        {products.map((product) =>
          product.package === CardProductPackage.free ? (
            <Grid item key={product.name} className="relative">
              <ButtonUnstyled
                onClick={() => onSelect(product)}
                className={twMerge(
                  `w-12 h-12 cursor-pointer text-center m-2
                  rounded-full outline-offset-2 outline-primary-400
                  hover:enabled:outline
                  focus:outline`,
                  product.card_design === value && 'outline',
                )}
              >
                <img
                  src={product.colour_palette_url}
                  alt=""
                  className="w-11 rounded-full"
                />
              </ButtonUnstyled>
              <img
                src={checkmark}
                alt=""
                className={twMerge(
                  `absolute w-4 h-4 rounded-full right-2 top-1`,
                  product.card_design === value ? 'visible' : 'hidden',
                )}
              />
            </Grid>
          ) : null,
        )}
      </Grid>
    </>
  );
};
