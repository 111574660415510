import { RouteComponentProps, useLocation } from '@reach/router';
import { LayoutBasic } from 'components';
import { HelpAndAccessibility } from 'support/components';

import { LoginSmsOtpForm } from '../components/LoginSmsOtpForm';

interface SmsVerifyPageLocationState {
  fallback?: boolean;
}

export const SmsVerifyPage = (props: RouteComponentProps) => {
  const { state } = useLocation();

  const { fallback = false } = (state as SmsVerifyPageLocationState) || {
    resend: false,
  };

  return (
    <LayoutBasic pageName="SMS - Verify" chat={false}>
      <div className="my-0 mx-8 max-w-[550px] text-grey-400">
        <LoginSmsOtpForm fallback={fallback} />
      </div>
      <HelpAndAccessibility />
    </LayoutBasic>
  );
};
