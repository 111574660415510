import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Affiliate } from 'apis/affiliates';
import { ReLoginModal } from 'auth/components/ReLoginModal';
import { SessionRefreshErrorModal } from 'auth/components/SessionRefreshErrorModal';
import { Card, LayoutBasic } from 'components/LayoutBasic';
import { TitleLarge } from 'components/TypographyTw';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LoginForm } from 'login/components/LoginForm';
import { LoginFeatureFlags } from 'login/models/LoginFeatureFlags';
import { loginActions } from 'login/store/slice';

export const LoginPage = ({ ...props }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [failedLoginAttempts, setFailedLoginAttempts] = useState<number>(0);

  const affiliate = new URLSearchParams(window.location.search).get(
    'affiliate',
  );
  const affiliateUserIdentifier = new URLSearchParams(
    window.location.search,
  ).get('affiliate_user_identifier');

  const isXTMLinkingEnabled =
    useFlags()[LoginFeatureFlags.XTMAccountLinkingEnabled];

  if (isXTMLinkingEnabled) {
    if (affiliate === Affiliate.XTM && affiliateUserIdentifier) {
      dispatch(
        loginActions.setAffiliationURLParams({
          affiliate,
          affiliateUserIdentifier,
        }),
      );
    }
  }

  return (
    <LayoutBasic
      chat={failedLoginAttempts >= 2}
      pageName="Login"
      className="bg-grey-100 light:bg-white"
      includeLanguageToggle={true}
    >
      <SessionRefreshErrorModal />
      <ReLoginModal />
      <Card>
        <TitleLarge className="mb-6">
          {intl.formatMessage({
            id: 'LoginPage.Title',
            defaultMessage: 'Login to KOHO',
            description: 'Title of the login page',
          })}
        </TitleLarge>
        <LoginForm
          emailAddress={props.location?.state?.emailAddress}
          setFailedLoginAttempts={setFailedLoginAttempts}
        />
      </Card>
    </LayoutBasic>
  );
};
