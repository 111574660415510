import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Card } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { useNavigate } from '@reach/router';
import { AddFundsFeatureFlags } from 'addFunds/feature-flags';
import { track } from 'analytics/analytics';
import {
  IVelocityData,
  MoneyMovementType,
  useGetVelocityQuery,
} from 'apis/velocity';
import { KDSIcons } from 'assets/images/kds_icons';
import { LocaleContext } from 'components';
import { BoxTw } from 'components/BoxTw';
import CircularProgress from 'components/CircularProgress';
import { Paragraph, ParagraphBold, TitleLarge } from 'components/TypographyTw';
import { useM1ContestStatus } from 'contest/hooks/useContestStatus';
import DownloadApp from 'features/modals/DownloadApp';
import { useToggle } from 'hooks/useToggle';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TemplateTw } from 'layout/TemplateTw';
import { selectProfileVerified } from 'profile/store/selectors';
import { methodsMaxVelocityUtil } from 'utility/addMoneyMethodsVelocity';

export const AddMoneyMethods = () => {
  const { data: velocityData, isLoading } = useGetVelocityQuery();

  const items = useGetItems(
    {
      etransfer: () => handleETransferClick('/load/e-transfer'),
      directDeposit: () => handleDDClick('/load/direct-deposit'),
      debitCard: () => handleDebitClick('/load/debit-card'),
      cashLoads: () => openDownloadAppModal(),
      billPayLoad: () => handleBillPayClick('load/deposit-via-bill-pay'),
    },
    velocityData,
  );

  const intl = useIntl();
  const navigate = useNavigate();

  const {
    value: isDownloadAppModalOpen,
    on: openDownloadAppModal,
    off: closeDownloadAppModal,
  } = useToggle();

  const userIsVerified = useSelector(selectProfileVerified);

  const [showM1Contest] = useM1ContestStatus();

  function handleETransferClick(to: string) {
    track({
      event: 'Clicked',
      properties: { name: 'eTransfer', to, element: 'button', type: 'button' },
    });
    navigate(to);
  }

  function handleDDClick(to: string) {
    track({
      event: 'Clicked',
      properties: {
        name: 'direct-deposit',
        to,
        element: 'button',
        type: 'button',
      },
    });

    if (!userIsVerified) {
      navigate('/kyc/entry');
    } else {
      navigate(to);
    }
  }

  function handleDebitClick(to: string) {
    track({
      event: 'Clicked',
      properties: {
        name: 'debit-card-load',
        to,
        element: 'button',
        type: 'button',
      },
    });

    if (!userIsVerified) {
      navigate('/kyc/entry');
    } else {
      navigate(to);
    }
  }

  function handleBillPayClick(to: string) {
    track({
      event: 'Clicked',
      properties: {
        name: 'bill-pay-load',
        to,
        element: 'button',
        type: 'button',
      },
    });

    if (!userIsVerified) {
      navigate('/kyc/entry');
    } else {
      navigate(to);
    }
  }

  return (
    <TemplateTw name="AddMoneyMethods">
      <div className="min-h-screen mx-auto w-[90%]">
        <TitleLarge className="mb-[80px] pt-8">
          {intl.formatMessage({
            id: 'AddMoneyMethods.AddMoney.Title',
            defaultMessage: 'Add money',
          })}
        </TitleLarge>
        {isLoading ? (
          <div className="flex justify-center kh-mt-6">
            <CircularProgress />
          </div>
        ) : (
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
            {showM1Contest && (
              <BoxTw className="rounded-xl bg-secondary-75">
                <div className="flex p-4 items-center">
                  <div className="self-start mr-4 flex-shrink-0">
                    <KDSIcons.Icons.Income />
                  </div>
                  <div className="flex-grow">
                    <div>
                      <ParagraphBold className="my-0">
                        {intl.formatMessage({
                          id: 'AddMoneyMethods.PlayForProgress.Title',
                          defaultMessage: 'Add and spend at least $100',
                        })}
                      </ParagraphBold>
                    </div>
                    <Paragraph className="mb-1">
                      {intl.formatMessage({
                        id: 'AddMoneyMethods.PlayForProgress.Description',
                        defaultMessage:
                          'Step 1 of The Play For Progress game. Do it within 14 days and you could win up to $1000 or premium KOHO products!',
                      })}
                    </Paragraph>
                  </div>
                </div>
              </BoxTw>
            )}
            {items.map((item) => (
              <Card
                component="button"
                onClick={item.onClick}
                key={item.title}
                className="w-full px-4 py-0 rounded-xl group block text-left"
              >
                <div className="flex py-4 items-center">
                  <div className="self-start mr-4 flex-shrink-0">
                    <div className="h-10 w-10 bg-primary-50 rounded-lg flex items-center justify-center">
                      <div className="text-primary-300">{item.icon}</div>
                    </div>
                  </div>
                  <div className="flex-grow">
                    <div>
                      <ParagraphBold className="text-grey-500 my-0">
                        {item.title}
                      </ParagraphBold>
                    </div>
                    <Paragraph className="text-grey-400 mb-1">
                      {item.description}
                    </Paragraph>
                    <Paragraph className="text-grey-400 kh-mb-0">
                      {item.timeline}
                    </Paragraph>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <ChevronRight className="text-grey-400 h-5 w-5 item-center self-center" />
                  </div>
                </div>
              </Card>
            ))}
          </div>
        )}
      </div>
      <DownloadApp
        open={isDownloadAppModalOpen}
        description={intl.formatMessage({
          id: 'AddMoneyMethods.DownloadApp.Description',
          defaultMessage: 'Deposit your cash with the app.',
        })}
        onClose={closeDownloadAppModal}
      />
    </TemplateTw>
  );
};

const useGetItems = (
  clickEvent: {
    etransfer: () => void;
    directDeposit: () => void;
    debitCard: () => void;
    cashLoads: () => void;
    billPayLoad: () => void;
  },
  velocityData?: IVelocityData,
): {
  icon: any;
  title: string;
  description: string;
  timeline?: string;
  loading?: boolean;
  onClick: () => void;
}[] => {
  const intl = useIntl();
  const localeContext = useContext(LocaleContext);

  const billPayLoadEnabled =
    useFlags()[AddFundsFeatureFlags.DepositViaBillPayEnabled];

  const debitCardLoadEnabled =
    useFlags()[AddFundsFeatureFlags.DebitCardLoadingEnabled];

  const items = [
    {
      icon: <KDSIcons.Icons.DeliveryV2 />,
      title: intl.formatMessage({
        id: 'AddMoneyMethods.eTransfer.Title',
        defaultMessage: 'INTERAC e-Transfer®️',
      }),
      description: velocityData
        ? intl.formatMessage(
            {
              id: 'AddMoneyMethods.eTransfer.VelocityDescription',
              defaultMessage: 'Up to {amount} at once',
            },
            {
              amount: localeContext
                .intlFormatMoney(
                  methodsMaxVelocityUtil(velocityData)[
                    MoneyMovementType.AutodepositETransfer
                  ],
                )
                .format('pretty'),
            },
          )
        : '',
      timeline: intl.formatMessage({
        id: 'AddMoneyMethods.eTransfer.TimelineDescription',
        defaultMessage: 'Arrives within 5 minutes',
      }),
      onClick: clickEvent.etransfer,
    },
    {
      icon: <KDSIcons.Icons.Calendar />,
      title: intl.formatMessage({
        id: 'AddMoneyMethods.DirectDeposit.Title',
        defaultMessage: 'Direct deposit',
      }),
      description: intl.formatMessage({
        id: 'AddMoneyMethods.DirectDeposit.Description',
        defaultMessage: 'Your paycheque, or government benefits.',
      }),
      timeline: intl.formatMessage({
        id: 'AddMoneyMethods.DirectDeposit.TimelineDescription',
        defaultMessage: 'Generally a day early',
      }),
      onClick: clickEvent.directDeposit,
    },

    {
      icon: <KDSIcons.Icons.DollarV2 />,
      title: intl.formatMessage({
        id: 'AddMoneyMethods.Cash.Title',
        defaultMessage: 'Cash deposit',
      }),
      description: velocityData
        ? intl.formatMessage(
            {
              id: 'AddMoneyMethods.Cash.VelocityDescription',
              defaultMessage: 'Up to {amount} at once',
            },
            {
              amount: localeContext
                .intlFormatMoney(
                  methodsMaxVelocityUtil(velocityData)[
                    MoneyMovementType.CashLoad
                  ],
                )
                .format('pretty'),
            },
          )
        : '',
      timeline: intl.formatMessage({
        id: 'AddMoneyMethods.Cash.TimelineDescription',
        defaultMessage: 'Arrives within 10 minutes',
      }),
      onClick: clickEvent.cashLoads,
    },
  ];

  if (billPayLoadEnabled) {
    items.push({
      icon: <KDSIcons.Icons.BillsV2 />,
      title: intl.formatMessage({
        id: 'AddMoneyMethods.BillPayLoad.Title',
        defaultMessage: 'Deposit via Bill Pay',
      }),
      description: velocityData
        ? intl.formatMessage(
            {
              id: 'AddMoneyMethods.BillPayLoad.VelocityDescription',
              defaultMessage: 'Up to {amount} at once',
            },
            {
              amount: localeContext
                .intlFormatMoney(
                  methodsMaxVelocityUtil(velocityData)[
                    MoneyMovementType.BillPay
                  ],
                )
                .format('pretty'),
            },
          )
        : '',
      timeline: intl.formatMessage({
        id: 'AddMoneyMethods.BillPayLoad.TimelineDescription',
        defaultMessage: 'Arrives within 1-3 days',
      }),
      onClick: clickEvent.billPayLoad,
    });
  }

  if (debitCardLoadEnabled) {
    items.push({
      icon: <KDSIcons.Icons.CreditCardV2 />,
      title: intl.formatMessage({
        id: 'AddMoneyMethods.DebitCard.Title',
        defaultMessage: 'Debit card',
      }),
      description: velocityData
        ? intl.formatMessage(
            {
              id: 'AddMoneyMethods.DebitCard.VelocityDescription',
              defaultMessage: 'Up to {amount} at once',
            },
            {
              amount: localeContext
                .intlFormatMoney(
                  methodsMaxVelocityUtil(velocityData)[
                    MoneyMovementType.EasyLoad
                  ],
                )
                .format('pretty'),
            },
          )
        : '',
      timeline: intl.formatMessage({
        id: 'AddMoneyMethods.DebitCard.TimelineDescription',
        defaultMessage: 'Arrives instantly',
      }),
      onClick: clickEvent.debitCard,
    });
  }

  return items;
};
