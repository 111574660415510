import { rootApi } from 'apis/rootApi';

type ContestStatus =
  | 'initiated'
  | 'qa_completed'
  | 'reward_allocated'
  | 'reward_viewed'
  | 'reward_claimed';
type ContestType = 'm1_activation';
type RewardKey = 'CASH_PRIZE' | 'CASHBACK_BOOST' | 'CREDIT_BUILDING_DISCOUNT';

// /2.0/contests/eligible_user
interface ContestEligibilityStatus {
  account_group_id: string;
  user_id: string;
  status: ContestStatus;
  contest_type: ContestType;
  reward_key: RewardKey;
  created_at: string;
  updated_at: string;
}

// /2.0/contests/is_eligible
interface ContestEligibilityResponse {
  is_eligible: boolean;
}

export const contestsApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getContestEligibility: build.query<ContestEligibilityResponse, void>({
      query: () => ({
        url: '/2.0/contests/is-eligible',
        method: 'POST',
      }),
      providesTags: ['ContestEligibility'],
    }),
    getContestStatus: build.query<ContestEligibilityStatus, void>({
      query: () => ({
        url: '/2.0/contests/eligible-user',
      }),
      providesTags: ['ContestEligibility'],
    }),
  }),
});

export const { useLazyGetContestEligibilityQuery, useGetContestStatusQuery } =
  contestsApi;
