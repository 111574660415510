/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import ButtonUnstyled from '../../components/ButtonUnstyled';

/** @deprecated use ShowHideButtonTw from components/ShowHideButtonTw */
export const ShowHideButton = ({
  visible,
  ...props
}: { visible: boolean } & React.ComponentPropsWithRef<'button'>) => {
  const intl = useIntl();
  return (
    <ButtonUnstyled
      tabIndex={-1}
      sx={{
        mb: 0,
        color: 'primary',
        fontWeight: 'bold',
        fontFamily: 'body',
        fontSize: 2,
        p: 1,
        minWidth: 50,
        textAlign: 'center',
      }}
      {...props}
    >
      {visible
        ? intl.formatMessage({
            id: 'ButtonShowHide.Hide',
            defaultMessage: 'Hide',
          })
        : intl.formatMessage({
            id: 'ButtonShowHide.Show',
            defaultMessage: 'Show',
          })}
    </ButtonUnstyled>
  );
};
