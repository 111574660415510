import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { RouteComponentProps } from '@reach/router';
import { selectTheme } from 'appState/appState.slice';
import appStoreEn from 'assets/images/app-download/apple-en.svg';
import appStoreFr from 'assets/images/app-download/apple-fr.svg';
import googlePlayEn from 'assets/images/app-download/google-en.png';
import googlePlayFr from 'assets/images/app-download/google-fr.png';
import kohoIconV2 from 'assets/images/app-download/koho-icon-v2.png';
import kohoIcon from 'assets/images/app-download/koho-icon.png';
import footerImg from 'assets/images/login/footer.png';
import {
  FooterImageWrapper,
  LOCALE_EN,
  LayoutBasic,
  LocaleContext,
} from 'components';
import { LinkTw } from 'components/LinkTw';
import { Paragraph, TitleMedium } from 'components/TypographyTw';

export const IdentityVerificationLandingPage = (props: RouteComponentProps) => {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const theme = useSelector(selectTheme);

  const kohoAppBadge = (
    <div className="flex flex-col flex-[1_0_50%] items-center md:items-end pr-0 md:pr-8">
      <Paragraph className="legacy:font-bold font-medium my-0 flex flex-col items-center text-grey-500">
        <img
          className="w-[70px] mb-2"
          src={theme === 'light' ? kohoIconV2 : kohoIcon}
          role="presentation"
          // alt not needed, text below describes the image
          alt=""
        />
        {intl.formatMessage({
          id: 'IdentityVerificationLandingPage.KohoFinancial',
          defaultMessage: 'KOHO Financial',
        })}
      </Paragraph>
    </div>
  );

  const appDownloadLinks = (
    <div className="flex flex-col items-center md:items-start flex-[1_0_50%] p-0 md:p-8">
      <LinkTw
        trackName="IdentityVerification Apple Download"
        external
        to="https://apps.apple.com/ca/app/koho-financial/id1091010942"
        data-cy="btn-appleDownload"
        aria-label={intl.formatMessage({
          id: 'IdentityVerificationLandingPage.DownloadFromApple',
          defaultMessage: 'Download KOHO from the Apple app store',
        })}
      >
        <img
          src={locale === LOCALE_EN ? appStoreEn : appStoreFr}
          alt={intl.formatMessage({
            id: 'IdentityVerificationLandingPage.DownloadFromApple',
            defaultMessage: 'Download KOHO from the Apple app store',
          })}
          className="w-[110px] md:w-[150px] mb-2 block"
        />
      </LinkTw>
      <LinkTw
        trackName="IdentityVerification Google Play Download"
        external
        to="https://play.google.com/store/apps/details?id=ca.koho"
        data-cy="btn-googleDownload"
        aria-label={intl.formatMessage({
          id: 'IdentityVerificationLandingPage.DownloadFromGoogle',
          defaultMessage: 'Download KOHO from Google Play',
        })}
      >
        <img
          src={locale === LOCALE_EN ? googlePlayEn : googlePlayFr}
          alt={intl.formatMessage({
            id: 'IdentityVerificationLandingPage.DownloadFromGoogle',
            defaultMessage: 'Download KOHO from Google Play',
          })}
          className="w-[110px] md:w-[150px] mb-2 block"
        />
      </LinkTw>
    </div>
  );

  return (
    <LayoutBasic
      className="legacy:bg-[#F5F5F5] bg-grey-50"
      pageName="Secure your account"
      includeLanguageToggle={true}
    >
      <div className="max-w-full md:max-w-[890px] mx-auto px-6 md:px-0">
        <TitleMedium className="text-center">
          {intl.formatMessage({
            id: 'IdentityVerificationLandingPage.PageTitle',
            defaultMessage: `For your account's security we need to verify your identity`,
          })}
        </TitleMedium>
        <Paragraph className="text-center mb-10 md:mb-8">
          {intl.formatMessage({
            id: 'IdentityVerificationLandingPage.PageBodyParagraph',
            defaultMessage: `Please download and use the KOHO app on your mobile device to securely verify your identity.`,
          })}
        </Paragraph>
        <div className="flex items-center mb-[64px]">
          {kohoAppBadge}
          {appDownloadLinks}
        </div>
      </div>
      {theme === 'legacy' && (
        <FooterImageWrapper bg="#F8A894" className="pb-2 relative bottom-0">
          <img
            className="relative z-[2] w-[95px] md:w-[125px]"
            role="presentation"
            // alt text not needed image is decorative
            alt=""
            src={footerImg}
          />
        </FooterImageWrapper>
      )}
    </LayoutBasic>
  );
};
