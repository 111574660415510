import { useMediaQuery, useTheme } from '@material-ui/core';
import { IBenefitGroup, IPlan } from 'apis/tiers';
import { twMerge } from 'tailwind-merge';
import { TierKey } from 'tiers/models';

import { TierCard } from './tierCard/index';

type Props = {
  boxClassName?: string;
  cardTrackName: string;
  handleSelectTier?: (tier: TierKey) => void;
  isFree?: boolean;
  onPlanChange?: (tier: IBenefitGroup, plan: IPlan | undefined) => void;
  plan?: string;
  selectable?: boolean;
  selectedTier?: TierKey;
  tiers: IBenefitGroup[];
  registrationFlow?: boolean;
};

export const Tiers = ({
  boxClassName,
  cardTrackName,
  handleSelectTier,
  isFree = false,
  onPlanChange,
  plan = 'monthly',
  selectable = false,
  selectedTier,
  tiers,
  registrationFlow = false,
}: Props) => {
  const muiTheme = useTheme();
  const isAfterTablet = useMediaQuery(muiTheme.breakpoints.up('md'));

  return (
    <div
      className={twMerge(
        'flex flex-col lg:flex-row gap-4 xl:gap-8 px-4 w-full',
        boxClassName,
      )}
    >
      {tiers
        .filter((benefit) => benefit.key !== TierKey.Easy)
        .map((benefit) => (
          <TierCard
            key={benefit.id}
            benefit={benefit}
            expandable={selectable && !isAfterTablet}
            selectable={selectable}
            selected={selectedTier === benefit.key}
            handleSelectTier={handleSelectTier}
            plan={plan}
            isFree={isFree}
            onPlanChange={onPlanChange}
            trackname={cardTrackName}
            registrationFlow={registrationFlow}
          />
        ))}
    </div>
  );
};
