import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { RouteComponentProps } from '@reach/router';
import { selectTheme } from 'appState/appState.slice';
// import { KDSIcons } from 'assets/images/kds_icons';
import { Paragraph, TitleMedium } from 'components/TypographyTw';

import phonesImageMC from '../assets/images/general/phoneSpongeCubeMC.png';
import {
  Card,
  FooterImageWrapper,
  LayoutBasic,
} from '../components/LayoutBasic';

export const GenericErrorPage = (props: RouteComponentProps) => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';

  return (
    <LayoutBasic pageName="Unidentified Error" className="bg-grey-75">
      <Card>
        {/* TODO: replace asset
        <KDSIcons.Spot.Wait className="mb-4" />
        */}
        <TitleMedium>
          {intl.formatMessage({
            id: 'ErrorGeneric.Title',
            defaultMessage: 'Oops! Something went wrong',
          })}
        </TitleMedium>
        <Paragraph>
          {intl.formatMessage({
            id: 'ErrorGeneric.Content',
            defaultMessage:
              'Please check your internet connection or try again in a few minutes. If the problem persists, please contact our support team.',
          })}
        </Paragraph>
      </Card>
      {!isLight && (
        <FooterImageWrapper>
          <img
            src={phonesImageMC}
            alt={`${intl.formatMessage({
              id: 'ErrorGeneric.FooterImageAltTag',
              defaultMessage: 'generic error footer image',
            })}`}
            className="w-[260px] relative z-[2]"
          />
        </FooterImageWrapper>
      )}
    </LayoutBasic>
  );
};
