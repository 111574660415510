import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Box,
  Card,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Skeleton } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { trackInput } from 'analytics/analytics';
import { useGetCurrentTierQuery } from 'apis/tiers';
import { KDSIcons } from 'assets/images/kds_icons';
import { Link, LocaleContext } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import DownloadApp from 'features/modals/DownloadApp';
import { theme } from 'theme';
import { TierKey } from 'tiers/models';
import Timeline from 'tiers/nba-incentives/NbaTimeline';

import NbaFAQ from './NbaFAQ';

const HasNotEarnedGiftCard: React.FC = () => {
  const intl = useIntl();
  const muiTheme = useTheme();
  const isTablet = useMediaQuery(muiTheme.breakpoints.up('sm'));
  const { deepLinks } = useContext(LocaleContext);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { data: tier, isLoading: getTierIsLoading } = useGetCurrentTierQuery();
  const isPaidTier = tier?.benefit_group?.key !== TierKey.Easy;

  if (getTierIsLoading)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignSelf="center"
        sx={{
          maxWidth: '500px',
          width: '100%',
        }}
      >
        <Skeleton variant="rect" width={500} height={450} animation="wave" />
      </Box>
    );

  const handleDownloadOnClick = () => {
    trackInput({
      type: 'Clicked',
      name: 'Nba - Banner - Download App',
      details: { element: 'a', to: '' },
    });

    setShowDownloadModal(true);
  };
  const handleOnClose = () => setShowDownloadModal(false);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gridGap="32px"
        sx={{
          py: '32px',
          px: '24px',
          mx: 'auto',
          backgroundColor: theme.colors.white,
          borderRadius: isTablet ? '8px' : '12px 12px 0px 0px',
          maxWidth: '500px',
          width: '100%',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '18px',
              color: '#1D428A',
              mb: '16px',
            }}
          >
            {intl.formatMessage({
              id: 'Nba.HasNotEarned.Content.Title',
              defaultMessage: 'How to get your e-Gift card',
            })}
          </Typography>
          <Timeline />
        </Box>

        <Card
          onClick={handleDownloadOnClick}
          sx={{
            borderRadius: '8px',
            border: `1px solid ${theme.colors.blueLight}`,
            boxShadow: '3px 6px 15px 0px #0000001A',
            padding: '16px',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              marginRight: '8px',
            }}
          >
            <KDSIcons.Icons.Download color="#1D428A" />
          </Box>
          <Box>
            <Box
              sx={{
                marginBottom: '8px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '16px',
                  color: theme.colors.text,
                }}
              >
                {intl.formatMessage({
                  id: 'Nba.HasNotEarned.DownloadLink.Title',
                  defaultMessage: 'Get the KOHO app to manage your membership',
                })}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '14px',
                  color: '#1D428A',
                }}
              >
                {intl.formatMessage({
                  id: 'Nba.HasNotEarned.DownloadLink.Subtitle',
                  defaultMessage: 'Download the app',
                })}
              </Typography>
              <ChevronRight sx={{ color: '#1D428A' }} />
            </Box>
          </Box>
        </Card>
        <NbaFAQ />
        <Box>
          <Link
            to={deepLinks.KOHO.NBATandC}
            trackName="Nba Incentives - Terms and Conditions"
            external={true}
            target="_blank"
            sx={{
              color: '#1D428A',
              fontWeight: 700,
              fontSize: '14px',
              textAlign: 'center',
              textDecoration: 'underline',
            }}
          >
            {intl.formatMessage({
              id: 'Nba.TermsAndConditions.Link',
              defaultMessage: 'Terms and conditions apply',
            })}
          </Link>
        </Box>
        <Box>
          {isPaidTier ? (
            <ButtonTw
              type="button"
              onClick={() => navigate('/transactions')}
              className="bg-[#1D428A]"
              variant="primary"
            >
              {intl.formatMessage({
                id: 'Nba.HasNotEarned.GotIt.CTA',
                defaultMessage: 'Got it!',
              })}
            </ButtonTw>
          ) : (
            <>
              <ButtonTw
                type="button"
                onClick={handleDownloadOnClick}
                trackName="Upgrade my plan"
                className="bg-[#1D428A]"
                variant="primary"
              >
                {intl.formatMessage({
                  id: 'Nba.HasNotEarned.UpgradePlan.CTA',
                  defaultMessage: 'Upgrade my plan',
                })}
              </ButtonTw>
              <ButtonTw
                onClick={() => navigate('/transactions')}
                type="button"
                variant="clear"
                className="text-[#1D428A] p-4"
              >
                {intl.formatMessage({
                  id: 'Nba.HasNotEarned.MaybeLater.CTA',
                  defaultMessage: 'Maybe later',
                })}
              </ButtonTw>
            </>
          )}
        </Box>
      </Box>
      <DownloadApp open={showDownloadModal} onClose={handleOnClose} />
    </>
  );
};

export default HasNotEarnedGiftCard;
