import { track } from 'analytics/analytics';
import { OnfidoApplicantReportType } from 'kyc/store/slice';
import { CommonSdkParameters } from 'onfido-sdk-ui/types/shared/SdkParameters';
import { StepDefinition } from 'onfido-sdk-ui/types/shared/Types';
import { legacyTheme } from 'styles/tw-themes/legacy';
import { lightTheme } from 'styles/tw-themes/light';

export type OnfidoInitOptions = {
  isLightTheme: boolean;
  locale?: string;
  onComplete: CommonSdkParameters['onComplete'];
  onError: CommonSdkParameters['onComplete'];
  reportTypes?: OnfidoApplicantReportType[];
  token?: string;
};

export const onfidoSteps: Record<OnfidoApplicantReportType, StepDefinition> = {
  document: {
    type: 'document',
    options: {
      useLiveDocumentCapture: true,
      uploadFallback: false,
      useUploader: false,
      documentTypes: {
        driving_licence: {
          country: 'CAN',
        },
        passport: true,
        national_identity_card: {
          country: 'CAN',
        },
        residence_permit: {
          country: 'CAN',
        },
      },
    },
  },
  face_video: {
    type: 'face',
    options: {
      useMultipleSelfieCapture: true,
      requestedVariant: 'video',
    },
  },
} as const;

export const initOnfido = async ({
  isLightTheme,
  locale,
  onComplete,
  onError,
  reportTypes,
  token,
}: OnfidoInitOptions): Promise<void> => {
  // import the library on demand to reduce unnecessary downloading
  // if user don't have to do Onfido
  const { Onfido } = await import('onfido-sdk-ui');
  const steps = reportTypes?.map((type) => onfidoSteps[type]);

  // Note: Onfido is loaded via an iframe so it doesn't have access to the tailwind css variables
  const theme = isLightTheme ? lightTheme : legacyTheme;

  if (!token || !Onfido || !steps?.length) {
    return;
  }

  track({ event: 'Onfido loaded' });

  Onfido.init({
    token,
    containerId: 'onfido-mount',
    crossDevicePolicy: 'force',
    steps,
    language: locale || 'en',
    customUI: {
      colorBackgroundSurfaceModal: 'white',
      borderWidthSurfaceModal: '0',
      fontFamilyTitle: isLightTheme
        ? `"Jokker Bold", Inter, system-ui, sans-serif`
        : `"Basis Grotesque Pro", system-ui, sans-serif`,
      fontSizeTitle: '32px',
      fontWeightTitle: 700,
      fontFamilySubtitle: isLightTheme
        ? `"Jokker Bold", Inter, system-ui, sans-serif`
        : `"Basis Grotesque Pro", system-ui, sans-serif`,
      fontSizeSubtitle: '16px',
      fontWeightSubtitle: 400,
      fontFamilyBody: isLightTheme
        ? `"Basel Grotesk", Poppins, system-ui, sans-serif`
        : `"Basis Grotesque Pro", system-ui, sans-serif`,
      fontSizeBody: '16px',
      fontWeightBody: 400,
      colorContentButtonPrimaryText: 'white',
      colorBorderLinkUnderline: theme.primary[300],
      colorContentLinkTextHover: theme.primary[300],
      colorBackgroundButtonPrimary: theme.primary[300],
      colorBackgroundButtonPrimaryHover: theme.primary[400],
      colorBackgroundButtonPrimaryActive: theme.primary[400],
      colorBorderButtonPrimary: theme.primary[300],
      colorContentButtonSecondaryText: theme.primary[300],
      colorBackgroundButtonSecondary: 'white',
      colorContentAlertInfo: 'white',
      colorBackgroundAlertInfo: theme.primary[300],
      colorBackgroundButtonSecondaryHover: theme.primary[300],
      colorBackgroundButtonSecondaryActive: theme.primary[300],
      colorBorderButtonSecondary: theme.primary[300],
      colorContentInfoPill: 'white',
      borderRadiusButton: '4px',
      colorBackgroundLinkActive: 'transparent',
      colorBackgroundLinkHover: 'transparent',
      colorIcon: theme.tertiary[400],
    },
    onComplete,
    onError,
  });
};
