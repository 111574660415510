import { useIntl } from 'react-intl';

import { RouteComponentProps } from '@reach/router';
import { LayoutBasic } from 'components';
import { ButtonLink } from 'components/ButtonTw';
import { Paragraph, TitleExtraLarge } from 'components/TypographyTw';

const NotFoundPage = (_: RouteComponentProps) => {
  const intl = useIntl();

  const image = `${import.meta.env.VITE_STATIC_URL}/not-found.png`;
  return (
    <LayoutBasic pageName="Not found">
      <div className="py-6 md:py-16">
        <div className="flex justify-center">
          <img
            src={image}
            alt={intl.formatMessage({
              id: 'notFound.imageAlt',
              defaultMessage: 'Page not found',
            })}
          />
        </div>
        <TitleExtraLarge className="uppercase md:text-6xl text-primary-350 mb-6 text-center">
          {intl.formatMessage({
            id: 'notFound.title',
            defaultMessage: 'Oops, this page is lost.',
          })}
        </TitleExtraLarge>
        <Paragraph className="text-center md:text-xl text-grey-500 mb-12">
          {intl.formatMessage({
            id: 'notFound.description',
            defaultMessage:
              'Breathe easy, your finances are still flourishing.',
          })}
        </Paragraph>
        <div className="flex justify-center">
          <ButtonLink to="/" className="w-auto inline-block px-4">
            {intl.formatMessage({
              id: 'notFound.button',
              defaultMessage: 'Take me home',
            })}
          </ButtonLink>
        </div>
      </div>
    </LayoutBasic>
  );
};

export default NotFoundPage;
