import { SubtitleSmall } from 'components/TypographyTw';
import { twMerge } from 'tailwind-merge';

export const khChipTypes = [
  'light',
  'dark',
  'outline',
  'outline-light',
] as const;

export type KhChipType = (typeof khChipTypes)[number];

export type KhChipDecorationType = 'stars' | null;

export const khChipThemes = [
  'primary',
  'secondary',
  'accent',
  'success',
  'warning',
  'danger',
  'grey',
] as const;

export type KhChipTheme = (typeof khChipThemes)[number];

const khChipColorClassMap: {
  [key in KhChipType]: {
    [key in KhChipTheme]: { chip: string; stars: string };
  };
} = {
  light: {
    primary: {
      chip: 'border-primary-75 text-primary-400 bg-primary-75',
      stars: 'fill-primary-400',
    },
    secondary: {
      chip: 'text-success-400 bg-secondary-75 border-secondary-75',
      stars: 'fill-success-400',
    },
    accent: {
      chip: 'text-accent-400 bg-accent-75 border-accent-75',
      stars: 'fill-accent-400',
    },
    success: {
      chip: 'text-success-400 bg-success-75 border-success-75',
      stars: 'fill-success-400',
    },
    warning: {
      chip: 'text-warning-400 bg-danger-50 border-danger-50',
      stars: 'fill-warning-400',
    },
    danger: {
      chip: 'text-danger-300 bg-danger-75 border-danger-75',
      stars: 'fill-danger-300',
    },
    grey: {
      chip: 'text-greyscale-400 bg-black/10 border-transparent',
      stars: 'hidden',
    },
  },
  dark: {
    primary: {
      chip: 'text-white bg-primary-300 border-primary-300',
      stars: 'fill-primary-300 stroke-white/100',
    },
    secondary: {
      chip: 'text-white bg-success-400 border-success-400',
      stars: 'fill-success-400 stroke-white/100',
    },
    accent: {
      chip: 'text-white bg-accent-400 border-accent-400',
      stars: 'fill-accent-400 stroke-white/100',
    },
    success: {
      chip: 'text-white bg-success-300 border-success-300',
      stars: 'fill-success-300 stroke-white/100',
    },
    warning: {
      chip: 'text-white bg-warning-400 border-warning-400',
      stars: 'fill-warning-400 stroke-white/100',
    },
    danger: {
      chip: 'text-white bg-danger-200 border-danger-200',
      stars: 'fill-danger-200 stroke-white/100',
    },
    grey: {
      chip: 'text-white bg-white/30 border-transparent',
      stars: 'hidden',
    },
  },
  outline: {
    primary: {
      chip: 'text-primary-400 bg-transparent border-primary-100',
      stars: 'fill-primary-400',
    },
    secondary: {
      chip: 'text-success-400 border-secondary-100 bg-transparent',
      stars: 'fill-success-400',
    },
    accent: {
      chip: 'text-accent-400 bg-transparent border-accent-75',
      stars: 'fill-accent-400',
    },
    success: {
      chip: 'text-success-400 bg-transparent border-success-100',
      stars: 'fill-success-400',
    },
    warning: {
      chip: 'text-warning-400 bg-transparent border-warning-100',
      stars: 'fill-warning-400',
    },
    danger: {
      chip: 'text-danger-200 bg-transparent border-danger-100',
      stars: 'fill-danger-200',
    },
    grey: {
      chip: 'text-greyscale-400 bg-transparent border-black/20',
      stars: 'hidden',
    },
  },
  'outline-light': {
    primary: {
      chip: 'text-primary-50 bg-transparent border-primary-75',
      stars: 'fill-primary-75',
    },
    secondary: {
      chip: 'text-secondary-50 bg-transparent border-secondary-100',
      stars: 'fill-secondary-75',
    },
    accent: {
      chip: 'text-accent-50 bg-transparent border-accent-75',
      stars: 'fill-accent-75',
    },
    success: {
      chip: 'text-success-50 bg-transparent border-success-75',
      stars: 'fill-success-75',
    },
    warning: {
      chip: 'text-warning-50 bg-transparent border-warning-75',
      stars: 'fill-warning-75',
    },
    danger: {
      chip: 'text-danger-50 bg-transparent border-danger-75',
      stars: 'fill-danger-75',
    },
    grey: {
      chip: 'text-white bg-transparent border-greyscale-75',
      stars: 'hidden',
    },
  },
};

export const KhChip = ({
  children,
  chipDecoration,
  chipType = 'dark',
  chipTheme = 'primary',
  className,
  chipIcon,
  ...props
}: {
  chipDecoration?: KhChipDecorationType;
  chipType?: KhChipType;
  chipTheme?: KhChipTheme;
  className?: string;
  children: string;
  chipIcon?: React.ReactNode;
}) => {
  return (
    <div
      className={twMerge(
        khChipColorClassMap[chipType][chipTheme].chip,
        `relative rounded-3xl px-2 py-[3px] inline-flex flex-grow-0 w-fit gap-1 border border-solid items-center`,
        className,
      )}
      {...props}
    >
      {chipIcon}
      <SubtitleSmall className="text-current">{children}</SubtitleSmall>
      {chipDecoration === 'stars' ? (
        <>
          <StarSVG
            className={twMerge(
              khChipColorClassMap[chipType][chipTheme].stars,
              'absolute top-[-2px] left-[-2px]',
            )}
          />
          <StarSVG
            className={twMerge(
              khChipColorClassMap[chipType][chipTheme].stars,
              'absolute bottom-[-2px] right-[-2px]',
            )}
          />
        </>
      ) : null}
    </div>
  );
};

const StarSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path d="M5.75729 1.13942C5.81985 0.885981 6.18015 0.885982 6.24271 1.13942L6.89841 3.79548C7.07645 4.51669 7.63956 5.0798 8.36077 5.25784L11.0168 5.91354C11.2703 5.9761 11.2703 6.3364 11.0168 6.39896L8.36077 7.05466C7.63956 7.2327 7.07645 7.79581 6.89841 8.51702L6.24271 11.1731C6.18015 11.4265 5.81985 11.4265 5.75729 11.1731L5.10159 8.51702C4.92355 7.79581 4.36044 7.2327 3.63923 7.05466L0.983174 6.39896C0.729731 6.3364 0.729732 5.9761 0.983175 5.91354L3.63923 5.25784C4.36044 5.0798 4.92355 4.51669 5.10159 3.79548L5.75729 1.13942Z" />
  </svg>
);

const chipDecorations = [null, 'stars'] as const;

const chips = khChipThemes.flatMap((chipTheme) => {
  return khChipTypes.flatMap((chipType) => {
    return chipDecorations.map((chipDecoration) => {
      return {
        chipTheme,
        chipType,
        chipDecoration,
      };
    });
  });
});

export const ChipShowcase = () => {
  return (
    <div className="grid grid-cols-[repeat(8,max-content)] justify-items-center items-center my-16">
      {chips.map((chip) => (
        <div
          className={twMerge(
            'p-2',
            chip.chipType === 'outline-light' ||
              (chip.chipTheme === 'grey' && chip.chipType === 'dark')
              ? 'bg-primary-400'
              : '',
          )}
        >
          <KhChip
            chipTheme={chip.chipTheme}
            chipDecoration={chip.chipDecoration}
            chipType={chip.chipType}
          >
            chip
          </KhChip>
        </div>
      ))}
      {chips.map((chip) => (
        <div
          className={twMerge(
            'p-2',
            chip.chipType === 'outline-light' ||
              (chip.chipTheme === 'grey' && chip.chipType === 'dark')
              ? 'bg-primary-400'
              : '',
          )}
        >
          <KhChip
            chipTheme={chip.chipTheme}
            chipDecoration={chip.chipDecoration}
            chipType={chip.chipType}
            chipIcon={<StarSVG className="fill-current" />}
          >
            chip
          </KhChip>
        </div>
      ))}
    </div>
  );
};
