import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  IBenefitGroup,
  IPlan,
  useGetChangeEstimateQuery,
  useGetCurrentTierQuery,
} from 'apis/tiers';

import { FreeTrialStepsProps } from '../upgradeConfirmationModal/FreeTrialSteps';
import { UpgradeStepsProps } from '../upgradeConfirmationModal/UpgradeSteps';

const StepsPlaceholder = () => {
  return (
    <div className="flex flex-col gap-5 ml-9 w-[70%]">
      <Skeleton variant="rect" height="36px" className="rounded-[4px]" />
      <Skeleton variant="rect" height="36px" className="rounded-[4px] mb-5" />
    </div>
  );
};

type Props = {
  selectedPlan: IPlan;
  selectedTier: IBenefitGroup;
  children: (props: FreeTrialStepsProps & UpgradeStepsProps) => JSX.Element;
};

export const Steps = ({ selectedPlan, selectedTier, children }: Props) => {
  const {
    data: activeTier,
    isLoading: activeTierIsLoading,
    isError: activeTierError,
  } = useGetCurrentTierQuery();
  const {
    data: changeEstimate,
    isLoading: changeEstimteIsLoading,
    isError: changeEstimateError,
  } = useGetChangeEstimateQuery({
    frequency: selectedPlan.key,
    tier: selectedTier.key,
  });

  const notLoadedSuccessfully =
    changeEstimteIsLoading ||
    changeEstimateError ||
    activeTierIsLoading ||
    activeTierError ||
    !activeTier ||
    !changeEstimate;

  return (
    <div className="flex flex-row">
      <div className="relative h-fit w-full">
        <Box className="absolute bg-gradient-to-b from-grey-75 via-grey-75 legacy:via-90% via-75% to-white w-[22px] h-full rounded-t-full mr-4" />
        {notLoadedSuccessfully ? (
          <StepsPlaceholder />
        ) : (
          children({
            tier: selectedTier,
            plan: selectedPlan,
            changeEstimate,
            activeTier,
          })
        )}
      </div>
    </div>
  );
};
