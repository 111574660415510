import { HttpResponse, http } from 'msw';

const registrationContextHandlers = [
  http.get(/\/context/, () => {
    return HttpResponse.json(
      {
        identity_only: false,
        has_account: false,
        user_reference_identifier: 'ABc123Def',
        email: 'test+mocked@koho.ca',
      },
      { status: 200 },
    );
  }),
];

export default registrationContextHandlers;
