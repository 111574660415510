import { ReactNode } from 'react';

import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

type CustomBox = {
  variant?: 'basic' | 'outlined' | 'shadow';
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
};

export const BoxTw = ({
  variant = 'basic',
  className,
  children,
  onClick,
  ...props
}: CustomBox) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onClick?.();
    }
  };

  const isInteractive = Boolean(onClick);

  return (
    <div
      {...props}
      onClick={onClick}
      onKeyDown={isInteractive ? handleKeyDown : undefined}
      tabIndex={isInteractive ? 0 : undefined}
      className={twMerge(
        clsx(
          variant === 'outlined' &&
            'legacy:rounded-[5px] rounded-lg border border-solid legacy:border-[#CCC] border-grey-100',
          variant === 'shadow' &&
            'legacy:[box-shadow:rgba(0,0,0,0.1)_3px_3px_10px_0px] legacy:rounded-[5px] rounded-lg',
          className,
        ),
      )}
    >
      {children}
    </div>
  );
};
