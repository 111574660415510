export const knownUtmProperties = [
  'utm_ad_id',
  'utm_adset_id',
  'utm_ad_name',
  'utm_adset_name',
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
];

// save utm_'s into session (not cookies because we don't want any conflicts
// with the koho.ca implementation
// koho.ca will set these in cookies and store for 30 days and
// pass over to web.koho.ca the cookies on koho.ca can be the source of truth,
// and also segment automatically keeps track of the utm_'s on all events
// this snippet here is mainly for intercom for now

export const storeUtmsInSession = () => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.forEach((val, key) => {
    const lowerCaseKey = key.toLocaleLowerCase();
    if (knownUtmProperties.includes(lowerCaseKey)) {
      sessionStorage.setItem(lowerCaseKey, val);
    }
  });
};

export const getUtmsFromSession = (): {
  utm_ad_id?: string;
  utm_adset_id?: string;
  utm_ad_name?: string;
  utm_adset_name?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
} =>
  knownUtmProperties.reduce((memo, utmKey) => {
    const utmValue = sessionStorage.getItem(utmKey);

    if (utmValue) {
      return { ...memo, ...{ [utmKey]: utmValue } };
    }

    return memo;
  }, {});
