import { useEffect } from 'react';

import { useToggle } from 'hooks/useToggle';
import { hashEmail } from 'utility/hashEmail';

export const amazonTrackEvent = (eventName: string, email?: string) => {
  const userData = { email, gdpr: { enabled: 'false' }, ttl: 604800 }; // 7 days TTL
  const emailHashed = email ? hashEmail(email) : '';

  (window as any).amzn('setUserData', userData);
  (window as any).amzn('trackEvent', eventName, {
    ...(emailHashed ? { clientDedupeId: emailHashed } : {}),
  });
};

export const useAmazonTrackStartRegistration = () => {
  const { value: tracked, on: setTracked } = useToggle();
  const amazonTrackingInitialized =
    typeof window !== 'undefined' && !!(window as any)?.amzn;

  useEffect(() => {
    if (!tracked && amazonTrackingInitialized) {
      setTracked();
      amazonTrackEvent('StartRegistration');
    }
  }, [tracked, amazonTrackingInitialized, setTracked]);
};
