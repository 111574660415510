/** @jsxImportSource theme-ui */
import { ReactNode } from 'react';

import { Link as InternalLink } from '@reach/router';
import { ThemeUIStyleObject } from 'theme-ui';

import { track } from '../analytics/analytics';

/** @deprecated - use LinkTw from components/LinkTw */
export const Link = ({
  children,
  trackName,
  to,
  external = false,
  target = '_blank',
  className,
  linkSx,
  ...props
}: {
  children: ReactNode;
  trackName: string;
  to: string;
  external?: boolean;
  target?: '_self' | '_blank';
  className?: string;
  linkSx?: ThemeUIStyleObject;
}) => {
  const handleClick = () => {
    track({
      event: `Clicked`,
      properties: {
        element: 'a',
        name: trackName,
        to: to,
      },
    });
  };

  const style = {
    color: 'primary',
    transition: '0.3s',
    fontWeight: 4,
    textDecoration: 'none',
    '&:hover': {
      filter: 'saturate(170%)',
    },
    ...linkSx,
  };

  if (external) {
    return (
      <a
        sx={style}
        className={className}
        onClick={handleClick}
        href={to}
        target={target}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <InternalLink
      className={className}
      sx={style}
      onClick={handleClick}
      to={to}
      {...props}
    >
      {children}
    </InternalLink>
  );
};
