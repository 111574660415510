import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { navigate } from '@reach/router';
import { INbaIncentiveEligibility } from 'apis/tiers';
import { Link, LocaleContext } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { theme } from 'theme';

import NbaFAQ from './NbaFAQ';

const EarnedGiftCard = ({ data }: { data: INbaIncentiveEligibility }) => {
  const intl = useIntl();
  const muiTheme = useTheme();
  const isTablet = useMediaQuery(muiTheme.breakpoints.up('sm'));
  const { deepLinks } = useContext(LocaleContext);
  const { reward_link } = data;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gridGap="32px"
        sx={{
          py: '32px',
          px: '24px',
          mx: 'auto',
          backgroundColor: theme.colors.white,
          borderRadius: isTablet ? '8px' : '12px 12px 0px 0px',
          maxWidth: '500px',
          width: '100%',
        }}
      >
        <Link
          to={reward_link}
          external={true}
          trackName="Nba Incentives - NBAStore.ca"
          sx={{
            borderRadius: '8px',
            border: `1px solid ${theme.colors.blueLight}`,
            boxShadow: '3px 6px 15px 0px #0000001A',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              marginBottom: '8px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '16px',
                color: theme.colors.text,
              }}
            >
              {intl.formatMessage({
                id: 'Nba.EarnedGiftCard.Title',
                defaultMessage: 'How to use your NBAStore.ca e-Gift card',
              })}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '12px',
                color: theme.colors.midGrey,
              }}
            >
              {intl.formatMessage({
                id: 'Nba.EarnedGiftCard.Subtitle',
                defaultMessage:
                  'Go to the NBAStore.ca where you’ll see your e-Gift card code and PIN.',
              })}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignSelf="center"
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '14px',
                color: '#1D428A',
              }}
            >
              {intl.formatMessage({
                id: 'Nba.EarnedGiftCard.Link',
                defaultMessage: 'Go to NBAStore.ca',
              })}
            </Typography>
            <ChevronRight sx={{ color: '#1D428A' }} />
          </Box>
        </Link>
        <NbaFAQ />
        <Box>
          <Link
            to={deepLinks.KOHO.NBATandC}
            trackName="Nba Incentives - Terms and Conditions"
            external={true}
            target="_blank"
            sx={{
              color: '#1D428A',
              fontWeight: 700,
              fontSize: '14px',
              textAlign: 'center',
              textDecoration: 'underline',
            }}
          >
            {intl.formatMessage({
              id: 'Nba.TermsAndConditions.Link',
              defaultMessage: 'Terms and conditions apply',
            })}
          </Link>
        </Box>
        <Box>
          <ButtonTw
            type="button"
            onClick={() => navigate('/transactions')}
            trackName="Upgrade my plan"
            className="bg-[#1D428A]"
            variant="primary"
          >
            {intl.formatMessage({
              id: 'Nba.EarnedGiftCard.CTA',
              defaultMessage: 'Got it',
            })}
          </ButtonTw>
        </Box>
      </Box>
    </>
  );
};

export default EarnedGiftCard;
