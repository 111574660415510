import { createSlice } from '@reduxjs/toolkit';
import { ICurrentBenefit, tiersApi } from 'apis/tiers';

export interface TiersState {
  is_paid_tier: boolean;
  data: ICurrentBenefit | null;
}

const initialState: TiersState | undefined = {
  is_paid_tier: false,
  data: null,
};

const tiersSlice = createSlice({
  name: 'tiers',
  initialState,
  reducers: {
    noop: (state) => state,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      tiersApi.endpoints.getCurrentTier.matchFulfilled,
      (state, { payload }) => {
        state.data = payload;

        // Check if the user is on a paid tier
        state.is_paid_tier =
          payload.subscription_id !== '' &&
          payload.subscription_id !== 'default';
      },
    );
  },
});

export const tiersActions = tiersSlice.actions;
export default tiersSlice;
