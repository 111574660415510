import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useAddressSearchQuery,
  useRetrieveAddressQuery,
} from 'apis/registration';
import { ButtonTw } from 'components/ButtonTw';
import CircularProgress from 'components/CircularProgress';
import { ParagraphSmall } from 'components/TypographyTw';
import { KhInputTextTw } from 'components/inputs/KhInputTextTw';
import debounce from 'lodash/debounce';
import { Address, AddressSearch } from 'models/Address';
import { twMerge } from 'tailwind-merge';

const ESCKeyCode = 27;

export const RegistrationAddressSearch = ({
  addressSelected,
  cantFindAddressClicked,
  className,
}: {
  addressSelected(address: Address): void;
  cantFindAddressClicked(): void;
  className: string;
}) => {
  const intl = useIntl();
  const [options, setOptions] = useState<AddressSearch[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [addressSearchResponse, setAddressSearchResponse] =
    useState<AddressSearch | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<AddressSearch | null>(
    null,
  );
  const containerRef = useRef<HTMLDivElement>(null);

  const addressSearchQueryResult = useAddressSearchQuery(
    {
      searchValue: searchTerm,
      lastId: addressSearchResponse?.id,
    },
    { skip: !searchTerm },
  );
  const addressRetrieveQueryResult = useRetrieveAddressQuery(
    selectedAddress?.id ?? skipToken,
  );

  useEffect(() => {
    if (addressSearchQueryResult.data) {
      const cantFindAddressOption: AddressSearch = {
        id: '',
        text: intl.formatMessage({
          id: 'Registration.AddressSearch.CantFindAddressOption',
          defaultMessage: "Can't find your address?",
        }),
        description: '',
      };

      const addresses = addressSearchQueryResult.data.concat([
        cantFindAddressOption,
      ]);
      setOptions(addresses || [cantFindAddressOption]);
      setMenuOpen(true);
    }
  }, [intl, addressSearchQueryResult.data]);

  useEffect(() => {
    if (addressRetrieveQueryResult.data) {
      addressSelected(addressRetrieveQueryResult.data[0]);
      setMenuOpen(false);
    }
  }, [addressSelected, addressRetrieveQueryResult.data]);

  const onChangeSearchTerm = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsLoading(true);
    const value = event.target.value;

    if (value) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }

    debounceFn(value);
  };

  const handleDebounceFn = (value: string) => {
    setSearchTerm(value);
    setIsLoading(false);
  };

  const debounceFn = useMemo(() => debounce(handleDebounceFn, 1000), []);

  const handleOutsideClick = (e) => {
    // click outside address input or paper
    if (!containerRef?.current?.contains(e.target)) {
      setMenuOpen(false);
    }
  };

  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === ESCKeyCode || e.key === 'Escape') {
      e.preventDefault();
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const isLoading =
    addressSearchQueryResult.isLoading ||
    addressRetrieveQueryResult.isLoading ||
    loading;

  return (
    <div ref={containerRef} className={twMerge('relative', className)}>
      <KhInputTextTw
        name="addressSearch"
        label={`${intl.formatMessage({
          id: 'Registration.AddressSearch.Label',
          defaultMessage: 'Address Search',
        })}`}
        placeholder={`${intl.formatMessage({
          id: 'Registration.AddressSearch.Placeholder',
          defaultMessage: 'Start typing...',
        })}`}
        id="addressSearch"
        onChange={onChangeSearchTerm}
        onKeyDown={handleOnKeyDown}
        autoComplete="off"
        data-cy="registration-address-search-input"
        trackName="address-search"
      />
      <Paper
        elevation={3}
        sx={{
          position: 'absolute',
          zIndex: 2,
          py: 2,
          mb: 2,
          display: menuOpen ? 'block' : 'none',
          maxWidth: ['375px'],
          width: '100%',
        }}
        role={isLoading ? undefined : 'menu'}
      >
        {isLoading ? (
          <CircularProgress className="flex m-auto" />
        ) : (
          options.map((address, i) => (
            <MenuItem
              key={address.id}
              onClick={() => {
                if (address.next === 'Retrieve') {
                  setSelectedAddress(address);
                } else if (address.next === 'Find') {
                  setAddressSearchResponse(address);
                } else {
                  cantFindAddressClicked();
                }
              }}
              tabIndex={0}
              data-cy="registration-address-search-item"
              className="block whitespace-normal"
            >
              <p className="light:font-[535] light:text-grey-500">
                {address.text}
              </p>
              <ParagraphSmall className="text-grey-400">
                {address.description}
              </ParagraphSmall>
            </MenuItem>
          ))
        )}
        {(addressSearchQueryResult.isError ||
          addressRetrieveQueryResult.isError) &&
          !loading && (
            <MenuItem style={{ whiteSpace: 'normal', display: 'block' }}>
              <ParagraphSmall className="font-bold mb-2 text-center text-danger-300">
                {intl.formatMessage({
                  id: 'Registration.AddressSearch.Error.Header',
                  defaultMessage: "Our search didn't work",
                })}
              </ParagraphSmall>
              <ParagraphSmall className="font-light mb-2 text-center text-danger-300">
                {intl.formatMessage({
                  id: 'Registration.AddressSearch.Error.Subheader',
                  defaultMessage:
                    'Please manually enter your address to continue.',
                })}
              </ParagraphSmall>
              <ButtonTw
                onClick={cantFindAddressClicked}
                type="button"
                variant="secondary"
                trackName="registration-address-search-error-manual-fallback-btn"
              >
                {intl.formatMessage({
                  id: 'Registration.AddressSearch.Error.Button',
                  defaultMessage: 'Enter address',
                })}
              </ButtonTw>
            </MenuItem>
          )}
      </Paper>
    </div>
  );
};
