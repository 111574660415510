import { showSnackbar } from 'components';
import { TierKey, TierName } from 'tiers/models';

import { rootApi } from './rootApi';

export interface IBenefitGroup {
  benefits: IBenefit[];
  id: string;
  is_trial_eligible: boolean;
  key: TierKey;
  name: TierName;
  plans?: IPlan[];
  average_savings_per_month: string;
  average_savings_per_year: string;
}

interface ICurrentBenefitGroup extends IBenefitGroup {
  /** @deprecated Do Not Use: Benefits are inaccurate */
  benefits: IBenefit[];
}

export interface IPlan {
  id: string;
  amount_cents: number;
  key: string;
}

export interface IBenefit {
  id: string;
  key: string;
  name: string;
  type: string;
  unit: string;
  value: string;
}

export interface IDelayedDowngrade {
  account_benefit_group_id: string;
  benefit_group: ICurrentBenefitGroup | null;
  benefit_group_id: string;
  benefit_group_plan_id: string;
  created_at: Date | null;
  id: string;
  updated_at: Date | null;
}

export interface ICurrentBenefit {
  account_group_id: string;
  benefit_group_id: string;
  benefit_group_set_id: string;
  benefit_group: ICurrentBenefitGroup | null;
  created_at: Date | null;
  delayed_downgrade?: IDelayedDowngrade;
  deleted_at?: Date | null;
  end_date: Date | null;
  free_trial_end_date: Date | null;
  frequency: string;
  id: string;
  legacy_start_date?: Date | null;
  next_payment_date: Date | null;
  start_date: Date | null;
  status: string;
  subscription_id: string;
  trial_days_remaining: string;
  updated_at: Date | null;
}

interface ManageSubscriptionArgs {
  benefit_group_id: string;
  benefit_group_plan_id: string;
  subscription_id: string;
}

export interface IChangeEstimate {
  prorated_subscription_amount: string;
  next_billing_date: Date | null;
}

interface ChangeEstimateArgs {
  frequency: string;
  tier: TierKey;
}

export interface INbaIncentiveEligibility {
  is_eligible: boolean; // true if user signed up with NBA code
  reward_link: string; // empty string if user has not been rewarded
}

export const tiersApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentTier: build.query<ICurrentBenefit, void>({
      query: () => ({
        url: '1.0/benefits/subscriptions/current',
        method: 'GET',
      }),
      providesTags: ['User', 'Tiers'],
    }),
    getTiers: build.query<IBenefitGroup[], void>({
      query: () => ({
        url: '1.0/benefits/benefit-groups',
        method: 'GET',
      }),
      transformResponse: (response: { data: IBenefitGroup[] }) => response.data,
      providesTags: ['User', 'Tiers'],
    }),
    manageSubscription: build.mutation<void, ManageSubscriptionArgs>({
      query: ({
        benefit_group_id,
        benefit_group_plan_id,
        subscription_id,
      }) => ({
        url: `1.0/benefits/subscriptions/${subscription_id}`,
        method: 'PUT',
        body: {
          benefit_group_id,
          benefit_group_plan_id,
        },
      }),
      invalidatesTags: ['User', 'Tiers'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          showSnackbar('Something went wrong trying to subscribe to a tier', {
            type: 'error',
          });
        }
      },
    }),
    getNbaEligibility: build.query<INbaIncentiveEligibility, void>({
      query: () => ({
        url: '1.0/my-plan/nba',
        method: 'GET',
      }),
      providesTags: ['NBA'],
    }),
    getChangeEstimate: build.query<IChangeEstimate, ChangeEstimateArgs>({
      query: ({ frequency, tier }) => ({
        url: `/1.0/benefits/subscriptions/change-estimate?frequency=${frequency}&tier_name=${tier}`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    cancelPlan: build.mutation<void, string>({
      query: (subscription_id) => ({
        url: `1.0/benefits/subscriptions/${subscription_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tiers'],
    }),
    cancelDowngrade: build.mutation<void, void>({
      query: () => ({
        url: '1.0/benefits/subscriptions/current/downgrades',
        method: 'DELETE',
      }),
      invalidatesTags: ['Tiers'],
    }),
    cancelPendingCancellation: build.mutation<void, void>({
      query: () => ({
        url: '1.0/benefits/subscriptions/reactivate',
        method: 'PATCH',
      }),
      invalidatesTags: ['Tiers'],
    }),
  }),
});

export const {
  useCancelDowngradeMutation,
  useCancelPendingCancellationMutation,
  useCancelPlanMutation,
  useGetChangeEstimateQuery,
  useGetCurrentTierQuery,
  useLazyGetCurrentTierQuery,
  useGetNbaEligibilityQuery,
  useGetTiersQuery,
  useManageSubscriptionMutation,
} = tiersApi;
