import { Skeleton } from '@material-ui/lab';
import { IBenefitGroup, useGetTiersQuery } from 'apis/tiers';
import { TierKey } from 'tiers/models';

function getInterestFromEverything(data: IBenefitGroup[]): string {
  const everythingTier = data.find((tier) => tier.key === TierKey.Everything);

  const interest = everythingTier?.benefits.find(
    (benefit) => benefit.key === 'save-rate',
  );

  return interest?.value || '';
}

/**
 * Example usage:
 *
 * const { rate, rateSkeleton } = useInterestFromTiers();
 *
 * {intl.formatMessage(
 *   {
 *     id: 'Page.Modal.Description',
 *     defaultMessage: 'Earn up to <rateSkeleton>{rate}</rateSkeleton>% interest'
 *   },
 *   { rate, rateSkeleton },
 * )}
 */
export function useInterestFromTiers() {
  const { data: tiers } = useGetTiersQuery();

  const rate = tiers ? getInterestFromEverything(tiers) : '';

  const rateSkeleton = (str: string) => {
    if (tiers && str !== '') {
      return str;
    } else {
      return (
        <Skeleton className="inline-flex" variant="text" width={'0.6em'} />
      );
    }
  };

  return { rate, rateSkeleton };
}
