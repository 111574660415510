//apis
import * as braze from '@braze/web-sdk';
// analytics
import * as Sentry from '@sentry/browser';
import { identify, segmentAnalytics, track } from 'analytics/analytics';
import { trackGaIds } from 'analytics/ga';
import { getUtmsFromSession } from 'analytics/utm';
import { rootApi } from 'apis/rootApi';
import { authActions } from 'auth/store/slice';
import { format } from 'date-fns';
import {
  FingerprintEventTypes,
  FingerprintsData,
} from 'fingerprints/fingerprints';
// utils
import { intercomUpdate } from 'intercom/intercom';
import { IntendedUse } from 'kyc/models/IntendedUse';
// models
import { Sources } from 'models';
import {
  Address,
  AddressCompleteResponse,
  AddressSearch,
} from 'models/Address';
import { impactRadiusSearchParamKey } from 'registration/pages/RegistrationMainFlowPage';
import shajs from 'sha.js';
import { RootState } from 'store';
// interface
import { UAParser } from 'ua-parser-js';
import { formatDate } from 'utility/formatDate';
import { trimObjectStrings } from 'utility/trimObjectStrings';

import { commissionUserRegistered } from '../analytics/rakuten';
import { getDeviceID } from '../libs/axios';
import { profileActions } from '../profile/store/slice';

export enum TermsVersion {
  visa = 1,
  mastercard = 2,
}

export enum MarketingConsentSubscriptionStatus {
  Subscribed = 'subscribed',
  Unsubscribed = 'unsubscribed',
  OptedIn = 'opted_in',
}

interface RegistrationIdentitiesRequest {
  email: string;
  password: string;
}

export enum RegistrationIdentityErrorCodes {
  DecodeError = 400001,
  MissingUserId = 400002,
  MissingOrganization = 400003,
  InternalClientError = 400004,
  EmailQuality = 400005,
  EmailDuplicate = 400006,
  EmailTypo = 400007,
  FailedIdentityAuth = 400008,
  PasswordHistory = 400009,
  PasswordLength = 400010,
  PasswordUpper = 400011,
  PasswordLower = 400012,
  UsernameViolation = 400014,
  ProviderViolation = 400015,
  FailedIdentityUnknown = 400016,
  FailedEmailCheck = 400017,
  PostIdentitiesBadRequest = 400018,
  ExistingIdentityBadPass = 400031,
  AuthenticateNotAuthorized = 401001,
  TooManyRequests = 429001,
  IdentitiesInternalServerError = 500003,
  EmailCheckInternalServerError = 500004,
  IdentitiesUnknownError = 500005,
  EmailCheckUnknownError = 500006,
  AuthenticationUnknownError = 500007,
}

export enum RegistrationUsersErrorCodes {
  OnboardingBlockedNameDOBLimit = 400032,
  OnboardingBlockedPostalCodeLimit = 400033,
  OnboardingBlockedDeviceIdLimit = 400034,
  OnboardingBlockedPhoneNumberLimit = 400035,
  OnboardingDelayedPostalCodeWarning = 400036,
  OnboardingDelayedIpLimit = 400037,
  OnboardingBlockedUnknown = 400038,
  VisaLockedErr = 400039,
  DecodeErr = 400040,
}

enum RegistrationEditPhoneNumberErrorCodes {
  BadRequest = 400045,
  FailedGetProfile = 500040,
  FailedUpdateProfile = 500041,
  FailedUpdateProfileModel = 500042,
}

export const isDuplicateUserError = (
  errorCode: RegistrationUsersErrorCodes,
) => {
  const duplicateUserErrors: RegistrationUsersErrorCodes[] = [
    RegistrationUsersErrorCodes.OnboardingBlockedNameDOBLimit,
    RegistrationUsersErrorCodes.OnboardingBlockedPostalCodeLimit,
    RegistrationUsersErrorCodes.OnboardingBlockedPhoneNumberLimit,
    RegistrationUsersErrorCodes.OnboardingBlockedDeviceIdLimit,
    RegistrationUsersErrorCodes.OnboardingDelayedPostalCodeWarning,
    RegistrationUsersErrorCodes.OnboardingDelayedIpLimit,
  ];
  return duplicateUserErrors.includes(errorCode);
};

interface RegistrationIdentityResponse {
  reference_identifier: string;
}

// Interface with correct country_iso properties for Users request
interface UsersRequestAddress extends Partial<Address> {
  city: string;
  line1: string;
  line2?: string;
  province: string;
  province_code: string;
  postal_code: string;
}

export interface RegistrationUsersRequest {
  anonymous_id: string;
  device_id: string;
  profile: {
    address: UsersRequestAddress;
    shipping_address?: UsersRequestAddress;
    date_of_birth: string;
    family_name: string;
    given_name: string;
    telephone: string;
    occupation?: {
      name: string;
      code: string;
    };
  };
  terms_and_conditions_version: number;
  tmx_session_id: string;
}

interface IntercomRegistrationProfileAttributes {
  email: string;
  phone: string;
  name: string;
  'Year Born': number;
  user_hash?: string;
  user_id: string;
  signed_up_at: string;
}

interface RegistrationUsersResponse {
  user: {
    id: number;
    reference_id: string;
    organization: 'koho';
    email: string;
    is_email_verified: boolean;
    etransfer_answer: null;
    terms_and_conditions_version: TermsVersion.mastercard;
    password?: null;
    access_code?: string;
    initial_device_id: string;
    initial_kyc_skipped: true;
    profile: {
      id: number;
      given_name: string;
      additional_name?: string;
      family_name: string;
      preferred_given_name?: string;
      preferred_additional_name?: string;
      preferred_family_name?: string;
      date_of_birth: string;
      telephone: string;
      address: Address;
      normalized_address: Address;
      occupation?: { name: string; code: string };
      is_third_party: boolean;
      is_foreign_tax_resident: boolean;
    };
    when_cleared?: string;
    is_suspended: boolean;
    is_cancelled: boolean;
    flag_status: string;
    has_access_code: boolean;
    when_created: string;
    language?: string;
  };
  intercom_android_hmac: string;
  intercom_ios_hmac: string;
  intercom_web_hmac: string;
}

const buildIntercomRegistrationUserTraits = (
  profile: RegistrationUsersResponse,
): IntercomRegistrationProfileAttributes => {
  const dob = profile.user.profile.date_of_birth;

  return {
    email: profile.user.email,
    phone: profile.user.profile.telephone,
    name: `${profile.user.profile.given_name} ${profile.user.profile.family_name}`,
    'Year Born': new Date(formatDate(dob)).getFullYear(),
    user_hash: profile.intercom_web_hmac,
    user_id: profile.user.reference_id,
    signed_up_at: profile.user.when_created,
  };
};

interface UserSegmentTraits {
  hashId: string;
  firstName: string;
  lastName: string;
  email: string;
  isActivated: boolean;
  cleared: boolean;
  address: {
    unitNumber: string;
    street: string;
    city: string;
    postalCode: string;
    state: string;
    country: string;
  };
  birthday: string;
  createdAt: string;
  updatedOn: string;
  hasAccessCode: boolean;
  verifiedEmail: boolean;
  notActivatedReasons: [];
}

const buildUserSegmentTraits = (
  res: RegistrationUsersResponse,
): UserSegmentTraits => {
  const { user } = res;
  const { profile } = user;
  const { address } = profile;
  return {
    hashId: shajs('sha256').update(res.user.reference_id).digest('hex'),
    firstName: profile.given_name,
    lastName: profile.family_name,
    email: user.email,
    isActivated: false,
    cleared: false,
    address: {
      unitNumber: address.line2,
      street: address.line1,
      city: address.city,
      postalCode: address.postal_code,
      state: address.province,
      country: 'Canada',
    },
    birthday: format(new Date(formatDate(profile.date_of_birth)), 'yyyy-MM-dd'),
    createdAt: format(new Date(), 'yyyy-MM-dd'),
    updatedOn: format(new Date(), 'yyyy-MM-dd'),
    hasAccessCode: false,
    notActivatedReasons: [],
    verifiedEmail: false,
  };
};

interface IntercomRegistrationReferralAttributes {
  source: string;
  sourceCode: string;
}

// referral details sent separately with referral api
const buildIntercomRegistrationReferralAttributes = (
  referral: ReferralRequest,
): IntercomRegistrationReferralAttributes => {
  return {
    source: referral.source,
    sourceCode: referral.campaign,
  };
};
export interface ReferralRequest {
  campaign: string;
  source: Sources;
  partner_user_id?: string;
}

export interface RegistrationErrorResponse<T> {
  data: {
    errors: { code: T }[];
  };
}
interface AddressRetrieveResponse {
  data: {
    items: Array<Address>;
  };
}

interface CreateAccountRequest {
  intended_use?: IntendedUse;
}

interface SendLinkRequest {
  type: string;
  tel_no: string;
}

export const registrationApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    identities: build.mutation<
      RegistrationIdentityResponse,
      {
        identity: RegistrationIdentitiesRequest;
        removeProfileProperties: boolean;
      }
    >({
      query: ({ identity }) => ({
        url: `/1.0/identities`,
        method: 'POST',
        body: {
          email: identity.email,
          password: identity.password,
        },
      }),
      async onQueryStarted(
        { identity, removeProfileProperties },
        { queryFulfilled, dispatch },
      ) {
        try {
          const { data } = await queryFulfilled;

          dispatch(authActions.setOtpTimeout());
          dispatch(authActions.setSession());

          const email = identity.email;
          // Intercom user is a lead at this point since we don't have an identifying hash_id
          intercomUpdate({
            'Sign up email': email,
            // update utms in intercom if user is anonymous AND opens the chat
            ...getUtmsFromSession(),
          });

          identify(
            data.reference_identifier,
            removeProfileProperties
              ? { 'Signup Started On': 'Web' }
              : { email, 'Signup Started On': 'Web' },
          );

          segmentAnalytics.alias(data.reference_identifier);

          track({
            event: 'Identity Creation Succeeded',
            properties: {
              email,
              // setting to subscribed because we are displaying the consent copy
              // for implicit consent. If we change to a checkbox implementation,
              // we will use OptedIn & Unsubscribed instead
              subscriptionStatus: MarketingConsentSubscriptionStatus.Subscribed,
              subscriptionDate: new Date().toISOString(),
            },
            userId: data.reference_identifier,
          });

          trackGaIds(data.reference_identifier);

          braze.changeUser(data.reference_identifier);
          // IMPORTANT: `openSession` should be called last - after `changeUser` and `automaticallyShowInAppMessages`
          braze.openSession();

          Sentry.configureScope((scope) => {
            scope.setUser({
              id: data.reference_identifier,
            });
          });
          // rakuten tracking for marketing
          commissionUserRegistered(data.reference_identifier);

          dispatch(
            profileActions.getProfileRefAfterRegistration(
              data.reference_identifier,
            ),
          );
        } catch (error) {
          const errorRes = error as {
            error: RegistrationErrorResponse<RegistrationIdentityErrorCodes>;
          };
          if (errorRes?.error?.data?.errors?.length > 0) {
            track({
              event: 'Identity Creation Failed',
              properties: { code: errorRes.error.data.errors[0].code },
            });
          }
        }
      },
    }),

    createUser: build.mutation<
      RegistrationUsersResponse,
      { user: RegistrationUsersRequest; removeProfileProperties: boolean }
    >({
      query: ({ user }) => ({
        url: `2.0/users`,
        method: 'POST',
        body: user,
      }),
      async onQueryStarted({ removeProfileProperties }, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const intercomRegistrationUserTraits =
            buildIntercomRegistrationUserTraits(data);
          // Intercom update converts intercom profile from lead to user
          // and sets information defined in IntercomRegistrationProfileAttributes as User Details
          // details here: https://www.notion.so/koho/Intercom-Attributes-and-Traits-768e5a95b3e345d79154c6157e7533f2?pvs=4
          intercomUpdate({
            ...intercomRegistrationUserTraits,
            ...getUtmsFromSession(),
          });

          identify(
            data.user.reference_id,
            removeProfileProperties
              ? {
                  hashId: shajs('sha256')
                    .update(data.user.reference_id)
                    .digest('hex'),
                }
              : buildUserSegmentTraits(data),
          );
          track({
            event: 'User Creation Succeeded',
            userId: data.user.reference_id,
          });
        } catch (error) {
          const errorRes = error as {
            error: RegistrationErrorResponse<RegistrationUsersErrorCodes>;
          };
          if (errorRes?.error?.data?.errors?.length > 0) {
            track({
              event: 'User Creation Failed',
              properties: { code: errorRes.error.data.errors[0].code },
            });
          }
        }
      },
    }),

    addressSearch: build.query<
      AddressSearch[],
      { searchValue: string; lastId?: string }
    >({
      query: ({ searchValue, lastId }) => ({
        url: `/1.0/address-complete?action=find&search_term=${searchValue}&last_id=${lastId}`,
        method: `GET`,
      }),
      transformResponse: (response: AddressCompleteResponse) =>
        response.data.items,
    }),
    retrieveAddress: build.query<Address[], string>({
      query: (payload) => ({
        url: `/1.0/address-complete?action=retrieve&id=${payload}`,
        method: 'GET',
      }),
      transformResponse: (response: AddressRetrieveResponse) =>
        trimObjectStrings(response.data.items),
    }),
    applyReferralCode: build.mutation<void, ReferralRequest>({
      query: (referralRequest: ReferralRequest) => ({
        url: '1.0/referrals/referrals',
        method: 'POST',
        body: referralRequest,
      }),
      // if referral request is made, update referral details in intercom
      // details here: https://www.notion.so/koho/Intercom-Attributes-and-Traits-768e5a95b3e345d79154c6157e7533f2?pvs=4
      async onQueryStarted(referralRequest, { queryFulfilled }) {
        try {
          await queryFulfilled;
          const intercomReferralTraits =
            buildIntercomRegistrationReferralAttributes(referralRequest);
          intercomUpdate({
            ...intercomReferralTraits,
            ...getUtmsFromSession(),
          });
          track({
            event: 'Referral Code Succeeded',
          });
        } catch (error) {
          const errorRes = error as {
            error: RegistrationErrorResponse<number>;
          };
          if (errorRes?.error?.data.errors[0].code > 0) {
            track({
              event: 'Referral Code Failed',
              properties: {
                code: errorRes?.error?.data.errors[0].code,
              },
            });
          }
        }
      },
    }),
    createAccount: build.mutation<void, CreateAccountRequest>({
      query: (request: CreateAccountRequest) => ({
        url: `2.0/accounts`,
        method: `POST`,
        body: request,
      }),
      async onQueryStarted(_, { queryFulfilled, getState }) {
        try {
          await queryFulfilled;

          const state = getState() as RootState;
          const userId = state?.profile.data?.reference_identifier;

          track({
            event: 'Create Account Succeeded',
            userId,
          });
          //impact radius tracking
          let clickID = localStorage.getItem(impactRadiusSearchParamKey);
          if (clickID) {
            track({
              event: 'Impact Radius User Registered',
              properties: {
                referrer: {
                  type: 'impactRadius',
                  id: clickID,
                },
              },
            });
            localStorage.removeItem(impactRadiusSearchParamKey);
          }
        } catch (error) {
          const errorRes = error as {
            error: RegistrationErrorResponse<number>;
          };
          track({
            event: 'Create Account Failed',
            properties: {
              code: errorRes?.error?.data?.errors[0].code,
            },
          });
        }
      },
    }),
    otpRequest: build.mutation<void, void>({
      query: () => ({
        url: `1.0/otp/sms`,
        method: `POST`,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          track({
            event: 'SMS OTP Request Succeeded',
          });
        } catch (error) {
          const errorRes = error as {
            error: RegistrationErrorResponse<number>;
          };
          track({
            event: 'SMS OTP Request Failed',
            properties: {
              code: errorRes?.error?.data?.errors[0].code,
            },
          });
        }
      },
    }),
    otpVerify: build.mutation<void, string>({
      query: (code) => ({
        url: `1.0/otp/sms/verify`,
        method: `POST`,
        body: { verification_code: code },
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(authActions.setOtpTimeout());
          dispatch(authActions.setSession());
          track({ event: 'SMS OTP Verification Succeeded' });
        } catch (error) {
          const errorRes = error as {
            error: RegistrationErrorResponse<number>;
          };

          track({
            event: 'SMS OTP Verification Failed',
            properties: {
              code: errorRes?.error?.data?.errors[0].code,
            },
          });
        }
      },
    }),
    editMobileNumber: build.mutation<void, string>({
      query: (phoneNumber) => ({
        url: `2.0/users/onboarding/sms`,
        method: 'PUT',
        body: { phone_number: phoneNumber },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          track({
            event: 'Edit Phone Number Succeeded',
          });
        } catch (error) {
          const errorRes = error as {
            error: RegistrationErrorResponse<RegistrationEditPhoneNumberErrorCodes>;
          };
          track({
            event: 'Edit Phone Number Failed',
            properties: {
              code: errorRes?.error?.data?.errors[0].code,
            },
          });
        }
      },
    }),
    fingerprints: build.mutation<void, void>({
      query: () => {
        const userAgent = new UAParser();
        const fingerprintsRequest: FingerprintsData = {
          model: userAgent.getBrowser().name,
          uuid: getDeviceID(),
          os_version: userAgent.getBrowser().version,
          app_version: import.meta.env.VITE_APP_VERSION_HEADER,
          platform: 'web',
          manufacturer: userAgent.getOS().name,
          is_virtual: false,
          serial: '',
          language:
            window.navigator.language || (window.navigator as any).userLanguage,
          screen_height: window.innerWidth.toString(),
          screen_width: window.innerHeight.toString(),
          event_type: FingerprintEventTypes.UserRegistered,
        };

        return {
          url: `/1.0/fingerprints`,
          method: 'POST',
          body: { fingerprint: fingerprintsRequest },
        };
      },
    }),
    sendSMSLink: build.mutation<void, SendLinkRequest>({
      query: (sendLinkRequest) => ({
        url: `/1.0/notifications/sms/sendlink`,
        method: 'POST',
        body: sendLinkRequest,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          track({ event: 'Registration SMS Send Link Failed' });
        }
      },
    }),
  }),
});

export const {
  useIdentitiesMutation,
  useCreateUserMutation,
  useApplyReferralCodeMutation,
  useRetrieveAddressQuery,
  useAddressSearchQuery,
  useCreateAccountMutation,
  useOtpRequestMutation,
  useOtpVerifyMutation,
  useEditMobileNumberMutation,
  useFingerprintsMutation,
  useSendSMSLinkMutation,
} = registrationApi;
