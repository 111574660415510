import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useGetGoalsQuery } from 'apis/goals';
import { useGetHISAStatusQuery } from 'apis/hisa';
import { selectTheme } from 'appState/appState.slice';
import { Illustrations } from 'assets/illustrations';
import { KDSIcons } from 'assets/images/kds_icons';
import {
  selectInterestEarned,
  selectVaultBalance,
} from 'balance/store/selectors';
import { LocaleContext, Modal } from 'components';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import CircularProgress from 'components/CircularProgress';
import {
  NumberLarge,
  NumberSmall,
  Paragraph,
  TitleLarge,
  TitleMedium,
  TitleSmall,
} from 'components/TypographyTw';
import DownloadApp from 'features/buttons/DownloadApp';
import { Goal } from 'goals/models/Goal';
import { TemplateTw } from 'layout/TemplateTw';

import { hisaActions, selectHisaOnboarding } from './hisa/store/slice';

export const SavingsPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const goals = useGetGoalsQuery();

  const { successfullyCreated } = useSelector(selectHisaOnboarding);

  return (
    <TemplateTw
      name="Savings Page"
      variant="center"
      widths={['90%', '75%', '75%', '75%']}
      className="light:bg-grey-50"
    >
      <div className="flex flex-col gap-6 light:p-6 light:pb-14 light:xl:pb-20 light:mb-14 light:xl:mb-0 light:bg-white light:rounded-xl">
        <TitleLarge className="mb-4">
          {intl.formatMessage({
            id: 'SavingsPage.Savings.Title',
            defaultMessage: 'My savings',
          })}
        </TitleLarge>

        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <InterestTile />
          </Grid>
          <Grid item xs={12} md={6}>
            <VaultTile />
          </Grid>
        </Grid>

        <BoxTw className="pt-4">
          {goals.data && goals.data.length > 0 && (
            <TitleMedium>
              {intl.formatMessage({
                id: 'SavingsPage.Goals.Title',
                defaultMessage: 'My goals',
              })}
            </TitleMedium>
          )}
          <List className="pb-5">
            {goals.data &&
              goals.data.map((goal, index) => (
                <GoalRow goal={goal} key={index} />
              ))}
            {goals.data && goals.data.length === 0 && (
              <EmptyGoals isError={goals.isError} isLoading={goals.isLoading} />
            )}
            {goals.isLoading && <Skeleton width="100%" height="75px" />}
          </List>
        </BoxTw>
        <Modal
          open={successfullyCreated}
          onClose={() => dispatch(hisaActions.setSuccessfullyCreated(false))}
          PaperProps={{ style: { maxWidth: '422px' } }}
        >
          <BoxTw className="gap-4 flex flex-col">
            <KDSIcons.Spot.Success />
            <TitleLarge className="my-0">
              {intl.formatMessage({
                id: 'SavingsPage.SuccessFullOnboarding.Title',
                defaultMessage: 'Your work is done!',
              })}
            </TitleLarge>

            <Paragraph>
              {intl.formatMessage({
                id: 'SavingsPage.SuccessFullOnboarding.Description',
                defaultMessage:
                  'We’ll finish setting up your account within the next 5 business days.',
              })}
            </Paragraph>
            <ButtonTw
              onClick={() =>
                dispatch(hisaActions.setSuccessfullyCreated(false))
              }
              trackName="Save onboarding success"
              type="button"
            >
              {intl.formatMessage({
                id: 'SavingsPage.SuccessFullOnboarding.Close',
                defaultMessage: 'Got it',
              })}
            </ButtonTw>
          </BoxTw>
        </Modal>
      </div>
    </TemplateTw>
  );
};

function AdaptableCard({ children }: { children: React.ReactNode }) {
  return (
    <BoxTw className="flex flex-col space-y-4 p-3 h-full rounded-xl legacy:[box-shadow:rgba(0,0,0,0.1)_3px_3px_10px_0px]  light:items-center light:bg-primary-50">
      {children}
    </BoxTw>
  );
}

function InterestTile() {
  const isLight = useSelector(selectTheme) === 'light';
  const intl = useIntl();
  const localeContext = useContext(LocaleContext);

  const HISA = useGetHISAStatusQuery();

  const interestEarned = localeContext
    .intlFormatMoney(useSelector(selectInterestEarned))
    .format();

  if (HISA.isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  const promoRate = HISA.data?.promotional_rate?.rate || null;

  const interestMessageConfig = {
    bold: (chunk: string) => <strong>{chunk}</strong>,
    interestRate: localeContext
      .intlFormatNumber(HISA.data?.interest_rate)
      .format('percent'),
    strike: (chunk: string) => <s>{chunk}</s>,
    promoRate: promoRate
      ? localeContext.intlFormatNumber(promoRate).format('percent')
      : null,
  };

  // can't use a ternary to determine the value of defaultMessage because lokalise gets confused
  const interestMsgWithPromo = intl.formatMessage(
    {
      id: 'SavingsPage.Interest.PromoParagraph',
      defaultMessage: `You're earning <strike>{interestRate}</strike> <bold>{promoRate}</bold> interest on your entire balance!`,
    },
    interestMessageConfig,
  );

  const interestMsgWithoutPromo = intl.formatMessage(
    {
      id: 'SavingsPage.Interest.Paragraph',
      defaultMessage: `You're earning <bold>{interestRate}</bold> interest on your entire balance.`,
    },
    interestMessageConfig,
  );

  let interestMessage = promoRate
    ? interestMsgWithPromo
    : interestMsgWithoutPromo;

  if (!HISA.data?.is_agreement_signed) {
    interestMessage = intl.formatMessage(
      {
        id: 'SavingsPage.Interest.PotentialEarnings',
        defaultMessage: `You could be earning <bold>{interestRate}</bold> interest on your entire balance!`,
      },
      interestMessageConfig,
    );
  }

  const Icon = isLight
    ? () => <img alt="" className="w-16" src={Illustrations.PiggyBank} />
    : KDSIcons.Features.Interest;

  return (
    <AdaptableCard>
      <Icon />
      <TitleMedium>
        {intl.formatMessage({
          id: 'SavingsPage.Interest.Title',
          defaultMessage: 'Interest Earned',
        })}
      </TitleMedium>
      <Paragraph className="light:text-center">{interestMessage}</Paragraph>
      <NumberLarge>{interestEarned}</NumberLarge>
    </AdaptableCard>
  );
}

function VaultTile() {
  const intl = useIntl();
  const isLight = useSelector(selectTheme) === 'light';
  const localeContext = useContext(LocaleContext);

  const vaultBalance = localeContext
    .intlFormatMoney(useSelector(selectVaultBalance))
    .format();

  const Icon = isLight
    ? () => <img alt="" className="w-16" src={Illustrations.Vault} />
    : KDSIcons.Navigation.Saving;

  return (
    <AdaptableCard>
      <Icon className="max-w-6" />
      <TitleMedium>
        {intl.formatMessage({
          id: 'SavingsPage.Vault.Title',
          defaultMessage: 'Vault',
        })}
      </TitleMedium>
      <Paragraph className="light:text-center">
        {intl.formatMessage({
          id: 'SavingsPage.Vault.Paragraph',
          defaultMessage:
            'Protect your savings by locking it away in your vault with the KOHO app.',
        })}
      </Paragraph>
      <NumberLarge>{vaultBalance}</NumberLarge>
    </AdaptableCard>
  );
}

function GoalRow({ goal }: { goal: Goal }) {
  const intl = useIntl();
  const localeContext = useContext(LocaleContext);

  const byText = intl.formatMessage({
    id: 'SavingsPage.Goals.Byline',
    defaultMessage: 'By',
  });

  const formattedDate = intl.formatDate(goal.target_complete_date, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <ListItem divider={true} className="px-0">
      <ListItemAvatar>
        <Avatar variant="rounded">
          <KDSIcons.Icons.CalendarTime className="text-white" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        className="font-bold"
        primary={<TitleSmall className="my-1">{goal.name}</TitleSmall>}
        secondary={byText + ' ' + formattedDate}
      ></ListItemText>
      <div style={{ textAlign: 'right' }}>
        <NumberLarge>
          {localeContext.intlFormatMoney(goal.current_balance).format()}
        </NumberLarge>
        <br />
        <NumberSmall>
          &nbsp;/&nbsp;
          {localeContext.intlFormatMoney(goal.target_amount).format()}
        </NumberSmall>
      </div>
    </ListItem>
  );
}

function EmptyGoals({
  isError,
  isLoading,
}: {
  isError: boolean;
  isLoading: boolean;
}) {
  const intl = useIntl();
  const isLight = useSelector(selectTheme) === 'light';

  let message: string | JSX.Element = intl.formatMessage({
    id: 'SavingsPage.Goals.EmptyMessage',
    defaultMessage:
      'Create a goal in-app to start saving for anything you want.',
  });

  if (isError) {
    message = intl.formatMessage({
      id: 'SavingsPage.Goals.EmptyError',
      defaultMessage:
        "Sorry, we're not able to load your goals right now. Please try again later or open the Savings tab in-app.",
    });
  }

  if (isLoading) {
    message = <CircularProgress />;
  }

  const Icon = isLight
    ? () => <img alt="" className="mb-3 h-24" src={Illustrations.Save} />
    : () => <KDSIcons.Spot.Richer className="mb-3" />;

  return (
    <BoxTw className="flex flex-col items-center justify-center p-5 rounded-xl light:border-2 light:border-solid legacy:bg-primary-50">
      <Icon />
      <Paragraph className="text-center">{message}</Paragraph>
      <BoxTw className="mt-3">
        <DownloadApp />
      </BoxTw>
    </BoxTw>
  );
}
