import { ReactNode } from 'react';

import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

type Props = {
  children: ReactNode;
  dataCy?: string;
  onSelect: () => void;
  selectable: boolean;
  selected: boolean;
};

export const Card = ({
  children,
  dataCy,
  onSelect,
  selectable,
  selected,
}: Props) => {
  return (
    <div
      data-cy={dataCy}
      tabIndex={selectable ? 0 : undefined}
      onClick={onSelect}
      onKeyDown={(e) => {
        (e.code === 'Space' || e.code === 'Enter') && onSelect();
      }}
      className={twMerge(
        clsx(
          'flex flex-col justify-between rounded-lg border p-4 pb-6 lg:max-w-[352px] legacy:[box-shadow:3px_6px_15px_0px_#0000001A] light:bg-white',
          'legacy:border-tiers-blue-75 legacy:border-opacity-75',
          selected && 'legacy:border-tiers-blue-300 border-primary-300',
          selectable &&
            'cursor-pointer transition-all duration-[0.2s] ease-in-out hover:legacy:border-tiers-blue-300 hover:border-primary-300',
        ),
      )}
    >
      {children}
    </div>
  );
};
