/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import AddCircle from '@material-ui/icons/AddCircle';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { ButtonTw } from 'components/ButtonTw';
import {
  Paragraph,
  ParagraphSmall,
  TitleLarge,
  TitleSmall,
} from 'components/TypographyTw';

import { Recipient } from '../../models/Recipient';
import { RecipientAvatar } from './RecipientAvatar';

export const Recipients = ({
  filter,
  list,
  onClickAddRecipient,
  onClickRecipient,
}: {
  filter?: (recipient: Recipient) => boolean;
  list: Recipient[];
  onClickAddRecipient: () => void;
  onClickRecipient: (recipient: Recipient) => void;
}) => {
  const intl = useIntl();

  const sortedRecipients = list.sort((last, next) =>
    last.initials[0] < next.initials[0] ? -1 : 1,
  );
  const groupedRecipients = sortedRecipients.reduce(
    (groups: { letter: string; list: Recipient[] }[], recipient: Recipient) => {
      if (filter && !filter(recipient)) return groups;

      const updatedGroup = [...groups];
      const lastGroup = updatedGroup[updatedGroup.length - 1];
      const recipientNameFirstLetter = recipient.fullName[0];
      if (!lastGroup || lastGroup.letter !== recipientNameFirstLetter[0]) {
        updatedGroup.push({
          letter: recipientNameFirstLetter[0],
          list: [recipient],
        });
        return updatedGroup;
      }

      lastGroup.list.push(recipient);
      return updatedGroup;
    },
    [],
  );

  return (
    <section>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item className="w-4/5">
          <TitleLarge>
            {intl.formatMessage({
              id: 'Recipients.Heading',
              defaultMessage: 'Who do you want to send money to?',
            })}
          </TitleLarge>
        </Grid>
        <Grid item>
          <ButtonTw onClick={() => onClickAddRecipient()}>
            <AddCircle className="fill-white text-3xl" />
          </ButtonTw>
        </Grid>
      </Grid>
      {groupedRecipients.map((group) => (
        <List className="m-0" key={group.letter}>
          <ListSubheader className="p-0">
            <TitleSmall>{group.letter.toUpperCase()}</TitleSmall>
          </ListSubheader>
          {group.list.map((recipient, i) => (
            <React.Fragment key={group.letter + i}>
              <ListItem
                className="px-0 py-4"
                button={true}
                divider={true}
                onClick={() => onClickRecipient(recipient)}
              >
                <ListItemIcon>
                  <RecipientAvatar recipient={recipient}></RecipientAvatar>
                </ListItemIcon>
                <ListItemText>
                  <Paragraph className="m-0">{recipient.fullName}</Paragraph>
                  <ParagraphSmall>{recipient.address}</ParagraphSmall>
                </ListItemText>
                <ChevronRight className="text-grey-400"></ChevronRight>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      ))}
    </section>
  );
};
