import React from 'react';

import { Checkbox, CheckboxProps, withStyles } from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { trackInput } from 'analytics/analytics';

const CustomCheckbox = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    width: 25,
    height: 25,
  },
})(Checkbox);

type KhCheckboxProps = CheckboxProps & { trackName: string };

export const KhInputCheckbox = React.forwardRef<any, KhCheckboxProps>(
  ({ onClick, trackName, ...props }, ref) => {
    const click: React.MouseEventHandler<HTMLButtonElement> = (event) => {
      if (trackName) {
        trackInput({ name: trackName, type: 'Clicked' });
      }

      if (onClick) {
        onClick(event);
      }
    };
    return (
      <CustomCheckbox
        icon={<CircleUnchecked />}
        checkedIcon={<CircleCheckedFilled />}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        color="primary"
        ref={ref}
        onClick={click}
        {...props}
      />
    );
  },
);
