import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Modal, ModalProps } from 'components';
import { ButtonTw } from 'components/ButtonTw';

import { contactActions } from './store/contact.slice';

export const ContactModal = ({ open, onClose }: ModalProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const openPreviousChat = () => {
    dispatch(contactActions.intercomOpen());
  };

  const openNewChat = () => {
    dispatch(contactActions.forethoughtOpen());
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="md"
      title={intl.formatMessage({
        id: 'Contact.Modal.Title',
        defaultMessage: 'Please pick an option',
      })}
    >
      <div className="flex flex-col gap-4 w-auto md:w-[500px]">
        <ButtonTw onClick={openNewChat}>
          {intl.formatMessage({
            id: 'Contact.Modal.OpenNewChatButton',
            defaultMessage: 'Open new chat',
          })}
        </ButtonTw>
        <ButtonTw onClick={openPreviousChat} variant="secondary">
          {intl.formatMessage({
            id: 'Contact.Modal.OpenPreviousChatButton',
            defaultMessage: 'Open previous chat',
          })}
        </ButtonTw>
      </div>
    </Modal>
  );
};
