import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Theme,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import RemoveIcon from '@material-ui/icons/Remove';
import { LocaleContext } from 'components/I18NContextProvider';

const useStyles = makeStyles<Theme>({
  container: {
    backgroundColor: '#F1F5F8',
    borderRadius: '8px',
    padding: '16px',
    width: '100%',
  },
  item: {
    padding: '16px',
  },
});

const NbaFAQ: React.FC = ({ children }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [open, setOpen] = React.useState<number[]>([]);
  const [faqOpen, setFaqOpen] = React.useState(true);
  const { deepLinks } = useContext(LocaleContext);

  const nbaUrl = deepLinks.NBA.NBAStore;
  const handleClick = (index: number) => {
    if (open.includes(index)) {
      setOpen(open.filter((i) => i !== index));
    } else {
      setOpen([...open, index]);
    }
  };

  const handleFAQClick = () => {
    setFaqOpen(!faqOpen);
  };

  return (
    <Box className={classes.container}>
      <ListItem button disableGutters onClick={handleFAQClick}>
        <ListItemText
          primary={intl.formatMessage({
            id: 'Nba.FAQ.Title',
            defaultMessage: 'Frequently asked questions',
          })}
          primaryTypographyProps={{
            style: {
              fontSize: '18px',
              fontWeight: 700,
            },
          }}
        />
        {faqOpen ? (
          <ArrowDropUpIcon fontSize="large" />
        ) : (
          <ArrowDropDownIcon fontSize="large" />
        )}
      </ListItem>
      <Collapse in={faqOpen} timeout="auto" unmountOnExit>
        <ListItem button divider disableGutters onClick={() => handleClick(1)}>
          <ListItemText
            primary={intl.formatMessage({
              id: 'Nba.FAQ.HowToGetEGiftCard',
              defaultMessage: 'How do I get my NBAStore.ca e-Gift Card?',
            })}
            primaryTypographyProps={{
              style: {
                fontWeight: 700,
              },
            }}
          />
          {open.includes(1) ? (
            <RemoveIcon htmlColor="#1D428A" />
          ) : (
            <AddIcon htmlColor="#1D428A" />
          )}
        </ListItem>
        <Collapse in={open.includes(1)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemText
                primary={intl.formatMessage(
                  {
                    id: 'Nba.FAQ.HowToGetNbaEGiftCardAnswer',
                    defaultMessage:
                      'Sign up with the referral code {code} for one of our 3 paid plans (Essential, Extra, Everything). After your trial ends and you continue on a plan, your unique <nbaLink>NBAStore.ca</nbaLink> e-Gift Card will be issued. To redeem, come back to this page and click the link to access your e-Gift Card Number and PIN. Shop at <nbaLink>NBAStore.ca</nbaLink>, then enter your Number and PIN at checkout in the field labeled ‘Apply Gift Card Number’, and complete your purchase. Terms apply. ',
                  },
                  {
                    code: <strong>KOHONBA75</strong>,
                    nbaLink: (link) => (
                      <a
                        href={nbaUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link}
                      </a>
                    ),
                  },
                )}
              />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button divider disableGutters onClick={() => handleClick(2)}>
          <ListItemText
            primary={intl.formatMessage({
              id: 'Nba.FAQ.WhereToFindEGiftCard',
              defaultMessage: 'Where do I find the NBAStore.ca e-Gift Card?',
            })}
            primaryTypographyProps={{
              style: {
                fontWeight: 700,
              },
            }}
          />
          {open.includes(2) ? (
            <RemoveIcon htmlColor="#1D428A" />
          ) : (
            <AddIcon htmlColor="#1D428A" />
          )}
        </ListItem>
        <Collapse in={open.includes(2)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemText
                primary={intl.formatMessage(
                  {
                    id: 'Nba.FAQ.WhereToFindEGiftCardAnswer',
                    defaultMessage:
                      'Once you meet the requirements of completing your free trial period for either the Essential, Extra or Everything KOHO plans, you will become eligible to claim your $75 <nbaLink>NBAStore.ca</nbaLink> e-Gift Card. Come back to this page to access your <nbaLink>NBAStore.ca</nbaLink> e-Gift Card. To redeem, click the provided link to access your unique e-Gift Card Number and PIN. Shop at <nbaLink>NBAStore.ca</nbaLink>, then enter your Number and PIN at checkout in the field labeled ‘Apply Gift Card Number’, and complete your purchase. Terms apply.',
                  },
                  {
                    nbaLink: (link) => (
                      <a
                        href={nbaUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link}
                      </a>
                    ),
                  },
                )}
              />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button divider disableGutters onClick={() => handleClick(3)}>
          <ListItemText
            primary={intl.formatMessage({
              id: 'Nba.FAQ.WhereToUseEGiftCard',
              defaultMessage: 'Where can I use my NBAStore.ca e-Gift Card?',
            })}
            primaryTypographyProps={{
              style: {
                fontWeight: 700,
              },
            }}
          />
          {open.includes(3) ? (
            <RemoveIcon htmlColor="#1D428A" />
          ) : (
            <AddIcon htmlColor="#1D428A" />
          )}
        </ListItem>
        <Collapse in={open.includes(3)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemText
                primary={intl.formatMessage(
                  {
                    id: 'Nba.FAQ.WhereToUseEGiftCardAnswer',
                    defaultMessage:
                      'You can use your e-Gift Card for purchases at <nbaLink>NBAStore.ca</nbaLink>',
                  },
                  {
                    nbaLink: (link) => (
                      <a
                        href={nbaUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link}
                      </a>
                    ),
                  },
                )}
              />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button disableGutters onClick={() => handleClick(4)}>
          <ListItemText
            primary={intl.formatMessage({
              id: 'Nba.FAQ.HowLongAccessEGiftCard',
              defaultMessage:
                'How long can I access my NBAStore.ca e-Gift Card?',
            })}
            primaryTypographyProps={{
              style: {
                fontWeight: 700,
              },
            }}
          />
          {open.includes(4) ? (
            <RemoveIcon htmlColor="#1D428A" />
          ) : (
            <AddIcon htmlColor="#1D428A" />
          )}
        </ListItem>
        <Collapse in={open.includes(4)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemText
                primary={intl.formatMessage(
                  {
                    id: 'Nba.FAQ.HowLongAccessEGiftCardAnswer',
                    defaultMessage:
                      'You have until June 30, 2024 to sign up for a paid plan trial to be eligible for a $75 <nbaLink>NBAStore.ca</nbaLink> e-Gift Card. Once you have completed the trial plan requirements, you are eligible to receive your <nbaLink>NBAStore.ca</nbaLink> e-Gift Card. You have until October 31, 2024 to claim your e-Gift Card through the KOHO app. Just be sure to save your <nbaLink>NBAStore.ca</nbaLink> e-Gift Card link containing your Number and PIN once you access it. Your e-Gift Card does not expire. See full terms and conditions.',
                  },
                  {
                    nbaLink: (link) => (
                      <a
                        href={nbaUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link}
                      </a>
                    ),
                  },
                )}
              />
            </ListItem>
          </List>
        </Collapse>
      </Collapse>
    </Box>
  );
};

export default NbaFAQ;
