import { ReactNode, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';

import { navigate } from '@reach/router';
import { track } from 'analytics/analytics';
import {
  PersonalizedOnboardingIntent,
  personalizedOnboardingIntent,
  useChecklistDismissMutation,
} from 'apis/personalizedOnboardingApi';
import { LocaleContext } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { LinkTw } from 'components/LinkTw';
import { ParagraphBold } from 'components/TypographyTw';

import { useHISAPath } from './useHISAPath';

export const useCompletionModalContent = ({
  closeModal,
  intent,
  openLearnMore,
}: {
  closeModal: () => void;
  intent: PersonalizedOnboardingIntent;
  openLearnMore: () => void;
}): {
  image: string | undefined;
  title: string;
  subtitle: ReactNode;
  action: ReactNode;
  handleClose: () => void;
  learnMore?: {
    title: string;
    content: string;
  };
} => {
  const intl = useIntl();
  const hisaPath = useHISAPath();
  const { locale } = useContext(LocaleContext);

  const [dismissChecklistMutation, { isLoading: isDismissing }] =
    useChecklistDismissMutation();

  const dismissChecklist = useCallback(
    (navigatePath?: string) => {
      dismissChecklistMutation().then(() => {
        navigatePath && navigate(navigatePath);
        closeModal();
      });
    },
    [dismissChecklistMutation, closeModal],
  );

  return {
    [personalizedOnboardingIntent.spending]: {
      image: {
        en: 'https://assets.koho.ca/personalized-onboarding/success-screen-cash-back_EN2x.png',
        fr: 'https://assets.koho.ca/personalized-onboarding/success-screen-cash-back_FR2x.png',
      }[locale],
      title: intl.formatMessage({
        id: 'PersonalizedOnboarding.CompletionModal.Spending.Title',
        defaultMessage: "You're set to earn!",
      }),
      subtitle: intl.formatMessage({
        id: 'PersonalizedOnboarding.CompletionModal.Spending.Subtitle',
        defaultMessage:
          'Over 1 million Canadians earned $16.3 million cash back by spending with KOHO.',
      }),
      action: (
        <ButtonTw
          onClick={() => dismissChecklist()}
          loading={isDismissing}
          trackName="personalized-onboarding-close-completed-btn"
          trackMetadata={{ intent: personalizedOnboardingIntent.spending }}
        >
          {intl.formatMessage({
            id: 'PersonalizedOnboarding.CompletionModal.Spending.SeeCashback',
            defaultMessage: 'See my cash back',
          })}
        </ButtonTw>
      ),
      handleClose: () => dismissChecklist(),
    },
    [personalizedOnboardingIntent.credit]: {
      image: {
        en: 'https://assets.koho.ca/personalized-onboarding/success-screen-credit_EN@2x.png',
        fr: 'https://assets.koho.ca/personalized-onboarding/success-screen-credit_FR@2x.png',
      }[locale],
      title: intl.formatMessage({
        id: 'PersonalizedOnboarding.CompletionModal.Credit.Title',
        defaultMessage: "You're building!",
      }),
      subtitle: (
        <span>
          {intl.formatMessage({
            id: 'PersonalizedOnboarding.CompletionModal.Credit.Subtitle',
            defaultMessage:
              'KOHO users see an average increase of 12 points after just 3 months of Credit Building.',
          })}{' '}
          <LinkTw
            to="#"
            onClick={() => {
              track({
                event: `Clicked`,
                properties: {
                  name: 'personalized-onboarding-completed-learn-more',
                  intent: personalizedOnboardingIntent.credit,
                },
              });

              openLearnMore();
            }}
            trackName="personalized-onboarding-completed-learn-more"
            className="block"
          >
            {intl.formatMessage({
              id: 'PersonalizedOnboarding.CompletionModal.LearnMore',
              defaultMessage: 'Learn more',
            })}
          </LinkTw>
        </span>
      ),
      action: (
        <ParagraphBold className="m-0">
          {intl.formatMessage({
            id: 'PersonalizedOnboarding.CompletionModal.Credit.SeeCreditScore',
            defaultMessage: 'See your credit score in the app',
          })}
        </ParagraphBold>
      ),
      handleClose: () => dismissChecklist(),
      learnMore: {
        title: intl.formatMessage({
          id: 'PersonalizedOnboarding.CompletionModal.Credit.LearnMore.Title',
          defaultMessage: 'Average credit score increase',
        }),
        content: intl.formatMessage({
          id: 'PersonalizedOnboarding.CompletionModal.Credit.LearnMore.Content',
          defaultMessage:
            'Credit score are based on complex models involving a variety of factors. Consistent on-time payments help improve credit scores. Missed or late payments may cause credit scores to decrease. Outcomes may vary among users.',
        }),
      },
    },
    [personalizedOnboardingIntent.saving]: {
      image: {
        en: 'https://assets.koho.ca/personalized-onboarding/success-screen-save_EN2x.png',
        fr: 'https://assets.koho.ca/personalized-onboarding/success-screen-save_EN2x.png',
      }[locale],
      title: intl.formatMessage({
        id: 'PersonalizedOnboarding.CompletionModal.Saving.Title',
        defaultMessage: "You're doing it!",
      }),
      subtitle: intl.formatMessage(
        {
          id: 'PersonalizedOnboarding.CompletionModal.Saving.Subtitle',
          defaultMessage:
            'Every dollar in your account earns you <b>5% interest</b>, paid monthly',
        },
        { b: (chunks) => <b>{chunks}</b> },
      ),
      action: (
        <ButtonTw
          onClick={() => dismissChecklist(hisaPath)}
          loading={isDismissing}
          trackName="personalized-onboarding-close-completed-btn"
          trackMetadata={{ intent: personalizedOnboardingIntent.saving }}
        >
          {intl.formatMessage({
            id: 'PersonalizedOnboarding.CompletionModal.Saving.SeeSavings',
            defaultMessage: 'See my savings',
          })}
        </ButtonTw>
      ),
      handleClose: () => dismissChecklist(),
    },
    [personalizedOnboardingIntent.borrowing]: {
      image: {
        en: 'https://assets.koho.ca/personalized-onboarding/success-screen-borrowing_EN2x.png',
        fr: 'https://assets.koho.ca/personalized-onboarding/success-screen-borrowing_FR2x.png',
      }[locale],
      title: intl.formatMessage({
        id: 'PersonalizedOnboarding.CompletionModal.Borrowing.Title',
        defaultMessage: 'You did it!',
      }),
      subtitle: intl.formatMessage(
        {
          id: 'PersonalizedOnboarding.CompletionModal.Borrowing.Subtitle',
          defaultMessage:
            "You've got $20 today, and you can <b>unlock up to $200</b> the more you use KOHO.",
        },
        { b: (chunks) => <b>{chunks}</b> },
      ),
      action: (
        <ButtonTw
          onClick={() => dismissChecklist('/cover')}
          loading={isDismissing}
          trackName="personalized-onboarding-close-completed-btn"
          trackMetadata={{ intent: personalizedOnboardingIntent.borrowing }}
        >
          {intl.formatMessage({
            id: 'PersonalizedOnboarding.CompletionModal.Borrowing.SeeCover',
            defaultMessage: 'See my Cover',
          })}
        </ButtonTw>
      ),
      handleClose: () => dismissChecklist(),
    },
    [personalizedOnboardingIntent.referral]: {
      image: {
        en: 'https://assets.koho.ca/personalized-onboarding/success-screen-referral_EN2x.png',
        fr: 'https://assets.koho.ca/personalized-onboarding/success-screen-referral_EN2x.png',
      }[locale],
      title: intl.formatMessage({
        id: 'PersonalizedOnboarding.CompletionModal.Referral.Title',
        defaultMessage: 'You earned it!',
      }),
      subtitle: intl.formatMessage({
        id: 'PersonalizedOnboarding.CompletionModal.Referral.Subtitle',
        defaultMessage:
          'You unlocked a referral bonus for both you and your friend.',
      }),
      action: (
        <ButtonTw
          onClick={() => dismissChecklist()}
          trackName="personalized-onboarding-close-completed-btn"
          trackMetadata={{ intent: personalizedOnboardingIntent.referral }}
        >
          {intl.formatMessage({
            id: 'PersonalizedOnboarding.CompletionModal.Referral.GotIt',
            defaultMessage: 'Got it',
          })}
        </ButtonTw>
      ),
      handleClose: () => dismissChecklist(),
    },
  }[intent];
};
