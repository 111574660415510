import { useState } from 'react';
import { useIntl } from 'react-intl';

import ButtonUnstyled from 'components/ButtonUnstyledTw';
import { Paragraph } from 'components/TypographyTw';

import { RegistrationCPCTOSModal } from './RegistrationCPCTOSModal';

export const RegistrationCPCPrivacyStatement = () => {
  const intl = useIntl();
  const [cpcTosModalOpen, setCPCTosModalOpen] = useState(false);

  return (
    <>
      <RegistrationCPCTOSModal
        isOpen={cpcTosModalOpen}
        onClose={() => setCPCTosModalOpen(false)}
      />
      <Paragraph className="mb-4 text-tertiary-300">
        {intl.formatMessage(
          {
            id: 'Registration.CPCPrivacyStatement.Body',
            defaultMessage:
              'Important: KOHO has partnered with Canada Post to provide this Product. Should you choose to apply for it, KOHO will share limited information about you with Canada Post, as described in the <privacyButton>Canada Post Privacy Notice</privacyButton>.',
          },
          {
            privacyButton: (str) =>
              OpenCPCPrivacyPolicyButton({
                str: str[0],
                onClick: () => setCPCTosModalOpen(true),
              }),
          },
        )}
      </Paragraph>
    </>
  );
};

const OpenCPCPrivacyPolicyButton = ({
  onClick,
  str,
}: {
  onClick: () => void;
  str: string;
}) => {
  return (
    <ButtonUnstyled
      className="text-primary-300 m-0 border-0"
      onClick={onClick}
      trackName="cpc-privacy-policy-btn"
    >
      {str}
    </ButtonUnstyled>
  );
};
