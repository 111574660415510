import { useIntl } from 'react-intl';

import { Grid } from '@material-ui/core';
import { useGetReferralsRewardsQuery } from 'apis/referralApi';
import CircularProgress from 'components/CircularProgress';
import { TemplateTw } from 'layout/TemplateTw';
import { ReferAndEarnShare } from 'referAndEarn/components/ReferAndEarnShare';
import { ReferAndEarnUnlockRewards } from 'referAndEarn/components/ReferAndEarnUnlockRewards';

export const ReferAndEarnPage = () => {
  const intl = useIntl();

  const { data: referralsRewardsData, isSuccess } =
    useGetReferralsRewardsQuery();

  return (
    <TemplateTw
      name={intl.formatMessage({
        id: 'ReferAndEarnPage.Title',
        defaultMessage: 'Refer & Earn',
      })}
    >
      {isSuccess ? (
        <Grid container spacing={8} className="p-[24px] lg:p-[48px] lg:pt-2">
          <Grid item xs={12} md={8}>
            <ReferAndEarnShare referralsRewardsData={referralsRewardsData} />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReferAndEarnUnlockRewards
              referralsRewardsData={referralsRewardsData}
              canRedeem={referralsRewardsData.gifts > 0}
            />
          </Grid>
        </Grid>
      ) : (
        <CircularProgress className="ml-12" />
      )}
    </TemplateTw>
  );
};
