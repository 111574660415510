import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ReLoginModal } from 'auth/components/ReLoginModal';
import { SessionRefreshErrorModal } from 'auth/components/SessionRefreshErrorModal';
import { Card, LayoutBasic } from 'components/LayoutBasic';
import { TitleLarge } from 'components/TypographyTw';
import { LoginForm } from 'login/components/LoginForm';

export const LoginPage = ({ ...props }) => {
  const intl = useIntl();

  const [failedLoginAttempts, setFailedLoginAttempts] = useState<number>(0);

  return (
    <LayoutBasic
      chat={failedLoginAttempts >= 2}
      pageName="Login"
      className="bg-grey-100 light:bg-white"
      includeLanguageToggle={true}
    >
      <SessionRefreshErrorModal />
      <ReLoginModal />
      <Card>
        <TitleLarge className="mb-6">
          {intl.formatMessage({
            id: 'LoginPage.Title',
            defaultMessage: 'Login to KOHO',
            description: 'Title of the login page',
          })}
        </TitleLarge>
        <LoginForm
          emailAddress={props.location?.state?.emailAddress}
          setFailedLoginAttempts={setFailedLoginAttempts}
        />
      </Card>
    </LayoutBasic>
  );
};
