import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Language } from 'appState/appState.slice';
import { LocaleContext, Modal } from 'components';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import { LinkTw } from 'components/LinkTw';
import { Paragraph, TitleSmall } from 'components/TypographyTw';

export const RegistrationCPCTOSModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const intl = useIntl();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <BoxTw className="p-4">
        <TitleSmall>
          {intl.formatMessage({
            id: 'RegistrationCPCTOSModal.Title',
            defaultMessage: 'Canada Post Privacy Notice',
          })}
        </TitleSmall>
        <Paragraph>
          {intl.formatMessage({
            id: 'RegistrationCPCTOSModal.Content',
            defaultMessage:
              'When you complete an application for the Canada Post MyMoney Account (Product) KOHO shares some information about you with Canada Post including your name, email address, mailing address, year of birth, language preference, and means of registration, so Canada Post can:',
          })}
        </Paragraph>
        <ul className="list-disc ml-6">
          <li>
            <Paragraph>
              {intl.formatMessage({
                id: 'RegistrationCPCTOSModal.Points.1',
                defaultMessage: 'measure the performance of the Product and',
              })}
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              {intl.formatMessage({
                id: 'RegistrationCPCTOSModal.Points.2',
                defaultMessage:
                  'communicate with you about other products and services that may interest you.',
              })}
            </Paragraph>
          </li>
        </ul>
        <Paragraph>
          {intl.formatMessage(
            {
              id: 'RegistrationCPCTOSModal.MoreInfo',
              defaultMessage:
                'for more information, please read the Canada Post <privacyPolicy>Privacy Policy</privacyPolicy> or <contactUs>contact us</contactUs>.',
            },
            {
              privacyPolicy: PrivacyPolicyLink,
              contactUs: ContactLink,
            },
          )}
        </Paragraph>
        <ButtonTw onClick={onClose}>
          {intl.formatMessage({
            id: 'RegistrationCPCTOSModal.Dismiss',
            defaultMessage: 'Dismiss',
          })}
        </ButtonTw>
      </BoxTw>
    </Modal>
  );
};

const PrivacyPolicyLink = (str: string) => {
  const localeContext = useContext(LocaleContext);

  return (
    <LinkTw
      className="underline text-tertiary font-normal"
      to={
        localeContext.locale === Language.EN
          ? 'https://www.canadapost-postescanada.ca/cpc/en/our-company/transparency-and-trust/privacy-centre/privacy-policy.page'
          : 'https://www.canadapost-postescanada.ca/scp/fr/notre-entreprise/transparence-et-confiance/centre-de-protection-de-la-vie-privee/politique-protection-renseignements-personnels.page'
      }
      external={true}
      trackName="registration - CPC Privacy Policy link"
    >
      {str}
    </LinkTw>
  );
};

const ContactLink = (str: string) => {
  const localeContext = useContext(LocaleContext);

  return (
    <LinkTw
      className="underline text-tertiary font-normal"
      to={
        localeContext.locale === Language.EN
          ? 'https://www.canadapost-postescanada.ca/cpc/en/support/kb/company-policies/feedback-comments/how-to-contact-us-online-or-by-phone'
          : 'https://www.canadapost-postescanada.ca/scp/fr/soutien/bc/politiques-entreprise/commentaires/communiquer-avec-nous-en-ligne-ou-par-telephone'
      }
      external={true}
      trackName="registration - CPC Contact link"
    >
      {str}
    </LinkTw>
  );
};
