import { twMerge } from 'tailwind-merge';

export const ProgressBar = ({
  className,
  step,
  totalSteps,
}: {
  className?: string;
  step: number;
  totalSteps: number;
}) => {
  const width = (step / totalSteps) * 100;

  return (
    <div className="h-1 w-full legacy:bg-grey-200 bg-grey-75 rounded-lg">
      <div
        style={{ width: `${width}%` }}
        className={twMerge(
          'h-inherit rounded-lg bg-success-300 duration-1000',
          className,
        )}
      />
    </div>
  );
};
