import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { trackInput } from 'analytics/analytics';
import { selectTheme } from 'appState/appState.slice';
// assets
import headerImg from 'assets/images/app-download/app-download-bg.9aslkna.jpg';
import appStoreEn from 'assets/images/app-download/apple-en.svg';
import appStoreFr from 'assets/images/app-download/apple-fr.svg';
import headerImgV2 from 'assets/images/app-download/download-app-v2.0ce1dc04de003.jpg';
import googlePlayEn from 'assets/images/app-download/google-en.png';
import googlePlayFr from 'assets/images/app-download/google-fr.png';
// components
import { LOCALE_EN, LocaleContext } from 'components';
import { LinkTw } from 'components/LinkTw';
import { Modal } from 'components/Modal';
import { Paragraph, ParagraphBold, TitleLarge } from 'components/TypographyTw';
import DownloadAppButton from 'features/buttons/DownloadApp';
// types
import type { Image } from 'types/Image';

interface DownloadAppProps {
  description?: string;
  onClose?: () => void;
  open?: boolean;
}

const DownloadApp = ({
  description,
  onClose,
  open = false,
}: DownloadAppProps) => {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';

  const image: Image = {
    url: isLight ? headerImgV2 : headerImg,
  };

  const modalDescription =
    description ||
    intl.formatMessage({
      id: 'DownloadAppModal.Description',
      defaultMessage:
        'Manage your plan and enjoy all your benefits with the KOHO app.',
    });

  const modalTitle = intl.formatMessage({
    id: 'DownloadAppModal.Title',
    defaultMessage: 'Get the app',
  });

  const OrText = intl.formatMessage({
    id: 'DownloadAppModal.CTA.OptionalText',
    defaultMessage: 'Or',
  });

  const handleClose = () => {
    trackInput({
      type: 'Clicked',
      name: 'Modal - Download App — Close',
      details: { element: 'button' },
    });

    onClose && onClose();
  };

  return (
    <Modal
      image={image}
      open={open}
      onClose={handleClose}
      data-cy="download-app-modal"
      fullWidth
      closeIconProps={{ fontSize: 'medium' }}
      absolutePositioning={false}
    >
      <div className="flex flex-col items-center gap-4 w-full">
        <div>
          <TitleLarge className="text-center">{modalTitle}</TitleLarge>
          <Paragraph className="mt-2">{modalDescription}</Paragraph>
        </div>

        <DownloadAppButton />

        <ParagraphBold className="m-0">{OrText}</ParagraphBold>

        <LinkTw
          trackName="Modal - Download App — Apple App Store"
          external
          to="https://apps.apple.com/ca/app/koho-financial/id1091010942"
          aria-label={intl.formatMessage({
            id: 'DownloadAppModal.DownloadFromApple',
            defaultMessage: 'Download KOHO from the Apple app store',
          })}
        >
          <img
            src={locale === LOCALE_EN ? appStoreEn : appStoreFr}
            alt=""
            height="44"
            style={{ display: 'block' }}
          />
        </LinkTw>

        <LinkTw
          trackName="Modal - Download App — Google Play"
          external
          to="https://play.google.com/store/apps/details?id=ca.koho"
          aria-label={intl.formatMessage({
            id: 'DownloadAppModal.DownloadFromGoogle',
            defaultMessage: 'Download KOHO from Google Play',
          })}
        >
          <img
            src={locale === LOCALE_EN ? googlePlayEn : googlePlayFr}
            alt=""
            style={{
              aspectRatio: 'auto 562 / 166',
              height: 40,
              display: 'block',
            }}
          />
        </LinkTw>
      </div>
    </Modal>
  );
};

export default DownloadApp;
