import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Typography, useMediaQuery } from '@material-ui/core';
import { IBillingPaymentMethod } from 'apis/billing';
import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';
import { ReactComponent as Amex } from 'assets/images/logos/amex.svg';
import { ReactComponent as DinersClub } from 'assets/images/logos/diners-club.svg';
import { ReactComponent as Discover } from 'assets/images/logos/discover.svg';
import { ReactComponent as Jcb } from 'assets/images/logos/jcb.svg';
import { ReactComponent as MCard } from 'assets/images/logos/mastercard.svg';
import { ReactComponent as UnionPay } from 'assets/images/logos/unionPay.svg';
import { ReactComponent as Visa } from 'assets/images/logos/visa.svg';
import { ReactComponent as KOHOCardIcon } from 'assets/images/transactions/koho-card-indigo.svg';
import { ReactComponent as KOHOCardIconLegacy } from 'assets/images/transactions/koho-card.svg';
import { PaymentMethodCardNetwork } from 'billing/models/PaymentMethodCardNetwork';
import clsx from 'clsx';
import { BoxTw } from 'components/BoxTw';
import { useIcons } from 'hooks/useIcon';
import { mobile } from 'theme';

export const CardLogo = ({
  brand,
}: {
  brand: PaymentMethodCardNetwork | undefined;
}) => {
  const iconClass = 'w-10 mr-3';
  switch (brand) {
    case PaymentMethodCardNetwork.Visa:
      return <Visa className={iconClass} />;
    case PaymentMethodCardNetwork.Mastercard:
      return <MCard className={iconClass} />;
    case PaymentMethodCardNetwork.Amex:
      return <Amex className={iconClass} />;
    case PaymentMethodCardNetwork.Discover:
      return <Discover className={iconClass} />;
    case PaymentMethodCardNetwork.Jcb:
      return <Jcb className={iconClass} />;
    case PaymentMethodCardNetwork.Diners:
      return <DinersClub className={iconClass} />;
    case PaymentMethodCardNetwork.Unionpay:
      return <UnionPay className={iconClass} />;
    default:
      return <KDSIcons.Navigation.Spending className={iconClass} />;
  }
};

const KOHOMethod = ({
  balance,
  disabled,
  longForm = false,
}: {
  balance: string | undefined;
  disabled: boolean;
  longForm?: boolean;
}) => {
  const intl = useIntl();

  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';

  return (
    <>
      {isLight ? (
        <KOHOCardIcon
          className={clsx('w-10 mr-3', disabled ? 'opacity-50' : 'opacity-100')}
        />
      ) : (
        <KOHOCardIconLegacy
          className={clsx('w-10 mr-3', disabled ? 'opacity-50' : 'opacity-100')}
        />
      )}
      <Typography
        className={clsx(
          'font-black text-grey-400 mr-1',
          disabled ? 'opacity-50' : 'opacity-100',
        )}
      >
        {longForm
          ? intl.formatMessage({
              id: 'KOHOPaymentMethod.Title',
              defaultMessage: 'KOHO Spendable',
            })
          : 'KOHO'}
      </Typography>
      <Typography
        className={clsx(
          'font-medium text-grey-400',
          disabled ? 'opacity-50' : 'opacity-100',
        )}
      >
        ({balance})
      </Typography>
    </>
  );
};

const CardMethod = ({ method }: { method: IBillingPaymentMethod }) => {
  return (
    <>
      <CardLogo brand={method.card_brand} />

      <Typography className="font-black mr-1 text-grey-400">
        {'•••• ' + method.last4}
      </Typography>
    </>
  );
};

const AddCardMethod = () => {
  const intl = useIntl();
  const isMobile = useMediaQuery(mobile);
  const icons = useIcons();

  return (
    <BoxTw className="flex items-center">
      <icons.Icons.Add className="mr-3 text-grey-400" />
      <Typography className="font-black text-grey-400 mr-1">
        {isMobile
          ? intl.formatMessage({
              id: 'PaymentMethodOptions.AddNew.short',
              defaultMessage: 'Debit/Credit card',
            })
          : intl.formatMessage({
              id: 'PaymentMethodOptions.AddNew',
              defaultMessage: 'Add credit/debit card',
            })}
      </Typography>
    </BoxTw>
  );
};

export { AddCardMethod, CardMethod, KOHOMethod };
