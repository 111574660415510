import { useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { BoxProps } from '@material-ui/core';
import { BoxTw } from 'components/BoxTw';
import { TitleSmall } from 'components/TypographyTw';
import QRCodeStyling from 'qr-code-styling';

import { RequestMoneyInitialsAvatar } from './RequestMoneyInitialsAvatar';

export const RequestMoneyQRCode = ({
  destination,
  initials,
  className,
}: BoxProps & {
  destination: string;
  initials: string;
}) => {
  const intl = useIntl();

  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: 175,
        height: 175,
        margin: 0,
        data: destination,
        // setting error correction level to H (the highest possible) to make sure the QR code is
        // readable even though we cover portion of it with the user's initials.
        qrOptions: { typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' },
        dotsOptions: { type: 'square', color: '#373e4d' },
        cornersSquareOptions: { type: 'square', color: '#373e4d' },
        cornersDotOptions: { type: 'square', color: '#373e4d' },
      }),
    [destination],
  );

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [ref, qrCode]);

  useEffect(() => {
    qrCode.update({
      data: destination,
    });
  }, [destination, qrCode]);

  return (
    <BoxTw className={className}>
      <TitleSmall className="text-white text-center">
        {intl.formatMessage({
          id: 'RequestMoneyQRCode.Scan',
          defaultMessage: 'Scan to pay',
        })}
      </TitleSmall>
      <BoxTw className="rounded bg-white h-[180px] w-[180px] flex justify-center">
        <div className="m-auto h-[175px] relative" ref={ref} />
        <RequestMoneyInitialsAvatar
          initials={initials}
          className="absolute top-1/2"
        />
      </BoxTw>
    </BoxTw>
  );
};
