/** @jsxImportSource theme-ui */
import { ButtonHTMLAttributes, HTMLAttributes, MouseEventHandler } from 'react';

import { Link, LinkProps as ReachLinkProps } from '@reach/router';
import { ThemeUIStyleObject } from 'theme-ui';

import { trackInput } from '../analytics/analytics';
import { theme } from '../theme';
import CircularProgress from './CircularProgress';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'clear'
  | 'clearInverted'
  | 'cancel'
  | 'coral'
  | 'outline'
  | 'primaryInverted';

interface BaseProps {
  variant?: ButtonVariant;
  trackName?: string;
  trackMetadata?: Record<string, string | number | boolean>;
}

interface ButtonProps
  extends BaseProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}
interface ButtonLinkExternalProps
  extends BaseProps,
    React.ComponentPropsWithoutRef<'a'> {}
interface ButtonLinkProps extends BaseProps, ReachLinkProps<any> {
  to: string;
}

const InnerWrap = (props: HTMLAttributes<HTMLSpanElement>) => {
  /* span wrapper is to account for Safari bug in v14 that causes premature text wrapping */
  return <span className="w-full relative" {...props} />;
};

const buttonTypes = {
  primary: {
    color: 'white',
    backgroundColor: 'primary',
    '&:hover:enabled': {
      color: 'white',
      cursor: 'pointer',
      bg: 'primaryActive',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 0 2px rgba(47, 60, 207, 0.5)',
    },
    '&:disabled': {
      bg: 'primaryDisabled',
    },
  },
  primaryInverted: {
    color: '#2F3CCF',
    backgroundColor: 'white',
    '&:hover:enabled': {
      color: 'white',
      cursor: 'pointer',
      bg: 'primaryActive',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 0 2px rgba(47, 60, 207, 0.5)',
    },
    '&:disabled': {
      bg: 'primaryDisabled',
    },
  },
  secondary: {
    backgroundColor: 'white',
    color: 'primary',
    borderWidth: '1px',
    borderColor: 'primary',
    borderStyle: 'solid',
    '&:hover:enabled, &:focus': {
      backgroundColor: 'primary',
      color: 'white',
      cursor: 'pointer',
    },
  },
  tertiary: {
    backgroundColor: 'transparent',
    color: 'primary',
    borderColor: 'transparent',
    '&:hover:enabled': {
      color: 'primaryActive',
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 0 2px rgba(47, 60, 207, 0.5)',
    },
    '&:disabled': {
      borderColor: 'transparent',
      color: 'primaryDisabled',
    },
  },
  coral: {
    color: 'white',
    backgroundColor: 'coral',
    '&:hover:enabled, &:focus': {
      cursor: 'pointer',
      opacity: 1,
    },
    '&:disabled': {
      opacity: '0.5',
    },
  },
  clear: {
    p: 2,
    backgroundColor: 'transparent',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    color: 'primary',
  },
  clearInverted: {
    p: 2,
    backgroundColor: 'transparent',
    border: 'none',
    '&:hover:enabled': {
      cursor: 'pointer',
      bg: 'primaryActive',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 0 2px rgba(47, 60, 207, 0.5)',
    },
    color: 'white',
  },
  cancel: {
    backgroundColor: '#F5F5F5',
    color: '#74748B',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  outline: {
    backgroundColor: 'transparent',
    color: 'primary',
    borderWidth: '1px',
    borderColor: 'primary',
    borderStyle: 'solid',
    '&:hover:enabled': {
      backgroundColor: 'primary',
      color: 'white',
      cursor: 'pointer',
    },
    '&:hover:active': {
      backgroundColor: 'primaryActive',
      color: 'white',
    },
  },
};

const getStyle = (variant: ButtonVariant): ThemeUIStyleObject => {
  return {
    py: 3,
    px: 3,
    border: 0,
    borderRadius: theme.borderRadius.small,
    fontFamily: theme.fonts.body,
    fontWeight: 4,
    fontSize: theme.fontSizes[2],
    textAlign: 'center',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ['100%'],
    transition: theme.transitions.quick,
    '&, &:active, &:hover, &:visited': {
      textDecoration: 'none',
    },
    '&:disabled': {
      filter: 'saturate(10%)',
      opacity: 0.4,
      '&:hover': {
        cursor: 'inherit',
      },
    },
    ...buttonTypes[variant],
  };
};

/** @deprecated - use ButtonTw from components/ButtonTw instead */
export const Button = ({
  children,
  type = 'submit',
  variant = 'primary',
  onClick,
  trackName,
  trackMetadata,
  loading,
  ...props
}: ButtonProps) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> | undefined = (
    event,
  ) => {
    if (trackName) {
      trackInput({
        type: 'Clicked',
        name: trackName,
        details: { type: type, element: 'button', ...trackMetadata },
      });
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button type={type} sx={getStyle(variant)} onClick={handleClick} {...props}>
      <InnerWrap>
        {loading ? (
          <div>
            {/* Adding non breaking space to make sure the button has
                the height of regular text in it while loading */}
            &nbsp;
            <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
              <CircularProgress variant="small" className="text-[inherit]" />
            </div>
          </div>
        ) : (
          children
        )}
      </InnerWrap>
    </button>
  );
};

export const ButtonLinkExternal = ({
  children,
  variant = 'primary',
  onClick,
  trackName,
  trackMetadata,
  ...props
}: ButtonLinkExternalProps) => {
  const handleClick: MouseEventHandler<HTMLAnchorElement> | undefined = (
    event,
  ) => {
    if (trackName) {
      trackInput({
        type: 'Clicked',
        name: trackName,
        details: { element: 'a', to: props.href || '', ...trackMetadata },
      });
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <a
      sx={getStyle(variant)}
      onClick={handleClick}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      <InnerWrap>{children}</InnerWrap>
    </a>
  );
};

/** @deprecated - use ButtonLink from components/ButtonTw instead */
export const ButtonLink = ({
  children,
  variant = 'primary',
  onClick,
  trackName,
  trackMetadata,
  ...props
}: ButtonLinkProps) => {
  const handleClick: MouseEventHandler<any> | undefined = (event) => {
    if (trackName) {
      trackInput({
        type: 'Clicked',
        name: trackName,
        details: { element: 'a', to: props.to, ...trackMetadata },
      });
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Link sx={getStyle(variant)} onClick={handleClick} {...(props as any)}>
      <InnerWrap>{children}</InnerWrap>
    </Link>
  );
};
