import { useIntl } from 'react-intl';

import { default as CircularProgressMUI } from '@material-ui/core/CircularProgress';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
  variant?: 'small' | 'large';
}
const CircularProgress = ({ variant, className }: Props) => {
  const intl = useIntl();

  const label = intl.formatMessage({
    id: 'CircularProgress.Loading',
    defaultMessage: 'Loading',
  });

  const sizes = {
    small: 16,
    large: 24,
  };

  return (
    <CircularProgressMUI
      className={twMerge('text-primary-300', className)}
      aria-label={label}
      aria-busy="true"
      {...(variant && { size: sizes[variant] })}
    />
  );
};

export default CircularProgress;
