import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useEditMobileNumberMutation } from 'apis/registration';
import { SecurityAssurance } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { ErrorParagraph, Paragraph, TitleLarge } from 'components/TypographyTw';
import { replaceMaskedPhoneRegex } from 'components/forms';
import { useValidationMsg } from 'components/forms/useValidationMsg';
import { KhInputMobileNumber } from 'components/inputs/KhInputMobileNumber';
import { MobileNumberSchema } from 'registration/zodForms/zodFormRegistrationMobileNumber';
import {
  useTrackElementViewedOnce,
  useTrackPageViewedOnceDeprecated,
} from 'utility/analyticsHooks';

export const RegistrationEditMobileNumberPage = ({
  navigateToNextPage,
  editMobileNumberForm,
}: {
  navigateToNextPage: () => void;
  editMobileNumberForm: UseFormReturn<MobileNumberSchema, any>;
}) => {
  const intl = useIntl();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = editMobileNumberForm;

  const [editMobileNumber, { error: editMobileNumberError }] =
    useEditMobileNumberMutation();

  const submitEditMobileNumberForm: SubmitHandler<MobileNumberSchema> = async (
    data: MobileNumberSchema,
  ) => {
    try {
      await editMobileNumber(
        data.mobileNumber.replace(replaceMaskedPhoneRegex, ''),
      ).unwrap();
      navigateToNextPage();
    } catch (err) {
      // do nothing
    }
  };

  useTrackPageViewedOnceDeprecated({
    name: 'registration-edit-mobile-number',
    overrideUrl: `registration/edit-mobile-number`,
  });

  useTrackElementViewedOnce({
    element: errors?.mobileNumber,
    name: 'edit-mobile-number-input-error',
  });

  return (
    <>
      <TitleLarge className="legacy:mb-6 legacy:sm:mb-8">
        {intl.formatMessage({
          id: 'Registration.EditMobileNumberPage.Title',
          defaultMessage: 'Edit your mobile number',
        })}
      </TitleLarge>
      <Paragraph className="mb-4 legacy:mb-6 legacy:sm:mb-8">
        {intl.formatMessage({
          id: 'Registration.EditMobileNumberPage.Subtitle',
          defaultMessage: `We’ll resend your code when you're done.`,
        })}
      </Paragraph>
      <form onSubmit={handleSubmit(submitEditMobileNumberForm)}>
        <div className="mb-4">
          <KhInputMobileNumber
            {...register('mobileNumber')}
            label={intl.formatMessage({
              id: 'Registration.EditMobileNumberPage.MobileNumberLabel',
              defaultMessage: 'Mobile number',
            })}
            trackName="edit-mobile-number"
            error={!!errors.mobileNumber}
            helperText={useValidationMsg(errors.mobileNumber?.message)}
          />

          {editMobileNumberError && (
            <ErrorParagraph>
              {intl.formatMessage({
                id: 'Registration.EditMobileNumberPage.DefaultError',
                defaultMessage: `There was a problem updating your mobile number.`,
              })}
            </ErrorParagraph>
          )}
        </div>
        <ButtonTw
          type="submit"
          trackName="move-edit-mobile-number"
          disabled={!isValid}
          className="mb-6"
        >
          {intl.formatMessage({
            id: 'Registration.EditMobileNumberPage.CTA',
            defaultMessage: 'Next',
          })}
        </ButtonTw>
      </form>
      <SecurityAssurance />
    </>
  );
};
