import { Box } from '@material-ui/core';
import { mobile } from 'theme';
import { useTrackPageViewedOnce } from 'utility/analyticsHooks';

import { DownloadTheKohoAppContent } from './DownloadTheKohoAppContent';
import { DownloadTheKohoAppReviewCards } from './DownloadTheKohoAppReviewCards';

export const DownloadTheKohoAppPage = () => {
  useTrackPageViewedOnce({
    name: 'download-app-page',
  });

  return (
    <Box
      sx={{
        [mobile]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflowX: 'hidden',
          width: '100%',
        },
      }}
      data-testid="download-app-page"
    >
      <DownloadTheKohoAppContent />
      <DownloadTheKohoAppReviewCards />
    </Box>
  );
};
