import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { navigate } from '@reach/router';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { trackInput } from 'analytics/analytics';
import { useLazyGetCurrentTierQuery } from 'apis/tiers';
import { selectTheme } from 'appState/appState.slice';
// components
import { ButtonTw } from 'components/ButtonTw';
import { Modal } from 'components/Modal';
import { Paragraph, TitleLarge } from 'components/TypographyTw';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectLoginShowWelcomeBackModal } from 'login/store/selectors';
import { loginActions } from 'login/store/slice';
import { selectIsPaidTier } from 'tiers/store/selectors';
// types
import type { Image } from 'types/Image';

import { Affiliate, useGetAffiliatesQuery } from '../../apis/affiliates';
import { RegistrationFeatureFlags } from '../../registration/models/RegistrationFeatureFlags';

const imageUrlLegacy = 'https://assets.koho.ca/tiers/welcome_modal.jpeg';
const imageUrl = 'https://assets.koho.ca/modals/welcome.png';

const WelcomeModal = ({ open = false }: { open: boolean }) => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);
  const dispatch = useDispatch();

  const [localOpen, setLocalOpen] = useState(open);
  const isPaidTier = useSelector(selectIsPaidTier);
  const [getTier] = useLazyGetCurrentTierQuery();

  const isLight = themeName === 'light';

  const isWelcomeBackVariant = useSelector(selectLoginShowWelcomeBackModal);

  const handleClose = () => {
    trackInput({
      type: 'Clicked',
      name: 'Modal - Welcome -- Cancel',
      details: { element: 'button' },
    });

    setLocalOpen(!localOpen);
    dispatch(loginActions.setShouldShowWelcomeBackModal(false));
    // refetch tier on close in case it has been updated in the BE;
    // this modal is shown to XTM users, whose tier will be asynchronously updated
    // after they log in
    getTier();
  };

  useEffect(() => {
    setLocalOpen(open);
  }, [open]);

  const modalDefaultDescription = intl.formatMessage({
    id: 'WelcomeModal.Description',
    defaultMessage:
      'Thanks for choosing KOHO. The first step on your financial journey is adding money to your account. Would you like to add some now?',
  });

  const capEmployeeModalDescription = intl.formatMessage(
    {
      id: 'WelcomeModal.CapEmployeeDescription',
      defaultMessage:
        'Enjoy your first 3 months of KOHO Extra on us.<br></br> As a friendly reminder, spend $50 to get your $25 cash back within 60 days.<br></br>Start by adding some money!',
    },
    {
      br: () => <br />,
    },
  );

  const capGeneralModalDescription = intl.formatMessage(
    {
      id: 'WelcomeModal.CapGeneralDescription',
      defaultMessage:
        'Enjoy your first month of any KOHO plans on us. <br></br>As a friendly reminder, spend $100 to get your $25 cash back within 60 days.<br></br>Start by adding some money!',
    },
    {
      br: () => <br />,
    },
  );

  const doubleBr = () => (
    <>
      <br />
      <br />
    </>
  );
  const xtmRegistrationDescription = intl.formatMessage(
    {
      id: 'WelcomeModal.XTMRegistrationDescription',
      defaultMessage: `Save more and stress less with KOHO. <br></br> Need your payouts daily? Now you’ll get an auto-deposit straight into your account, and lots more perks! <br></br>Plus, get the 1st month of the Essential plan for free!`,
    },
    {
      br: doubleBr,
    },
  );

  const xtmWelcomeBackDescriptionNoTier = intl.formatMessage(
    {
      id: 'WelcomeModal.XTMWelcomeBackDescriptionNoTier',
      defaultMessage: `Need your payouts daily? Now you’ll get an auto-deposit straight into your account, and lots more perks!<br></br>Spend, save, exhale. <br></br>Plus, get the 1st month of the Essential plan for free!`,
    },
    {
      br: doubleBr,
    },
  );

  const xtmWelcomeBackDescriptionHasTier = intl.formatMessage(
    {
      id: 'WelcomeModal.XTMWelcomeBackDescriptionHasTier',
      defaultMessage: `Need your payouts daily? Now you’ll get an auto-deposit straight into your account, and lots more perks!<br></br>Spend, save, exhale.`,
    },
    {
      br: doubleBr,
    },
  );

  const isAffiliateRegistrationEnabled =
    useFlags()[RegistrationFeatureFlags.EnableAffiliateRegistration];

  const { data: affiliateData } = useGetAffiliatesQuery(
    isAffiliateRegistrationEnabled ? undefined : skipToken,
  );

  const getModalDescription = () => {
    if (affiliateData?.affiliates.includes(Affiliate.XTM)) {
      if (!isWelcomeBackVariant) {
        return xtmRegistrationDescription;
      }

      if (isPaidTier) {
        return xtmWelcomeBackDescriptionHasTier;
      }

      return xtmWelcomeBackDescriptionNoTier;
    }

    if (affiliateData?.affiliates.includes(Affiliate.CanadaPostEmployee)) {
      return capEmployeeModalDescription;
    }

    if (affiliateData?.affiliates.includes(Affiliate.CanadaPostGeneral)) {
      return capGeneralModalDescription;
    }

    return modalDefaultDescription;
  };

  const modalTitle = isWelcomeBackVariant
    ? intl.formatMessage({
        id: 'WelcomeModal.TitleWelcomeBack',
        defaultMessage: 'Welcome back',
      })
    : intl.formatMessage({
        id: 'WelcomeModal.Title',
        defaultMessage: 'Welcome to KOHO!',
      });

  const ctaAddMoney = intl.formatMessage({
    id: 'WelcomeModal.CTA.AddMoney.Text',
    defaultMessage: 'Add money',
  });

  const ctaCancel = intl.formatMessage({
    id: 'WelcomeModal.CTA.Cancel.Text',
    defaultMessage: 'Not now',
  });

  const image: Image = {
    displayHeights: ['160px', '350px'],
    displayWidths: ['370px', '600px'],
    url: isLight ? imageUrl : imageUrlLegacy,
  };

  return (
    <Modal
      data-cy="welcome-modal"
      image={image}
      open={localOpen}
      onClose={handleClose}
      classes={{
        paper: 'bg-tiers-blue-300 light:bg-primary-500',
        root: '[&_.MuiSvgIcon-root]:text-white text-center light:[&_.MuiDialogContent-root]:pb-5',
      }}
      absolutePositioning={false}
      maxWidth="sm"
    >
      <TitleLarge className="legacy:text-white light:text-secondary-75">
        {modalTitle}
      </TitleLarge>
      <Paragraph
        className="text-white mb-8"
        data-cy="welcome-modal-description"
      >
        {getModalDescription()}
      </Paragraph>
      <div className="flex gap-2">
        <ButtonTw
          data-cy="welcome-modal-cta-add-money"
          onClick={() => {
            navigate('/load');
            dispatch(loginActions.setShouldShowWelcomeBackModal(false));
          }}
          trackName="Modal - Welcome -- Add money"
          variant={isLight ? 'secondaryInverted' : 'primaryInverted'}
        >
          {ctaAddMoney}
        </ButtonTw>
        <ButtonTw
          data-cy="welcome-modal-cta-cancel"
          onClick={handleClose}
          className="text-white"
          variant={isLight ? 'clearInverted' : 'clear'}
        >
          {ctaCancel}
        </ButtonTw>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
