/** @jsxImportSource theme-ui */
import { Box, BoxProps } from '@material-ui/core';
import { mobile, theme } from 'theme';

export const Card = ({
  children,
  sx,
  borderColor,
  boxShadow,
  ...boxProps
}: BoxProps & { border?: string; boxShadow?: string }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        borderRadius: '8px',
        bgcolor: theme.colors.white,
        boxShadow: boxShadow || theme.shadows.heavy,
        borderColor: theme.colors.primary,
        padding: '16px',
        [mobile]: {
          marginBottom: '16px',
          width: 'auto',
        },
        ...sx,
      }}
      {...boxProps}
    >
      {children}
    </Box>
  );
};
