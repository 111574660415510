/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import {
  TextInputSecretAnswer,
  TextInputSecretQuestion,
} from '../../sendMoney/components';

export const TransferSecret = ({
  controlledValue,
  onChange,
  onValidChange,
  obfuscateAnswer,
}: {
  controlledValue: { question: string; answer: string };
  onChange: any;
  onValidChange: (name: string, isValid: boolean) => any;
  obfuscateAnswer?: boolean;
}) => {
  const secret = controlledValue;
  const [validate, setValidate] = useState(!obfuscateAnswer);

  const handleSecretChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidate(true);
    onChange(e);
  };

  return (
    <>
      <TextInputSecretQuestion
        onChange={onChange}
        onValidChange={(isValid) => onValidChange('secretQuestion', isValid)}
        className="mb-6"
        controlledValue={secret.question}
      />
      <TextInputSecretAnswer
        props={{
          onChange: handleSecretChange,
          onValidChange: (isValid) => onValidChange('secretAnswer', isValid),
          controlledValue: secret.answer,
        }}
        skipValidation={!validate}
        className="mb-6"
      />
    </>
  );
};
