import List from '@material-ui/core/List';
import { Logo } from 'components/Logo';

interface Props {
  paths: React.ReactNode[];
}

export function DesktopNav({ paths }: Readonly<Props>) {
  return (
    <>
      <div className="h-[10%] flex items-center legacy:justify-center light:px-10">
        <Logo />
      </div>
      <div className="flex h-[90%] legacy:ml-[10%] mr-0 legacy:pr-0 light:px-10">
        <List
          component="nav"
          aria-label="navigations"
          className="flex flex-col w-full mb-6"
        >
          {paths}
        </List>
      </div>
    </>
  );
}
