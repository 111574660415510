import { useSelector } from 'react-redux';

import { selectTheme } from 'appState/appState.slice';
import { twMerge } from 'tailwind-merge';
import { Image } from 'types/Image';

import { BoxTw } from './BoxTw';
import { Paragraph, TitleMedium } from './TypographyTw';

/**
 * @typedef {Object} WidgetProps
 * @property {ReactNode} children - Any ReactNode children
 * @property {string} description - The copy text for the widget
 * @property {Image} image - {@link Image} object
 * @property {string} title - The title of the modal
 */
type WidgetProps = {
  alerts?: React.ReactNode;
  bodyClassName?: string;
  children: React.ReactNode;
  contentClassName?: string;
  description: string | React.ReactNode;
  image: Image;
  title: string;
  titleClassName?: string;
  widgetClassName?: string;
};

/**
 * Displays a widget with a title and description. Optionally can display an image or children React nodes.
 * @param {WidgetProps} - {@link WidgetProps} object
 * @returns a Widget component
 */
export const Widget = ({
  alerts,
  bodyClassName,
  children,
  contentClassName,
  description,
  image = { url: '', alt: '' },
  title,
  titleClassName,
  widgetClassName,
  ...props
}: WidgetProps) => {
  const themeName = useSelector(selectTheme);
  return (
    <BoxTw
      className={twMerge(
        'rounded-lg h-fit overflow-hidden text-white leading-[0] light:p-8 light:bg-white',
        widgetClassName,
      )}
      variant={themeName === 'light' ? 'basic' : 'shadow'}
      {...props}
    >
      {image.url !== '' && (
        <img
          className={twMerge(
            'w-full light:max-h-[320px] object-cover light:rounded-lg  light:mb-8',
            'legacy:h-[140px] legacy:min-[600px]:h-[260px] legacy:min-[960px]:h-[160px]',
          )}
          src={image.url}
          alt={image.alt}
        />
      )}
      <div
        className={twMerge(
          'legacy:bg-tiers-blue-300 legacy:p-6',
          contentClassName,
        )}
      >
        {alerts}
        <TitleMedium
          className={twMerge(
            'mt-0 legacy:text-white font-body light:text-2xl light:normal-case light:font-medium light:mb-2',
            titleClassName,
          )}
        >
          {title}
        </TitleMedium>
        <Paragraph
          className={twMerge('legacy:text-white text-grey-400', bodyClassName)}
        >
          {description}
        </Paragraph>
        {children}
      </div>
    </BoxTw>
  );
};

export default Widget;
