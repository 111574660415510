import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { LinkTw } from 'components/LinkTw';
import { BodyXs } from 'components/TypographyTw';

import { LocaleContext } from './I18NContextProvider';

export const MarketingTermsAndPolicy = () => {
  const intl = useIntl();
  const { deepLinks } = useContext(LocaleContext);

  const linkClasses = `text-grey-400 hover:text-grey-500 text-sm legacy:font-bold legacy:hover:underline legacy:hover:decoration-1
    light:font-body light:font-xs/[18px] light:font-[450] light:text-grey-500
    `;

  const termsOfUseLink = (str) => (
    <LinkTw
      className={linkClasses}
      external={true}
      target="_blank"
      to={deepLinks.KOHO.TermsOfUseMC}
      trackName="KOHO Terms of Use"
    >
      {str}
    </LinkTw>
  );

  const privacyLink = (str) => (
    <LinkTw
      className={linkClasses}
      external={true}
      target="_blank"
      to={deepLinks.KOHO.PrivacyPolicyMC}
      trackName="Privacy Policy"
    >
      {str}
    </LinkTw>
  );

  const content = intl.formatMessage(
    {
      id: 'Registration.IdentityTermsPolicy',
      defaultMessage: `By clicking 'Next', you agree that KOHO may retain the information you provide in order to email you about your incomplete enrollment, and you agree to <termsOfUseLink>KOHO's Terms of Use</termsOfUseLink> and <privacyLink>Privacy Policy.</privacyLink>`,
    },
    {
      termsOfUseLink: (link) => termsOfUseLink(link),
      privacyLink: (link2) => privacyLink(link2),
    },
  );

  return <BodyXs className="mt-4">{content}</BodyXs>;
};
