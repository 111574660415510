import { createApi } from '@reduxjs/toolkit/query/react';

import baseQueryDefault from './baseQuery';

export const rootApi = createApi({
  reducerPath: 'rootApi',
  baseQuery: baseQueryDefault,
  endpoints: () => ({}),
  tagTypes: [
    'Aliases',
    'Cards',
    'Checklist',
    'ContestEligibility',
    'Cover',
    'ContestEligibility',
    'CreditBuilding',
    'HISA',
    'NBA',
    'PaymentMethods',
    'Tiers',
    'User',
    'BillingMethods',
    'PreferredBillingMethods',
    'MFA',
    'Affiliates',
  ],
});
