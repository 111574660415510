import { Logo } from 'components/Logo';
import { twMerge } from 'tailwind-merge';

import { LanguageToggle } from '.';
import { IntercomChatButton } from '../intercom/IntercomChatButton';

export const NavbarBasic = ({
  className,
  chat = true,
  languageToggle = true,
  ...props
}: {
  className?: string;
  chat: boolean | undefined;
  languageToggle: boolean | undefined;
}) => {
  return (
    <nav
      className={twMerge(
        'flex overflow-auto items-center',
        'py-4 xl:py-6',
        'px-8 md:px-[5%] xl:px-[2%]',
        className,
      )}
      {...props}
    >
      <div className="flex flex-1">
        <Logo />
      </div>

      {(languageToggle || chat) && (
        <div className="flex gap-4 items-center">
          {languageToggle && <LanguageToggle />}
          {chat && <IntercomChatButton className="relative top-0 right-0" />}
        </div>
      )}
    </nav>
  );
};

export default NavbarBasic;
