/** @jsxImportSource theme-ui */
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { zodResolver } from '@hookform/resolvers/zod';
import { Backdrop } from '@material-ui/core';
import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import {
  amazonTrackEvent,
  useAmazonTrackStartRegistration,
} from 'analytics/amzn';
import { segmentAnalytics, track } from 'analytics/analytics';
import { fbTrackEvent, useFbTrackStartRegistration } from 'analytics/fbq';
import { trackRemarketing } from 'analytics/gtag';
import { rakutenGlobaltag } from 'analytics/rakuten';
import { trackRedditSignUp } from 'analytics/rdt';
import {
  trackSpotifySignUpFinished,
  trackSpotifySignUpStarted,
} from 'analytics/spotify';
import { tiktokTrackEvent } from 'analytics/ttq';
import { trackTwitterSignUpFinished } from 'analytics/twitter';
import { trackEventUnbounce } from 'analytics/unbounce';
import { useCPCTracking } from 'analytics/useCpc';
import { trackVeritonicPurchase } from 'analytics/veritonic';
import {
  Affiliate,
  useLazyGetAffiliatesQuery,
  usePostAffiliateWithAffiliateUserIdentifierMutation,
  usePostReferralAffiliateMutation,
} from 'apis/affiliates';
import {
  ReferralRequest,
  RegistrationErrorResponse,
  RegistrationIdentityErrorCodes,
  RegistrationUsersErrorCodes,
  RegistrationUsersRequest,
  TermsVersion,
  isDuplicateUserError,
  useApplyReferralCodeMutation,
  useCreateAccountMutation,
  useCreateUserMutation,
  useFingerprintsMutation,
  useIdentitiesMutation,
  useOtpVerifyMutation,
  useSendSMSLinkMutation,
} from 'apis/registration';
import { useLazyGetProfileQuery } from 'apis/users';
import { SessionRefreshModal } from 'auth/components';
import axios from 'axios';
import { Card, LayoutBasic } from 'components';
import CircularProgress from 'components/CircularProgress';
import { MarketingTermsAndPolicy } from 'components/MarketingTermsAndPolicy';
import { replaceMaskedPhoneRegex, validPOBoxRegex } from 'components/forms';
import { IntendedUse } from 'kyc/models/IntendedUse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { loginActions } from 'login/store/slice';
import { ReferralsValidationData } from 'models';
import { Address } from 'models/Address';
import { Occupation } from 'profile/data/occupations';
import { provinceInfo } from 'profile/data/provinces';
import { selectReferrerDataData } from 'referrals/store/selectors';
import { RegistrationDuplicateUserModal } from 'registration/components/RegistrationDuplicateUserModal';
import { RegistrationFeatureFlags } from 'registration/models/RegistrationFeatureFlags';
import { RegistrationPage } from 'registration/models/RegistrationPage';
import { IdentityExistingPage } from 'registration/pages/RegistrationStepIdentityExistingPage';
import { RegistrationIdentityPage } from 'registration/pages/RegistrationStepIdentityPage';
import { RegistrationMobileNumberPage } from 'registration/pages/RegistrationStepMobileNumberPage';
import { RegistrationUserInfoPage } from 'registration/pages/RegistrationStepUserInfoPage';
import {
  AddressFormSchema,
  zodFormRegistrationAddress,
  zodFormRegistrationAddressHome,
} from 'registration/zodForms/zodFormRegistrationAddress';
import {
  IdentityFormSchema,
  zodFormRegistrationIdentity,
} from 'registration/zodForms/zodFormRegistrationIdentity';
import {
  IdentityExistingFormSchema,
  zodFormRegistrationIdentityExisting,
} from 'registration/zodForms/zodFormRegistrationIdentityExisting';
import {
  MobileNumberSchema,
  zodFormRegistrationMobileNumber,
} from 'registration/zodForms/zodFormRegistrationMobileNumber';
import { RegistrationSmsOtpVerifyForm } from 'registration/zodForms/zodFormRegistrationSmsOtp';
import {
  UserInfoSchema,
  zodFormRegistrationUserInfo,
} from 'registration/zodForms/zodFormRegistrationUserInfo';
import { HelpAndAccessibility } from 'support/components';

import { getDeviceID } from 'libs/axios';
import { TM_PAGES, ThreatMetrixService } from 'libs/threatmetrix';

import { AnalyticsFeatureFlags } from '../../analytics/AnalyticsFeatureFlags';
import { RegistrationProgressIndicator } from '../components/RegistrationProgressIndicator';
import { RegistrationAddressPage } from './RegistrationStepAddressPage';
import { RegistrationEditMobileNumberPage } from './RegistrationStepEditMobileNumberPage';
import { RegistrationHomeAddressPage } from './RegistrationStepHomeAddressPage';
import { RegistrationIntentPage } from './RegistrationStepIntentPage';
import { RegistrationStepOccupationPage } from './RegistrationStepOccupation';
import { RegistrationSmsOtpPage } from './RegistrationStepSmsOtpPage';

interface RegistrationPageProps
  extends RouteComponentProps<{
    location: { state?: { registrationPage?: RegistrationPage } };
  }> {}

export const RegistrationMainFlowPage = (props: RegistrationPageProps) => {
  const intl = useIntl();
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isCoverTraffic =
      params.get('af_adset') === 'COVEROVERDRAFTPROTECTION';

    if (isCoverTraffic) {
      dispatch(loginActions.setLoginDestination('/transactions'));
    }
  }, [dispatch, location]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isCreditBuildingTraffic =
      params.get('af_adset')?.trim() === 'CREDIT-BUILDING';

    if (isCreditBuildingTraffic) {
      dispatch(loginActions.setLoginDestination('/transactions'));
      track({ event: 'koho.ca/credit-building User Received' });
    }
  }, [dispatch, location]);

  const [currentRegistrationPage, setCurrentRegistrationPage] =
    useState<RegistrationPage>(
      props?.location?.state?.registrationPage || RegistrationPage.Identity,
    );
  const [createIdentity, identityMutationData] = useIdentitiesMutation();
  const [errorMsg, setErrorMsg] = useState('');

  const [createUser, userMutationData] = useCreateUserMutation({
    fixedCacheKey: 'registrationUser',
  });
  const [createUserErrorMsg, setCreateUserErrorMsg] = useState('');

  const [cadPostAddressUser, setCadPostAddressUser] = useState<Address | null>(
    null,
  );
  const [cadPostAddressHome, setCadPostAddressHome] = useState<Address | null>(
    null,
  );
  const [referralCodeValue, setReferralCodeValue] = useState<string>();
  const [smsOtpInvalid, setSmsOtpInvalid] = useState(false);
  const [registrationFinishIsLoading, setRegistrationFinishIsLoading] =
    useState(false);

  const [verifyOtp, verifyOtpMutationData] = useOtpVerifyMutation();

  const [postFingerprints] = useFingerprintsMutation();
  const [createAccount, createAccountMutationData] = useCreateAccountMutation();
  const [sendSMSLink] = useSendSMSLinkMutation();
  const [getProfile, { isLoading: getProfileIsLoading }] =
    useLazyGetProfileQuery();
  const [postReferralAffiliate] = usePostReferralAffiliateMutation();
  const [affiliatesData, { isLoading: affiliatesDataIsLoading }] =
    useLazyGetAffiliatesQuery();
  const [
    showCPCPrivacyStatementOnIdentityPage,
    setShowCPCPrivacyStatementOnIdentityPage,
  ] = useState(false);
  const [postAffiliateWithAffiliateUserIdentifier] =
    usePostAffiliateWithAffiliateUserIdentifierMutation();

  const homeAddressRequiredIfPOBoxUsed =
    useFlags()[RegistrationFeatureFlags.HomeAddressRequiredIfPOBoxUsed];
  const removeSegmentProfileProperties =
    useFlags()[AnalyticsFeatureFlags.RemoveSegmentProfileProperties];
  const shouldSendSMSLink =
    useFlags()[RegistrationFeatureFlags.EnableSMSDownloadLink];
  const isAlwaysRedirectToKycEnabled =
    useFlags()[RegistrationFeatureFlags.AlwaysRedirectToKYC];
  const isAffiliateRegistrationEnabled =
    useFlags()[RegistrationFeatureFlags.EnableAffiliateRegistration];
  const isAffiliateRegistrationFromAffiliateUserIdentifierEnabled =
    useFlags()[
      RegistrationFeatureFlags
        .EnableAffiliateRegistrationWithAffiliateUserIdentifier
    ];
  const loadingTimeoutSeconds =
    useFlags()[RegistrationFeatureFlags.KYCInterceptLoadingTimeout];

  const [duplicateUserModalOpen, setDuplicateUserModalOpen] = useState(false);
  const [occupation, setOccupation] = useState<Occupation | null>(null);

  const { trackCPCPage, trackCPCSuccessAccountCreation } = useCPCTracking();

  useEffect(() => {
    const params = new URLSearchParams(window.location.hash.split('#').pop());
    params.forEach((val, key) => {
      if (
        key === 'referral' ||
        (key === 'referralCode' && isValidReferralCode(val))
      ) {
        isExistingReferralCode(val).then((existingReferralCode) => {
          if (existingReferralCode) {
            setReferralCodeValue(val);
          }
        });
      }
    });

    const afSub1 = new URLSearchParams(window.location.search).get('af_sub1');
    if (
      !!afSub1 &&
      isAffiliateRegistrationEnabled &&
      isValidReferralCode(afSub1)
    ) {
      isExistingReferralCode(afSub1).then((existingReferralCode) => {
        if (existingReferralCode) {
          setReferralCodeValue(afSub1);
        }
      });
    }

    const shortlink = new URLSearchParams(window.location.search).get(
      'shortlink',
    );
    if (shortlink === 'cpc') {
      setShowCPCPrivacyStatementOnIdentityPage(true);
    }
  }, [isAffiliateRegistrationEnabled]);
  const referrerData = useSelector(selectReferrerDataData);

  useEffect(() => {
    const impactRadiusParam = new URLSearchParams(window.location.search).get(
      impactRadiusSearchParamKey,
    );
    if (impactRadiusParam) {
      localStorage.setItem(impactRadiusSearchParamKey, impactRadiusParam);
    }
  }, []);

  // add rakuten marketing tracking
  useEffect(() => rakutenGlobaltag(), []);
  // add global tag manager
  useEffect(() => trackRemarketing('cpBzCOuZ4ZYDEI-zyK4D'), []);

  useEffect(() => {
    ThreatMetrixService.profile(TM_PAGES.REGISTRATION);
  }, []);

  useFbTrackStartRegistration();
  useAmazonTrackStartRegistration();

  useEffect(() => {
    trackSpotifySignUpStarted();
  }, []);

  // Registration Identity page
  const identityForm = useForm<IdentityFormSchema>({
    mode: 'onTouched',
    resolver: zodResolver(zodFormRegistrationIdentity),
  });

  const identityExistingForm = useForm<IdentityExistingFormSchema>({
    mode: 'onTouched',
    resolver: zodResolver(zodFormRegistrationIdentityExisting),
  });

  const submitIdentityForm: SubmitHandler<{
    email: string;
    password: string;
  }> = async (data) => {
    try {
      await createIdentity({
        identity: data,
        removeProfileProperties: removeSegmentProfileProperties,
      }).unwrap();

      if (isAffiliateRegistrationEnabled && referralCodeValue) {
        await postReferralAffiliate({
          referral_code: referralCodeValue,
        });
      }

      if (isAffiliateRegistrationFromAffiliateUserIdentifierEnabled) {
        const affiliate = new URLSearchParams(window.location.search).get(
          'affiliate',
        );
        const affiliateUserIdentifier = new URLSearchParams(
          window.location.search,
        ).get('affiliate_user_identifier');

        if (affiliate && affiliateUserIdentifier) {
          postAffiliateWithAffiliateUserIdentifier({
            affiliate: affiliate as Affiliate,
            affiliate_user_identifier: affiliateUserIdentifier,
          }).unwrap();
        }
      }

      trackCPCPage();
      setCurrentRegistrationPage(RegistrationPage.UserInfo);
    } catch (error) {
      const errorResponse =
        error as RegistrationErrorResponse<RegistrationIdentityErrorCodes>;
      if (errorResponse?.data?.errors.length > 0) {
        switch (errorResponse.data.errors[0].code) {
          case RegistrationIdentityErrorCodes.FailedEmailCheck:
          case RegistrationIdentityErrorCodes.EmailQuality:
          case RegistrationIdentityErrorCodes.EmailTypo:
          case RegistrationIdentityErrorCodes.UsernameViolation:
            setErrorMsg(
              intl.formatMessage({
                id: 'identityErrorMessage.EmailInvalid',
                defaultMessage: 'Please enter a valid email address.',
              }),
            );
            return;

          case RegistrationIdentityErrorCodes.EmailCheckInternalServerError:
          case RegistrationIdentityErrorCodes.EmailCheckUnknownError:
          case RegistrationIdentityErrorCodes.AuthenticationUnknownError:
          case RegistrationIdentityErrorCodes.IdentitiesUnknownError:
          case RegistrationIdentityErrorCodes.IdentitiesInternalServerError:
          case RegistrationIdentityErrorCodes.FailedIdentityUnknown:
          case RegistrationIdentityErrorCodes.ProviderViolation:
          case RegistrationIdentityErrorCodes.FailedIdentityAuth:
          case RegistrationIdentityErrorCodes.MissingOrganization:
          case RegistrationIdentityErrorCodes.MissingUserId:
          case RegistrationIdentityErrorCodes.DecodeError:
            setErrorMsg(
              intl.formatMessage({
                id: 'identityErrorMessage.UnexpectedError',
                defaultMessage: 'Unexpected error, please try again.',
              }),
            );
            return;

          case RegistrationIdentityErrorCodes.PasswordHistory:
            setErrorMsg(
              intl.formatMessage({
                id: 'identityErrorMessage.PasswordHistory',
                defaultMessage: 'Please enter a valid password.',
              }),
            );
            return;

          case RegistrationIdentityErrorCodes.PasswordLength:
            setErrorMsg(
              intl.formatMessage({
                id: 'identityErrorMessage.PasswordLength',
                defaultMessage: 'Password: 8 characters minimum. ',
              }),
            );
            return;

          case RegistrationIdentityErrorCodes.PasswordUpper:
            setErrorMsg(
              intl.formatMessage({
                id: 'identityErrorMessage.PasswordUpperCaseRequired',
                defaultMessage: 'Password: 1 upper case character required.',
              }),
            );
            return;

          case RegistrationIdentityErrorCodes.PasswordLower:
            setErrorMsg(
              intl.formatMessage({
                id: 'identityErrorMessage.PasswordLowerCaseRequired',
                defaultMessage: 'Password: 1 lower case character required.',
              }),
            );
            return;

          case RegistrationIdentityErrorCodes.TooManyRequests:
            setErrorMsg(
              intl.formatMessage({
                id: 'identityErrorMessage.TooManyRequests',
                defaultMessage: `Too many requests. Please wait and try again.`,
              }),
            );
            return;

          case RegistrationIdentityErrorCodes.ExistingIdentityBadPass:
            identityExistingForm.setValue(
              'email',
              identityForm.getValues('email'),
            );
            setCurrentRegistrationPage(RegistrationPage.IdentityExisting);

            return;

          case RegistrationIdentityErrorCodes.EmailDuplicate:
            navigate('/login', {
              state: { emailAddress: identityForm.getValues('email') },
            });
            return;

          default:
            setErrorMsg(
              intl.formatMessage({
                id: 'identityErrorMessage.DefaultError',
                defaultMessage: `Sorry, an error occurred.`,
              }),
            );
        }
      } else {
        setErrorMsg(
          intl.formatMessage({
            id: 'identityErrorMessage.DefaultError',
            defaultMessage: `Sorry, an error occurred.`,
          }),
        );
      }
    }
  };

  const [applyReferralCode] = useApplyReferralCodeMutation();
  const [referralRequest, setReferralRequest] =
    useState<ReferralRequest | null>(null);

  const userInfoForm = useForm<UserInfoSchema>({
    mode: 'onTouched',
    resolver: zodResolver(zodFormRegistrationUserInfo),
  });

  const submitAddressForm: SubmitHandler<any> = () => {
    const oneOfAddressLinesIsPoBox = [
      validPOBoxRegex.test(addressUserForm.getValues('addressLine1')),
      validPOBoxRegex.test(addressUserForm.getValues('addressLine2')),
    ].some((x) => !!x);

    if (homeAddressRequiredIfPOBoxUsed && oneOfAddressLinesIsPoBox) {
      setCurrentRegistrationPage(RegistrationPage.HomeAddress);
    } else {
      setCurrentRegistrationPage(RegistrationPage.Occupation);
    }
  };

  const submitUserInfoPageForm: SubmitHandler<any> = () => {
    setCurrentRegistrationPage(RegistrationPage.Address);
  };

  const submitHomeAddressPageForm = () => {
    setCurrentRegistrationPage(RegistrationPage.Occupation);
  };

  const addressUserForm = useForm<AddressFormSchema>({
    mode: 'all',
    resolver: zodResolver(zodFormRegistrationAddress),
  });

  const addressHomeForm = useForm<AddressFormSchema>({
    mode: 'all',
    resolver: zodResolver(zodFormRegistrationAddressHome),
  });

  const mobileNumberForm = useForm<MobileNumberSchema>({
    mode: 'onTouched',
    resolver: zodResolver(zodFormRegistrationMobileNumber),
  });

  const submitMobileNumberForm: SubmitHandler<MobileNumberSchema> = async (
    data: MobileNumberSchema,
  ) => {
    setCreateUserErrorMsg('');

    const tmx_session_id = ThreatMetrixService.session_id;
    const anonymous_id = (
      await segmentAnalytics.user()
    ).anonymousId() as string;

    const userToCreate: {
      user: RegistrationUsersRequest;
      removeProfileProperties: boolean;
    } = {
      user: {
        anonymous_id,
        device_id: getDeviceID(),
        terms_and_conditions_version: TermsVersion.mastercard,
        tmx_session_id,
        profile: {
          given_name: userInfoForm.getValues('givenName').trim(),
          family_name: userInfoForm.getValues('familyName').trim(),
          date_of_birth: userInfoForm.getValues('dateOfBirth'),
          telephone: data.mobileNumber.replace(replaceMaskedPhoneRegex, ''),
          // if the user entered an address on the home page, that means the address
          // from the user page is the shipping address
          ...(addressHomeForm.formState.isValid &&
          addressHomeForm.getValues('addressLine1') !== ''
            ? {
                shipping_address: {
                  // Fill in with data from canada post api (if available)
                  ...cadPostAddressUser,
                  // Fill in/override the rest with form data
                  city: addressUserForm.getValues('city'),
                  line1: addressUserForm.getValues('addressLine1'),
                  line2: addressUserForm.getValues('addressLine2'),
                  province:
                    provinceInfo[addressUserForm.getValues('province')].name,
                  province_code:
                    provinceInfo[addressUserForm.getValues('province')].code,
                  postal_code: addressUserForm.getValues('postalCode'),
                },
                address: {
                  ...cadPostAddressHome,
                  city: addressHomeForm.getValues('city'),
                  line1: addressHomeForm.getValues('addressLine1'),
                  line2: addressHomeForm.getValues('addressLine2'),
                  province:
                    provinceInfo[addressHomeForm.getValues('province')].name,
                  province_code:
                    provinceInfo[addressHomeForm.getValues('province')].code,
                  postal_code: addressHomeForm.getValues('postalCode'),
                },
              }
            : {
                address: {
                  ...cadPostAddressUser,
                  city: addressUserForm.getValues('city'),
                  line1: addressUserForm.getValues('addressLine1'),
                  line2: addressUserForm.getValues('addressLine2'),
                  province:
                    provinceInfo[addressUserForm.getValues('province')].name,
                  province_code:
                    provinceInfo[addressUserForm.getValues('province')].code,
                  postal_code: addressUserForm.getValues('postalCode'),
                },
              }),
          ...(occupation !== null && {
            occupation: {
              code: occupation.code,
              name: occupation.value,
            },
          }),
        },
      },
      removeProfileProperties: removeSegmentProfileProperties,
    };

    try {
      await createUser(userToCreate).unwrap();
    } catch (err) {
      if (
        isDuplicateUserError(
          (err as RegistrationErrorResponse<RegistrationUsersErrorCodes>)?.data
            ?.errors[0]?.code,
        )
      ) {
        setDuplicateUserModalOpen(true);
      } else {
        setCreateUserErrorMsg(
          intl.formatMessage({
            id: 'RegistrationMainFlowPage.CreateUserErrorMessage',
            defaultMessage: 'Something went wrong. Please try again.',
          }),
        );
      }

      return;
    }

    if (referralRequest) {
      try {
        await applyReferralCode(referralRequest).unwrap();
      } catch (err) {
        // ignore
      }

      if (isAffiliateRegistrationEnabled) {
        affiliatesData()
          .unwrap()
          .then(async (response) => {
            if (!response.affiliates?.length) {
              // Create the affiliate if it wasn't created at identity creation
              await postReferralAffiliate({
                referral_code: referralRequest.campaign,
              });
            }
          });
      }
    }

    try {
      await postFingerprints().unwrap();
    } catch (err) {
      // ignore
    }
    setCurrentRegistrationPage(RegistrationPage.VerifyMobile);
  };

  const navigateToEditMobileNumber = () => {
    setCurrentRegistrationPage(RegistrationPage.EditMobileNumber);
  };
  const editMobileNumberForm = useForm<MobileNumberSchema>({
    mode: 'onTouched',
    resolver: zodResolver(zodFormRegistrationMobileNumber),
  });

  const navigateToVerifyMobilePage = () =>
    setCurrentRegistrationPage(RegistrationPage.VerifyMobile);
  const onOccupationFormSubmit = () => {
    setCurrentRegistrationPage(RegistrationPage.MobileNumber);
  };

  const submitVerifyOtp = async (data: RegistrationSmsOtpVerifyForm) => {
    try {
      await verifyOtp(data.otp).unwrap();
      setCurrentRegistrationPage(RegistrationPage.Intent);
    } catch {
      setSmsOtpInvalid(true);
    }
  };

  const shouldNavigateToKYCIntercept = (affiliates: Affiliate[]) =>
    isAffiliateRegistrationEnabled &&
    (affiliates.includes(Affiliate.CanadaPostEmployee) ||
      affiliates.includes(Affiliate.XTM));

  const createUserAccount = async (
    intendedUse?: IntendedUse,
  ): Promise<void> => {
    setRegistrationFinishIsLoading(true);
    const body = intendedUse
      ? {
          intended_use: intendedUse,
        }
      : {};

    try {
      await createAccount(body).unwrap();
    } catch {
      navigate('registration-account-error');
      return;
    }
    const profileData = await getProfile().unwrap();

    trackEventUnbounce();

    if (profileData.profile) {
      fbTrackEvent('CompleteRegistration', {
        'ud[em]': profileData.profile?.email,
        'ud[fn]': profileData.profile?.given_name,
        'ud[ln]': profileData.profile?.family_name,
        'ud[external_id]': profileData.profile?.email,
      });

      amazonTrackEvent('CompleteRegistration', profileData.profile?.email);

      tiktokTrackEvent('CompleteRegistration', {
        settings: { email: profileData.profile?.email },
      });

      trackRedditSignUp(profileData.profile?.email);

      trackSpotifySignUpFinished();

      trackTwitterSignUpFinished();

      trackCPCSuccessAccountCreation();
    }

    trackVeritonicPurchase(referralCodeValue || referralRequest?.campaign);

    /**
     * Note: we need to update the LD client with the new user context, so the Tiers
     * selection page will either show the page or redirect to the transactions page.
     */

    const affiliates = await affiliatesData()
      .unwrap()
      .catch(() => {
        // ignore, shouldn't block registration flow
      });

    if (shouldNavigateToKYCIntercept(affiliates?.affiliates || [])) {
      setTimeout(async () => {
        const profile = await getProfile();

        if (profile.data?.profile?.cleared) {
          navigate('/transactions', { state: { showWelcomeModal: true } });
          return;
        }

        navigate('/kyc/registration-intercept');
      }, loadingTimeoutSeconds * 1000);
    } else if (isAlwaysRedirectToKycEnabled) {
      navigate('/kyc/entry');
    } else {
      navigate('/transactions', { state: { showWelcomeModal: true } });
    }

    let tel = profileData.profile
      ? profileData.profile.tel
      : mobileNumberForm
          .getValues('mobileNumber')
          .replace(replaceMaskedPhoneRegex, '');

    if (shouldSendSMSLink) {
      sendSMSLink({ type: 'onboarding', tel_no: tel });
    }
  };

  const getCurrentStep = () => {
    switch (currentRegistrationPage) {
      case RegistrationPage.Identity:
      case RegistrationPage.IdentityExisting:
        return 1;
      case RegistrationPage.UserInfo:
        return 2;
      case RegistrationPage.Address:
      case RegistrationPage.HomeAddress:
        return 3;
      case RegistrationPage.Occupation:
        return 4;
      case RegistrationPage.MobileNumber:
      case RegistrationPage.EditMobileNumber:
        return 5;
      case RegistrationPage.VerifyMobile:
        return 6;
      case RegistrationPage.Intent:
        return 7;
    }
  };

  return (
    <>
      <Backdrop
        open={
          createAccountMutationData.isLoading ||
          getProfileIsLoading ||
          affiliatesDataIsLoading ||
          registrationFinishIsLoading
        }
        className="z-10"
      >
        <CircularProgress />
      </Backdrop>
      <LayoutBasic pageName="Registration" chat={false}>
        <SessionRefreshModal />
        <RegistrationDuplicateUserModal
          isOpen={duplicateUserModalOpen}
          onModalClosed={() => setDuplicateUserModalOpen(false)}
        />
        <div className="flex h-full items-start py-6">
          <Card sx={{ py: 0 }}>
            <RegistrationProgressIndicator
              showBackButton={[
                RegistrationPage.MobileNumber,
                RegistrationPage.HomeAddress,
                RegistrationPage.Occupation,
                RegistrationPage.Address,
              ].includes(currentRegistrationPage)}
              onBackButtonClicked={() => {
                if (currentRegistrationPage === RegistrationPage.Address) {
                  setCurrentRegistrationPage(RegistrationPage.UserInfo);
                }
                if (currentRegistrationPage === RegistrationPage.HomeAddress) {
                  setCurrentRegistrationPage(RegistrationPage.Address);
                }
                if (currentRegistrationPage === RegistrationPage.Occupation) {
                  setCurrentRegistrationPage(RegistrationPage.Address);
                }
                if (currentRegistrationPage === RegistrationPage.MobileNumber) {
                  setCurrentRegistrationPage(RegistrationPage.Occupation);
                }
              }}
              totalSteps={7}
              currentStep={getCurrentStep()}
            />
            {currentRegistrationPage === RegistrationPage.Identity && (
              <RegistrationIdentityPage
                submitForm={(form) =>
                  submitIdentityForm({
                    email: form.email,
                    password: form['new-password'],
                  })
                }
                identityForm={identityForm}
                apiErrorMsg={errorMsg}
                referralCode={referralCodeValue}
                referrerName={referrerData?.referral_user?.given_name}
                loading={identityMutationData.isLoading}
                showCPCPrivacyStatement={showCPCPrivacyStatementOnIdentityPage}
              />
            )}
            {currentRegistrationPage === RegistrationPage.IdentityExisting && (
              <IdentityExistingPage
                formSubmit={(form) =>
                  submitIdentityForm({
                    email: form.email,
                    password: form['current-password'],
                  })
                }
                form={identityExistingForm}
                isLoading={identityMutationData.isLoading}
                isError={identityMutationData.isError}
              />
            )}
            {currentRegistrationPage === RegistrationPage.UserInfo && (
              <RegistrationUserInfoPage
                userInfoForm={userInfoForm}
                submitForm={submitUserInfoPageForm}
              />
            )}
            {currentRegistrationPage === RegistrationPage.Address && (
              <RegistrationAddressPage
                addressForm={addressUserForm}
                submitForm={submitAddressForm}
                setCadPostAddress={setCadPostAddressUser}
              />
            )}
            {currentRegistrationPage === RegistrationPage.HomeAddress && (
              <RegistrationHomeAddressPage
                addressForm={addressHomeForm}
                submitForm={submitHomeAddressPageForm}
                setCadPostAddress={setCadPostAddressHome}
                shippingAddressLine1={addressUserForm.getValues('addressLine1')}
              />
            )}
            {currentRegistrationPage === RegistrationPage.Occupation && (
              <RegistrationStepOccupationPage
                onOccupationSelected={setOccupation}
                occupation={occupation}
                onSubmit={onOccupationFormSubmit}
              />
            )}
            {currentRegistrationPage === RegistrationPage.MobileNumber && (
              <RegistrationMobileNumberPage
                submitForm={submitMobileNumberForm}
                mobileNumberForm={mobileNumberForm}
                onReferralRequestUpdated={setReferralRequest}
                referralCodePreSet={referralCodeValue}
                createUserLoading={userMutationData.isLoading}
                createUserError={createUserErrorMsg}
              />
            )}
            {currentRegistrationPage === RegistrationPage.VerifyMobile && (
              <RegistrationSmsOtpPage
                phoneNumber={mobileNumberForm.getValues('mobileNumber')}
                editMobileNumber={navigateToEditMobileNumber}
                mobileNumberEdited={editMobileNumberForm.getValues(
                  'mobileNumber',
                )}
                smsOtpInvalid={smsOtpInvalid}
                setSmsOtpInvalid={setSmsOtpInvalid}
                submitVerifyOtp={submitVerifyOtp}
                isLoading={verifyOtpMutationData.isLoading}
                showCPCPrivacyStatement={!showCPCPrivacyStatementOnIdentityPage}
              />
            )}
            {currentRegistrationPage === RegistrationPage.EditMobileNumber && (
              <RegistrationEditMobileNumberPage
                navigateToNextPage={navigateToVerifyMobilePage}
                editMobileNumberForm={editMobileNumberForm}
              ></RegistrationEditMobileNumberPage>
            )}

            {currentRegistrationPage === RegistrationPage.Intent && (
              <RegistrationIntentPage intentClicked={createUserAccount} />
            )}
          </Card>
        </div>
        <Card sx={{ mt: 'auto', mb: 0 }}>
          {currentRegistrationPage === RegistrationPage.Identity && (
            <div className="mb-8 text-center">
              <MarketingTermsAndPolicy />
            </div>
          )}
          <HelpAndAccessibility />
        </Card>
      </LayoutBasic>
    </>
  );
};

const isValidReferralCode = (referralCode: string): boolean => {
  return /^[a-zA-Z0-9]*$/.test(referralCode);
};

const isExistingReferralCode = async (
  referralCode: string,
): Promise<boolean> => {
  const res = await axios.get<ReferralsValidationData>(
    `${import.meta.env.VITE_GATEWAY_API}referrals/code/verify/${referralCode}`,
  );

  if (!res?.data?.invalid_reason) {
    return true;
  }

  return false;
};

export const impactRadiusSearchParamKey = 'irclickid';
