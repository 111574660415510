import { IVelocityData, MoneyMovementType } from 'apis/velocity';

type velocityMap = {
  [key in MoneyMovementType]?: string;
};
export function methodsMaxVelocityUtil(velocityData: IVelocityData) {
  const velocityToAmount: velocityMap = {};

  if (velocityData) {
    velocityData.money_movement.forEach((movement) => {
      velocityToAmount[movement.type] = movement.max_transaction_amount;
    });
  }
  return velocityToAmount;
}
