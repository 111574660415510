import { useIntl } from 'react-intl';

import { Box } from '@material-ui/core';
import { track } from 'analytics/analytics';
import clsx from 'clsx';
import { ButtonTw } from 'components/ButtonTw';
import { twMerge } from 'tailwind-merge';

export type PlanOption = 'monthly' | 'annual';

const TierTab = ({
  changeTab,
  label,
  selected,
}: {
  changeTab: () => void;
  label: string;
  selected: boolean;
}) => {
  return (
    <ButtonTw
      variant="outline"
      className={twMerge(
        clsx(
          'box-border legacy:border legacy:border-solid legacy:border-grey-200 legacy:bg-white legacy:text-grey-400 font-bold text-base py-4 px-3 w-full cursor-pointer',
          'transition-all duration-[0.2s] ease-in-out',
          'legacy:rounded-[5px] legacy:hover:text-primary-300 legacy:hover:bg-grey-75 legacy:hover:border-grey-75',
          selected &&
            'legacy:border-grey-75 legacy:bg-grey-75 legacy:text-primary-300 border-primary-400 bg-primary-400 text-white',
        ),
      )}
      onClick={changeTab}
    >
      {label}
    </ButtonTw>
  );
};

type PlanTabProps = {
  className?: string;
  setTab: (p: PlanOption) => void;
  tab: PlanOption;
};

export const PlanTabs = ({ className, setTab, tab }: PlanTabProps) => {
  const intl = useIntl();
  return (
    <Box
      className={twMerge(
        'flex flex-row gap-3 justify-center w-full',
        className,
      )}
    >
      <TierTab
        label={intl.formatMessage({
          id: 'TiersTrial.Tabs.monthly',
          defaultMessage: 'Monthly',
        })}
        selected={tab === 'monthly'}
        changeTab={() => {
          setTab('monthly');
          track({
            event: 'Clicked',
            properties: { name: 'tiers-selection-monthly' },
          });
        }}
      />
      <TierTab
        label={intl.formatMessage({
          id: 'TiersTrial.Tabs.yearly',
          defaultMessage: 'Yearly',
        })}
        selected={tab === 'annual'}
        changeTab={() => {
          setTab('annual');
          track({
            event: 'Clicked',
            properties: { name: 'tiers-selection-annual' },
          });
        }}
      />
    </Box>
  );
};
