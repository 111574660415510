import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { List, ListItem, ListItemIcon } from '@material-ui/core';
import { track } from 'analytics/analytics';
import { Affiliate, useGetAffiliatesQuery } from 'apis/affiliates';
import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';
import { ButtonTw } from 'components/ButtonTw';
import {
  BodySm,
  LabelLg,
  Paragraph,
  ParagraphSmall,
  TitleLarge,
} from 'components/TypographyTw';
import { useIcons } from 'hooks/useIcon';
import { IntendedUse } from 'kyc/models/IntendedUse';

interface IntentOption {
  title: string;
  subheading?: string;
  intent: IntendedUse;
  icon: ReactElement;
}

export const RegistrationIntentComponent = ({
  intentClicked,
}: {
  intentClicked: (intent: IntendedUse) => Promise<void>;
}) => {
  const intl = useIntl();
  const [otherOptionsShown, setOtherOptionsShown] = useState(false);
  const KhIcons = useIcons();
  const themeName = useSelector(selectTheme);
  const { data: affiliatesData } = useGetAffiliatesQuery();

  const isLightTheme = themeName === 'light';
  const ItemLabel = isLightTheme ? LabelLg : Paragraph;
  const ItemBody = isLightTheme ? BodySm : ParagraphSmall;

  const mainOptions: Array<IntentOption> = [
    {
      title: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.Cashback.Title',
        defaultMessage: 'Earn cash back',
      }),
      subheading: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.Cashback.Subheading',
        defaultMessage: 'Start earning on everyday spending',
      }),
      intent: IntendedUse.Cashback,
      icon: (
        <KhIcons.Features.Roundups className="text-primary-300 light:text-icons-fill" />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.CreditBuilding.Title',
        defaultMessage: 'Build credit',
      }),
      subheading: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.CreditBuilding.Subheading',
        defaultMessage: 'Grow your credit history with ease',
      }),
      intent: IntendedUse.CreditBuilding,
      icon: (
        <KhIcons.Navigation.Grow className="text-primary-300 light:text-icons-fill" />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.Saving.Title',
        defaultMessage: 'Save more',
      }),
      subheading: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.Saving.Subheading',
        defaultMessage: 'Earn interest, set goals, and more',
      }),
      intent: IntendedUse.Saving,
      icon: (
        <KhIcons.Icons.Rich className="text-primary-300 light:text-icons-fill" />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.Borrowing.Title',
        defaultMessage: 'Borrow money',
      }),
      subheading: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.Borrowing.Subheading',
        defaultMessage: 'Borrow with zero-interest, no credit check',
      }),
      intent: IntendedUse.Borrowing,
      icon: (
        <KhIcons.Icons.Transfer className="text-primary-300 light:text-icons-fill" />
      ),
    },
  ];

  const otherOptions: Array<IntentOption> = [
    {
      title: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.DailyLiving.Title',
        defaultMessage: 'Daily living expenses',
      }),
      intent: IntendedUse.DailyLivingExpenses,
      icon: (
        <KhIcons.Icons.Bills className="text-primary-300 light:text-icons-fill" />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.VirtualCurrency.Title',
        defaultMessage: 'Virtual currency purchases',
      }),
      intent: IntendedUse.VirtualCurrency,
      icon: (
        <KhIcons.Icons.Dollar className="text-primary-300 light:text-icons-fill" />
      ),
    },
  ];

  return (
    <>
      <TitleLarge
        className="legacy:mb-0 legacy:text-2xl legacy:md:text-3xl"
        data-cy="intent-page-title"
      >
        {affiliatesData?.affiliates.includes(Affiliate.XTM)
          ? intl.formatMessage({
              id: 'Registration.IntentPage.TitleXTM',
              defaultMessage: 'What are you interested in?',
            })
          : intl.formatMessage({
              id: 'Registration.IntentPage.Title',
              defaultMessage: 'What would you like to do next?',
            })}
      </TitleLarge>
      <Paragraph className="mb-6 legacy:mb-8">
        {affiliatesData?.affiliates.includes(Affiliate.XTM)
          ? intl.formatMessage({
              id: 'Registration.IntentPage.SubtitleXTM',
              defaultMessage:
                "Let's help you make money moves. You can change your mind later!",
            })
          : intl.formatMessage({
              id: 'Registration.IntentPage.Subtitle',
              defaultMessage: 'Choose the one that’s most important to you.',
            })}
      </Paragraph>
      <List className="mb-6 light:py-0">
        {mainOptions.map((option) => {
          return (
            <ListItem
              button
              divider
              key={option.intent}
              onClick={() => {
                track({
                  event: 'Clicked',
                  properties: {
                    name: `intent-${option.intent}`,
                  },
                });
                intentClicked(option.intent);
              }}
              data-cy={`main-intent-option-${option.intent}`}
              className="px-0 py-4"
            >
              <ListItemIcon className="light:mr-4 light:w-[40px] light:min-w-[40px] light:bg-icons-container light:p-2 light:rounded">
                <div className="light:mx-auto">{option.icon}</div>
              </ListItemIcon>
              <div>
                <ItemLabel className="legacy:font-bold mb-0 light:mb-1">
                  {option.title}
                </ItemLabel>
                <ItemBody className="legacy:text-xs light:m-0">
                  {option.subheading}
                </ItemBody>
              </div>
              <KDSIcons.Icons.Drilldown className="ml-auto legacy:text-grey-250 light:mr-4 light:text-primary-300" />
            </ListItem>
          );
        })}
      </List>
      <ButtonTw
        variant="ghost"
        onClick={() => setOtherOptionsShown(true)}
        disabled={otherOptionsShown}
      >
        {otherOptionsShown
          ? intl.formatMessage({
              id: 'Registration.IntentPage.OtherOptions',
              defaultMessage: 'Other options',
            })
          : intl.formatMessage({
              id: 'Registration.IntentPage.SomethingElse',
              defaultMessage: 'Something else',
            })}
      </ButtonTw>
      {otherOptionsShown && (
        <List>
          {otherOptions.map((option) => {
            return (
              <ListItem
                button
                divider
                key={option.intent}
                onClick={() => intentClicked(option.intent)}
                className="px-0 py-4"
              >
                <ListItemIcon className="light:mr-4 light:w-[40px] light:min-w-[40px] light:bg-icons-container light:p-2 light:rounded">
                  <div className="light:mx-auto">{option.icon}</div>
                </ListItemIcon>
                <div>
                  <ItemLabel className="legacy:font-bold mb-0">
                    {option.title}
                  </ItemLabel>
                </div>
                <KDSIcons.Icons.Drilldown className="ml-auto legacy:text-grey-250 light:mr-4 light:text-primary-300" />
              </ListItem>
            );
          })}
        </List>
      )}
    </>
  );
};
