export const deepLinksEn = {
  KOHO: {
    CardholderAgreement: 'https://www.koho.ca/legal#CardHolderAgreement',
    CardholderAgreementMC:
      'https://www.koho.ca/legal/?ver=mastercard#CardHolderAgreement',
    CreditBuilding: 'https://www.koho.ca/credit-building/',
    EarlyPayroll: 'https://www.koho.ca/earlypayroll/',
    Home: 'https://www.koho.ca',
    PowerUp: 'https://www.koho.ca/powerups/',
    Premium: 'https://www.koho.ca/premium',
    PrivacyPolicy: 'https://www.koho.ca/legal#PrivacyPolicy',
    PrivacyPolicyMC: 'https://www.koho.ca/legal/?ver=mastercard#PrivacyPolicy',
    Save: 'https://www.koho.ca/save',
    TermsOfUse: 'https://www.koho.ca/legal#KOHOTermsOfUse',
    TermsOfUseMC: 'https://www.koho.ca/legal/?ver=mastercard#KOHOTermsOfUse',
    Help: 'https://help.koho.ca/en/',
    HelpSelfie:
      'https://help.koho.ca/en/articles/4188626-verifying-your-identity-with-selfie',
    BillPay: 'https://help.koho.ca/en/articles/1516665-how-do-i-pay-a-bill',
    ETransfer:
      'https://help.koho.ca/en/articles/1499728-how-do-i-e-transfer-to-koho',
    ETransferThirdParty:
      'https://help.koho.ca/en/articles/5572751-what-s-a-3rd-party-e-transfer',
    Gamer: 'https://help.koho.ca/en/articles/5683246-koho-gamer',
    HelpCashback: 'https://help.koho.ca/en/articles/2034876-what-is-cash-back',
    VirtualCardFAQ:
      'https://help.koho.ca/en/articles/2145453-what-is-a-virtual-card',
    CardsFAQ: 'https://help.koho.ca/en/articles/1516744-your-card-faqs',
    CashBackFAQ:
      'https://help.koho.ca/en/articles/1502948-how-does-cash-back-work',
    CreditFAQ: 'https://help.koho.ca/en/collections/4756829-faq',
    ReferralFAQ:
      'https://help.koho.ca/en/articles/3104910-how-do-i-refer-friends-to-koho',
    SaveFAQ: 'https://help.koho.ca/en/collections/3582884-save',
    NBATandC: 'https://www.koho.ca/legal/#NBASignUpOffer',
    CashbackOnRent: 'https://www.koho.ca/legal/#CashbackOnRentTerms',
  },
  PTC: {
    PrivacyPolicy: 'https://www.peoplestrust.com/en/legal/privacy-security/',
  },
  GOV: {
    loanData:
      'https://www.canada.ca/en/financial-consumer-agency/services/loans/payday-loans.html',
  },
  NBA: {
    NBAStore: 'https://nbastore.ca/',
  },
};

export const deepLinksFr = {
  KOHO: {
    CardholderAgreement: 'https://www.koho.ca/fr/legal#CardHolderAgreement',
    CardholderAgreementMC:
      'https://www.koho.ca/fr/legal/?ver=mastercard#CardHolderAgreement',
    CreditBuilding: 'https://www.koho.ca/fr/credit-building/',
    EarlyPayroll: 'https://www.koho.ca/fr/earlypayroll/',
    Home: 'https://www.koho.ca/fr',
    PowerUp: 'https://www.koho.ca/fr/powerups/',
    Premium: 'https://www.koho.ca/fr/premium',
    PrivacyPolicy: 'https://www.koho.ca/fr/legal#PrivacyPolicy',
    PrivacyPolicyMC:
      'https://www.koho.ca/fr/legal/?ver=mastercard#PrivacyPolicy',
    Save: 'https://www.koho.ca/fr/save',
    TermsOfUse: 'https://www.koho.ca/fr/legal#KOHOTermsOfUse',
    TermsOfUseMC: 'https://www.koho.ca/fr/legal/?ver=mastercard#KOHOTermsOfUse',
    Help: 'https://help.koho.ca/fr/',
    HelpSelfie:
      'https://help.koho.ca/fr/articles/4188626-verifier-votre-identite-avec-selfie',
    BillPay:
      'https://help.koho.ca/fr/articles/1516665-comment-payer-une-facture',
    ETransfer:
      'https://help.koho.ca/fr/articles/1499728-how-do-i-e-transfer-to-koho',
    ETransferThirdParty:
      'https://help.koho.ca/fr/articles/5572751-what-s-a-3rd-party-e-transfer',
    Gamer: 'https://help.koho.ca/fr/articles/5683246-gamer',
    HelpCashback: 'https://help.koho.ca/fr/articles/2034876-remises-en-argent',
    VirtualCardFAQ: 'https://help.koho.ca/fr/articles/2145453',
    CardsFAQ: 'https://help.koho.ca/fr/articles/1516744',
    CashBackFAQ:
      'https://help.koho.ca/fr/articles/1502948-les-remises-en-argent-comment-ca-fonctionne',
    CreditFAQ: 'https://help.koho.ca/fr/collections/4756829-faq',
    ReferralFAQ:
      'https://help.koho.ca/fr/articles/3104910-nouveau-programme-de-recompenses-pour-referer-vos-ami-e-s',
    SaveFAQ: 'https://help.koho.ca/fr/collections/3582884-epargne',
    NBATandC: 'https://www.koho.ca/fr/legal/#NBASignUpOffer',
    CashbackOnRent: 'https://www.koho.ca/fr/legal/#CashbackOnRentTerms',
  },
  PTC: {
    PrivacyPolicy:
      'https://www.peoplestrust.com/fr/legale/protection-des-renseignements-personnels-et-securite/protection-des-renseignements-personnels/',
  },
  GOV: {
    loanData:
      'https://www.canada.ca/fr/agence-consommation-matiere-financiere/services/prets/prets-sur-salaire.html',
  },
  NBA: {
    NBAStore: 'https://nbastore.ca/fr',
  },
};
