/** @jsxImportSource theme-ui */
import { ReactNode } from 'react';

import { Box, BoxProps } from '@material-ui/core';
import { ThemeUIStyleObject } from 'theme-ui';

import { theme } from '../theme';

/**
 * @deprecated use <TitleMedium> instead
 */
export const H2 = ({ children, ...props }) => (
  <h2
    sx={{
      fontFamily: theme.fonts.heading,
      fontSize: theme.fontSizes[4],
      fontWeight: 4,
      lineHeight: 'heading',
      mt: 0,
      mb: 5,
      letterSpacing: '-0.2px',
    }}
    {...props}
  >
    {children}
  </h2>
);

/**
 * @deprecated use <TitleSmall> instead
 */
export const H3 = ({ children, ...props }) => (
  <h3
    sx={{
      fontFamily: theme.fonts.heading,
      fontSize: theme.fontSizes[3],
      fontWeight: 4,
      lineHeight: 'heading',
      my: 1,
      mx: 0,
      letterSpacing: '-0.2px',
    }}
    {...props}
  >
    {children}
  </h3>
);

/**
 * @deprecated use <TitleSmaller> instead
 */
export const H4 = ({ children, ...props }) => (
  <h4
    sx={{
      fontFamily: theme.fonts.heading,
      fontSize: theme.fontSizes[2],
      fontWeight: 4,
      lineHeight: 'heading',
      my: 1,
      mx: 0,
      letterSpacing: '-0.2px',
    }}
    {...props}
  >
    {children}
  </h4>
);

export const Text = ({ children, ...props }) => (
  <div
    sx={{
      fontFamily: theme.fonts.body,
      fontSize: theme.fontSizes[2],
      fontWeight: 2,
      lineHeight: 'heading',
      letterSpacing: '-0.2px',
    }}
    {...props}
  >
    {children}
  </div>
);

/**
 * @deprecated use <Paragraph> instead
 */
export const P = ({ children, ...props }) => (
  <p
    sx={{
      fontFamily: 'body',
      fontSize: 2,
      fontWeight: 1,
      mb: 3,
      mt: 0,
      letterSpacing: '-0.2px',
    }}
    {...props}
  >
    {children}
  </p>
);

/** @deprecated - use TitleLarge from TypographyTw */
export const TitleLarge = ({
  children,
  sx,
  ...props
}: {
  sx?: ThemeUIStyleObject;
  children: ReactNode;
}) => (
  <h1
    sx={{
      fontFamily: 'heading',
      fontSize: 5,
      fontWeight: 4,
      lineHeight: '36px',
      letterSpacing: '-0.2px',
      color: 'text',
      my: 3,
      ...sx,
    }}
    {...props}
  >
    {children}
  </h1>
);

/** @deprecated - use TitleMedium from TypographyTw */
export const TitleMedium = ({ children, color = 'text', ...props }) => (
  <h2
    sx={{
      fontFamily: 'heading',
      fontSize: 4,
      fontWeight: 4,
      lineHeight: '28px',
      letterSpacing: '-0.2px',
      color: color,
      my: 3,
    }}
    {...props}
  >
    {children}
  </h2>
);

/** @deprecated - use TitleSmall from TypographyTw */
export const TitleSmall = ({
  children,
  sx,
  ...props
}: {
  sx?: ThemeUIStyleObject;
  children: ReactNode;
}) => (
  <h3
    sx={{
      fontFamily: 'heading',
      fontSize: 3,
      fontWeight: 3,
      lineHeight: '24px',
      letterSpacing: '-0.2px',
      color: 'text',
      my: 3,
      ...sx,
    }}
    {...props}
  >
    {children}
  </h3>
);

/** @deprecated - use Paragraph from TypographyTw */
export const Paragraph = ({ children, color = 'text', ...props }) => (
  <p
    sx={{
      fontFamily: 'body',
      fontSize: 2,
      fontWeight: 1,
      lineHeight: '22px',
      letterSpacing: '-0.2px',
      color: color,
      my: 3,
    }}
    {...props}
  >
    {children}
  </p>
);

/** @deprecated - use ParagraphBold from TypographyTw */
export const ParagraphBold = ({ children, margin = 3, ...props }) => (
  <Paragraph sx={{ fontWeight: 4, my: margin }} {...props}>
    {children}
  </Paragraph>
);

export const ParagraphMedium = ({ children, color = 'text', ...props }) => (
  <Paragraph sx={{ fontWeight: 3 }} {...props}>
    {children}
  </Paragraph>
);

/**
 * @deprecated use <Subtitle> instead
 */
export const ParagraphSubtitle = ({ children, ...props }) => (
  <Paragraph sx={{ color: theme.colors.midGrey }} {...props}>
    {children}
  </Paragraph>
);

/** @deprecated - use ParagraphSmall from TypographyTw */
export const ParagraphSmall = ({
  children,
  sx,
  ...props
}: {
  sx?: ThemeUIStyleObject;
  children: ReactNode;
}) => (
  <p
    sx={{
      fontFamily: 'body',
      fontSize: 1,
      fontWeight: 2,
      lineHeight: '18px',
      letterSpacing: '-0.2px',
      color: 'midGrey',
      m: 0,
      p: 0,
      ...sx,
    }}
    {...props}
  >
    {children}
  </p>
);

/** @deprecated - use ErrorParagraph from TypographyTw */
export const ErrorParagraph = ({ children, ...props }) => (
  <p
    sx={{
      fontFamily: 'body',
      fontSize: 2,
      fontWeight: 2,
      lineHeight: '22px',
      letterSpacing: '-0.2px',
      color: 'red',
      my: 3,
    }}
    {...props}
  >
    {children}
  </p>
);

/** @deprecated - use NumberExtraLarge from TypographyTw */
export const NumberExtraLarge = ({ children, ...props }) => (
  <span
    sx={{
      fontFamily: 'numbers',
      fontSize: '50px',
      fontWeight: 600,
      lineHeight: '42px',
      letterSpacing: '-1.5px',
      color: 'text',
      m: 0,
      p: 0,
    }}
    {...props}
  >
    {children}
  </span>
);

/** @deprecated - use NumberLarge from TypographyTw */
export const NumberLarge = ({ children, ...props }) => (
  <span
    sx={{
      fontFamily: 'numbers',
      fontSize: 5,
      fontWeight: 3,
      lineHeight: '42px',
      letterSpacing: '-0.2px',
      color: 'text',
      m: 0,
      p: 0,
    }}
    {...props}
  >
    {children}
  </span>
);

export const NumberMedium = ({ children, color = 'text', ...props }) => (
  <span
    sx={{
      fontFamily: 'numbers',
      fontSize: 4,
      fontWeight: 3,
      lineHeight: '28px',
      letterSpacing: '-0.2px',
      color,
      m: 0,
      p: 0,
    }}
    {...props}
  >
    {children}
  </span>
);

/** @deprecated - use NumberSmall from TypographyTw */
export const NumberSmall = ({
  isCredit,
  children,
  sx,
  ...props
}: {
  children: ReactNode;
  isCredit?: boolean;
  sx?: ThemeUIStyleObject;
}) => (
  <span
    sx={{
      fontFamily: 'numbers',
      fontSize: 2,
      fontWeight: 2,
      lineHeight: '18px',
      letterSpacing: '-0.2px',
      color: isCredit ? 'success' : 'text',
      m: 0,
      p: 0,
      ...sx,
    }}
    {...props}
  >
    {children}
  </span>
);

/** @deprecated - use SubtitleSmall from components/TypographyTw */
export const SubtitleSmall = ({ color = 'text', ...props }: BoxProps) => (
  <Box
    sx={{
      fontFamily: 'body',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '18px',
      letterSpacing: '-0.2px',
      color: color,
      m: 0,
      p: 0,
    }}
    {...props}
  ></Box>
);

/**
 * @alias Subtitle.Regular
 */
/** @deprecated - use Subtitle from components/TypographyTw */
export const Subtitle = ({
  color = theme.colors.midGrey,
  ...props
}: BoxProps) => (
  <Box
    sx={{
      fontFamily: 'body',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 2,
      lineHeight: '18px',
      letterSpacing: '-0.2px',
      color: color,
      m: 0,
      p: 0,
    }}
    {...props}
  />
);

/** @deprecated - use SubtitleMedium from components/TypographyTw */
export const SubtitleMedium = ({ color = 'text', ...props }: BoxProps) => (
  <Box
    sx={{
      fontFamily: 'body',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 3,
      lineHeight: '18px',
      letterSpacing: '-0.2px',
      color: color,
      m: 0,
      p: 0,
    }}
    {...props}
  />
);

export const SubtitleBold = ({ color = 'text', ...props }: BoxProps) => (
  <Box
    sx={{
      fontFamily: 'body',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 4,
      lineHeight: '18px',
      letterSpacing: '-0.2px',
      color: color,
      m: 0,
      p: 0,
    }}
    {...props}
  />
);

/** @deprecated - use TitleXLarge from TypographyTw */
export const TitleXLarge = ({ color = 'text', ...props }: BoxProps) => (
  <Box
    sx={{
      fontFamily: 'body',
      fontSize: '40px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '48px',
      letterSpacing: '-0.2px',
      color: color,
      m: 0,
      p: 0,
    }}
    {...props}
  />
);

export const TitleBoldMedium = ({ color = 'text', ...props }: BoxProps) => (
  <Box
    sx={{
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '22px',
      letterSpacing: '-0.2px',
      color: theme.colors.tertiaryT300,
      m: 0,
      p: 0,
    }}
    {...props}
  />
);

export const SubtitleBasic = ({ color = 'text', ...props }: BoxProps) => (
  <Box
    sx={{
      fontFamily: 'body',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '18px',
      letterSpacing: '-0.2px',
      color: color,
      m: 0,
      p: 0,
    }}
    {...props}
  ></Box>
);
