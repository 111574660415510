import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { DialogProps } from '@material-ui/core';
import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';
import clsx from 'clsx';
import { Modal } from 'components';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import CircularProgress from 'components/CircularProgress';
import { Paragraph } from 'components/TypographyTw';

export type FundingStatusScreenStates =
  | 'loading'
  | 'done'
  | 'velocityLimit'
  | 'error';

interface FundingStatusModalProps extends Omit<DialogProps, 'children'> {
  open: boolean;
  screen: FundingStatusScreenStates;
  onConfirm: () => void;
}

export const FundingStatusModal = ({
  open,
  screen,
  onConfirm,
  ...props
}: Readonly<FundingStatusModalProps>) => {
  const intl = useIntl();

  let icon: React.ReactElement = <></>;

  let title: string = '',
    description: string = '';

  let buttonLabel: string | null = null;

  const themeName = useSelector(selectTheme);
  const isLegacyTheme = themeName === 'legacy';

  switch (screen) {
    case 'loading':
      icon = <KDSIcons.Spot.Wait className="mt-6" />;
      title = intl.formatMessage({
        id: 'LoadDebitCardPage.AddingFundsModal.LoadingTitle',
        defaultMessage: 'Adding funds to your account',
      });
      description = intl.formatMessage({
        id: 'LoadDebitCardPage.AddingFundsModal.LoadingDescription',
        defaultMessage:
          'Almost done! Hang tight while we securely process your funds.',
      });
      break;
    case 'done':
      icon = <KDSIcons.Spot.Success className="mt-6" />;
      title = intl.formatMessage({
        id: 'LoadDebitCardPage.AddingFundsModal.SuccessTitle',
        defaultMessage: 'Funds successfully loaded',
      });
      description = intl.formatMessage({
        id: 'LoadDebitCardPage.AddingFundsModal.SuccessDescription',
        defaultMessage: 'Your account is successfully loaded. Enjoy!',
      });
      buttonLabel = intl.formatMessage({
        id: 'LoadDebitCardPage.AddingFundsModal.Done',
        defaultMessage: 'Got it',
      });
      break;
    case 'error':
      icon = <KDSIcons.Spot.Stop className="mt-6" />;
      title = intl.formatMessage({
        id: 'LoadDebitCardPage.AddingFundsModal.ErrorTitle',
        defaultMessage: 'Something went wrong...',
      });
      description = intl.formatMessage({
        id: 'LoadDebitCardPage.AddingFundsModal.ErrorDescription',
        defaultMessage:
          'We couldn’t load your funds at the moment.  Check your network connection, or try again in a few minutes.',
      });
      buttonLabel = intl.formatMessage({
        id: 'LoadDebitCardPage.AddingFundsModal.Done',
        defaultMessage: 'Got it',
      });
      break;
    case 'velocityLimit':
      icon = <KDSIcons.Spot.Stop className="mt-6" />;
      title = intl.formatMessage({
        id: 'LoadDebitCardPage.AddingFundsModal.VelocityLimitTitle',
        defaultMessage: 'Velocity limit reached',
      });
      description = intl.formatMessage({
        id: 'LoadDebitCardPage.AddingFundsModal.VelocityLimitDescription',
        defaultMessage:
          'Your funding was denied because you’ve exceeded your transfer limit. Please try again tomorrow!',
      });
      buttonLabel = intl.formatMessage({
        id: 'LoadDebitCardPage.AddingFundsModal.Done',
        defaultMessage: 'Got it',
      });
      break;
    default:
      break;
  }

  return (
    <Modal
      closeButton={false}
      onClose={() => undefined}
      title={title}
      absolutePositioning={false}
      open={open}
      {...props}
    >
      {isLegacyTheme && { icon }}
      <Paragraph className="mb-8 mt-5">
        {description}
      </Paragraph>
      {buttonLabel && <ButtonTw onClick={onConfirm}>{buttonLabel}</ButtonTw>}
      {!buttonLabel && screen === 'loading' && (
        <BoxTw className="text-center">
          <CircularProgress />
        </BoxTw>
      )}
    </Modal>
  );
};
