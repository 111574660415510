import { default as Documents } from './Documents';
import { default as Success } from './Success';
import { ReactComponent as Activity } from './activity.svg';
import { ReactComponent as Bank } from './bank.svg';
import { ReactComponent as Camera } from './camera.svg';
import { ReactComponent as CheckVerified } from './check-verified.svg';
import { ReactComponent as Coin } from './coin.svg';
import { ReactComponent as Contacts } from './contacts.svg';
import { ReactComponent as Growth } from './growth.svg';
import { ReactComponent as HassleFree } from './hassle-free.svg';
import { ReactComponent as Paid } from './paid.svg';
import { ReactComponent as ProfileNotFound } from './profile-not-found.svg';
import { ReactComponent as Richer } from './richer.svg';
import { ReactComponent as Stocks } from './stocks.svg';
import { ReactComponent as Stop } from './stop.svg';
import { ReactComponent as SuperRich } from './super-rich.svg';
import { ReactComponent as Tip } from './tip.svg';
import { ReactComponent as Track } from './track.svg';
import { ReactComponent as VeryRich } from './very-rich.svg';
import { ReactComponent as Wait } from './wait.svg';

export const Spot = {
  Activity,
  Bank,
  Camera,
  CheckVerified,
  Coin,
  Contacts,
  Documents,
  Growth,
  HassleFree,
  Paid,
  ProfileNotFound,
  Richer,
  Stocks,
  Stop,
  Success,
  SuperRich,
  Tip,
  Track,
  VeryRich,
  Wait,
};
