import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RouteComponentProps, navigate } from '@reach/router';
import axios from 'axios';
import { selectAuthenticationSuccess } from 'passwordReset/store/selectors';

import { selectTheme } from '../appState/appState.slice';
import phonesImageMC from '../assets/images/general/phoneSpongeCubeMC.png';
import { Card, FooterImageWrapper, LayoutBasic } from '../components';
import { PasswordResetAccountLocked } from './AccountLocked';
import { PasswordResetEmailRequest } from './EmailRequest';
import { PasswordResetEmailSent } from './EmailSent';
import { PasswordResetLinkExpired } from './LinkExpired';
import { PasswordResetOtpLimitReached } from './OtpLimitReached';
import { PasswordResetCreate } from './Password';
import { PasswordResetSmsRequest } from './SmsRequest';
import { PasswordResetSuccess } from './Success';
import { passwordResetActions } from './store/slice';

const PasswordReset = ({
  children,
}: { children: ReactNode } & RouteComponentProps) => {
  const themeName = useSelector(selectTheme);
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const headers = axios.defaults.headers.common['X-Organization'];
  const authenticationChecked = useSelector(selectAuthenticationSuccess);

  useEffect(() => {
    if (!loaded && !!headers && !authenticationChecked) {
      setLoaded(true);
      const params = new URLSearchParams(window.location.href.split('?').pop());
      const token = params.get('token') || '';
      const email = params.get('email') || '';

      if (email) {
        dispatch(passwordResetActions.requestValue({ email }));
      }

      if (token.length > 0) {
        dispatch(passwordResetActions.setEmailToken(token));
      } else {
        navigate('/password-reset/email');
      }
    }
  }, [loaded, dispatch, headers, authenticationChecked]);

  return (
    <LayoutBasic
      pageName="Password reset"
      className="bg-grey-100 light:bg-white"
    >
      <Card>{children}</Card>
      {themeName === 'legacy' ? (
        <FooterImageWrapper>
          <img src={phonesImageMC} alt="" className="w-64 relative z-20" />
        </FooterImageWrapper>
      ) : null}
    </LayoutBasic>
  );
};

export {
  PasswordReset,
  PasswordResetCreate,
  PasswordResetEmailRequest,
  PasswordResetSuccess,
  PasswordResetLinkExpired,
  PasswordResetSmsRequest,
  PasswordResetEmailSent,
  PasswordResetOtpLimitReached,
  PasswordResetAccountLocked,
};
