declare const _satellite: { track: (event: string) => void };

export const useCPCTracking = () => {
  const enableCPCTracking = import.meta.env.VITE_CPC_TRACKING_SCRIPT;

  const trackCPCPage = () => {
    try {
      enableCPCTracking && _satellite.track('swoogo_Page_Navigation');
    } catch (e) {
      // do nothing - just do not show an error on the console
    }
  };

  const trackCPCSuccessAccountCreation = () => {
    try {
      enableCPCTracking && _satellite.track('swoogo_Page_Navigation_AC');
    } catch (e) {
      // do nothing - just do not show an error on the console
    }
  };

  return { trackCPCPage, trackCPCSuccessAccountCreation };
};
