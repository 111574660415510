import { useIntl } from 'react-intl';

import { useMediaQuery } from '@material-ui/core';
import { ParagraphMedium } from 'components/Typography';
import {
  Paragraph,
  TitleExtraLarge,
  TitleMedium,
} from 'components/TypographyTw';
import { Card } from 'creditBuilding/components/Card';
import { desktop, mobile, theme } from 'theme';
import { Box } from 'theme-ui';

const LineBreak = () => (
  <span>
    <br />
  </span>
);

const ColoredText = ({ children, color }) => (
  <span style={{ color }}>{children}</span>
);

export const DownloadTheKohoAppReviewCards = () => {
  const isDesktop = useMediaQuery(desktop);
  const intl = useIntl();

  const CanadiansUsingKohoTitle = intl.formatMessage(
    {
      id: 'DownloadTheAppReviewCard.Review.Title',
      defaultMessage: `Other Canadians <br></br> <color> love </color> using KOHO`,
    },
    {
      br: () => <LineBreak />,
      color: (args) => (
        <ColoredText color={theme.colors.primary}>{args}</ColoredText>
      ),
    },
  );

  const RatedByCanadiansTitle = intl.formatMessage(
    {
      id: 'DownloadTheAppReviewCard.Rated.Title',
      defaultMessage: `<color>#1</color>`,
    },
    {
      color: (args) => (
        <ColoredText color={theme.colors.primary}>{args}</ColoredText>
      ),
    },
  );

  const RatedByCanadiansSubtitle = intl.formatMessage(
    {
      id: 'DownloadTheAppReviewCard.Rated.SubTitle',
      defaultMessage: `<color> Rated Canadian <br></br>financial app </color>`,
    },
    {
      br: () => <LineBreak />,
      color: (args) => (
        <ColoredText color={theme.colors.grey300}>{args}</ColoredText>
      ),
    },
  );

  const RatingOnAppStoreTitle = intl.formatMessage(
    {
      id: 'DownloadTheAppReviewCard.Rated.Title',
      defaultMessage: `<color>4.8</color>`,
    },
    {
      color: (args) => (
        <ColoredText color={theme.colors.primary}>{args}</ColoredText>
      ),
    },
  );

  const RatingOnAppStoreSubtitle = intl.formatMessage(
    {
      id: 'DownloadTheAppReviewCard.Rated.SubTitle',
      defaultMessage: `<color> Rating on the <br></br> app store </color>`,
    },
    {
      br: () => <LineBreak />,
      color: (args) => (
        <ColoredText color={theme.colors.grey300}>{args}</ColoredText>
      ),
    },
  );

  const CanadiansUsingKohoAppTitle = intl.formatMessage(
    {
      id: 'DownloadTheAppReviewCard.Rated.Title',
      defaultMessage: `<color>1M+</color>`,
    },
    {
      color: (args) => (
        <ColoredText color={theme.colors.primary}>{args}</ColoredText>
      ),
    },
  );

  const CanadiansUsingKohoAppSubtitle = intl.formatMessage(
    {
      id: 'DownloadTheAppReviewCard.Rated.SubTitle',
      defaultMessage: `<color> Canadians using <br></br>KOHO </color>`,
    },
    {
      br: () => <LineBreak />,
      color: (args) => (
        <ColoredText color={theme.colors.grey300}>{args}</ColoredText>
      ),
    },
  );

  const ReviewCard = ({ review, userName }) => (
    <Card
      width={350}
      height={{ xs: 'auto', md: 220 }}
      bgcolor={theme.colors.white}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          paddingX: 2,
          paddingTop: 2,
          paddingBottom: 0,
          [mobile]: {
            width: 'auto',
          },
        }}
      >
        <Paragraph
          sx={{ lineHeight: '22px' }}
          className="m-0 text-left text-grey-500"
        >
          {review}
        </Paragraph>
        <ParagraphMedium sx={{ mb: 1 }}>{userName}</ParagraphMedium>
      </Box>
    </Card>
  );

  const cardReviewProps = [
    {
      review: intl.formatMessage({
        id: 'CreditBuildingLandingPageReviewCards.CreditImprovedReview.Content',
        defaultMessage:
          'My credit has been poor since I can remember. To my surprise my credit has improved from the 500’s to the mid 600’s in just 5 months! I finally feel like I’m on my way to a bright finanncial future.',
      }),
      userName: intl.formatMessage({
        id: 'CreditBuildingLandingPageReviewCards.User.Kristie',
        defaultMessage: '- Kristie',
      }),
    },
    {
      review: intl.formatMessage({
        id: 'CreditBuildingLandingPageReviewCards.HighlyRecommendReview.Content',
        defaultMessage:
          'I love KOHO it’s easy and convenient! It’s a great way to build your credit and earn cash back.',
      }),
      userName: intl.formatMessage({
        id: 'CreditBuildingLandingPageReviewCards.User.Patrick',
        defaultMessage: '- Alexander',
      }),
    },
    {
      review: intl.formatMessage({
        id: 'CreditBuildingLandingPageReviewCards.RebuildingCreditReview.Content',
        defaultMessage: 'Love KOHO! The cash back helps me a lot!',
      }),
      userName: intl.formatMessage({
        id: 'CreditBuildingLandingPageReviewCards.User.Rewertou',
        defaultMessage: '– Ranae',
      }),
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '92px 64px',
        mb: '8px',
        mt: '80px',
        backgroundColor: theme.colors.greyXLight,
        textAlign: 'center',
        [mobile]: { padding: '64px 24px', my: 0 },
      }}
    >
      <TitleExtraLarge className={isDesktop ? '' : 'text-[24px]'}>
        {CanadiansUsingKohoTitle}
      </TitleExtraLarge>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '63px',
          marginY: '64px',
          [mobile]: { display: 'block', marginY: '40px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            [mobile]: { marginBottom: '20px' },
          }}
        >
          <TitleMedium>{RatedByCanadiansTitle}</TitleMedium>
          <Paragraph className="font-bold -mt-[10px]">
            {RatedByCanadiansSubtitle}
          </Paragraph>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            [mobile]: { marginBottom: '20px' },
          }}
        >
          <TitleMedium>{RatingOnAppStoreTitle}</TitleMedium>
          <Paragraph className="font-bold -mt-[10px]">
            {RatingOnAppStoreSubtitle}
          </Paragraph>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TitleMedium>{CanadiansUsingKohoAppTitle}</TitleMedium>
          <Paragraph className="font-bold -mt-[10px]">
            {CanadiansUsingKohoAppSubtitle}
          </Paragraph>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gridColumnGap: 20,
          padding: '26px 6px',
          [mobile]: { display: 'block', padding: '0px 6px' },
        }}
      >
        {cardReviewProps.map((review, index) => (
          <Box
            key={'cardReview' + index}
            sx={{
              [mobile]: {
                justifyContent: 'center',
                display: 'flex',
                mb: '16px',
              },
            }}
          >
            <ReviewCard {...review} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
