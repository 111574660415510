import { useIntl } from 'react-intl';

import { useNavigate } from '@reach/router';
import { Illustrations } from 'assets/illustrations';
import { Modal } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { Paragraph, TitleLarge } from 'components/TypographyTw';

export const XTMCancelModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <img src={Illustrations.Stop} className="h-20 w-20 mt-12 mb-6" alt="" />
      <TitleLarge>
        {intl.formatMessage({
          id: 'XTMCancelModal.Title',
          defaultMessage: 'Canceling your plan...',
        })}
      </TitleLarge>
      <Paragraph className="mb-6">
        {intl.formatMessage({
          id: 'XTMCancelModal.Subtitle',
          defaultMessage:
            'If you cancel, you may no longer get free e-Transfers and other benefits. Are you sure?',
        })}
      </Paragraph>
      <ButtonTw
        type="button"
        onClick={onClose}
        className="mb-4"
        trackName="xtm-cancel-keep-plan"
      >
        {intl.formatMessage({
          id: 'XTMCancelModal.StayOnPlan',
          defaultMessage: 'Stay on my plan',
        })}
      </ButtonTw>
      <ButtonTw
        type="button"
        variant="clear"
        className="text-danger-300"
        onClick={() => navigate('/cancel-plan')}
        trackName="xtm-cancel-cancel-plan"
      >
        {intl.formatMessage({
          id: 'XTMCancelModal.CancelOnPlan',
          defaultMessage: 'Cancel my plan',
        })}
      </ButtonTw>
    </Modal>
  );
};
