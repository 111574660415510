import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Skeleton } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { track } from 'analytics/analytics';
import {
  Affiliate,
  FREE_METAL_CARD_PROMO_DAYS,
  useGetAffiliatesQuery,
} from 'apis/affiliates';
import { Language } from 'appState/appState.slice';
import kohoMetalCardIndigo from 'assets/images/cards/KOHO-MetalCard_Indigo.jpg';
import {
  ButtonTw,
  LocaleContext,
  Paragraph,
  ParagraphBold,
  TitleLarge,
} from 'components';
import { addDays, format } from 'date-fns';
import { TemplateTw } from 'layout/TemplateTw';
import { useInterestFromTiers } from 'tiers/hooks/useInterestFromTiers';

export const MetalCardFreePromo = () => {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  const { data: affiliateData, isLoading: isAffiliationLoading } =
    useGetAffiliatesQuery();

  const { rate, rateSkeleton } = useInterestFromTiers();

  const activeXTMAffiliation = affiliateData?.affiliations.find(
    (affiliation) =>
      affiliation.affiliate === Affiliate.XTM &&
      affiliation.onboarding_status === 'active',
  );

  const navigateToETransfer = () => {
    const eTransferPath = '/load/e-transfer';
    track({
      event: 'Clicked',
      properties: {
        name: 'eTransfer',
        to: eTransferPath,
        element: 'button',
        type: 'button',
      },
    });
    navigate(eTransferPath);
  };

  return (
    <TemplateTw
      name="MetalCardFreePromo"
      variant="center"
      className="text-center"
    >
      <TitleLarge className="uppercase">
        {intl.formatMessage(
          {
            id: 'MetalCardFreePromo.Title',
            defaultMessage: 'Win a free <color>metal card</color>',
          },
          {
            color: (args) => <span className="text-primary-300">{args}</span>,
          },
        )}
      </TitleLarge>
      <ParagraphBold>
        {intl.formatMessage({
          id: 'MetalCardFreePromo.Subtitle',
          defaultMessage:
            'Strengthen your wallet with a sleek Metal Card, worth $150!',
        })}
      </ParagraphBold>
      <div className="mb-6 mt-2 flex justify-center">
        <img
          className="w-[206px] h-[327px] rounded-lg"
          src={kohoMetalCardIndigo}
          alt="KOHO Metal Card"
        />
      </div>

      {isAffiliationLoading && (
        <div>
          <Skeleton className="w-full" height={32} />
          <Skeleton className="w-full" height={18} />
          <Skeleton className="w-full" height={18} />
        </div>
      )}

      {activeXTMAffiliation && activeXTMAffiliation.activated_at && (
        <Paragraph>
          {intl.formatMessage(
            {
              id: 'MetalCardFreePromo.DetailsWin',
              defaultMessage:
                'To enter to win, transfer at least $150 from any account, including AnyDay, to your KOHO by {date}.',
            },
            {
              date: format(
                addDays(
                  new Date(activeXTMAffiliation.activated_at),
                  FREE_METAL_CARD_PROMO_DAYS,
                ),
                'PPP',
              ),
            },
          )}
        </Paragraph>
      )}

      <Paragraph>
        {intl.formatMessage(
          {
            id: 'MetalCardFreePromo.DetailsBenefits',
            defaultMessage:
              'With KOHO, you’ll earn up to <rateSkeleton>{rate}</rateSkeleton>% interest and up to 2% cash back on groceries, transport, food and drink!',
          },
          { rate, rateSkeleton },
        )}
      </Paragraph>

      <div className="border-t-2 mt-4 pt-4">
        <ButtonTw
          onClick={() => navigateToETransfer()}
          className="mt-4"
          variant="primary"
          data-cy="add-funds-button"
        >
          {intl.formatMessage({
            id: 'MetalCardFreePromo.AddViaETransfer',
            defaultMessage: 'Add via e-Transfer',
          })}
        </ButtonTw>
        <ButtonTw
          onClick={() =>
            window.open(
              `https://www.koho.ca/${
                locale === Language.FR ? 'fr/' : ''
              }legal/#transferfundsterms`,
              '_blank',
            )
          }
          className="mt-4"
          variant="clear"
          data-cy="see-terms-button"
        >
          {intl.formatMessage({
            id: 'MetalCardFreePromo.Back',
            defaultMessage: 'See Terms and Conditions',
          })}
        </ButtonTw>
      </div>
    </TemplateTw>
  );
};
