import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useGetDirectDepositInfoQuery } from 'apis/directDeposit';
import { KDSIcons } from 'assets/images/kds_icons';
import { LocaleContext, Modal } from 'components';
import { BoxTw } from 'components/BoxTw';
import ButtonUnstyled from 'components/ButtonUnstyledTw';
import { LinkTw } from 'components/LinkTw';
import {
  Paragraph,
  ParagraphSmall,
  SubtitleMedium,
  SubtitleSmall,
  TitleLarge,
  TitleSmall,
} from 'components/TypographyTw';
import { TemplateTw } from 'layout/TemplateTw';
import { selectProfileName } from 'profile/store/selectors';
import { useInterestFromTiers } from 'tiers/hooks/useInterestFromTiers';

import { LineCopier } from '../../components/LineCopier';

export const LoadDirectDepositPage = () => {
  const intl = useIntl();
  const context = useContext(LocaleContext);

  const [instructionsModalOpen, setInstructionsModalOpen] = useState(false);

  const openModal = () => setInstructionsModalOpen(true);
  const closeModal = () => setInstructionsModalOpen(false);

  const name = useSelector(selectProfileName);

  const { data, isLoading } = useGetDirectDepositInfoQuery();

  return (
    <TemplateTw name="LoadDirectDeposit" variant="center">
      <TitleLarge>
        {intl.formatMessage({
          id: 'LoadDirectDeposit.Title',
          defaultMessage: 'Get paid directly into your account',
        })}
      </TitleLarge>

      <Paragraph className="my-0">
        {intl.formatMessage({
          id: 'LoadDirectDeposit.Description',
          defaultMessage:
            'Use your information below to set up a direct deposit and get paid a day early!*',
        })}
      </Paragraph>

      <BoxTw className="pb-6 border-b border-solid border-white/10">
        <ButtonUnstyled className="w-full" onClick={openModal}>
          <BoxTw className="rounded py-4 flex items-center transition-transform duration-200 shadow-[0_4px_8px_rgba(0,0,0,0.1)]">
            <KDSIcons.Icons.Question className="ml-2 text-primary-300 basis-12" />

            <Paragraph className="mx-2 my-0 font-bold text-primary-300">
              {intl.formatMessage({
                id: 'LoadDirectDeposit.BenefitLink',
                defaultMessage:
                  'What are the benefits of setting up direct deposit?',
              })}
            </Paragraph>
          </BoxTw>
        </ButtonUnstyled>
      </BoxTw>

      <BoxTw className="my-2 light:bg-white light:p-6 light:md:p-8 rounded-lg xl:mt-0">
        <>
          <TitleSmall className="mb-4">
            {intl.formatMessage({
              id: 'LoadDirectDeposit.TapInstructions',
              defaultMessage: 'Tap to copy your account info',
            })}
          </TitleSmall>

          <LineCopier
            loading={isLoading}
            label={intl.formatMessage({
              id: 'DirectDepositInstructions.AccountNumber',
              defaultMessage: 'Account number',
            })}
            value={data ? data.client_number : ''}
          />

          <BoxTw className="flex flex-col md:flex-row md:gap-6">
            <LineCopier
              loading={isLoading}
              label={intl.formatMessage({
                id: 'DirectDepositInstructions.Institution',
                defaultMessage: 'Institution',
              })}
              value={data ? data.financial_institution_number : ''}
              className="basis-1/2"
            />

            <LineCopier
              loading={isLoading}
              label={intl.formatMessage({
                id: 'DirectDepositInstructions.Transit',
                defaultMessage: 'Transit',
              })}
              value={data ? data.transit_number : ''}
              className="basis-1/2"
            />
          </BoxTw>

          <LineCopier
            loading={isLoading}
            label={intl.formatMessage({
              id: 'DirectDepositInstructions.InstitutionName',
              defaultMessage: 'Institution name',
            })}
            value={data ? data.financial_institution_name : ''}
          />
        </>
      </BoxTw>

      <ParagraphSmall className="py-6 border-b border-solid border-grey-100">
        {intl.formatMessage(
          {
            id: 'LoadDirectDeposit.NameAssurance',
            defaultMessage:
              'Make sure the name on the deposit is {name}, otherwise it might be rejected.',
          },
          { name },
        )}
      </ParagraphSmall>

      <SubtitleSmall className="py-6 text-grey-200">
        {intl.formatMessage({
          id: 'LoadDirectDeposit.LegalDisclaimer',
          defaultMessage:
            '*While Direct Deposits are typically received and processed one day before your scheduled payday, the actual timing may vary depending on when the payment is issued. Please allow up to 2 business days for complete processing.',
        })}
      </SubtitleSmall>

      {/* <Button sx={{ mb: 4 }}>Void cheque</Button> */}

      <BoxTw>
        <Accordion
          square={false}
          defaultExpanded={true}
          classes={{
            root: 'bg-grey-75 rounded [&_p]:m-0 [&_.Mui-expanded.MuiAccordionSummary-content]:my-[10px]',
          }}
        >
          <AccordionSummary
            classes={{ root: '[&_.Mui-expanded]:min-h-0' }}
            expandIcon={<ArrowDropDownIcon className="text-black" />}
          >
            <TitleSmall className="m-0">
              {intl.formatMessage({
                id: 'LoadDirectDeposit.FAQ.Title',
                defaultMessage: 'Have questions?',
              })}
            </TitleSmall>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'block' }}>
            <Accordion
              classes={{
                root: 'm-0 bg-transparent shadow-none',
                expanded: 'before:opacity-100',
              }}
            >
              <AccordionSummary
                classes={{ root: 'py-2' }}
                expandIcon={<ArrowDropDownIcon className="text-black" />}
              >
                <Paragraph className="m-0 font-bold">
                  {intl.formatMessage({
                    id: 'LoadDirectDeposit.FAQ.Paycheque',
                    defaultMessage: 'Do I need to deposit my full paycheque?',
                  })}
                </Paragraph>
              </AccordionSummary>
              <AccordionDetails classes={{ root: 'py-2' }}>
                <Paragraph className="my-0">
                  {intl.formatMessage({
                    id: 'LoadDirectDeposit.FAQ.PaychequeAnswer',
                    defaultMessage:
                      'Not at all! Your paycheque can be split into different accounts. Just let your employer know how much you’d like to deposit',
                  })}
                </Paragraph>
              </AccordionDetails>
            </Accordion>
            <Accordion
              classes={{
                root: 'm-0 bg-transparent shadow-none',
                expanded: 'before:opacity-100',
              }}
            >
              <AccordionSummary
                classes={{ root: 'py-2' }}
                expandIcon={<ArrowDropDownIcon className="text-black" />}
              >
                <Paragraph className="m-0 font-bold">
                  {intl.formatMessage({
                    id: 'LoadDirectDeposit.FAQ.Delays',
                    defaultMessage:
                      'Are there any delays in receiving my paycheque?',
                  })}
                </Paragraph>
              </AccordionSummary>
              <AccordionDetails classes={{ root: 'py-2' }}>
                <Paragraph className="my-0">
                  {intl.formatMessage({
                    id: 'LoadDirectDeposit.FAQ.DelaysAnswer',
                    defaultMessage:
                      'For government deposits (ie. from the CRA), you can typically expect to receive those within 3-5 business days from the issue date.',
                  })}
                </Paragraph>
              </AccordionDetails>
            </Accordion>
            <div className="flex justify-center border-t border-[rgba(0,0,0,0.12)] pt-8">
              <LinkTw
                external
                trackName="View more direct deposit FAQs"
                to={`https://help.koho.ca/${context.locale}/collections/132492-move-money`}
              >
                {intl.formatMessage({
                  id: 'LoadDirectDeposit.FAQ.More',
                  defaultMessage: 'View more direct deposit FAQs',
                })}
              </LinkTw>
            </div>
          </AccordionDetails>
        </Accordion>
      </BoxTw>

      <InstructionsModal open={instructionsModalOpen} onClose={closeModal} />
    </TemplateTw>
  );
};

const InstructionsModal = ({ open, onClose }) => {
  const intl = useIntl();

  const { rate, rateSkeleton } = useInterestFromTiers();

  return (
    <Modal
      absolutePositioning={false}
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <BoxTw className="px-2">
        <TitleLarge className="mt-0 mb-6">
          {intl.formatMessage({
            id: 'LoadDirectDeposit.InstructionsModal.Title',
            defaultMessage: 'Why set up Direct Deposit?',
          })}
        </TitleLarge>

        {/* TODO: Replace with asset */}
        <div className="mb-4">
          <Paragraph className="font-bold my-1">
            {intl.formatMessage({
              id: 'LoadDirectDeposit.InstructionsModal.EarlyTitle',
              defaultMessage: 'You get paid a day early*',
            })}
          </Paragraph>
          <SubtitleMedium>
            {intl.formatMessage({
              id: 'LoadDirectDeposit.InstructionsModal.EarlyDescription',
              defaultMessage:
                'We process payments as soon as we get them. How fast is your bank?',
            })}
          </SubtitleMedium>
        </div>

        {/* TODO: Replace with asset */}
        <div className="mb-4">
          <Paragraph className="font-bold my-1">
            {intl.formatMessage({
              id: 'LoadDirectDeposit.InstructionsModal.InterestTitle',
              defaultMessage: 'Earn interest on your balance',
            })}
          </Paragraph>
          <SubtitleMedium>
            {intl.formatMessage(
              {
                id: 'LoadDirectDeposit.InstructionsModal.InterestDescription',
                defaultMessage:
                  'You can earn up to <rateSkeleton>{rate}</rateSkeleton>% interest on your entire balance based on your plan.',
              },
              { rate, rateSkeleton },
            )}
          </SubtitleMedium>
        </div>

        {/* TODO: Replace with asset */}
        <div className="mb-4">
          <Paragraph className="font-bold my-1">
            {intl.formatMessage({
              id: 'LoadDirectDeposit.InstructionsModal.BillsTitle',
              defaultMessage: 'Never miss a bill',
            })}
          </Paragraph>
          <SubtitleMedium>
            {intl.formatMessage({
              id: 'LoadDirectDeposit.InstructionsModal.BillsDescription',
              defaultMessage:
                'Set up pre-authorized debits (PAD) to pay your bills on time, every time.',
            })}
          </SubtitleMedium>
        </div>
      </BoxTw>
    </Modal>
  );
};
