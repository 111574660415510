import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { track } from 'analytics/analytics';
import { useLogoutMutation } from 'apis/auth';
import appStoreFr from 'assets/images/app-download/apple-fr.svg';
import appStoreEn from 'assets/images/app-download/apple.en.png';
import downloadAppQrCode from 'assets/images/app-download/download-app-qr-code.png';
import googlePlayEn from 'assets/images/app-download/google-en.png';
import googlePlayFr from 'assets/images/app-download/google-fr.png';
import redirectUserDesktopEn from 'assets/images/redirectUsersDesktopEn.png';
import redirectUserDesktopFr from 'assets/images/redirectUsersDesktopFr.png';
import redirectUserMobileEn from 'assets/images/redirectUsersMobileEn.png';
import redirectUserMobileFr from 'assets/images/redirectUsersMobileFr.png';
import {
  LOCALE_EN,
  LOCALE_FR,
  LocaleContext,
  ParagraphMedium,
} from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { LinkTw } from 'components/LinkTw';
import { Logo } from 'components/Logo';
import { Paragraph, TitleExtraLarge } from 'components/TypographyTw';
import { desktop, mobile, theme } from 'theme';
import { Box } from 'theme-ui';
import { getMobileOperatingSystem } from 'utility/getMobileOperatingSystem';

const redirectUserDesktopImage = {
  [LOCALE_EN]: redirectUserDesktopEn,
  [LOCALE_FR]: redirectUserDesktopFr,
};

const redirectUserMobileImage = {
  [LOCALE_EN]: redirectUserMobileEn,
  [LOCALE_FR]: redirectUserMobileFr,
};

export const DownloadTheKohoAppContent = ({
  osDefault,
}: {
  osDefault?: 'ios' | 'android' | 'web';
}) => {
  const [os, setOs] = useState(osDefault ?? getMobileOperatingSystem());
  const isDesktop = useMediaQuery(desktop);
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [logout] = useLogoutMutation();
  const redirectUserDesktop = redirectUserDesktopImage[locale];
  const redirectUserMobile = redirectUserMobileImage[locale];

  useEffect(() => {
    const updateOs = () => {
      const newOs = getMobileOperatingSystem();
      if (newOs !== os) {
        setOs(newOs);
      }
    };

    updateOs();

    window.addEventListener('resize', updateOs);
    return () => window.removeEventListener('resize', updateOs);
  }, [os]);

  const logoutClicked = () => {
    track({ event: 'Logout CTA Clicked' });
    logout({ navigateToLogin: true, deleteSession: true });
  };

  let appDownloadLinks;
  if (os === 'ios') {
    appDownloadLinks = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LinkTw
          trackName="DownloadTheKohoAppContent Apple Download"
          external
          to="https://apps.apple.com/ca/app/koho-financial/id1091010942"
          data-cy="download-app-btn-appleDownload"
          aria-label={intl.formatMessage({
            id: 'DownloadTheKohoAppContent.DownloadFromApple',
            defaultMessage: 'Download KOHO from the Apple app store',
          })}
        >
          <img
            src={locale === LOCALE_EN ? appStoreEn : appStoreFr}
            alt={intl.formatMessage({
              id: 'DownloadTheKohoAppContent.DownloadFromApple',
              defaultMessage: 'Download KOHO from the Apple app store',
            })}
            style={{ width: '165px', height: '46px' }}
          />
        </LinkTw>
      </Box>
    );
  } else if (os === 'android') {
    appDownloadLinks = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LinkTw
          trackName="DownloadTheKohoAppContent Google Play Download"
          external
          to="https://play.google.com/store/apps/details?id=ca.koho"
          data-cy="download-app-btn-googleDownload"
          aria-label={intl.formatMessage({
            id: 'DownloadTheKohoAppContent.DownloadFromGoogle',
            defaultMessage: 'Download KOHO from Google Play',
          })}
        >
          <img
            src={locale === LOCALE_EN ? googlePlayEn : googlePlayFr}
            alt={intl.formatMessage({
              id: 'DownloadTheKohoAppContent.DownloadFromGoogle',
              defaultMessage: 'Download KOHO from Google Play',
            })}
            style={{ width: '165px', height: '46px' }}
          />
        </LinkTw>
      </Box>
    );
  }

  return (
    <Grid
      container
      sx={{
        mt: '69px',
        mx: '144px',
        [mobile]: { mx: 0, mt: '24px', flexDirection: 'column' },
      }}
    >
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          mb: '20px',
          ml: '6px',
          [mobile]: {
            mt: '20px',
            mb: 0,
            display: 'flex',
            ml: '24px',
          },
        }}
      >
        <Logo />
      </Grid>

      <Grid
        item
        xs={12}
        md={3}
        sx={{
          mb: '20px',
          [mobile]: {
            mb: 0,
            mt: '-28px',
          },
        }}
      >
        <ButtonTw
          onClick={logoutClicked}
          variant="clear"
          className="text-secondary-300 lg:ml-[4%] md:ml-[40%] ml-[35%]"
        >
          <strong>
            {intl.formatMessage({
              id: 'SettingsPage.Logout',
              defaultMessage: 'Log out',
            })}
          </strong>
        </ButtonTw>
      </Grid>

      <Grid item xs={12} md={5} sx={{ [mobile]: { order: 2, width: 'auto' } }}>
        <TitleExtraLarge
          className={`${
            isDesktop ? '' : 'text-[32px]'
          } mt-[45px] mb-[8px] lg:w-[328px] md:w-[auto] lg:ml-[0] md:ml-[24px] mx-[24px] text-center lg:text-left `}
        >
          {intl.formatMessage(
            {
              id: 'DownloadTheKohoAppContent.MainPage.Title',
              defaultMessage: `Download the KOHO app and <color>get all the benefits</color>`,
            },
            {
              br: () => (
                <span>
                  <br />
                </span>
              ),
              color: (args) => (
                <span style={{ color: theme.colors.primary }}>{args}</span>
              ),
            },
          )}
        </TitleExtraLarge>

        <Paragraph className="text-grey-300 lg:w-[371px] md:w-[auto] mt-[16px] lg:mx-[0] md:mx-[24px] text-center mx-[24px] md:mb-[51px] lg:text-left">
          {intl.formatMessage({
            id: 'DownloadTheKohoAppContent.MainPage.SubTitle',
            defaultMessage:
              'Easily download the KOHO app today for iOS or Android and get access to many features that help you save, spend, borrow, and build your credit.',
          })}
        </Paragraph>

        <Box
          sx={{
            mb: '8px',
            borderRadius: '16px',
          }}
        >
          {isDesktop ? (
            <>
              <ParagraphMedium>
                {intl.formatMessage({
                  id: 'DownloadTheKohoAppContent.MainPage.ScanQrCode',
                  defaultMessage: 'Scan the QR code to download the app',
                })}
              </ParagraphMedium>
              <Box sx={{ display: 'flex', marginTop: '20px' }}>
                <img
                  src={downloadAppQrCode}
                  width={186}
                  height={186}
                  alt="QR code to download the app"
                />
              </Box>
            </>
          ) : (
            <Box
              sx={{
                mt: '30px',
                mb: '40px',
              }}
            >
              {appDownloadLinks}
            </Box>
          )}
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={5}
        sx={{
          mt: '44px',
          [mobile]: {
            order: 1,
            mt: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <img
          src={isDesktop ? redirectUserDesktop : redirectUserMobile}
          alt="Desktop logo"
        />
      </Grid>
    </Grid>
  );
};
