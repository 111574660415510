import { useIntl } from 'react-intl';

import { useGetCurrentTierQuery } from 'apis/tiers';
import { Modal } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { Paragraph } from 'components/TypographyTw';
import { useToggle } from 'hooks/useToggle';

import { Steps } from '../tierChangeSteps';
import { DowngradeSteps } from './DowngradeSteps';

export type DowngradeSuccessProps = {
  downgradeDate: string;
  newTierCost: string;
  newTierName: string;
};

export const DowngradeSuccess = ({
  downgradeDate,
  newTierCost,
  newTierName,
}: DowngradeSuccessProps) => {
  const intl = useIntl();
  const { value: isModalOpen, off: closeModal } = useToggle(true);
  const { data: currentTier } = useGetCurrentTierQuery();

  const currentUsersTierPlan = currentTier?.benefit_group?.plans?.find(
    (plan) => plan.key === currentTier.frequency,
  );

  const currentTierSubscription = currentTier?.benefit_group;

  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      title={intl.formatMessage(
        {
          id: 'DowngradeSuccess.Modal.Title',
          defaultMessage: 'Your plan will change on {date}',
        },
        { date: downgradeDate },
      )}
    >
      <Paragraph className="text-grey-400 mb-6">
        {intl.formatMessage({
          id: 'DowngradeSuccess.Modal.Subtitle',
          defaultMessage: "Here's what happens next:",
        })}
      </Paragraph>
      {currentUsersTierPlan && currentTierSubscription && (
        <Steps
          selectedPlan={currentUsersTierPlan}
          selectedTier={currentTierSubscription}
        >
          {({ activeTier }) => (
            <DowngradeSteps
              frequency={activeTier.frequency}
              oldTier={activeTier}
              newTierCost={newTierCost}
              newTierName={newTierName}
            />
          )}
        </Steps>
      )}
      <ButtonTw
        className="mt-8 bg-tiers-blue-300"
        onClick={closeModal}
        trackName="tiers-downgrade-confirm"
      >
        {intl.formatMessage({
          id: 'DowngradeSuccess.Modal.GotIt',
          defaultMessage: 'Got it',
        })}
      </ButtonTw>
    </Modal>
  );
};
