import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { MoneyRequest } from 'apis/requestMoneyApi';
import { LocaleContext } from 'components';
import { BoxTw } from 'components/BoxTw';
import { Paragraph, TitleXLarge } from 'components/TypographyTw';

import { RequestMoneyReferralDetails } from './RequestMoneyReferralDetails';

export const RequestMoneyInfoDesktop = ({
  request,
}: {
  request: MoneyRequest;
}) => {
  const intl = useIntl();
  const context = useContext(LocaleContext);

  return (
    <BoxTw>
      <TitleXLarge>
        {request.amount
          ? intl.formatMessage(
              {
                id: 'RequestMoneyInfoDesktop.Title',
                defaultMessage: 'Send {requesterName} {amount}',
              },
              {
                requesterName: request.requester_given_name,
                amount: context.intlFormatMoney(request.amount).format(),
              },
            )
          : intl.formatMessage(
              {
                id: 'RequestMoneyInfoDesktop.TitleNoAmount',
                defaultMessage: 'Send {requesterName} money',
              },
              {
                requesterName: request.requester_given_name,
              },
            )}
      </TitleXLarge>
      {request.message && (
        <Paragraph className="rounded bg-grey-75 text-center p-4 mb-6">
          {intl.formatMessage(
            {
              id: 'RequestMoneyInfoDesktop.Message',
              defaultMessage: '“{message}”',
            },
            {
              message: request.message,
            },
          )}
        </Paragraph>
      )}
      <RequestMoneyReferralDetails request={request} />
    </BoxTw>
  );
};
