/** @jsxImportSource theme-ui */
import { useState } from 'react';
import { useIntl } from 'react-intl';

import InputAdornment from '@material-ui/core/InputAdornment';
import { ShowHideButtonTw } from 'passwordReset/Components/ButtonShowHideTw';
import { twMerge } from 'tailwind-merge';

import { TextInput, TextInputProps, Validators } from '../../components/forms';

export const TextInputSecretAnswer = ({
  props,
  skipValidation,
  className,
}: {
  props: TextInputProps;
  skipValidation?: boolean;
  className?: string;
}) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);

  return (
    <TextInput
      {...props}
      className={twMerge('mb-6', className)}
      label={intl.formatMessage({
        id: 'TextInputSecretAnswer.Label',
        defaultMessage: 'Secret answer',
      })}
      name="secretAnswer"
      type={show ? 'text' : 'password'}
      placeholder={
        skipValidation
          ? intl.formatMessage({
              id: 'TextInputSecretAnswer.Placeholder',
              defaultMessage: 'Hidden',
            })
          : ''
      }
      inputProps={{ maxLength: 25 }}
      InputProps={
        skipValidation
          ? undefined
          : {
              endAdornment: (
                <InputAdornment position="end">
                  <ShowHideButtonTw
                    onClick={() => setShow(!show)}
                    visible={show}
                  />
                </InputAdornment>
              ),
            }
      }
      validators={
        skipValidation
          ? []
          : [
              Validators.required,
              Validators.minLength(3, intl),
              Validators.maxLength(25, intl),
              Validators.secretAnswer,
            ]
      }
    />
  );
};
