import { useIntl } from 'react-intl';

import { useNavigate } from '@reach/router';
// import { KDSIcons } from 'assets/images/kds_icons';
import { SessionRefreshModal } from 'auth/components';
import { Card, LayoutBasic } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { Paragraph, TitleLarge } from 'components/TypographyTw';
import { HelpAndAccessibility } from 'support/components';

export const RegistrationAccountErrorPage = ({ path }: { path: string }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <LayoutBasic pageName="RegistrationAccountError" chat={false}>
      <SessionRefreshModal />
      <Card>
        {/* TODO: replace asset
        <KDSIcons.Spot.Stop />
        */}
        <TitleLarge className="mb-6">
          {intl.formatMessage({
            id: 'Registration.AccountErrorPage.Title',
            defaultMessage: 'Something went wrong...',
          })}
        </TitleLarge>
        <Paragraph className="mb-6">
          {intl.formatMessage({
            id: 'Registration.AccountErrorPage.Content',
            defaultMessage:
              'Try again in a few minutes. Your progress is saved, so all you have to do is log in, and we’ll handle the rest!',
          })}
        </Paragraph>
        <ButtonTw trackName="go-back-home" onClick={() => navigate('/login')}>
          {intl.formatMessage({
            id: 'Registration.AccountErrorPage.CTA',
            defaultMessage: 'Go back home',
          })}
        </ButtonTw>
      </Card>
      <HelpAndAccessibility />
    </LayoutBasic>
  );
};
