import { AnalyticsBrowser } from '@segment/analytics-next';
import * as Sentry from '@sentry/browser';

export const segmentAnalytics = AnalyticsBrowser.load(
  {
    writeKey: import.meta.env.VITE_SEGMENT_KEY,
    cdnURL: import.meta.env.VITE_SEGMENT_CDN_URL,
  },
  ...(import.meta.env.VITE_SEGMENT_PROXY_HOST !== ''
    ? [
        {
          integrations: {
            'Segment.io': {
              apiHost: import.meta.env.VITE_SEGMENT_PROXY_HOST,
            },
          },
        },
      ]
    : []),
);

export interface TrackEvent {
  event: string;
  userId?: string;
  properties?: {
    [key: string]: any;
  };
}

type InputEventType = 'Clicked' | 'Focused';
type InputEvent = {
  type: InputEventType;
  name: string;
  details?: { [key: string]: string };
};

export const track = (event: TrackEvent) => {
  segmentAnalytics.track(event.event, {
    page: window.location.pathname,
    ...event.properties,
  });

  if (window.gtag) {
    window.gtag('event', event.event, {
      page: window.location.pathname,
      send_to: import.meta.env.VITE_GA4_MEASUREMENT_ID,
      ...event.properties,
    });
  }

  // Experimenting with having all user behaviour events in Sentry
  Sentry.addBreadcrumb({
    category: 'User Behaviour',
    message: event.event,
    level: 'info',
  });
};

export const trackInput = (event: InputEvent) => {
  const inputEvent: TrackEvent = {
    event: event.type,
    properties: { name: event.name, ...event?.details },
  };

  track(inputEvent);
};

export const trackPage = (name: string) => {
  segmentAnalytics.page({
    name,
  });
};

export const identify = (id: string, traits: { [key: string]: any }) => {
  segmentAnalytics.identify(id, traits);
};

export const analyticsReset = () => {
  segmentAnalytics.reset();
};
