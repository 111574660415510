import { useCallback } from 'react';

import { Player } from '@lottiefiles/react-lottie-player';
import { navigate } from '@reach/router';
import { track } from 'analytics/analytics';
import {
  PersonalizedOnboardingState,
  personalizedOnboardingIntentEmojiMap,
} from 'apis/personalizedOnboardingApi';
import { Icons } from 'assets/images/kds_icons/icons';
import confettiAnimation from 'assets/lottie/small-confetti-pop-lottie.json';
import clsx from 'clsx';
import ButtonUnstyledTw from 'components/ButtonUnstyledTw';
import { Paragraph, SubtitleSmall, TitleMedium } from 'components/TypographyTw';
import { useToggle } from 'hooks/useToggle';
import { twMerge } from 'tailwind-merge';

import { CompletionModal } from './CompletionModal';
import { ProgressBar } from './ProgressBar';

export const PersonalizedOnboardingWidget = ({
  personalizedOnboardingData,
}: {
  personalizedOnboardingData: PersonalizedOnboardingState | undefined;
}) => {
  const {
    value: isCompletionModalOpen,
    on: openCompletionModal,
    off: closeCompletionModal,
  } = useToggle();

  const cardAction = useCallback(() => {
    if (!personalizedOnboardingData?.spend_card.completed) {
      track({
        event: 'Clicked',
        properties: {
          name: 'personalized-onboarding-widget',
          intent: personalizedOnboardingData?.intent,
          checklistKey: personalizedOnboardingData?.onboarding_page.header.key,
          step: personalizedOnboardingData?.step,
        },
      });
      return navigate('/onboarding-checklist');
    }

    track({
      event: 'Clicked',
      properties: {
        name: 'personalized-onboarding-completed-widget',
        intent: personalizedOnboardingData?.intent,
      },
    });
    return openCompletionModal();
  }, [
    openCompletionModal,
    personalizedOnboardingData?.intent,
    personalizedOnboardingData?.onboarding_page.header.key,
    personalizedOnboardingData?.spend_card.completed,
    personalizedOnboardingData?.step,
  ]);

  if (!personalizedOnboardingData) return null;

  const { intent, spend_card, step, total_steps } = personalizedOnboardingData;
  const isCompleted = spend_card.completed;

  return (
    <>
      <ButtonUnstyledTw
        className={clsx(
          'overflow-hidden p-4 relative legacy:rounded-lg rounded-xl',
          'legacy:shadow-[3px_3px_10px_0_rgba(0,0,0,0.1)]',
          isCompleted
            ? 'bg-gradient-to-r legacy:from-[#2CB689] legacy:to-[#52B462] from-success-200 to-success-300 light:border-none'
            : 'bg-white',
        )}
        data-cy="personalized-onboarding-widget"
        onClick={cardAction}
      >
        {isCompleted && (
          <>
            <Player
              autoplay
              keepLastFrame
              src={confettiAnimation}
              speed={0.6}
              style={{
                pointerEvents: 'none',
                position: 'absolute',
                width: 'inherit',
                left: 0,
                right: 0,
                scale: '160%',
                zIndex: 0,
                opacity: 0.5,
              }}
            />
          </>
        )}
        <div className="z-0 relative">
          <TitleMedium
            className={clsx(
              'm-0',
              isCompleted ? 'text-white' : 'text-grey-400',
            )}
          >
            {spend_card.header}
          </TitleMedium>
          <div className="flex items-center gap-1 rounded-full border border-solid border-primary-75 py-1 px-2 my-4 bg-white">
            <SubtitleSmall
              className={twMerge(
                clsx(
                  'legacy:text-success-300 text-primary-300',
                  isCompleted && 'text-success-300',
                ),
              )}
            >
              {step}/{total_steps}
            </SubtitleSmall>
            <ProgressBar
              step={step}
              totalSteps={total_steps}
              className={twMerge(
                clsx(
                  'bg-primary-200 legacy:bg-success-300',
                  isCompleted && 'bg-success-300',
                ),
              )}
            />
            <SubtitleSmall>
              {personalizedOnboardingIntentEmojiMap[intent]}
            </SubtitleSmall>
          </div>
          <div className="flex justify-between">
            <Paragraph
              className={clsx(
                'm-0',
                isCompleted ? 'text-white' : 'text-grey-300',
              )}
            >
              {spend_card.body}
            </Paragraph>
            <Icons.ArrowRight
              className={clsx(isCompleted ? 'text-white' : 'text-primary-300')}
            />
          </div>
        </div>
      </ButtonUnstyledTw>
      <CompletionModal
        intent={intent}
        closeModal={closeCompletionModal}
        openModal={openCompletionModal}
        isOpen={isCompletionModalOpen}
      />
    </>
  );
};
