import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { useGetCurrentTierQuery, useGetTiersQuery } from 'apis/tiers';
import { Template } from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { desktop, theme } from 'theme';
import { BackToTransactions } from 'tiers/components/BackToTransactions';
import { CancelAnytime } from 'tiers/components/CancelAnytime';
import { ChangePlan } from 'tiers/components/ChangePlan';
import { PlanTabs } from 'tiers/components/PlanTabs';
import { Tiers } from 'tiers/components/Tiers';
import { useSelectedTierPlan } from 'tiers/hooks/useSelectedTierPlan';
import { TiersFeatureFlags } from 'tiers/models/TiersFeatureFlags';
import { selectIsPaidTier } from 'tiers/store/selectors';

import { selectLaunchDarklyFlagsIsLoading } from 'libs/launchdarkly/store/selectors';

export const TierUpgradePage = () => {
  const intl = useIntl();
  const muiTheme = useTheme();
  const isAfterTablet = useMediaQuery(muiTheme.breakpoints.up('md'));
  const isDesktop = useMediaQuery(desktop);

  const isTiersSelectionEnabled =
    useFlags()[TiersFeatureFlags.EnableNonMemberTiersSelection];
  const ldFlagsIsLoading = useSelector(selectLaunchDarklyFlagsIsLoading);
  const isPaidTier = useSelector(selectIsPaidTier);

  const {
    data: tiers,
    isLoading: tiersIsLoading,
    isUninitialized: tiersIsUninitialized,
  } = useGetTiersQuery();

  const { isLoading: currentTierIsLoading } = useGetCurrentTierQuery();

  const { setPlan, setTier, currentTierSelected, currentPlanSelected } =
    useSelectedTierPlan({ tiers: tiers ?? [] });

  if (
    tiersIsUninitialized ||
    tiersIsLoading ||
    ldFlagsIsLoading ||
    currentTierIsLoading
  ) {
    return <Skeleton variant="rect" />;
  }

  if (isPaidTier) {
    navigate('/tiers/my-plan');
    return null;
  }

  if (!isTiersSelectionEnabled) {
    navigate('/transactions', { state: { showWelcomeModal: false } });
    return null;
  }

  if (!tiers || tiers.length === 0) {
    navigate('/transactions', { state: { showWelcomeModal: false } });
    return null;
  }

  return (
    <Template name="Tiers - Upgrade">
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          pb: isDesktop ? 80 : 48,
          alignItems: 'center',
          width: 'fit-content',
          px: 24,
          pt: isDesktop ? 0 : 80,
          mx: 'auto',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            justifyContent: 'center',
            alignItems: isAfterTablet ? 'center' : 'start',
            mb: 24,
            mx: 16,
            width: '100%',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: '32px',
              fontWeight: 900,
              textAlign: 'center',
              mb: isAfterTablet ? 32 : 24,
              mr: isAfterTablet ? 0 : 'auto',
              color: theme.colors.text,
            }}
            data-cy="tier-upgrade-header"
          >
            {intl.formatMessage({
              id: 'TiersUpgrade.header',
              defaultMessage: 'Upgrade plan',
            })}
          </Typography>
          <PlanTabs
            className="w-full lg:w-[calc(33%-8px)] xl:w-[calc(33%-16px)]"
            tab={currentPlanSelected?.key ?? 'monthly'}
            setTab={setPlan}
          />
        </Box>
        <Tiers
          boxClassName="mb-2 px-0"
          selectable
          tiers={tiers}
          plan={currentPlanSelected?.key}
          selectedTier={currentTierSelected?.key}
          handleSelectTier={setTier}
          cardTrackName="tiers-upgrade-card"
        />

        <Box
          sx={{
            maxWidth: isAfterTablet ? '352px' : 'unset',
            mx: 'auto',
            width: '100%',
          }}
        >
          {currentTierSelected ? (
            <ChangePlan
              tier={currentTierSelected}
              selectedPlan={currentPlanSelected}
              trackEventName="tiers-selection-confirm"
            />
          ) : null}
          <BackToTransactions
            dataCy="tier-upgrade-back-button"
            trackName="tiers-selection-cancel"
          >
            {intl.formatMessage({
              id: 'TiersUpgrade.Back',
              defaultMessage: 'Back',
            })}
          </BackToTransactions>
          <CancelAnytime />
        </Box>
      </Box>
    </Template>
  );
};
