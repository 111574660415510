import { useIntl } from 'react-intl';

import { Modal } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { Paragraph, TitleLarge } from 'components/TypographyTw';

export const RequestMoneyRequestCodeInfoModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const intl = useIntl();

  return (
    <Modal absolutePositioning={false} open={open} onClose={onClose}>
      <TitleLarge>
        {intl.formatMessage({
          id: 'RequestMoneyRequestCodeInfoModal.Title',
          defaultMessage: 'Request codes',
        })}
      </TitleLarge>
      <Paragraph className="my-4">
        {intl.formatMessage({
          id: 'RequestMoneyRequestCodeInfoModal.Description',
          defaultMessage:
            'If you have the KOHO app, you can find and pay requests by entering a code. Just tap the ‘Send’ button on your Spend tab, tap the ‘Enter a request code’ banner, and enter the code you see here.',
        })}
      </Paragraph>
      <ButtonTw onClick={onClose}>
        {intl.formatMessage({
          id: 'RequestMoneyRequestCodeInfoModal.Button',
          defaultMessage: 'Got it',
        })}
      </ButtonTw>
    </Modal>
  );
};
