import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { NavigateOptions, navigate } from '@reach/router';
import { useSendSMSLinkMutation } from 'apis/registration';
import { ButtonTw } from 'components/ButtonTw';
import { selectProfileTel } from 'profile/store/selectors';
import { getMobileOperatingSystem } from 'utility/getMobileOperatingSystem';

interface DownloadAppProps {
  justText?: boolean;
  redirect?: string;
  redirectOptions?: NavigateOptions<{}>;
}

const DownloadApp = ({
  justText,
  redirect,
  redirectOptions,
}: Readonly<DownloadAppProps>): JSX.Element => {
  const os = getMobileOperatingSystem();
  const intl = useIntl();
  const [sendSMSLink, sendSMSLinkData] = useSendSMSLinkMutation();
  const [isLoading, setIsLoading] = useState(false);
  const profilePhoneNumber = useSelector(selectProfileTel);

  useEffect(() => {
    let timer;
    if (redirect && sendSMSLinkData.isSuccess) {
      timer = setTimeout(() => navigate(redirect, redirectOptions), 1000);
    }

    return () => timer && clearTimeout(timer);
  }, [redirect, redirectOptions, sendSMSLinkData.isSuccess]);

  const sendSMS = () => {
    if (profilePhoneNumber) {
      sendSMSLink({
        tel_no: profilePhoneNumber,
        type: 'onboarding',
      });
    } else {
      setIsLoading(true);
    }
  };

  if ((os === 'ios' || os === 'android') && !justText) {
    const url =
      os === 'ios'
        ? 'https://go.onelink.me/app/ad584467'
        : 'https://go.onelink.me/app/e76f5835';
    return (
      <a href={url} target="_blank" rel="noreferrer" className="no-underline">
        <ButtonTw trackName="App Download Button">
          {intl.formatMessage({
            id: 'DownloadAppWidget.ButtonApp',
            defaultMessage: 'Download the KOHO app',
          })}
        </ButtonTw>
      </a>
    );
  }

  return (
    <ButtonTw
      data-cy="text-me-a-download-link"
      trackName="Send SMS Link Button"
      loading={sendSMSLinkData.isLoading || isLoading}
      disabled={sendSMSLinkData.isSuccess}
      onClick={sendSMS}
    >
      {sendSMSLinkData.isSuccess
        ? intl.formatMessage({
            id: 'DownloadAppWidget.ButtonWebSuccess',
            defaultMessage: 'Link sent!',
          })
        : intl.formatMessage({
            id: 'DownloadAppWidget.ButtonWeb',
            defaultMessage: 'Text me a download link',
          })}
    </ButtonTw>
  );
};

export default DownloadApp;
