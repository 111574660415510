import { useIntl } from 'react-intl';

import { Dialog, DialogContent } from '@material-ui/core';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import { Paragraph, TitleSmall } from 'components/TypographyTw';

export interface ConfirmDeclinePromptProps {
  isOpen: boolean;
  handleNoAndClose: () => void;
  handleYesAndClose: () => void;
}

export const ConfirmDeclinePrompt: React.FC<ConfirmDeclinePromptProps> = ({
  isOpen,
  handleNoAndClose,
  handleYesAndClose,
}) => {
  const intl = useIntl();

  return (
    <Dialog
      open={isOpen}
      onClose={handleNoAndClose}
      aria-labelledby="iet-confirm-prompt-title"
      aria-describedby="iet-confirm-prompt-description"
      data-cy="iet-confirm-prompt"
    >
      <DialogContent>
        <div id="iet-alert-dialog-title">
          <TitleSmall className="mb-4 mt-0">
            {intl.formatMessage({
              id: 'InboundETransferPage.ConfirmDeclinePrompt.Heading',
              defaultMessage: 'Decline Transfer',
            })}
          </TitleSmall>
        </div>
        <Paragraph
          className="mt-0"
          id="iet-confirm-prompt-description"
          data-cy="iet-confirm-prompt-message"
        >
          {intl.formatMessage({
            id: 'InboundETransferPage.ConfirmDeclinePrompt.Message',
            defaultMessage: 'Are you sure you want to decline transfer?',
          })}
        </Paragraph>
        <BoxTw className="grid grid-cols-2 gap-x-2">
          <ButtonTw
            onClick={handleNoAndClose}
            className="mb-2"
            data-cy="iet-confirm-prompt-cta-reject"
            variant={'secondary'}
          >
            {intl.formatMessage({
              id: 'InboundETransferPage.ConfirmDeclinePrompt.Reject',
              defaultMessage: `No`,
            })}
          </ButtonTw>
          <ButtonTw
            onClick={handleYesAndClose}
            className="mb-2"
            data-cy="iet-confirm-prompt-cta-confirm"
          >
            {intl.formatMessage({
              id: 'InboundETransferPage.ConfirmDeclinePrompt.Confirm',
              defaultMessage: `Yes, Decline`,
            })}
          </ButtonTw>
        </BoxTw>
      </DialogContent>
    </Dialog>
  );
};
