import { CSSProperties, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@material-ui/core';
import { useGetCurrentTierQuery } from 'apis/tiers';
import { KDSIcons } from 'assets/images/kds_icons';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { useTrackPageViewedOnceDeprecated } from 'utility/analyticsHooks';

import SitewideAlert from '../components/SitewideAlert';
import { theme } from '../theme';
import { LinkTw } from './LinkTw';
import { Paragraph } from './TypographyTw';
import { DesktopNav, MobileNav, PathItem, usePathList } from './navs';

export type Variants = 'center' | 'default';

/** @deprecated - use TemplateTw from layout/templateTw */
export const Template = ({
  bgColor,
  children,
  className,
  hideSettingsLink = false,
  name,
  variant = 'default',
  widths = ['90%', '90%', '50%', '50%'],
}: {
  bgColor?: string;
  children: ReactNode;
  className?: string;
  hideSettingsLink?: boolean;
  name: string;
  widths?: [string, string, string, string];
  variant?: Variants;
}) => {
  useGetCurrentTierQuery();

  useTrackPageViewedOnceDeprecated({ name });

  const pathList = usePathList();
  const paths = pathList.map((page) => {
    if (page.path === undefined && page.onClick === undefined) {
      throw new Error('fix your paths');
    }

    return <PathItem page={page} key={page.name}></PathItem>;
  });

  return (
    <div>
      <SitewideAlert />
      <div className="xl:hidden">
        <MobileNav paths={paths} />
      </div>

      <div className="hidden xl:block fixed left-0 w-[calc(23%+8px)] h-full legacy:bg-grey-75 bg-white light:border-r-8 light:border-grey-50">
        <DesktopNav paths={paths} />
      </div>

      <main
        className={twMerge(
          'xl:w-[77%] h-full legacy:absolute legacy:right-0 bg-[var(--bgColor)] xl:float-end',
          className,
        )}
        style={{ '--bgColor': bgColor ?? theme.colors.white } as CSSProperties}
      >
        <SettingsLink background={!!bgColor} hide={hideSettingsLink} />

        <ContentWrap center={variant === 'center'} widths={widths}>
          {children}
        </ContentWrap>
      </main>
    </div>
  );
};

const SettingsLink = ({ background, hide }) => {
  const intl = useIntl();

  if (hide) return null;

  return (
    <Box pr={4} pt={3} display="none" className="xl:flex xl:justify-end">
      <LinkTw
        to="/settings"
        trackName="settings"
        className="no-underline hover:no-underline flex items-center"
      >
        <KDSIcons.Icons.Profile
          className={twMerge(
            clsx('mr-2 text-grey-400', background && 'text-white'),
          )}
        />
        <Paragraph
          className={twMerge(
            clsx('text-grey-400 my-4', background && 'text-white'),
          )}
        >
          {intl.formatMessage({
            id: 'TemplateSideMenu.Settings',
            defaultMessage: 'Settings',
          })}
        </Paragraph>
      </LinkTw>
    </Box>
  );
};

const ContentWrap = ({ children, center, widths }) =>
  center ? (
    <Box display="flex" alignItems="center" justifyContent="center">
      <div
        className="flex flex-col px-6 py-10 h-full w-[var(--width-default)] md:w-[var(--width-md)] lg:w-[var(--width-lg)] xl:w-[var(--width-xl)]"
        style={
          {
            '--width-default': widths[0],
            '--width-md': widths[1],
            '--width-lg': widths[2],
            '--width-xl': widths[3],
          } as CSSProperties
        }
      >
        {children}
      </div>
    </Box>
  ) : (
    <Box>{children}</Box>
  );
