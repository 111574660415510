import { useIntl } from 'react-intl';

import { BoxProps } from '@material-ui/core';
import { useNavigate } from '@reach/router';
import { ButtonLinkExternal } from 'components';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import {
  Paragraph,
  ParagraphSmall,
  TitleMedium,
} from 'components/TypographyTw';
import { getMobileOperatingSystem } from 'utility/getMobileOperatingSystem';

export const RequestMoneyDownloadApp = ({ className }: BoxProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const os = getMobileOperatingSystem();

  return (
    <BoxTw className={className}>
      <TitleMedium className="my-0">
        {intl.formatMessage({
          id: 'RequestMoneyDownloadApp.Title',
          defaultMessage: 'Join 1 million+ Canadians using KOHO',
        })}
      </TitleMedium>
      <Paragraph className="my-0">
        {intl.formatMessage(
          {
            id: 'RequestMoneyDownloadApp.Description',
            defaultMessage:
              '<boldText>Not a KOHO member?</boldText> Settle-up on bills, earn cash back, build credit, and more when you sign-up.',
          },
          {
            boldText: (text: string) => boldText(text),
          },
        )}
      </Paragraph>
      {os === 'unknown' ? (
        <ButtonTw
          variant="coral"
          onClick={() => navigate(`/registration`)}
          className="mb-4"
          trackName="request-money-sign-up"
        >
          {intl.formatMessage({
            id: 'RequestMoneyDownloadApp.SignupButton',
            defaultMessage: 'Sign-up for KOHO',
          })}
        </ButtonTw>
      ) : (
        <ButtonLinkExternal
          variant="coral"
          className="mb-4"
          href={
            os === 'ios'
              ? `https://go.onelink.me/app/ad584467?af_channel=request_money_referral`
              : `https://go.onelink.me/app/e76f5835?af_channel=request_money_referral`
          }
          trackName="request-money-download-koho"
        >
          {intl.formatMessage({
            id: 'RequestMoneyDownloadApp.DownloadButton',
            defaultMessage: 'Download KOHO',
          })}
        </ButtonLinkExternal>
      )}

      <ParagraphSmall className="text-center">
        {intl.formatMessage({
          id: 'RequestMoneyDownloadApp.Footer',
          defaultMessage: '⏰ It only takes a few minutes',
        })}
      </ParagraphSmall>
    </BoxTw>
  );
};

const boldText = (text: string) => <strong>{text}</strong>;
