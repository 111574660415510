import { useEffect } from 'react';

import { useMediaQuery } from '@material-ui/core';
import { muiTheme } from 'App';
import { track } from 'analytics/analytics';
import {
  PersonalizedOnboardingIntent,
  personalizedOnboardingIntentEmojiMap,
} from 'apis/personalizedOnboardingApi';
// import { KDSIcons } from 'assets/images/kds_icons';
import confetti from 'canvas-confetti';
import { StatelessDrawerModal } from 'components/DrawerModal';
import { Paragraph, TitleLarge } from 'components/TypographyTw';
import { useToggle } from 'hooks/useToggle';
import { useCompletionModalContent } from 'personalizedOnboarding/utils/useCompletionModalContent';

const triggerConfetti = (emoji: string, originY: number) => {
  const scalar = 4;
  const emojiShape = confetti.shapeFromText({ text: emoji, scalar });
  const shoot = () =>
    confetti({
      shapes: [emojiShape],
      scalar,
      origin: { y: originY },
      particleCount: 12,
      spread: 300,
      ticks: 280,
      gravity: 2,
      decay: 0.98,
      startVelocity: 8,
      // the types are missing this option, even though it is valid
      // @ts-ignore
      flat: true,
      zIndex: 2000,
    });

  setTimeout(() => {
    shoot();
  }, 400);
  setTimeout(() => {
    shoot();
  }, 550);
};

export const CompletionModal = ({
  intent,
  isOpen,
  openModal,
  closeModal,
}: {
  closeModal: () => void;
  intent: PersonalizedOnboardingIntent;
  isOpen: boolean;
  openModal: () => void;
}) => {
  const isAfterTablet = useMediaQuery(muiTheme.breakpoints.up('md'));
  const {
    value: isLearnMoreOpen,
    on: openLearnMore,
    off: closeLearnMore,
  } = useToggle();
  const content = useCompletionModalContent({
    intent,
    closeModal,
    openLearnMore,
  });
  const confettiIcon = personalizedOnboardingIntentEmojiMap[intent];

  useEffect(() => {
    if (isOpen) triggerConfetti(confettiIcon, isAfterTablet ? 0.2 : 0.4);
    if (!isOpen) confetti.reset();
  }, [confettiIcon, isAfterTablet, isOpen]);

  return (
    <>
      <StatelessDrawerModal
        isOpen={isOpen}
        openModal={openModal}
        closeModal={() => {
          track({
            event: 'Clicked',
            properties: {
              name: 'personalized-onboarding-close-completed',
              intent,
            },
          });
          content.handleClose();
        }}
        drawerClasses="legacy:bg-tiers-blue-300 light:bg-primary-300 pb-0 [&_button>svg]:text-white"
        content={() => (
          <>
            <div className="flex mx-14 mb-6 justify-center h-min-[200px] light:mt-8">
              <img
                src={content.image}
                className="rounded-2xl w-auto h-max-[250px] object-contain w-max-full"
                alt=""
                role="presentation"
              />
            </div>
            <div className="flex flex-col items-center text-center p-10 pb-12 -mx-6 mb-0 sm:-mb-5 bg-white">
              <TitleLarge>{content.title}</TitleLarge>
              <Paragraph>{content.subtitle}</Paragraph>
              {content.action}
            </div>
          </>
        )}
      />
      <StatelessDrawerModal
        isOpen={isLearnMoreOpen}
        openModal={openLearnMore}
        closeModal={closeLearnMore}
        modalProps={{
          className: '[&_div:nth-of-type(2)]:my-0',
          // TODO: replace asset
          // icon: <KDSIcons.Spot.Tip width={64} height={64} />,
        }}
        title={content.learnMore?.title}
        content={() => (
          <div className="mt-2 text-grey-400">{content.learnMore?.content}</div>
        )}
      />
    </>
  );
};
