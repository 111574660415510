import { FocusEvent, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsFeatureFlags } from 'analytics/AnalyticsFeatureFlags';
import { useNewLoginMutation } from 'apis/auth';
import { useValidateMFAMutation } from 'apis/mfa';
import {
  OtpErrorCodes,
  VerifyOtpErrorResponse,
  useDispatchOtpMutation,
  useVerifyOtpMutation,
} from 'apis/smsOtp';
import { selectTheme } from 'appState/appState.slice';
import { ReactComponent as CheckMarkSuccess } from 'assets/images/checkmark-success.svg';
import success from 'assets/images/sms-verify.png';
import { selectAuthState } from 'auth/store/selectors';
import { deleteOtpTimeout } from 'auth/store/utils';
import { ButtonTw } from 'components/ButtonTw';
import CircularProgress from 'components/CircularProgress';
import { ErrorParagraph, Paragraph, TitleLarge } from 'components/TypographyTw';
import { KhInputTextTw } from 'components/inputs/KhInputTextTw';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  selectLoginOtpRememberDevice,
  selectLoginTelLast4Digits,
} from 'login/store/selectors';
import { loginActions } from 'login/store/slice';
import { selectSmsOtp } from 'smsOtp/store/selectors';
import { smsOtpActions } from 'smsOtp/store/slice';
import { useGetErrorMessage } from 'utility/useGetAuthErrorMessage';

const OTP_VALID_LENGTH = 6;

export const LoginSmsOtpForm = ({ fallback }: { fallback: boolean }) => {
  const getErrorMessage = useGetErrorMessage();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [isInit, setIsInit] = useState(true);
  const [clickedResend, setClickedResend] = useState<boolean>(false);
  const [dispatchOtpErrorMsg, setDispatchOtpErrorMsg] = useState('');
  const [verifyOtpErrorMsg, setVerifyOtpErrorMsg] = useState('');

  const smsOtp = useSelector(selectSmsOtp);
  const last4Digits = useSelector(selectLoginTelLast4Digits);

  const rememberDevice = useSelector(selectLoginOtpRememberDevice);
  const authState = useSelector(selectAuthState);

  const themeName = useSelector(selectTheme);
  const isLegacyTheme = themeName === 'legacy';

  const [dispatchOtp, dispatchOtpData] = useDispatchOtpMutation();
  const dispatchRequestAction = async () => {
    try {
      await dispatchOtp({ clickedResend }).unwrap();
    } catch (error) {
      const errMsg = getDispatchErrorMsgByStatusCode(clickedResend);
      setDispatchOtpErrorMsg(intl.formatMessage(errMsg));
    }
  };

  const [newLogin, newLoginData] = useNewLoginMutation();

  const [verifyOtp, verifyOtpData] = useVerifyOtpMutation();
  const verifyOtpRequest = async (code: string) => {
    try {
      await verifyOtp({
        verification_code: code,
        remember_me: rememberDevice,
      }).unwrap();
      dispatch(loginActions.smsOtpSuccess(removeSegmentProfileProperties));
    } catch (err) {
      const errorRes = err as VerifyOtpErrorResponse<OtpErrorCodes>;
      const errorCode = errorRes?.data?.errors?.[0]?.code;
      const message = getVerifyErrorMsgByStatusCode(errorCode);
      setVerifyOtpErrorMsg(intl.formatMessage(message));
    }
  };

  const [validateMFA, validateMFAData] = useValidateMFAMutation();
  const validateMFARequest = (code: string) => {
    validateMFA({
      mfa_token: code,
      mfa_option: 'sms',
    }).unwrap();
  };

  const removeSegmentProfileProperties =
    useFlags()[AnalyticsFeatureFlags.RemoveSegmentProfileProperties];

  if (isInit && !authState.isNewLogin) {
    deleteOtpTimeout();
    dispatchRequestAction();
    setIsInit(false);
  }

  const handleResend = () => {
    if (authState.isNewLogin) {
      newLogin({
        email: authState.newLoginInfo.email,
        password: authState.newLoginInfo.pass,
        mfa_option: 'sms',
      });

      return;
    }
    if (!clickedResend) setClickedResend(true);
    dispatchRequestAction();
  };

  const onChange = (event: FocusEvent<HTMLInputElement>) => {
    const numberRegex = /^[0-9\b]+$/;
    const code = event.target.value;
    if (event.target.value === '' || numberRegex.test(event.target.value)) {
      const val = code.length ? code.substring(0, 6) : '';
      dispatch(smsOtpActions.set(val));

      if (val.length === OTP_VALID_LENGTH) {
        // TODO: add debounce
        authState.isNewLogin ? validateMFARequest(val) : verifyOtpRequest(val);
      }
    }
  };

  useEffect(() => {
    if (fallback && authState.isNewLogin) {
      // We need to call newLogin again to force the sms verification
      newLogin({
        email: authState.newLoginInfo.email,
        password: authState.newLoginInfo.pass,
        mfa_option: 'sms',
        skipFingerprint: true,
      });
    }
  }, [
    fallback,
    authState.isNewLogin,
    authState.newLoginInfo.email,
    authState.newLoginInfo.pass,
    newLogin,
  ]);

  const form = (
    <>
      <form
        autoComplete="off"
        className="mb-4"
        noValidate
        onSubmit={(e) => e.preventDefault()}
      >
        <KhInputTextTw
          label={intl.formatMessage({
            id: 'Global.Label.SmsCode',
            defaultMessage: 'SMS Code',
          })}
          name="otp"
          id="sms-otp-form"
          required
          autoFocus
          onChange={onChange}
          value={smsOtp}
          className="mb-4"
          error={verifyOtpData.isError || newLoginData.isError}
          disabled={verifyOtpData.isLoading || newLoginData.isLoading}
          data-cy="sms-code-input"
        />
        {verifyOtpData.isError ? (
          <ErrorParagraph
            data-cy="sms-verify-error-msg"
            className="text-center"
          >
            {verifyOtpErrorMsg}
          </ErrorParagraph>
        ) : null}
      </form>
      <div className="flex flex-col items-center">
        {!dispatchOtpData.isLoading && !validateMFAData.isLoading ? (
          <ButtonTw
            variant="tertiary"
            onClick={handleResend}
            data-cy="sms-dispatch-resend-btn"
          >
            {intl.formatMessage({
              id: 'SmsOtpForm.ResendButton.V3',
              defaultMessage: 'Resend me the code',
            })}
          </ButtonTw>
        ) : (
          <CircularProgress />
        )}
        {dispatchOtpData.isError && (
          <ErrorParagraph data-cy="sms-dispatch-error-msg">
            {dispatchOtpErrorMsg}
          </ErrorParagraph>
        )}
        {validateMFAData.isError && (
          <ErrorParagraph data-cy="sms-verify-error-msg">
            {getErrorMessage(
              (validateMFAData.error as { status: number }).status,
            )}
          </ErrorParagraph>
        )}
        {clickedResend && dispatchOtpData.isSuccess && (
          <CheckMarkSuccess height={40} width={40} />
        )}
      </div>
    </>
  );

  return (
    <>
      <TitleLarge>
        {intl.formatMessage({
          id: 'SmsOtpForm.Variant.Title.V3',
          defaultMessage: 'Enter your security code',
        })}
      </TitleLarge>
      <Paragraph data-cy="sms-code-number-paragraph">
        {intl.formatMessage(
          {
            id: 'SmsOtpForm.Variant.Content.V3',
            defaultMessage: `We've sent a 6-digit code to your phone number ending in {numbers}. Please enter it below. Be sure not to share this info with anyone!`,
          },
          {
            numbers: authState.isNewLogin
              ? authState.mfaPhoneDigits
              : last4Digits,
          },
        )}
      </Paragraph>
      {form}
      {isLegacyTheme && (
        <img
          src={success}
          alt={`${intl.formatMessage({
            id: 'SmsOtpForm.ImageAltTag.V3',
            defaultMessage: 'Sms success',
          })}`}
          className="w-[191px] md:w-[255px] mt-2 md:mt-6 pt-8 mx-auto"
        />
      )}
    </>
  );
};

const getDispatchErrorMsgByStatusCode = (clickedResend: boolean) => {
  let msg = otpDispatchErrorMessages.default;
  if (clickedResend) {
    msg = otpDispatchErrorMessages.resend;
  }

  return msg;
};

const getVerifyErrorMsgByStatusCode = (errorCode?: number) => {
  let msg = otpVerifyErrorMessages.default;

  if (
    errorCode === OtpErrorCodes.VerifyBadRequest ||
    errorCode === OtpErrorCodes.TokenInvalid
  ) {
    msg = otpVerifyErrorMessages.invalid;
  }

  if (errorCode === OtpErrorCodes.TooManyRequests) {
    msg = otpVerifyErrorMessages.tooMany;
  }

  return msg;
};

const otpVerifyErrorMessages = defineMessages({
  default: {
    id: 'OtpSms.ErrorMessage.Default',
    defaultMessage: 'Something was wrong, please try again.',
  },
  invalid: {
    id: 'OtpSms.ErrorMessage.InvalidCode',
    defaultMessage: 'Invalid verification code.',
  },
  tooMany: {
    id: 'OtpSms.ErrorMessage.TooManyRequests',
    defaultMessage: 'Too many requests.',
  },
});

const otpDispatchErrorMessages = defineMessages({
  default: {
    id: 'SmsOtp.Dispatch.Failed',
    defaultMessage: 'There was a problem sending the code, please try again.',
  },
  resend: {
    id: 'SmsOtp.Dispatch.Resend.Failed',
    defaultMessage:
      'There was a problem resending the code, please try again later!',
  },
});
