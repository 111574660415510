import { EmptyStates } from './empty-states';
import { Features } from './features';
import { Icons } from './icons';
import { Navigation } from './navigation';
import { Plan } from './plan';
import { Premium } from './premium';
import { Spot } from './spot';

export const KDSIcons = {
  EmptyStates,
  Features,
  Icons,
  Navigation,
  Premium,
  Spot,
  Plan,
};
