import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  NumberSmall,
  Paragraph,
  ParagraphSmall,
  TitleMedium,
} from 'components/TypographyTw';
import { useIcons } from 'hooks/useIcon';
import { twMerge } from 'tailwind-merge';

import { LocaleContext } from '../../components/I18NContextProvider';
import { Money } from '../../models/Money';
import { Transaction } from '../models';
import {
  selectTransactions,
  selectTransactionsLoading,
} from '../store/selectors';
import { transactionsActions } from '../store/slice';

export const TransactionsList = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const transactions = useSelector(selectTransactions);
  const loading = useSelector(selectTransactionsLoading);
  const context = useContext(LocaleContext);
  const {
    EmptyStates: { Activity },
  } = useIcons();

  useEffect(() => {
    dispatch(transactionsActions.getTransactionsRequest());
  }, [dispatch, context.apiLanguageHeader]);

  if (loading) {
    return (
      <Box py={2}>
        <Skeleton variant="rect" height={40} className="mb-4" />
        <Skeleton variant="rect" height={'60vh'} />
      </Box>
    );
  }

  if (!transactions || transactions.length === 0) {
    return (
      <div className="flex flex-col">
        <div
          className={twMerge(
            'flex bg-grey-50 items-center justify-center rounded-xl flex-col text-center p-8 text-grey-400',
            'legacy:bg-grey-75 legacy:rounded-[5px] legacy:p-6',
          )}
        >
          <Activity className="legacy:mb-0 mb-5" />
          <Paragraph>
            {intl.formatMessage({
              id: 'TransactionsList.NoHistory',
              defaultMessage:
                'You currently have no transaction history. Make a purchase to see your transactions here.',
            })}
          </Paragraph>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col p-0 md:px-6 md:pb-6">
      <TitleMedium>
        {intl.formatMessage({
          id: 'TransactionsList.Title',
          defaultMessage: 'Latest activity',
        })}
      </TitleMedium>

      {transactions.map((transaction: Transaction, index) => (
        <div
          className="flex items-center justify-between border-b border-grey-200 py-2"
          key={`transaction-${index}`}
        >
          <div className="flex items-center justify-start">
            <img
              src={transaction.merchant.logo || transaction.category.icon_url}
              alt={`${transaction.merchant.name || transaction.category.name}`}
              className="rounded-[20%] min-w-[45px] w-[45px]"
            />
            <div className="ml-4 md:ml-6 flex flex-col">
              <Paragraph className="m-0 legacy:font-bold font-medium">
                {transaction.details}
              </Paragraph>
              <ParagraphSmall>
                {intl.formatDate(new Date(transaction.when), {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
                &nbsp;•&nbsp;
                {new Intl.DateTimeFormat(context.locale, {
                  hour: 'numeric',
                  minute: 'numeric',
                }).format(new Date(transaction.when))}
                &nbsp;•&nbsp;
                {transaction.category.name}
              </ParagraphSmall>
            </div>
          </div>
          <NumberSmall isCredit={new Money(transaction.amount).gt(0)}>
            {context.intlFormatMoney(transaction.amount).format('deficit')}
          </NumberSmall>
        </div>
      ))}
    </div>
  );
};
