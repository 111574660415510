import { useIntl } from 'react-intl';

import { useNavigate } from '@reach/router';
import { MoneyRequest } from 'apis/requestMoneyApi';
import { BoxTw } from 'components/BoxTw';
import { ButtonLinkExternal, ButtonTw } from 'components/ButtonTw';
import { CopyButton } from 'components/CopyButton';
import {
  Paragraph,
  ParagraphSmall,
  TitleMedium,
} from 'components/TypographyTw';
import { getMobileOperatingSystem } from 'utility/getMobileOperatingSystem';

export const RequestMoneyReferralDetails = ({
  request,
}: {
  request: MoneyRequest;
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const os = getMobileOperatingSystem();

  return (
    <BoxTw>
      <TitleMedium>
        {intl.formatMessage(
          {
            id: 'RequestMoneyReferralDetails.Title',
            defaultMessage:
              'Join {requesterName} and 1 million+ other Canadians',
          },
          {
            requesterName: request.requester_given_name,
          },
        )}
      </TitleMedium>
      <Paragraph className="mb-4">
        {intl.formatMessage(
          {
            id: 'RequestMoneyReferralDetails.Description',
            defaultMessage:
              'Not a KOHO member? Settle-up, and <boldText>get a $20 bonus</boldText> when you download the app, sign-up, and make your first purchase.',
          },
          {
            boldText: (text: string) => boldText(text),
          },
        )}
      </Paragraph>
      <BoxTw className="flex justify-between border-b border-grey-200 mb-4 lg:mb-6">
        <Paragraph className="font-bold">
          {intl.formatMessage({
            id: 'RequestMoneyReferralDetails.ReferralCode',
            defaultMessage: 'Referral code',
          })}
          :
        </Paragraph>
        <BoxTw className="flex">
          <Paragraph>{request.referral_code}</Paragraph>
          <CopyButton
            value={request.referral_code}
            trackingLabel="Referral code"
            className="ml-2 px-4"
          />
        </BoxTw>
      </BoxTw>

      {os === 'unknown' ? (
        <ButtonTw
          variant="coral"
          onClick={() =>
            navigate(`/registration#referral=${request.referral_code}`)
          }
          className="mb-4"
          trackName="request-money-sign-up"
        >
          {intl.formatMessage({
            id: 'RequestMoneyReferralDetails.SignupButton',
            defaultMessage: 'Sign-up for KOHO',
          })}
        </ButtonTw>
      ) : (
        <ButtonLinkExternal
          variant="coral"
          className="mb-4"
          href={
            os === 'ios'
              ? `https://go.onelink.me/app/ad584467?af_sub1=${request.referral_code}&af_channel=request_money_referral`
              : `https://go.onelink.me/app/e76f5835?af_sub1=${request.referral_code}&af_channel=request_money_referral`
          }
          trackName="request-money-download-koho"
        >
          {intl.formatMessage({
            id: 'RequestMoneyReferralDetails.DownloadButton',
            defaultMessage: 'Download KOHO',
          })}
        </ButtonLinkExternal>
      )}

      <ParagraphSmall className="text-center">
        {intl.formatMessage({
          id: 'RequestMoneyReferralDetails.Footer',
          defaultMessage: '⏰ It only takes a few minutes',
        })}
      </ParagraphSmall>
    </BoxTw>
  );
};

const boldText = (text: string) => <strong>{text}</strong>;
