import { CSSProperties, ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';
import { useGetCurrentTierQuery } from 'apis/tiers';
import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';
import { useBalanceModal } from 'balance/components/BalanceModal';
import clsx from 'clsx';
import { LinkTw } from 'components/LinkTw';
import SitewideAlert from 'components/SitewideAlert';
import { Paragraph } from 'components/TypographyTw';
import { lightTheme } from 'styles/tw-themes/light';
import { twMerge } from 'tailwind-merge';
import { TransactionsSidebar } from 'transactions/components';
import { useTrackPageViewedOnceDeprecated } from 'utility/analyticsHooks';

import {
  DesktopNav,
  MobileNav,
  PathItem,
  usePathList,
} from '../components/navs';

export type Variants = 'center' | 'default';

export const TemplateTw = ({
  bgColor,
  children,
  className,
  hideSettingsLink = false,
  name,
  variant = 'default',
  wrapperClassName,
  widths = ['90%', '90%', '50%', '50%'],
}: {
  bgColor?: string;
  children: ReactNode;
  className?: string;
  hideSettingsLink?: boolean;
  name: string;
  widths?: [string, string, string, string];
  variant?: Variants;
  wrapperClassName?: string;
}) => {
  useGetCurrentTierQuery();
  const themeName = useSelector(selectTheme);

  useTrackPageViewedOnceDeprecated({ name });

  const pathList = usePathList();
  const paths = pathList.map((page) => {
    if (page.path === undefined && page.onClick === undefined) {
      throw new Error('fix your paths');
    }

    return <PathItem page={page} key={page.name}></PathItem>;
  });

  return (
    <div>
      <SitewideAlert />
      <div className="xl:hidden">
        <MobileNav paths={paths} />
      </div>

      <div className="hidden xl:block fixed left-0 legacy:w-[20%] w-[23%] h-full legacy:bg-grey-75 bg-white">
        <DesktopNav paths={paths} />
      </div>

      <main
        className={twMerge(
          'w-full legacy:xl:w-[80%] xl:w-[77%] h-full light:min-h-[100vh] legacy:absolute legacy:right-0 bg-[var(--bgColor)] xl:float-end',
          className,
        )}
        style={
          {
            '--bgColor':
              bgColor ?? themeName === 'light'
                ? lightTheme.grey[50]
                : lightTheme.white,
          } as CSSProperties
        }
      >
        <SettingsLink background={!!bgColor} hide={hideSettingsLink} />

        <ContentWrap
          center={variant === 'center'}
          widths={widths}
          className={wrapperClassName}
        >
          {children}
        </ContentWrap>
      </main>
    </div>
  );
};

const SettingsLink = ({ background, hide }) => {
  const intl = useIntl();

  if (hide) return null;

  return (
    <div className="pr-8 pt-6 hidden xl:flex xl:justify-end">
      <LinkTw
        to="/settings"
        trackName="settings"
        className="no-underline hover:no-underline flex items-center"
      >
        <KDSIcons.Icons.Profile
          className={twMerge(
            clsx('mr-2 text-grey-400', background && 'text-white'),
          )}
        />
        <Paragraph
          className={twMerge(
            clsx('text-grey-400 my-4', background && 'text-white'),
          )}
        >
          {intl.formatMessage({
            id: 'TemplateSideMenu.Settings',
            defaultMessage: 'Settings',
          })}
        </Paragraph>
      </LinkTw>
    </div>
  );
};

const ContentWrap = ({ children, center, className, widths }) =>
  center ? (
    <div className="flex items-center justify-center">
      <div
        className={twMerge(
          'flex flex-col px-6 py-10 h-full w-[var(--width-default)] md:w-[var(--width-md)] lg:w-[var(--width-lg)] xl:w-[var(--width-xl)]',
          className,
        )}
        style={
          {
            '--width-default': widths[0],
            '--width-md': widths[1],
            '--width-lg': widths[2],
            '--width-xl': widths[3],
          } as CSSProperties
        }
      >
        {children}
      </div>
    </div>
  ) : (
    <div>{children}</div>
  );

export const WrapperWithSidebar = ({ children }: { children: ReactNode }) => {
  const { showBalanceModal } = useBalanceModal();

  const showBalance = useCallback(
    () => showBalanceModal(true),
    [showBalanceModal],
  );
  return (
    <Grid
      className="legacy:p-6 legacy:pt-0 legacy:xl:p-4 legacy:xl:pt-2 light:xl:mt-2"
      container
      direction="row-reverse"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid item xs={12} lg={4} className="legacy:xl:pl-6">
        <div className="light:xl:mr-8 light:max-xl:mx-8">
          <TransactionsSidebar showBalance={showBalance} />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        lg={8}
        className="legacy:xl:pr-6 xl:pr-8 xl:pl-0 light:px-8"
      >
        {children}
      </Grid>
    </Grid>
  );
};
