import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Chip } from '@material-ui/core';
import { navigate } from '@reach/router';
import { useGetTiersQuery } from 'apis/tiers';
import { Widget } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import DownloadApp from 'features/modals/DownloadApp';
import { useIcons } from 'hooks/useIcon';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectProfile, selectProfileVerified } from 'profile/store/selectors';
import { legacyTheme } from 'styles/tw-themes/legacy';
import { twMerge } from 'tailwind-merge';
import { useUpgradeMetadata } from 'tiers/hooks/useUpgradeMetadata';
import { TiersFeatureFlags } from 'tiers/models/TiersFeatureFlags';
import { selectIsPaidTier } from 'tiers/store/selectors';
import { getTierManagementUrl, isTrialEligible } from 'tiers/utils/helpers';

const FreeTrialChip = () => {
  const intl = useIntl();
  const Icons = useIcons();

  return (
    <div className="flex mb-4">
      <Chip
        className={twMerge(
          'overflow-visible px-[10px] py-2 bg-primary-50 text-primary-300',
          'legacy:bg-tiers-blue-50 legacy:text-tiers-blue-400',
        )}
        icon={
          <Icons.Icons.Tiers
            // TODO: Remove after legacy theme is sunsetted
            // This is only used for the legacy Tier Icon
            color={legacyTheme['tiers-blue'][300]}
            className="w-6 text-primary-300"
          />
        }
        label={intl.formatMessage({
          id: 'UpgradePlanWidget.FreeTrial',
          defaultMessage: 'Free 30-day Trial',
        })}
        classes={{ label: 'pr-1 text-[12px]/[1.5] font-bold' }}
      />
    </div>
  );
};

export const UpgradePlanWidget = () => {
  const profile = useSelector(selectProfile);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const intendedUse = profile?.intended_use;
  const { ctaText, description, img, title } = useUpgradeMetadata(intendedUse);

  const { data: tiers } = useGetTiersQuery();
  const trialEligible = !!tiers && isTrialEligible(tiers);
  const userVerified = !!useSelector(selectProfileVerified);
  const isPaidTier = useSelector(selectIsPaidTier);
  const navigationUrl = getTierManagementUrl({
    userVerified,
    isPaidTier,
    trialEligible,
  });

  const isTiersSelectionEnabled =
    useFlags()[TiersFeatureFlags.EnableNonMemberTiersSelection];

  const handleOnClick = () => setShowDownloadModal(true);
  const handleOnClose = () => setShowDownloadModal(false);
  const navigateToTiersSelection = () => navigate(navigationUrl);

  return (
    <>
      <Widget
        alerts={
          isTiersSelectionEnabled && trialEligible ? <FreeTrialChip /> : null
        }
        description={description}
        image={img}
        title={title}
      >
        <ButtonTw
          aria-label={ctaText}
          data-cy="tiers-upgrade-plan-widget-cta"
          onClick={
            isTiersSelectionEnabled ? navigateToTiersSelection : handleOnClick
          }
          trackName="Tiers - Upgrade plan widget"
          variant="primaryInverted"
        >
          {ctaText}
        </ButtonTw>
      </Widget>
      {!isTiersSelectionEnabled && (
        <DownloadApp open={showDownloadModal} onClose={handleOnClose} />
      )}
    </>
  );
};

export default UpgradePlanWidget;
