import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Backdrop, Grid, IconButton, useMediaQuery } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import {
  PutMoneyRequestError,
  PutMoneyRequestErrorCodes,
  PutMoneyRequestErrorResponse,
  useMoneyRequestPutNonceMutation,
} from 'apis/requestMoneyApi';
import { KDSIcons } from 'assets/images/kds_icons';
import { HttpStatusCode } from 'axios';
import { LayoutBasic } from 'components';
import { BoxTw } from 'components/BoxTw';
import CircularProgress from 'components/CircularProgress';
import { CopyButton } from 'components/CopyButton';
import { Paragraph, ParagraphSmall } from 'components/TypographyTw';
import { RequestMoneyDownloadApp } from 'requestMoney/components/RequestMoneyDownloadApp';
import { RequestMoneyETransferDetails } from 'requestMoney/components/RequestMoneyETransferDetails';
import { RequestMoneyEmojiBackground } from 'requestMoney/components/RequestMoneyEmojiBackground';
import { RequestMoneyErrorCard } from 'requestMoney/components/RequestMoneyErrorCard';
import { RequestMoneyInfoDesktop } from 'requestMoney/components/RequestMoneyInfoDesktop';
import { RequestMoneyInfoMobile } from 'requestMoney/components/RequestMoneyInfoMobile';
import { RequestMoneyQRCode } from 'requestMoney/components/RequestMoneyQRCode';
import { RequestMoneyReferralDetails } from 'requestMoney/components/RequestMoneyReferralDetails';
import { RequestMoneyRequestCodeInfoModal } from 'requestMoney/components/RequestMoneyRequestCodeInfoModal';
import { desktop } from 'theme';

export const RequestMoneyIntermediaryPage = ({
  requestCode = '',
}: {
  requestCode?: string;
} & RouteComponentProps) => {
  const intl = useIntl();
  const isDesktop = useMediaQuery(desktop);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);
  const [putMoneyRequest, moneyRequestData] = useMoneyRequestPutNonceMutation();
  const [putMoneyRequestErr, setPutMoneyRequestErr] = React.useState<
    PutMoneyRequestError | undefined
  >();

  useEffect(() => {
    if (requestCode) {
      putMoneyRequest(requestCode)
        .unwrap()
        .catch((err) => {
          const errorRes = err as PutMoneyRequestErrorResponse;
          let moneyRequestError: PutMoneyRequestError;

          switch (errorRes.status) {
            case HttpStatusCode.NotFound:
              moneyRequestError = PutMoneyRequestError.InvalidCode;
              break;
            case HttpStatusCode.Gone:
              moneyRequestError = PutMoneyRequestError.RequestFulfilled;
              break;
            default:
              if (
                errorRes.error?.code ===
                PutMoneyRequestErrorCodes.RequestExpired
              ) {
                moneyRequestError = PutMoneyRequestError.RequestExpired;
              } else {
                moneyRequestError = PutMoneyRequestError.UnknownError;
              }
          }

          setPutMoneyRequestErr(moneyRequestError);
        });
    }
  }, [requestCode, putMoneyRequest]);

  return (
    <LayoutBasic pageName="Request Money">
      <Backdrop
        open={moneyRequestData.isLoading}
        className="z-10"
        invisible={true}
      >
        <CircularProgress />
      </Backdrop>
      <RequestMoneyRequestCodeInfoModal
        open={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
      />
      {!!putMoneyRequestErr && (
        <BoxTw className="px-6 lg:px-0">
          <RequestMoneyErrorCard
            error={putMoneyRequestErr}
            className="mb-8 w-full lg:w-[400px]"
          />
          <RequestMoneyDownloadApp className="w-full lg:w-[400px]" />
        </BoxTw>
      )}
      {!!moneyRequestData.data && (
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          spacing={isDesktop ? 10 : 0}
          className="p-4 lg:p-16"
        >
          <Grid item lg={4} xs={12}>
            {isDesktop ? (
              <RequestMoneyInfoDesktop request={moneyRequestData.data} />
            ) : (
              <RequestMoneyInfoMobile request={moneyRequestData.data} />
            )}
          </Grid>
          <Grid item lg={4} xs={12}>
            {isDesktop && (
              <>
                <BoxTw className="relative flex justify-center">
                  <RequestMoneyEmojiBackground
                    emoji={moneyRequestData.data.emoji}
                  />
                  <RequestMoneyQRCode
                    className="absolute"
                    destination={moneyRequestData.data.qr_code_destination}
                    initials={moneyRequestData.data.requester_initials}
                  />
                </BoxTw>
                <div className="flex items-center justify-center">
                  <Paragraph className="font-bold">
                    {intl.formatMessage({
                      id: 'RequestMoneyPage.UseCode',
                      defaultMessage: 'Or use the request code',
                    })}
                  </Paragraph>
                  <IconButton onClick={() => setIsInfoModalOpen(true)}>
                    <KDSIcons.Icons.QuestionBold className="text-primary-300" />
                  </IconButton>
                </div>
              </>
            )}
            <BoxTw className="flex justify-between border-b border-grey-200">
              <Paragraph className="my-0">
                {intl.formatMessage({
                  id: 'RequestMoneyCopyField.Label',
                  defaultMessage: 'Request code',
                })}
                :
              </Paragraph>
              <BoxTw className="flex">
                <Paragraph className="my-0">
                  {moneyRequestData.data.request_code}
                </Paragraph>
                <CopyButton
                  className="ml-2 px-4"
                  value={moneyRequestData.data.request_code}
                  trackingLabel="Request code"
                />
              </BoxTw>
            </BoxTw>
            <RequestMoneyETransferDetails
              request={moneyRequestData.data}
              className="mb-8"
            />
            {isDesktop ? (
              <BoxTw className="flex justify-center items-center mt-4">
                <KDSIcons.Icons.QuestionBold className="mr-2" />
                <ParagraphSmall>
                  {intl.formatMessage(
                    {
                      id: 'RequestMoneyExpiry.ExpiresAt',
                      defaultMessage: 'This request will expire on {date}',
                    },
                    {
                      date: intl.formatDate(moneyRequestData.data.expires_at, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        timeZone: 'utc',
                      }),
                    },
                  )}
                </ParagraphSmall>
              </BoxTw>
            ) : (
              <RequestMoneyReferralDetails request={moneyRequestData.data} />
            )}
          </Grid>
        </Grid>
      )}
    </LayoutBasic>
  );
};
