import { useSelector } from 'react-redux';

import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';

export const useIcons = () => {
  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';

  return {
    EmptyStates: {
      Activity: isLight
        ? KDSIcons.EmptyStates.FileX
        : KDSIcons.EmptyStates.Activity,
    },
    Features: {
      Interest: isLight ? KDSIcons.Icons.Interest : KDSIcons.Features.Interest,
      Roundups: isLight ? KDSIcons.Icons.KOHOCard : KDSIcons.Features.Roundups,
    },
    Icons: {
      Add: isLight ? KDSIcons.Icons.Add : KDSIcons.Icons.AddLegacy,
      Bills: isLight ? KDSIcons.Icons.Bills : KDSIcons.Icons.BillsLegacy,
      Calendar: isLight
        ? KDSIcons.Icons.Calendar
        : KDSIcons.Icons.CalendarLegacy,
      Dollar: isLight ? KDSIcons.Icons.MoneySign : KDSIcons.Icons.Dollar,
      Gift: isLight ? KDSIcons.Icons.Gift : KDSIcons.Icons.GiftLegacy,
      KohoCard: isLight
        ? KDSIcons.Icons.KOHOCard
        : KDSIcons.Icons.KOHOCardLegacy,
      Question: isLight ? KDSIcons.Icons.HelpCircle : KDSIcons.Icons.Question,
      Rich: isLight ? KDSIcons.Icons.Container : KDSIcons.Icons.Rich,
      Send: isLight ? KDSIcons.Icons.Send : KDSIcons.Icons.SendLegacy,
      Tiers: isLight ? KDSIcons.Icons.Tiers : KDSIcons.Icons.TiersLegacy,
      Time: isLight ? KDSIcons.Icons.Time : KDSIcons.Icons.TimeLegacy,
      Transfer: isLight ? KDSIcons.Icons.Payback : KDSIcons.Icons.Transfer,
      Travel: isLight ? KDSIcons.Icons.Travel : KDSIcons.Icons.TravelLegacy,
      HouseMoney: isLight
        ? KDSIcons.Icons.HouseMoney
        : KDSIcons.Icons.HouseMoneyLegacy,
      Unlock: isLight ? KDSIcons.Icons.Unlock : KDSIcons.Icons.UnlockLegacy,
      Warning: isLight ? KDSIcons.Icons.Warning : KDSIcons.Icons.WarningLegacy,
    },
    Navigation: {
      Cover: isLight ? KDSIcons.Icons.BillsV2 : KDSIcons.Icons.BillsLegacy,
      Credit: isLight ? KDSIcons.Icons.CreditMeter : KDSIcons.Navigation.Credit,
      Grow: isLight ? KDSIcons.Icons.CreditMeter : KDSIcons.Navigation.Grow,
      Saving: isLight ? KDSIcons.Icons.Vault : KDSIcons.Navigation.Saving,
      Settings: isLight
        ? KDSIcons.Icons.Settings
        : KDSIcons.Icons.SettingsLegacy,
      Spending: isLight
        ? KDSIcons.Icons.KOHOCard
        : KDSIcons.Navigation.Spending,
    },
    // I Know that probably I'll be repeating icons. But I need to make this fast to move to the other ticket and launch this. Going to fix it later this week.
    Plan: {
      Grocery: isLight ? KDSIcons.Icons.Payback : KDSIcons.Features.Roundups,
      CashbackPartners: isLight
        ? KDSIcons.Plan.Partners
        : KDSIcons.Features.Powerups,
      SaveRate: isLight ? KDSIcons.Plan.Interest : KDSIcons.Features.Interest,
      FreeCreditScore: isLight
        ? KDSIcons.Icons.CreditMeter
        : KDSIcons.Navigation.Credit,
      PriceDiscount: isLight
        ? KDSIcons.Plan.CreditBuilding
        : KDSIcons.Navigation.Grow,
      NoForeignTxFees: isLight
        ? KDSIcons.Plan.ForeignFees
        : KDSIcons.Premium.Vacation,
    },
    Spot: {
      Success: isLight ? KDSIcons.Spot.CheckVerified : KDSIcons.Spot.Success,
    },
  };
};
