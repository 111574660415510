import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { navigate } from '@reach/router';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetHISAStatusQuery, useValidateAddressQuery } from 'apis/hisa';
import { selectTheme } from 'appState/appState.slice';
import { Illustrations } from 'assets/illustrations';
import { KDSIcons } from 'assets/images/kds_icons';
import { Divider } from 'components';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import {
  Paragraph,
  ParagraphSmall,
  TitleLarge,
  TitleSmall,
} from 'components/TypographyTw';
import { TemplateTw } from 'layout/TemplateTw';
import {
  selectProfileAddress,
  selectProfileVerified,
} from 'profile/store/selectors';
import { useInterestFromTiers } from 'tiers/hooks/useInterestFromTiers';

export const HisaEntryPage = () => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);

  const address = useSelector(selectProfileAddress);
  const userVerified = useSelector(selectProfileVerified);

  const { error: addressError, isLoading: validationIsLoading } =
    useValidateAddressQuery(address ?? skipToken);

  const HISA = useGetHISAStatusQuery();

  const { rate, rateSkeleton } = useInterestFromTiers();

  useEffect(() => {
    if (HISA.data) {
      const { is_external_account_created, is_agreement_signed } = HISA.data;

      if (is_external_account_created && is_agreement_signed) {
        navigate('/savings');
      }
    }
  }, [HISA.data]);

  return (
    <TemplateTw
      name="HISA Entry"
      variant="center"
      wrapperClassName="light:bg-white light:p-6 light:md:p-8 rounded-lg my-8 xl:mt-0"
    >
      <TitleLarge>
        {intl.formatMessage(
          {
            id: 'HisaEntryPage.Title',
            defaultMessage:
              'Earn up to <rateSkeleton>{rate, number}</rateSkeleton>% interest on your account',
          },
          { rate, rateSkeleton },
        )}
      </TitleLarge>
      <ParagraphSmall>
        {intl.formatMessage({
          id: 'HisaEntryPage.Subtitle',
          defaultMessage: 'Make your money work harder for you.',
        })}
      </ParagraphSmall>

      <BoxTw className="flex items-start">
        <BoxTw className="flex-[1_3_0%] mr-4">
          <TitleSmall className="mb-0">
            {intl.formatMessage({
              id: 'HisaEntryPage.RicherAccount',
              defaultMessage: 'Same account, only richer.',
            })}
          </TitleSmall>
          <Paragraph>
            {intl.formatMessage(
              {
                id: 'HisaEntryPage.RicherAccountDescription',
                defaultMessage:
                  'Earn up to <rateSkeleton>{rate, number}</rateSkeleton>% interest annually on all the money you have in KOHO including your spendable balance, savings goals, and vault.',
              },
              { rate, rateSkeleton },
            )}
          </Paragraph>
        </BoxTw>
        <BoxTw className="flex-1 mt-2 max-w-[80px]">
          {themeName === 'legacy' ? (
            <KDSIcons.Spot.Bank />
          ) : (
            <img alt="" className="w-[80px]" src={Illustrations.Bank} />
          )}
        </BoxTw>
      </BoxTw>

      <BoxTw className="flex items-start">
        <BoxTw className="flex-[1_3_0%] mr-4">
          <TitleSmall className="mb-0">
            {intl.formatMessage({
              id: 'HisaEntryPage.Gains',
              defaultMessage: "It's all about the gains.",
            })}
          </TitleSmall>
          <Paragraph>
            {intl.formatMessage({
              id: 'HisaEntryPage.GainsDescription',
              defaultMessage:
                'To maximize your gains, interest will be calculated daily and paid out monthly.',
            })}
          </Paragraph>
        </BoxTw>
        <BoxTw className="flex-1 mt-2 max-w-[80px]">
          {themeName === 'legacy' ? (
            <KDSIcons.Spot.Growth />
          ) : (
            <img alt="" className="w-[80px]" src={Illustrations.Earn} />
          )}
        </BoxTw>
      </BoxTw>

      <BoxTw className="flex items-start">
        <BoxTw className="flex-[1_3_0%] mr-4">
          <TitleSmall className="mb-0">
            {intl.formatMessage({
              id: 'HisaEntryPage.SafelyInsured',
              defaultMessage: 'Safely insured with CDIC',
            })}
          </TitleSmall>
          <Paragraph>
            {intl.formatMessage({
              id: 'HisaEntryPage.SafelyInsuredDescription',
              defaultMessage:
                'Up to $100K of your funds are insured through a CDIC member institution.',
            })}
          </Paragraph>
        </BoxTw>
        <BoxTw className="flex-1 mt-2 max-w-[80px]">
          {themeName === 'legacy' ? (
            <KDSIcons.Spot.HassleFree />
          ) : (
            <img alt="" className="w-[80px]" src={Illustrations.Shield} />
          )}
        </BoxTw>
      </BoxTw>

      <ButtonTw
        className="mt-4"
        disabled={validationIsLoading}
        trackName="HISA Entry Continue"
        onClick={() => {
          let destination = '/hisa/setup';

          if (!userVerified) {
            destination = '/kyc/entry';
          }

          if (addressError && 'status' in addressError) {
            if (addressError.status === 400) {
              destination = '/hisa/address';
            }
          }

          if (HISA.data) {
            const { is_external_account_created, is_agreement_signed } =
              HISA.data;

            if (is_external_account_created && !is_agreement_signed) {
              destination = '/hisa/agreement';
            }

            if (is_external_account_created && is_agreement_signed) {
              destination = '/savings';
            }
          }

          navigate(destination);
        }}
      >
        {intl.formatMessage({
          id: 'HisaEntryPage.StartEarningButton',
          defaultMessage: 'Start earning',
        })}
      </ButtonTw>

      <Divider />

      <ParagraphSmall className="mb-4">
        {intl.formatMessage(
          {
            id: 'HisaEntryPage.CDICTrust',
            defaultMessage: `All cash balances from your KOHO Save account(s) are held in trust at a Canada Deposit Insurance Corporation <linkToAbout>(CDIC)</linkToAbout> member institution. CDIC is a federal Crown corporation. CDIC is not a bank or a private insurance company. CDIC protects eligible deposits held at <linkToHome>CDIC member institutions</linkToHome> in case of a member's failure.`,
          },
          {
            linkToAbout: (str) => (
              <a
                href="https://www.cdic.ca/about-us/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <strong>{str}</strong>
              </a>
            ),
            linkToHome: (str) => (
              <a
                href="https://www.cdic.ca/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <strong>{str}</strong>
              </a>
            ),
          },
        )}
      </ParagraphSmall>

      <ParagraphSmall>
        {intl.formatMessage(
          {
            id: 'HisaEntryPage.CDICTrustDeposits',
            defaultMessage: `For eligible deposits held in trust at a CDIC member institution, CDIC insures up to $100,000 for each beneficiary named in a trust, provided certain disclosure rules are met. Coverage is free and automatic. Find more information on how CDIC trust protection works <linkToDeposits>here.</linkToDeposits>`,
          },
          {
            linkToDeposits: (str) => (
              <a
                href="https://www.cdic.ca/your-coverage/how-deposit-insurance-works/deposits-held-in-trust/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <strong>{str}</strong>
              </a>
            ),
          },
        )}
      </ParagraphSmall>
    </TemplateTw>
  );
};
