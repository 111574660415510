import { BoxProps } from '@material-ui/core';
import clsx from 'clsx';
import { BoxTw } from 'components/BoxTw';
import { twMerge } from 'tailwind-merge';

export const RequestMoneyEmojiBackground = ({
  emoji,
  className,
}: BoxProps & {
  emoji?: string;
}) => {
  if (!emoji) {
    emoji = '💸';
  }

  const rows = [...Array(20).keys()];
  const cols = [...Array(10).keys()];
  return (
    <BoxTw
      className={twMerge(
        'bg-primary-300 rounded-lg overflow-hidden max-h-[300px] pointer-events-none [user-select:none]',
        className,
      )}
      aria-label="hidden"
    >
      {rows.map((row) => (
        <div
          className="flex justify-between pointer-events-none opacity-70"
          key={row}
        >
          {cols.map((col) => (
            <div
              className={clsx(
                'relative leading-[40px] text-xl mr-[30px]',
                row % 2 === 0 && 'left-[26px]',
              )}
              key={col}
            >
              {emoji}
            </div>
          ))}
        </div>
      ))}
    </BoxTw>
  );
};
