import { ReactNode } from 'react';

import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

const Circle = ({
  children,
  light = false,
}: {
  children: ReactNode;
  light?: boolean;
}) => {
  return (
    <div
      className={twMerge(
        clsx(
          'flex items-center justify-center rounded-full shrink-0 w-[48px] h-[48px] legacy:bg-primary-300 bg-primary-300',
          light && 'legacy:bg-grey-75 bg-primary-300',
        ),
      )}
    >
      {children}
    </div>
  );
};

export default Circle;
