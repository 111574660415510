import { useIntl } from 'react-intl';

import { RouteComponentProps } from '@reach/router';
// import { KDSIcons } from 'assets/images/kds_icons';
import { Paragraph, TitleLarge } from 'components/TypographyTw';

export const PasswordResetSuccess = (_props: RouteComponentProps) => {
  const intl = useIntl();
  return (
    <>
      {/* TODO: update asset
      <KDSIcons.Spot.Success />
      */}
      <TitleLarge>
        {intl.formatMessage({
          id: 'PasswordResetSuccess.Title',
          defaultMessage: 'Your password has been changed!',
        })}
      </TitleLarge>
      <Paragraph>
        {intl.formatMessage(
          {
            id: 'PasswordResetSuccess.Content',
            defaultMessage: 'Open the {link} and log in to enjoy KOHO.',
          },
          { link: <a href="https://koho.onelink.me/Iw0N/3bcbce62">app</a> },
        )}
      </Paragraph>
    </>
  );
};
