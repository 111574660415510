import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from '@reach/router';
import { AuthFlags } from 'auth/authFlags';
import LoadingOverlay from 'components/Loading/Overlay';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

const AuthProvider = ({ children, ...props }) => {
  const auth0ClientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const ldClient = useLDClient();
  const isAuth0Enabled = useFlags()[AuthFlags.EnableAuth0];

  // Ensures LD client is ready, otherwise show loading
  if (!ldClient) {
    return <LoadingOverlay />;
  }

  if (!isAuth0Enabled || !auth0ClientId || !auth0Domain) {
    return <>{children}</>;
  }

  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={auth0ClientId}
      clientId={auth0Domain}
      authorizationParams={{
        redirect_uri: window.location.origin + '/transactions',
      }}
      {...props}
    >
      {children}
    </Auth0Provider>
  );
};
export default AuthProvider;
