import { useIntl } from 'react-intl';

import { Dialog } from '@material-ui/core';
import { ReferralReward } from 'apis/referralApi';
import { KDSIcons } from 'assets/images/kds_icons';
import headerImg from 'assets/images/referAndEarn/referral-info-extra-header.jpg';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import { Paragraph, TitleLarge } from 'components/TypographyTw';

export const RewardItemInfoModal = ({
  open,
  onClose,
  canRedeem,
  onRedeemClicked,
  reward,
}: {
  open: boolean;
  onClose: () => void;
  canRedeem: boolean;
  onRedeemClicked: (rewardId: string) => void;
  reward: ReferralReward | null;
}) => {
  const intl = useIntl();

  return (
    <Dialog
      open={reward !== null}
      onClose={onClose}
      className="[&_.MuiDialog-paper]:rounded-lg"
    >
      <img src={headerImg} alt="" className="relative" />
      <button
        onClick={onClose}
        className="cursor-pointer absolute top-5 right-6 bg-transparent border-none"
        aria-label={intl.formatMessage({
          id: 'ReferAndEarn.RewardItemInfoModal.CloseButtonLabel',
          defaultMessage: 'Close dialog',
        })}
      >
        <KDSIcons.Icons.Cross className="text-white" />
      </button>
      <BoxTw className="p-6">
        <TitleLarge className="text-center">{reward?.reward_header}</TitleLarge>
        <Paragraph className="text-center">
          {reward?.reward_sub_header}
        </Paragraph>
        <ButtonTw
          onClick={() => onRedeemClicked(reward?.id || '')}
          disabled={!canRedeem}
        >
          <span className="flex items-center justify-center">
            {!canRedeem && (
              <KDSIcons.Icons.LockDetailed className="w-4 h-4 text-white mr-[10px]" />
            )}
            <p>
              {canRedeem
                ? intl.formatMessage({
                    defaultMessage: 'Redeem',
                    id: 'ReferAndEarn.RewardItemInfoModal.ButtonEnabled',
                  })
                : intl.formatMessage({
                    defaultMessage: 'Refer to redeem',
                    id: 'ReferAndEarn.RewardItemInfoModal.ButtonDisabled',
                  })}
            </p>
          </span>
        </ButtonTw>
      </BoxTw>
    </Dialog>
  );
};
