import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Typography } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { KDSIcons } from 'assets/images/kds_icons';
import { ButtonTw } from 'components/ButtonTw';

export const BillingMethodsAddCardForm = ({
  cardAdded,
  ctaText,
}: {
  cardAdded: (billingMethodId: string) => void;
  ctaText?: string;
}) => {
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      Sentry.captureException(new Error('Stripe.js not loaded'));
      return;
    }

    setIsLoading(true);

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    if (error) {
      if (
        (error?.type === 'card_error' || error?.type === 'validation_error') &&
        error?.message
      ) {
        setMessage(error.message);
      } else {
        setMessage('An unexpected error occurred.');
      }
    }

    if (setupIntent?.status === 'succeeded') {
      cardAdded(setupIntent.payment_method as string);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  } as StripePaymentElementOptions;

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />

      {!isLoading && message && (
        <div className="flex items-start p-4 mt-4 bg-danger-300 text-white rounded-md">
          <KDSIcons.Icons.Warning className="mr-2" />
          <Typography>{message}</Typography>
        </div>
      )}

      <ButtonTw
        id="submit"
        disabled={isLoading || !stripe || !elements}
        loading={isLoading}
        className="mt-4"
        variant="primary"
        trackName="add-billing-card-form-submit"
        data-cy={'add-billing-card-form-submit'}
      >
        <Typography className="font-bold">
          {ctaText
            ? ctaText
            : intl.formatMessage({
                id: 'BillingMethodsAddCardForm.Submit',
                defaultMessage: 'Save',
              })}
        </Typography>
      </ButtonTw>
    </form>
  );
};
