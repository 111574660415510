import { CSSProperties, ReactNode } from 'react';

import InfoOutlined from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { Paragraph } from './TypographyTw';

export type AlertProps = {
  className?: string;
  color?: string;
  content?: string;
  children?: ReactNode;
  icon?: ReactNode;
  alignTop?: boolean;
};

export const Alert = ({
  color = '#00579B',
  children,
  className,
  content,
  icon,
  alignTop = false,
  ...props
}: AlertProps) => {
  return (
    <div
      className={twMerge(
        clsx(
          'flex items-center legacy:rounded-md rounded-lg p-4 text-[color:var(--alert-color)]',
          alignTop && 'items-start',
          className,
        ),
      )}
      style={{ '--alert-color': color } as CSSProperties}
      {...props}
    >
      <div className="flex items-center justify-center h-full">
        <div className="flex grow items-center justify-center">
          {icon ? icon : <InfoOutlined />}
        </div>
      </div>
      <div className="flex items-center justify-center pl-4">
        {children ? (
          children
        ) : (
          <Paragraph
            className="text-[color:var(--alert-color)] m-0"
            style={{ '--alert-color': color } as CSSProperties}
          >
            {content}
          </Paragraph>
        )}
      </div>
    </div>
  );
};
