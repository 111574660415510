import { Dispatch, SetStateAction, useState } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { SecurityAssurance } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { Paragraph, TitleLarge } from 'components/TypographyTw';
import { Address } from 'models/Address';
import { RegistrationAddressEntry } from 'registration/components/RegistrationAddressEntry';
import { RegistrationAddressSearch } from 'registration/components/RegistrationAddressSearch';
import { AddressFormSchema } from 'registration/zodForms/zodFormRegistrationAddress';
import { useTrackPageViewedOnceDeprecated } from 'utility/analyticsHooks';

export const RegistrationAddressPage = ({
  addressForm,
  submitForm,
  setCadPostAddress,
}: {
  addressForm: UseFormReturn<AddressFormSchema, any>;
  submitForm: (data: AddressFormSchema) => void;
  setCadPostAddress: Dispatch<SetStateAction<Address | null>>;
}) => {
  const intl = useIntl();

  const [showAddressEntry, setShowAddressEntry] = useState(
    // If the user goes back to this page, show the address entry filled out with their details
    !!addressForm.getValues('addressLine1'),
  );

  const updateAddressValue = (address: Address): void => {
    setCadPostAddress(address);

    addressForm.setValue('addressLine1', address.line1);
    addressForm.setValue('addressLine2', address.line2);
    addressForm.setValue('city', address.city);
    addressForm.setValue('postalCode', address.postal_code);
    addressForm.setValue('province', address.province_code);

    setShowAddressEntry(true);
  };

  useTrackPageViewedOnceDeprecated({
    name: 'registration-address',
    overrideUrl: `registration/address`,
  });

  const submit: SubmitHandler<AddressFormSchema> = (data) => {
    submitForm(data);
  };

  return (
    <form>
      <TitleLarge data-cy="address-title">
        {intl.formatMessage({
          id: 'Registration.AddressPage.Title',
          defaultMessage: "What's your home address?",
        })}
      </TitleLarge>
      <Paragraph className="mb-8">
        {intl.formatMessage({
          id: 'Registration.AddressPage.Subtitle',
          defaultMessage:
            'Please enter your address as it appears on official documents',
        })}
      </Paragraph>
      {showAddressEntry ? (
        <>
          <RegistrationAddressEntry form={addressForm} submit={submit} />
          <ButtonTw
            type="button"
            variant="tertiary"
            onClick={() => {
              setShowAddressEntry(false);
              addressForm.resetField('addressLine1');
              addressForm.resetField('addressLine2');
              addressForm.resetField('city');
              addressForm.resetField('postalCode');
              addressForm.resetField('province');
            }}
            data-cy="address-redo-search"
            className="mb-4"
          >
            {intl.formatMessage({
              id: 'Registration.AddressPage.RedoSearch',
              defaultMessage: 'Re-do search',
            })}
          </ButtonTw>
        </>
      ) : (
        <RegistrationAddressSearch
          className="mb-4"
          addressSelected={updateAddressValue}
          cantFindAddressClicked={() => setShowAddressEntry(true)}
        />
      )}

      <ButtonTw
        className="mb-6"
        data-cy="address-cta-button"
        disabled={!addressForm.formState.isValid}
        onClick={addressForm.handleSubmit(submit)}
        trackName="move-address"
        type="button"
      >
        {intl.formatMessage({
          id: 'Registration.AddressPage.CTA',
          defaultMessage: 'Next',
        })}
      </ButtonTw>
      <SecurityAssurance />
    </form>
  );
};
