import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { CircularProgress } from '@material-ui/core';
import { navigate } from '@reach/router';
import { track } from 'analytics/analytics';
import { useValidateMFAMutation } from 'apis/mfa';
import { KDSIcons } from 'assets/images/kds_icons';
import { LayoutBasic } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { LinkTw } from 'components/LinkTw';
import { ErrorParagraph, Paragraph, TitleLarge } from 'components/TypographyTw';
import { KhInputTextTw } from 'components/inputs/KhInputTextTw';
import { HelpAndAccessibility } from 'support/components';
import { useGetErrorMessage } from 'utility/useGetAuthErrorMessage';

const OTP_VALID_LENGTH = 6;

export const MFAVerify = ({ path }: { path?: string }) => {
  const getErrorMessage = useGetErrorMessage();
  const [code, setCode] = useState('');
  const intl = useIntl();

  const [validateMFA, validateMFAData] = useValidateMFAMutation();

  const inputPlaceholder = intl.formatMessage({
    defaultMessage: 'Verification code',
    id: 'MFAVerify.Placeholder',
  });

  useEffect(() => {
    if (validateMFAData.isSuccess) {
      navigate('/transactions');
    }
  }, [validateMFAData.isSuccess]);

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const code = event.target.value;
    setCode(code);

    if (code === '' || parseInt(code.trim())) {
      const val = code.length ? code.substring(0, 6) : '';

      if (val.length === OTP_VALID_LENGTH) {
        validateMFA({
          mfa_token: val,
          mfa_option: 'totp',
        }).unwrap();
      }
    }
  };

  const handlePasteCode = () => {
    navigator.clipboard.readText().then((val) => {
      setCode(val);
      if (val.length === OTP_VALID_LENGTH) {
        validateMFA({
          mfa_token: val,
          mfa_option: 'totp',
        }).unwrap();
      }
    });
  };

  const trackTextField = () => {
    track({
      event: 'Clicked',
      properties: {
        name: '2sv-verify-input',
      },
    });
  };

  return (
    <LayoutBasic pageName="MFA - Verify" chat={false}>
      <div className="my-0 mx-8 max-w-[550px] text-grey-400">
        <TitleLarge>
          {intl.formatMessage({
            defaultMessage: 'Check your authenticator app',
            id: 'MFAVerify.CheckYourAuthenticatorApp',
          })}
        </TitleLarge>
        <Paragraph className="mb-9">
          {intl.formatMessage({
            defaultMessage:
              'Your authenticator app should have a 6-digit code for KOHO. Copy and paste it below before the code expires.',
            id: 'MFAVerify.Description',
          })}
        </Paragraph>
        <form
          noValidate
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
          className="mb-4"
        >
          <KhInputTextTw
            variant="outlined"
            label={inputPlaceholder}
            required
            autoFocus
            value={code}
            onChange={onChange}
            error={validateMFAData.isError}
            data-cy="mfa-code-input"
            onClick={() => trackTextField()}
            onFocus={() => trackTextField()}
          />
        </form>
        <ButtonTw
          className="mb-6"
          disabled={validateMFAData.isLoading}
          onClick={() => {
            track({
              event: 'Clicked',
              properties: {
                name: '2sv-verify-paste',
              },
            });
            handlePasteCode();
          }}
          variant="outline"
        >
          {validateMFAData.isLoading ? (
            <CircularProgress size="1rem" />
          ) : (
            intl.formatMessage({
              defaultMessage: 'Paste from clipboard',
              id: 'MFAVerify.PasteFromClipboard',
            })
          )}
        </ButtonTw>
        {validateMFAData.isError && (
          <ErrorParagraph>
            {getErrorMessage(
              (validateMFAData.error as { status: number }).status,
            )}
          </ErrorParagraph>
        )}
        <div className="flex items-center">
          <LinkTw
            state={{
              fallback: true,
            }}
            trackName="2sv-verify-help"
            to="/sms-verify"
            data-cy="cant-access-link"
          >
            {intl.formatMessage({
              defaultMessage: "Can't access your authenticator app?",
              id: 'MFAVerify.CantAccessYourAuthenticatorApp',
            })}
          </LinkTw>
          <KDSIcons.Icons.ArrowRight className="text-primary-300 h-4 w-4" />
        </div>
      </div>
      <HelpAndAccessibility />
    </LayoutBasic>
  );
};
