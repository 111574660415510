import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Box, Chip } from '@material-ui/core';
import { IBenefitGroup, IPlan } from 'apis/tiers';
import { selectTheme } from 'appState/appState.slice';
import { ReactComponent as TiersIconGradient } from 'assets/images/logos/everything-gradient-en.svg';
import { ReactComponent as TiersIconGradientFr } from 'assets/images/logos/everything-gradient-fr.svg';
import { ReactComponent as TiersIconWhite } from 'assets/images/logos/everything-white-en.svg';
import { ReactComponent as TiersIconWhiteFr } from 'assets/images/logos/everything-white-fr.svg';
import { ReactComponent as Check } from 'assets/images/white-check.svg';
import clsx from 'clsx';
import { LOCALE_EN, LOCALE_FR, LocaleContext } from 'components';
import { Paragraph } from 'components/TypographyTw';
import { Money } from 'models';
import { twMerge } from 'tailwind-merge';
import { useTiersData } from 'tiers/hooks/useTiersData';
import { TierKey } from 'tiers/models';

const everythingLogoOptions = {
  [LOCALE_EN]: {
    white: TiersIconWhite,
    gradient: TiersIconGradient,
  },
  [LOCALE_FR]: {
    white: TiersIconWhiteFr,
    gradient: TiersIconGradientFr,
  },
};

type Props = {
  description: string;
  isFree: boolean;
  plan: IPlan | undefined;
  selectable: boolean;
  selected: boolean;
  tier: IBenefitGroup;
  registrationFlow?: boolean;
};

export const TierCardHeader = ({
  description,
  isFree,
  plan,
  selectable,
  selected,
  tier,
  registrationFlow = false,
}: Props) => {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const { getTierName } = useTiersData();
  const themeName = useSelector(selectTheme);

  const EverythingLogo =
    everythingLogoOptions[locale][selectable ? 'gradient' : 'white'];
  const planCost = new Money(plan?.amount_cents ?? 0, locale);

  const tierName = getTierName(tier.name);

  return (
    <Box>
      <div
        className={twMerge(
          clsx(
            'flex flex-row rounded-lg p-4 pb-2 justify-between legacy:bg-[#F9F9F9] bg-white',
            registrationFlow && tier.key !== TierKey.Everything && 'light:px-0',
            tier.key === TierKey.Everything &&
              !selectable &&
              'legacy:bg-tiers-blue-400 bg-primary-400',
            selectable && 'p-0 pb-2 bg-transparent legacy:bg-transparent',
          ),
        )}
      >
        <Box>
          {tier.key === TierKey.Everything && themeName === 'legacy' ? (
            <EverythingLogo
              height="32px"
              className="mb-1"
              data-cy="tiers-management-everything-tier"
            />
          ) : (
            <Paragraph
              className={twMerge(
                clsx(
                  'text-2xl legacy:font-black font-bold legacy:text-tiers-blue-400 text-primary-350 mb-1',
                  tier.key === TierKey.Essential &&
                    !selectable &&
                    'legacy:text-secondary-300 text-primary-200',
                  registrationFlow && 'light:uppercase light:font-heading',
                  tier.key === TierKey.Everything &&
                    registrationFlow &&
                    'text-white',
                ),
              )}
            >
              {tierName}
            </Paragraph>
          )}
          <div className="flex items-center">
            <Paragraph
              className={twMerge(
                clsx(
                  'text-sm legacy:font-black font-bold mr-2 text-grey-300 mb-0',
                  selectable && 'text-grey-400',
                  tier.key === TierKey.Everything &&
                    !selectable &&
                    'text-grey-250',
                  tier.key !== TierKey.Everything &&
                    !selectable &&
                    'text-grey-300',
                  isFree && 'line-through',
                ),
              )}
            >
              {plan?.key === 'monthly'
                ? intl.formatMessage(
                    {
                      id: 'TiersCard.PlanCost.monthly',
                      defaultMessage: '{cost}/month',
                    },
                    { cost: planCost.format('pretty') },
                  )
                : intl.formatMessage(
                    {
                      id: 'TiersCard.PlanCost.yearly',
                      defaultMessage: '{cost}/year',
                    },
                    { cost: planCost.format('pretty') },
                  )}
            </Paragraph>
            {isFree && (
              <Chip
                data-cy="tiers-management-free-chip"
                label={intl.formatMessage({
                  defaultMessage: '30 days free',
                  id: 'TiersCard.TrialDaysChip',
                })}
                className="text-primary-300 legacy:bg-primary-50 bg-primary-75 legacy:font-semibold font-medium [&_span]:leading-[13px]"
              />
            )}
          </div>
        </Box>
        {selectable && (
          <div
            className={twMerge(
              clsx(
                'flex items-center justify-center border border-grey-200 w-6 h-6 rounded-full bg-transparent',
                selected &&
                  'border-none legacy:bg-tiers-blue-300 bg-primary-350',
              ),
            )}
          >
            {selected ? (
              <Check data-cy={`tier-card-check-${tier.key}`} />
            ) : null}
          </div>
        )}
      </div>
      <Paragraph className="text-sm mb-4">{description}</Paragraph>
    </Box>
  );
};
