import { useIntl } from 'react-intl';

import { navigate } from '@reach/router';
import { ButtonTw, Widget } from 'components';

export const MetalCardFreePromoWidget = () => {
  const intl = useIntl();

  return (
    <Widget
      title={intl.formatMessage({
        id: 'MetalCardFreePromoWidget.Title',
        defaultMessage: 'Win a free Metal Card!',
      })}
      description={intl.formatMessage({
        id: 'MetalCardFreePromoWidget.Description',
        defaultMessage:
          'To enter, just transfer your AnyDay balance to your KOHO account.',
      })}
      image={{
        url: `${import.meta.env.VITE_STATIC_URL}/KOHO-Card-Pink.png`,
      }}
      titleClassName="text-primary-400 font-bold"
    >
      <ButtonTw onClick={() => navigate('/load')}>
        {intl.formatMessage({
          id: 'MetalCardFreePromoWidget.Button',
          defaultMessage: 'Move my money',
        })}
      </ButtonTw>
    </Widget>
  );
};
