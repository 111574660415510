import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { navigate } from '@reach/router';
import { BodySm, Paragraph, TitleLarge } from 'components/TypographyTw';
import { selectHisaOnboarding } from 'hisa/store/slice';
import { TemplateTw } from 'layout/TemplateTw';

import { SocialInsuranceNumber } from '../components/SocialInsuranceNumber';

export const HisaSetupPage = () => {
  const intl = useIntl();

  const onboardingData = useSelector(selectHisaOnboarding);

  return (
    <TemplateTw name="HISA Setup" variant="center">
      <BodySm>
        {intl.formatMessage({
          id: 'HisaSetupPage.EarnInterest',
          defaultMessage: 'Earn Interest',
        })}
      </BodySm>
      <TitleLarge sx={{ mb: 1 }}>
        {intl.formatMessage({
          id: 'HisaSetupPage.SIN.Title',
          defaultMessage: "What's your SIN?",
        })}
      </TitleLarge>
      <Paragraph sx={{ mb: 1 }}>
        {intl.formatMessage({
          id: 'HisaSetupPage.SIN.Lead',
          defaultMessage:
            'This is required to open interest-earning accounts in Canada.',
        })}
      </Paragraph>

      <SocialInsuranceNumber
        value={onboardingData.sin}
        onContinue={() => navigate('/hisa/agreement')}
      />
    </TemplateTw>
  );
};
