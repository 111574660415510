import { forwardRef } from 'react';

import { TextMaskPhone } from '../forms/TextMaskPhone';
import { KhInputTextProps, KhInputTextTw } from './KhInputTextTw';

export const KhInputMobileNumber = forwardRef<
  HTMLInputElement,
  KhInputTextProps
>((props: KhInputTextProps, ref) => {
  return (
    <KhInputTextTw
      ref={ref}
      type="tel"
      InputProps={{ inputComponent: TextMaskPhone, inputMode: 'numeric' }}
      {...props}
    />
  );
});
