/** @jsxImportSource theme-ui */
import { ReactNode } from 'react';

import { TrackEvent, track } from '../analytics/analytics';
import { theme } from '../theme';

export interface ButtonUnstyledProps {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: any;
  trackEvent?: TrackEvent;
  trackName?: string;
}

/** @deprecated - use ButtonUnstyled from components/ButtonUnstyledTw instead */
export const ButtonUnstyled = ({
  children,
  className,
  trackName,
  trackEvent,
  onClick,
  ...props
}: Omit<ButtonUnstyledProps, 'css'>) => {
  const handleClick = () => {
    if (trackName) {
      track({
        event: `Clicked`,
        properties: {
          element: 'button',
          type: 'button',
          name: trackName,
        },
      });
    }
    if (trackEvent) {
      track(trackEvent);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      {...props}
      className={className}
      type="button"
      sx={{
        bg: 'transparent',
        cursor: 'pointer',
        py: 0,
        px: 0,
        color: 'currentColor',
        textAlign: 'unset',
        display: 'inline-block',
        boxSizing: 'border-box',
        ...theme.buttons.unstyledTab,
        '&:hover:enabled, &:focus, &:visited': {
          ...theme.buttons.unstyledTab,
          outline: 'none',
        },
        ':disabled': {
          cursor: 'initial',
        },
      }}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default ButtonUnstyled;
