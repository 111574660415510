import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { useGetCurrentTierQuery, useGetTiersQuery } from 'apis/tiers';
import clsx from 'clsx';
import { TitleExtraLarge } from 'components/TypographyTw';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TemplateTw } from 'layout/TemplateTw';
import { twMerge } from 'tailwind-merge';
import { BackToTransactions } from 'tiers/components/BackToTransactions';
import { CancelAnytime } from 'tiers/components/CancelAnytime';
import { ChangePlan } from 'tiers/components/ChangePlan';
import { PlanTabs } from 'tiers/components/PlanTabs';
import { Tiers } from 'tiers/components/Tiers';
import { useSelectedTierPlan } from 'tiers/hooks/useSelectedTierPlan';
import { selectIsPaidTier } from 'tiers/store/selectors';

import { selectLaunchDarklyFlagsIsLoading } from 'libs/launchdarkly/store/selectors';

import { TiersFeatureFlags } from '../models/TiersFeatureFlags';

export const TiersTrialSelectionPage = () => {
  const intl = useIntl();
  const isPaidTier = useSelector(selectIsPaidTier);

  const isTiersSelectionEnabled =
    useFlags()[TiersFeatureFlags.EnableNonMemberTiersSelection];
  const ldFlagsIsLoading = useSelector(selectLaunchDarklyFlagsIsLoading);

  const {
    data: tiers,
    isLoading: tiersIsLoading,
    isUninitialized: tiersIsUninitialized,
    isSuccess: tiersIsSuccess,
  } = useGetTiersQuery();

  const { isLoading: currentTierIsLoading } = useGetCurrentTierQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { setPlan, setTier, currentTierSelected, currentPlanSelected } =
    useSelectedTierPlan({ tiers: tiers ?? [] });

  if (
    tiersIsUninitialized ||
    tiersIsLoading ||
    ldFlagsIsLoading ||
    currentTierIsLoading
  ) {
    return <Skeleton variant="rect" />;
  }

  if (isPaidTier) {
    navigate('/tiers/my-plan');
    return null;
  }

  if (!isTiersSelectionEnabled) {
    navigate('/transactions', { state: { showWelcomeModal: false } });
    return null;
  }

  if (tiersIsSuccess && (!tiers || tiers.length === 0)) {
    navigate('/transactions', { state: { showWelcomeModal: false } });
    return null;
  }

  return (
    <TemplateTw name="Tiers - Trial Selection">
      <div
        className={twMerge(
          clsx(
            'flex flex-col mb-12 lg:mb-20 items-center w-fit px-6 pt-20 lg:pt-0 mx-auto',
          ),
        )}
      >
        <div className="flex flex-col justify-center items-center mb-6 w-full">
          <TitleExtraLarge className="text-[32px] leading-normal legacy:font-black font-bold legacy:text-tiers-blue-400 text-primary-350 text-center mb-6 lg:mb-8 light:uppercase">
            {intl.formatMessage({
              id: 'TiersTrial.header',
              defaultMessage: 'Try any KOHO plan free for 30 days',
            })}
          </TitleExtraLarge>
          <PlanTabs
            className="w-full lg:w-[calc(33%-8px)] xl:w-[calc(33%-16px)]"
            tab={currentPlanSelected?.key || 'monthly'}
            setTab={setPlan}
          />
        </div>
        {tiers && (
          <Tiers
            boxClassName="mb-2 px-0"
            selectable
            tiers={tiers}
            plan={currentPlanSelected?.key}
            selectedTier={currentTierSelected?.key}
            handleSelectTier={setTier}
            isFree
            cardTrackName="tiers-trial-selection-card"
          />
        )}

        <Box className="max-w-[unset] lg:max-w-[352px] mx-auto w-full">
          {currentTierSelected ? (
            <ChangePlan
              isFree
              tier={currentTierSelected}
              selectedPlan={currentPlanSelected}
              trackEventName="tiers-selection-confirm"
            />
          ) : null}
          <BackToTransactions
            dataCy="tier-trial-back-button"
            trackName="tiers-selection-cancel"
          >
            {intl.formatMessage({
              id: 'TiersTrial.Back',
              defaultMessage: 'Back',
            })}
          </BackToTransactions>
          <CancelAnytime />
        </Box>
      </div>
    </TemplateTw>
  );
};
