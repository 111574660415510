import clsx from 'clsx';
import { ChecklistItem } from 'components/ChecklistItem';
import { InfoModal } from 'components/InfoModal';
import { Paragraph } from 'components/TypographyTw';
import { twMerge } from 'tailwind-merge';
import { TierKey } from 'tiers/models';

import { TFeatures } from '../../hooks/useTiersData';

type Props = {
  disableMultiColourChecks?: boolean;
  features: TFeatures;
  tierKey: TierKey;
};

export const Features = ({
  disableMultiColourChecks = false,
  features,
  tierKey,
}: Props) => {
  return (
    <ul className="pl-0 mb-4">
      {Object.entries(features)
        .filter(([_, item]) => item.value !== '')
        .map(([key, item]) => (
          <ChecklistItem
            key={key}
            checkClassName={twMerge(
              clsx(
                'legacy:text-success-300 text-primary-300',
                tierKey === TierKey.Everything &&
                  !disableMultiColourChecks &&
                  'legacy:text-tiers-blue-400 text-primary-300',
              ),
            )}
          >
            <Paragraph className="mb-0 text-sm legacy:font-medium mr-2 legacy:[&_s]:font-medium [&_s]:text-grey-300 legacy:[&_strong]:font-black [&_strong]:font-bold">
              {item.value}
            </Paragraph>
            {item.tooltip && <InfoModal {...item.tooltip} />}
          </ChecklistItem>
        ))}
    </ul>
  );
};
