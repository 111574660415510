import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Box from '@material-ui/core/Box';
import ButtonBackTw from 'components/ButtonBackTw';
import { BodySm } from 'components/TypographyTw';

export const RegistrationProgressIndicator = ({
  currentStep,
  totalSteps,
  showBackButton,
  onBackButtonClicked,
}: {
  currentStep: number;
  totalSteps: number;
  showBackButton?: boolean;
  onBackButtonClicked?: () => void;
}): JSX.Element => {
  const intl = useIntl();

  const [width, setWidth] = useState((currentStep / totalSteps) * 100);

  useEffect(() => {
    setWidth((currentStep / totalSteps) * 100);
  }, [currentStep, totalSteps]);

  return (
    <div className="mb-6 relative">
      {showBackButton && (
        <ButtonBackTw
          data-cy="registration-button-back"
          onBack={onBackButtonClicked}
          className="
            mt-0 md:mt-2
            absolute
            left-0 md:-left-24
            -top-1 md:top-0
          "
        />
      )}
      <BodySm className="mb-3 mt-0 text-grey-400 text-center lg:text-left">
        {intl.formatMessage({
          id: 'Registration.ProgressBar.Title',
          defaultMessage: 'Step',
        })}{' '}
        {currentStep}/{totalSteps}
      </BodySm>

      <Box
        sx={{
          height: '4px',
          width: '100%',
          backgroundColor: 'greyLight',
          borderRadius: '4px',
        }}
      >
        <Box
          sx={{
            width: `${width}%`,
            height: 'inherit',
            borderRadius: '8px',
            transition: '1s ease',
          }}
          className="legacy:bg-gradient-to-r legacy:from-primary-100 legacy:to-primary-300 light:bg-primary-300"
        ></Box>
      </Box>
    </div>
  );
};
