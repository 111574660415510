import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ButtonLink, ButtonTw } from 'components/ButtonTw';
import { SecurityAssurance } from 'components/SecurityAssurance';
import { Paragraph, TitleLarge } from 'components/TypographyTw';
import {
  InputValidationMsg,
  useValidationMsg,
} from 'components/forms/useValidationMsg';
import { KhInputEmail } from 'components/inputs/KhInputEmail';
import { KhInputPassword } from 'components/inputs/KhInputPassword';
import { IdentityExistingFormSchema } from 'registration/zodForms/zodFormRegistrationIdentityExisting';
import {
  useTrackElementViewedOnce,
  useTrackPageViewedOnceDeprecated,
} from 'utility/analyticsHooks';

export const IdentityExistingPage = ({
  form,
  formSubmit,
  isLoading,
  isError,
}: {
  form: UseFormReturn<IdentityExistingFormSchema, any>;
  formSubmit: SubmitHandler<IdentityExistingFormSchema>;
  isLoading: boolean;
  isError: boolean;
}) => {
  const intl = useIntl();

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = form;

  const submit = (data: IdentityExistingFormSchema) => {
    formSubmit(data);
  };

  useTrackPageViewedOnceDeprecated({
    name: 'registration-identity-existing',
    overrideUrl: `registration/identity-existing`,
  });

  useTrackElementViewedOnce({
    element: errors['current-password'],
    name: 'identity-existing-password-input-error',
  });

  return (
    <div>
      <TitleLarge className="mb-8" data-cy="identity-existing-title">
        {intl.formatMessage({
          id: 'Registration.IdentityExistingPage.Title',
          defaultMessage: 'Enter your password',
        })}
      </TitleLarge>
      <Paragraph className="mb-8">
        {intl.formatMessage({
          id: 'Registration.IdentityExistingPage.Instruction',
          defaultMessage:
            'Looks like you previously started sign-up with this email, but didn’t finish. Enter the password you originally created to continue.',
        })}
      </Paragraph>
      <form onSubmit={handleSubmit(submit)}>
        <KhInputEmail
          label={intl.formatMessage({
            id: 'Registration.IdentityExistingPage.EmailLabel',
            defaultMessage: 'Email',
          })}
          // opt out of tracking, since this input is always disabled
          // and is just to show the email to the user
          trackName=""
          disabled
          {...register('email')}
          error={!!errors.email}
          data-cy="identity-existing-email-input"
          sx={{ mb: 4 }}
          id="email"
          autoComplete="username"
        />
        <KhInputPassword
          label={intl.formatMessage({
            id: 'Registration.IdentityExistingPage.PasswordLabel',
            defaultMessage: 'Password',
          })}
          {...register('current-password')}
          error={!!errors['current-password']}
          helperText={useValidationMsg(
            isError ? InputValidationMsg.InvalidCredentials : '',
          )}
          data-cy="identity-existing-password-input"
          trackName="identity-existing-password"
          sx={{ mb: 4 }}
          id="current-password"
          autoComplete="current-password"
        />

        <ButtonTw
          type="submit"
          trackName="move-identity-exists"
          disabled={!isValid}
          loading={isLoading}
          className="mb-6"
          data-cy="identity-existing-cta-button"
        >
          {intl.formatMessage({
            id: 'Registration.IdentityExistingPage.Next',
            defaultMessage: 'Next',
          })}
        </ButtonTw>

        <ButtonLink
          trackName="forgot-password-identity"
          variant="tertiary"
          className="mb-6"
          to="/password-reset/email"
          data-cy="identity-existing-forgot-password"
        >
          {intl.formatMessage({
            id: 'Registration.IdentityExistingPage.ForgotPassword',
            defaultMessage: 'Forgot password',
          })}
        </ButtonLink>

        <SecurityAssurance />
      </form>
    </div>
  );
};
