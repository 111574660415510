import { twMerge } from 'tailwind-merge';

export const Divider = ({ className }: { className?: string }): JSX.Element => {
  return (
    <div
      className={twMerge(
        'my-8 border-t border-solid border-tertiary-100',
        className,
      )}
    />
  );
};
