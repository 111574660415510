import { Dispatch, SetStateAction, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { KDSIcons } from 'assets/images/kds_icons';
import { SecurityAssurance } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import {
  Paragraph,
  SubtitleMedium,
  SubtitleSmall,
  TitleLarge,
} from 'components/TypographyTw';
import { Address } from 'models/Address';
import { RegistrationAddressEntry } from 'registration/components/RegistrationAddressEntry';
import { RegistrationAddressSearch } from 'registration/components/RegistrationAddressSearch';
import { AddressFormSchema } from 'registration/zodForms/zodFormRegistrationAddress';
import { theme } from 'theme';
import { useTrackPageViewedOnceDeprecated } from 'utility/analyticsHooks';

export const RegistrationHomeAddressPage = ({
  addressForm,
  setCadPostAddress,
  shippingAddressLine1,
  submitForm,
}: {
  addressForm: UseFormReturn<AddressFormSchema, any>;
  setCadPostAddress: Dispatch<SetStateAction<Address | null>>;
  shippingAddressLine1: string;
  submitForm: () => void;
}) => {
  const intl = useIntl();

  const {
    formState: { isValid },
    setValue,
    handleSubmit,
    reset,
  } = addressForm;

  const [showAddressEntry, setShowAddressEntry] = useState(false);

  const updateAddressValue = (address: Address): void => {
    setCadPostAddress(address);

    setValue('addressLine1', address.line1);
    setValue('addressLine2', address.line2);
    setValue('city', address.city);
    setValue('postalCode', address.postal_code);
    setValue('province', address.province_code);

    setShowAddressEntry(true);
  };

  useTrackPageViewedOnceDeprecated({
    name: 'registration-home-address',
    overrideUrl: `registration/home-address`,
  });

  return (
    <form>
      <TitleLarge>
        {intl.formatMessage({
          id: 'Registration.HomeAddressPage.Title',
          defaultMessage: `What's your home address?`,
        })}
      </TitleLarge>
      <Paragraph className="mb-4">
        {intl.formatMessage({
          id: 'Registration.HomeAddressPage.Subtitle',
          defaultMessage:
            'We need an address other than your PO Box to verify your identity.',
        })}
      </Paragraph>

      {showAddressEntry ? (
        <>
          <RegistrationAddressEntry submit={submitForm} form={addressForm} />
          <ButtonTw
            type="button"
            variant="tertiary"
            onClick={() => {
              setShowAddressEntry(false);
              reset();
            }}
            className="mb-4"
            data-cy="home-address-redo-search"
          >
            {intl.formatMessage({
              id: 'Registration.HomeAddressPage.RedoSearch',
              defaultMessage: 'Re-do search',
            })}
          </ButtonTw>
        </>
      ) : (
        <>
          <RegistrationAddressSearch
            className="mb-4"
            addressSelected={updateAddressValue}
            cantFindAddressClicked={() => setShowAddressEntry(true)}
          />
          <div className="flex gap-4 justify-between mb-6 p-4 rounded-lg shadow-[3px_6px_15px_0px_rgba(0,0,0,0.1)]">
            <div>
              <SubtitleSmall className="mb-2">
                {intl.formatMessage({
                  id: 'Registration.HomeAddressPage.DeliveryAddress',
                  defaultMessage: 'Delivery address',
                })}
              </SubtitleSmall>
              <SubtitleMedium data-cy="home-address-shipping">
                {shippingAddressLine1}
              </SubtitleMedium>
            </div>
            <KDSIcons.Icons.CheckmarkFilled color={theme.colors.successful} />
          </div>
        </>
      )}

      <ButtonTw
        type="button"
        disabled={!isValid}
        trackName="move-home-address"
        className="mb-6"
        data-cy="home-address-cta-button"
        onClick={handleSubmit(submitForm)}
      >
        {intl.formatMessage({
          id: 'Registration.HomeAddressPage.CTA',
          defaultMessage: 'Next',
        })}
      </ButtonTw>
      <SecurityAssurance />
    </form>
  );
};
