import { ReactNode } from 'react';

import { ButtonBase, Drawer, useMediaQuery, useTheme } from '@material-ui/core';
import type { DialogProps, DrawerProps } from '@material-ui/core';
import { KDSIcons } from 'assets/images/kds_icons';
import { Modal } from 'components';
import type { ModalProps } from 'components/Modal';
import { TitleLarge } from 'components/TypographyTw';
import { useToggle } from 'hooks/useToggle';
import { twMerge } from 'tailwind-merge';

export type Props = {
  children?: ReactNode;
  content: ({
    openModal,
    closeModal,
  }: {
    openModal?: () => void;
    closeModal: () => void;
  }) => ReactNode;
  title?: string;
  drawerProps?: Omit<DrawerProps, 'open' | 'title'>;
  drawerClasses?: string;
  modalProps?: Omit<ModalProps, 'open' | 'title'>;
  modalClasses?: DialogProps['classes'];
};

export const StatelessDrawerModal = ({
  children,
  closeModal,
  content,
  drawerProps,
  drawerClasses,
  isOpen,
  modalProps,
  modalClasses,
  openModal,
  title,
}: Props & {
  closeModal: () => void;
  isOpen: boolean;
  openModal?: () => void;
}) => {
  const muiTheme = useTheme();
  const isAfterTablet = useMediaQuery(muiTheme.breakpoints.up('md'));

  return (
    <>
      {children && <ButtonBase onClick={openModal}>{children}</ButtonBase>}
      {isAfterTablet ? (
        <Modal
          disableScrollLock
          open={isOpen}
          onClose={closeModal}
          classes={modalClasses}
          title={title ?? ''}
          {...modalProps}
        >
          {content({ openModal, closeModal })}
        </Modal>
      ) : (
        <Drawer
          anchor="bottom"
          disableScrollLock
          open={isOpen}
          onClose={closeModal}
          classes={{
            paper: twMerge(
              'rounded-tl-md rounded-tr-md p-6 pb-16 h-min-[200px]',
              drawerClasses,
            ),
          }}
          {...drawerProps}
        >
          <ButtonBase onClick={closeModal} className="w-fit ml-auto">
            <KDSIcons.Icons.Cross height={20} width={20} />
          </ButtonBase>
          {title ? <TitleLarge>{title}</TitleLarge> : null}
          {content({ openModal, closeModal })}
        </Drawer>
      )}
    </>
  );
};

export const DrawerModal = (props: Props) => {
  const { value: isOpen, on: openModal, off: closeModal } = useToggle();

  return (
    <StatelessDrawerModal
      isOpen={isOpen}
      openModal={openModal}
      closeModal={closeModal}
      {...props}
    />
  );
};
