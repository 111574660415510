import { track } from 'analytics/analytics';

import { rootApi } from './rootApi';

export enum Affiliate {
  CanadaPostEmployee = 'CANADAPOSTEMPLOYEE',
  CanadaPostGeneral = 'CANADAPOSTGENERAL',
  XTM = 'XTM',
}

export const CANADA_POST_AFFILIATES = [
  Affiliate.CanadaPostEmployee,
  Affiliate.CanadaPostGeneral,
];
export function isCanadaPostAffiliate(affiliates?: Affiliate[]) {
  return affiliates?.some((affiliate) =>
    CANADA_POST_AFFILIATES.includes(affiliate),
  );
}

export function isXTMAffiliate(affiliates?: Affiliate[]) {
  return affiliates?.includes(Affiliate.XTM);
}

export interface AffiliateResponse {
  affiliates: Affiliate[];
  referral_code?: string;
}

interface PostAffiliateWithAffiliateUserIdentifierParams {
  affiliate: Affiliate;
  affiliate_user_identifier: string;
}

export const registrationApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    postReferralAffiliate: build.mutation<
      AffiliateResponse,
      { referral_code: string }
    >({
      query: (request) => ({
        url: '/2.0/users/affiliates/registration',
        method: 'POST',
        body: request,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          track({ event: 'Post Referral Affiliate Failed' });
        }
      },
      invalidatesTags: ['Affiliates'],
    }),
    postAffiliateWithAffiliateUserIdentifier: build.mutation<
      AffiliateResponse,
      PostAffiliateWithAffiliateUserIdentifierParams
    >({
      query: (request) => ({
        url: `/2.0/users/affiliates/${request.affiliate_user_identifier}`,
        method: 'POST',
        body: {
          affiliate: request.affiliate,
        },
      }),
    }),
    getAffiliates: build.query<AffiliateResponse, void>({
      query: () => ({
        url: '/2.0/users/affiliates',
        method: 'GET',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          track({ event: 'Get Affiliates Failed' });
        }
      },
      providesTags: ['Affiliates'],
    }),
    updateAffiliateConsentStatus: build.mutation<
      void,
      { affiliate: Affiliate; optedIn: boolean }
    >({
      query: ({ affiliate, optedIn }) => ({
        url: `/2.0/users/affiliates/consent`,
        method: 'PUT',
        body: {
          affiliate,
          opted_in: optedIn,
        },
      }),
    }),
  }),
});

export const {
  usePostReferralAffiliateMutation,
  useGetAffiliatesQuery,
  useLazyGetAffiliatesQuery,
  useUpdateAffiliateConsentStatusMutation,
  usePostAffiliateWithAffiliateUserIdentifierMutation,
} = registrationApi;
