import { ReactNode, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import deposit from 'assets/images/kds_icons/spot/coin.svg';
import clsx from 'clsx';
import { LocaleContext } from 'components';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import { TitleMedium } from 'components/TypographyTw';
import { TextInput, Validators } from 'components/forms';
import { InboundETransferActions } from 'inboundETransfer/store/slice';
import { Loadable } from 'utility/loadable';

import interac from '../../assets/images/logos/interac-logo.png';

interface TransferDetailProps {
  label: string;
  isNumber?: boolean;
  last?: boolean;
  children: ReactNode;
}

const TransferDetail = ({
  label,
  isNumber = false,
  last = false,
  children,
}: TransferDetailProps) => {
  return (
    <BoxTw className={clsx('flex flex-wrap', last ? 'mb-0' : 'mb-4')}>
      <BoxTw className="flex-[1_1_100%] text-xs font-medium text-grey-400">
        {label}
      </BoxTw>
      <BoxTw
        className={clsx(
          'flex-[1_1_100%] text-grey-400',
          isNumber
            ? 'legacy:font-legacyNumbers light:font-number text-2xl/[38px] font-semibold'
            : 'legacy:font-legacyHeading light:font-heading text-base/[22px] font-bold',
        )}
      >
        {children}
      </BoxTw>
    </BoxTw>
  );
};

interface TransferProps {
  fromName: string;
  amountCents: number;
  question: string;
  memo: string;
  secretAnswerValue: string;
  showIncorrectAnswerError: boolean;
  declineHandler: () => void;
  declineLoadable: Loadable;
  acceptHandler: () => void;
  acceptLoadable: Loadable;
}

export const InboundETransfer = ({
  fromName,
  amountCents,
  question,
  memo,
  secretAnswerValue,
  showIncorrectAnswerError,
  declineHandler,
  declineLoadable,
  acceptHandler,
  acceptLoadable,
}: TransferProps) => {
  const intl = useIntl();
  const { intlFormatMoney } = useContext(LocaleContext);

  const [inputError, setInputError] = useState(false);

  const dispatch = useDispatch();

  return (
    <BoxTw>
      <TitleMedium className="mb-6">
        {intl.formatMessage({
          id: 'InboundETransferPage.Heading',
          defaultMessage: 'Deposit this INTERAC e-Transfer® into your account',
        })}
      </TitleMedium>
      <BoxTw className="mb-2 flex justify-between items-center">
        <img src={deposit} alt="" />
        <img
          className="w-7 h-7"
          src={interac}
          alt={intl.formatMessage({
            id: 'InboundETransferPage.InteractETransferLogoAlt',
            defaultMessage: 'Interac e-Transfer Logo',
          })}
        />
      </BoxTw>

      <BoxTw className="rounded p-4 mb-2 shadow-[0px_1px_5px_rgba(0,0,0,0.1)]">
        <TransferDetail
          label={intl.formatMessage({
            id: 'InboundETransferPage.AmountLabel',
            defaultMessage: 'Amount (CAD)',
          })}
          isNumber
        >
          <span data-cy="transfer-amount">
            {intlFormatMoney(amountCents).format()}
          </span>
        </TransferDetail>
        <TransferDetail
          last={memo ? false : true}
          label={intl.formatMessage({
            id: 'InboundETransferPage.MemoLabel',
            defaultMessage: 'Sent from',
          })}
        >
          <span data-cy="transfer-name">{fromName}</span>
        </TransferDetail>
        {memo ? (
          <TransferDetail label={'Message'} last>
            <span data-cy="transfer-memo">{memo}</span>
          </TransferDetail>
        ) : null}
      </BoxTw>

      <BoxTw className="rounded p-4 mb-6 shadow-[0px_1px_5px_rgba(0,0,0,0.1)]">
        <TransferDetail
          label={intl.formatMessage({
            id: 'InboundETransferPage.SecurityQuestionLabel',
            defaultMessage: 'Security question',
          })}
        >
          <span data-cy="transfer-question">{question}</span>
        </TransferDetail>
        <TextInput
          label="Answer"
          onValidChange={(isValid) => setInputError(isValid)}
          value={secretAnswerValue}
          onChange={(event) => {
            dispatch(
              InboundETransferActions.InboundETransferAnswerUpdate({
                answer: event?.target?.value ?? '',
              }),
            );
          }}
          validators={[
            Validators.required,
            Validators.minLength(3, intl),
            Validators.maxLength(25, intl),
          ]}
          data-cy="transfer-answer-input"
        />
        {showIncorrectAnswerError ? (
          <BoxTw
            className="text-danger-300 pt-1 pl-3"
            data-cy="iet-warning-incorrect-answer"
          >
            {intl.formatMessage({
              id: 'InboundETransferPage.InlineError.IncorrectAnswer',
              defaultMessage: 'Incorrect answer, please try again',
            })}
          </BoxTw>
        ) : null}
      </BoxTw>
      <BoxTw className="mb-6 text-grey-300">
        {intl.formatMessage({
          id: 'InboundETransferPage.InteracLegalCopy',
          defaultMessage:
            'The INTERAC logo and INTERAC e-Transfer are registered Trade-marks of Interac Corp. Used under licence.',
        })}
      </BoxTw>
      <BoxTw className="grid grid-cols-2 gap-2">
        <ButtonTw
          disabled={
            !inputError || declineLoadable.loading || acceptLoadable.loading
          }
          variant="secondary"
          data-cy="iet-transfer-decline"
          onClick={declineHandler}
        >
          {intl.formatMessage({
            id: 'InboundETransferPage.DeclineCTA',
            defaultMessage: 'Decline',
          })}
        </ButtonTw>
        <ButtonTw
          disabled={
            !inputError || declineLoadable.loading || acceptLoadable.loading
          }
          data-cy="iet-transfer-accept"
          onClick={acceptHandler}
        >
          {intl.formatMessage({
            id: 'InboundETransferPage.AcceptCTA',
            defaultMessage: 'Accept',
          })}
        </ButtonTw>
      </BoxTw>
    </BoxTw>
  );
};
