import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Link } from '@reach/router';
import { ButtonTw } from 'components/ButtonTw';
import { selectRegistrationReferralCode } from 'referrals/store/selectors';
import { twMerge } from 'tailwind-merge';

import { track } from '../../analytics/analytics';
import iosButton from '../../assets/images/app-store.png';
import androidButton from '../../assets/images/google-play.png';

export type OS = 'android' | 'ios' | 'web';

const getMobileOperatingSystem = (): OS => {
  if (typeof window !== `undefined`) {
    const userAgent = navigator.userAgent || navigator.vendor;

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/android/i.test(userAgent)) {
      return 'android';
    } else if (
      /iPad|iPhone|iPod/.test(userAgent) &&
      !(window as any).MSStream
    ) {
      return 'ios';
    } else {
      return 'web';
    }
  } else {
    return 'web';
  }
};

export const SignUpWidgetTw = ({
  btnStyles,
  className,
  osDefault,
  ...props
}: {
  btnStyles?: string;
  className?: string;
  referralCode?: string;
  osDefault?: 'ios' | 'android' | 'web';
}) => {
  const os = osDefault || getMobileOperatingSystem();
  const intl = useIntl();
  const referralCode = useSelector(selectRegistrationReferralCode);

  const referralSignUpLink = '/registration#referral=' + referralCode?.campaign;
  const noReferralSignUpLink = '/registration';

  const signUpButton = ({ ...props }) => {
    switch (os) {
      case 'ios':
        return (
          <div {...props} className={className}>
            <a
              onClick={() =>
                track({
                  event: `Clicked`,
                  properties: {
                    element: 'img',
                    page: 'Referral Landing',
                    name: 'App Store',
                  },
                })
              }
              href={
                referralCode
                  ? 'https://go.onelink.me/app/ad584467?af_sub1=' +
                    referralCode.campaign
                  : 'https://go.onelink.me/app/ad584467'
              }
            >
              <img
                className="w-[175px]"
                src={iosButton}
                alt={`${intl.formatMessage({
                  id: 'SignUpWidget.DownloadIosAppImageAltTag',
                  defaultMessage: 'Download KOHO on the App Store',
                })}`}
              />
            </a>
          </div>
        );
      case 'android':
        return (
          <div {...props} className={className}>
            <a
              href={
                referralCode
                  ? 'https://go.onelink.me/app/e76f5835?af_sub1=' + referralCode
                  : 'https://go.onelink.me/app/e76f5835'
              }
              onClick={() =>
                track({
                  event: `Clicked`,
                  properties: {
                    element: 'img',
                    page: 'Referral Landing',
                    name: 'Google Play',
                  },
                })
              }
            >
              <img
                className="w-[175px]"
                src={androidButton}
                alt={`${intl.formatMessage({
                  id: 'SignUpWidget.DownloadGoogleAppImageAltTag',
                  defaultMessage: 'Download KOHO on Google Play',
                })}`}
              />
            </a>
          </div>
        );
      case 'web':
        return (
          <Link
            {...props}
            to={referralCode ? referralSignUpLink : noReferralSignUpLink}
            onClick={() =>
              track({
                event: `Clicked`,
                properties: {
                  element: 'button',
                  page: 'Referral Landing',
                  name: 'Sign Up',
                },
              })
            }
            className={twMerge('no-underline', className)}
          >
            <ButtonTw className={twMerge('w-auto', btnStyles)}>
              {intl.formatMessage({
                id: 'SignUpWidget.Button',
                defaultMessage: 'Join for free',
              })}
            </ButtonTw>
          </Link>
        );
    }
  };
  return signUpButton(props);
};
