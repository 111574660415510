import { ReactNode, useContext } from 'react';
import { useIntl } from 'react-intl';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useGetBillPayLoadInfoQuery } from 'apis/billPayLoad';
import {
  IVelocityData,
  MoneyMovementType,
  useGetVelocityQuery,
} from 'apis/velocity';
import { KDSIcons } from 'assets/images/kds_icons';
import { LocaleContext, ParagraphMedium } from 'components';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import { LineCopier } from 'components/LineCopier';
import LoadingCentered from 'components/Loading/Centered';
import {
  Paragraph,
  ParagraphBold,
  TitleLarge,
  TitleSmall,
} from 'components/TypographyTw';
import { TemplateTw } from 'layout/TemplateTw';
import { methodsMaxVelocityUtil } from 'utility/addMoneyMethodsVelocity';

export const LoadBillPay = () => {
  const intl = useIntl();
  const localeContext = useContext(LocaleContext);

  const { data: billPayLoadData, isLoading: billPayInfoLoading } =
    useGetBillPayLoadInfoQuery();
  const { data: velocityData, isLoading: velocityDataLoading } =
    useGetVelocityQuery();

  const href = `https://help.koho.ca/${localeContext.locale}/articles/10149078-what-is-deposit-via-bill-pay-and-how-does-it-work`;

  if (billPayInfoLoading || velocityDataLoading)
    return (
      <TemplateTw
        name="LoadBillPay"
        variant="center"
        widths={['90%', '75%', '75%', '75%']}
      >
        <TitleLarge>
          {intl.formatMessage({
            id: 'LoadBillPay.Title',
            defaultMessage: 'Deposit via Bill Pay',
          })}
        </TitleLarge>
        <LoadingCentered />
      </TemplateTw>
    );

  return (
    <TemplateTw
      name="LoadBillPay"
      variant="center"
      widths={['90%', '75%', '75%', '75%']}
    >
      <TitleLarge>
        {intl.formatMessage({
          id: 'LoadBillPay.Title',
          defaultMessage: 'Deposit via Bill Pay',
        })}
      </TitleLarge>

      {velocityData && (
        <Paragraph>
          {intl.formatMessage(
            {
              id: 'LoadBillPay.Subtitle',
              defaultMessage:
                'Add up to {amount} at once and get it in 1-3 days. Just use the bill pay feature in the bank account you’re sending from.',
            },
            {
              amount: localeContext
                .intlFormatMoney(
                  methodsMaxVelocityUtil(velocityData)[
                    MoneyMovementType.BillPay
                  ],
                )
                .format('pretty'),
            },
          )}
        </Paragraph>
      )}

      <BoxTw className="rounded-xl bg-white p-6 mt-6 flex flex-col">
        <TitleSmall>
          {intl.formatMessage({
            id: 'LoadBillPay.Info.Title',
            defaultMessage: 'Payee Details',
          })}
        </TitleSmall>
        <Paragraph>
          {intl.formatMessage({
            id: 'LoadBillPay.Info.Subtitle',
            defaultMessage:
              'Go to another bank account, find the bill pay feature and create a new payee with this information. ',
          })}
        </Paragraph>
        {billPayLoadData && (
          <>
            <LineCopier
              className="border-b border-solid py-4 w-1/2"
              label={intl.formatMessage({
                id: 'LoadBillPay.Info.AccountNumber',
                defaultMessage: 'Account number',
              })}
              value={billPayLoadData.payee_identifier}
            />
            <LineCopier
              className="border-b border-solid py-4 w-1/2"
              label={intl.formatMessage({
                id: 'LoadBillPay.Info.PayeeName',
                defaultMessage: 'Payee name',
              })}
              value="KOHO Financial"
            />
            <ButtonTw
              onClick={() => {
                window.open(href, '_blank', 'noopener,noreferrer');
              }}
              trackName="bill-pay-load-setup-guide"
              variant="secondary"
              className="w-fit p-2 text-primary-300 mt-2"
            >
              {intl.formatMessage({
                id: 'LoadBillPay.Info.SetupGuide',
                defaultMessage: 'See setup guide',
              })}
            </ButtonTw>
          </>
        )}
      </BoxTw>

      <BoxTw className="bg-white rounded-xl p-6 mt-8">
        <TitleSmall>
          {intl.formatMessage({
            id: 'LoadBillPay.DontForget.Title',
            defaultMessage: `Don't forget to`,
          })}
        </TitleSmall>
        <div className="flex py-4 items-center border-b border-solid shadow-none">
          <div className="self-start mr-4 flex-shrink-0 text-primary-200">
            <KDSIcons.Icons.Info />
          </div>
          <div className="flex-grow">
            <ParagraphMedium className="text-grey-500 my-0">
              {intl.formatMessage({
                id: 'LoadBillPay.DontForget.ItemOne.Title',
                defaultMessage: 'Use the account number above',
              })}
            </ParagraphMedium>
            <Paragraph className="text-grey-400 my-1">
              {intl.formatMessage({
                id: 'LoadBillPay.DontForget.ItemOne.Description',
                defaultMessage: `When you're adding a new payee, don't use your KOHO card number- only use the information above`,
              })}
            </Paragraph>
          </div>
        </div>
        <div className="flex py-4 items-center ">
          <div className="self-start mr-4 flex-shrink-0 text-primary-200">
            <KDSIcons.Icons.GoalAmount />
          </div>
          <div className="flex-grow">
            <ParagraphMedium className="text-grey-500 my-0">
              {intl.formatMessage({
                id: 'LoadBillPay.DontForget.ItemTwo.Title',
                defaultMessage: 'Double check your information',
              })}
            </ParagraphMedium>
            <Paragraph className="text-grey-400 my-1">
              {intl.formatMessage({
                id: 'LoadBillPay.DontForget.ItemTwo.Description',
                defaultMessage: `Incorrect information could slow down getting your money.`,
              })}
            </Paragraph>
          </div>
        </div>
      </BoxTw>

      <BoxTw className="bg-white rounded-xl p-6 mt-8">
        <TitleSmall>
          {intl.formatMessage({
            id: 'LoadBillPay.FAQ.Title',
            defaultMessage: `Frequently Asked Questions`,
          })}
        </TitleSmall>
        <>{velocityData && <BillPayLoadFAQ velocityData={velocityData} />}</>
      </BoxTw>
    </TemplateTw>
  );
};

const BillPayLoadFAQ = ({ velocityData }: { velocityData: IVelocityData }) => {
  const intl = useIntl();
  const localeContext = useContext(LocaleContext);

  const FAQs = [
    {
      key: 'WhatIsIt',
      question: intl.formatMessage({
        id: 'BillPay.FAQ.WhatIs.Question',
        defaultMessage: 'What is Deposit via Bill Pay, and how does it work?',
      }),
      answer: intl.formatMessage({
        id: 'BillPay.FAQ.WhatIs.Answer',
        defaultMessage:
          'Deposit via Bill Pay lets you add funds to your KOHO account directly from your bank by using your KOHO account number, just like paying a bill.',
      }),
    },
    {
      key: 'Fees',
      question: intl.formatMessage({
        id: 'BillPay.FAQ.Fees.Question',
        defaultMessage:
          'Are there any fees for using Deposit via Bill Pay to add funds to my KOHO account?',
      }),
      answer: intl.formatMessage({
        id: 'BillPay.FAQ.Fees.Answer',
        defaultMessage: 'KOHO doesn’t charge any fees for this loading method.',
      }),
    },
    {
      key: 'AutomaticPayments',
      question: intl.formatMessage({
        id: 'BillPay.FAQ.AutomaticPayments.Question',
        defaultMessage:
          'Can I schedule automatic payments to load money to my KOHO account through this method?',
      }),
      answer: intl.formatMessage({
        id: 'BillPay.FAQ.AutomaticPayments.Answer',
        defaultMessage:
          'That depends on the financial institution from which you send the money. We recommend checking directly with them to see if this is possible. ',
      }),
    },
    {
      key: 'TimingFunds',
      question: intl.formatMessage({
        id: 'BillPay.FAQ.TimingFunds.Question',
        defaultMessage: 'How long does it take to get my funds?',
      }),
      answer: intl.formatMessage({
        id: 'BillPay.FAQ.TimingFunds.Answer',
        defaultMessage: 'Usually 1-3 days. ',
      }),
    },
    {
      key: 'NotShowing',
      question: intl.formatMessage({
        id: 'BillPay.FAQ.NotShowing.Question',
        defaultMessage:
          "Why isn't KOHO showing up in my bank's bill payment options?",
      }),
      answer: intl.formatMessage({
        id: 'BillPay.FAQ.NotShowing.Answer',
        defaultMessage: `Not all banks list KOHO yet. Here's a list of those that do: Desjardins, RBC, National Bank, Laurentian Bank, Scotia (in process), BMO: PC BANK, Motus Bank, Meridian Credit Union, Manulife Bank, Citibank (RBC), Tangerine Bank, JP Morgan Chase (RBC), TFX Bill Pay, ZAG Bank`,
      }),
    },
    {
      key: 'DepositLimit',
      question: intl.formatMessage({
        id: 'BillPay.FAQ.DepositLimit.Question',
        defaultMessage:
          'How much money can I deposit via this method at a time?',
      }),
      answer: intl.formatMessage(
        {
          id: 'BillPay.FAQ.DepositLimit.Answer',
          defaultMessage: 'You can load up to {amount} at a time.',
        },
        {
          amount: localeContext
            .intlFormatMoney(
              methodsMaxVelocityUtil(velocityData)[MoneyMovementType.BillPay],
            )
            .format('pretty'),
        },
      ),
    },

    {
      key: 'CancelDeposit',
      question: intl.formatMessage({
        id: 'BillPay.FAQ.CancelDeposit.Question',
        defaultMessage:
          "Can I cancel a Deposit via Bill Pay load once it's been sent?",
      }),
      answer: intl.formatMessage({
        id: 'BillPay.FAQ.CancelDeposit.Answer',
        defaultMessage:
          'Unfortunately, you can’t cancel the payment once it has been sent. If there’s an issue, like you sent it to the wrong account, the funds will be returned to your bank (if the account doesn’t exist).',
      }),
    },
    {
      key: 'WrongAccount',
      question: intl.formatMessage({
        id: 'BillPay.FAQ.WrongAccount.Question',
        defaultMessage:
          'I sent money to the wrong account. How do I get it back?',
      }),
      answer: intl.formatMessage({
        id: 'BillPay.FAQ.WrongAccount.Answer',
        defaultMessage:
          ' Please ensure the account details provided are accurate. In case you provide the wrong account details, please contact your bank or KOHO immediately.',
      }),
    },
    {
      key: 'CardNumber',
      question: intl.formatMessage({
        id: 'BillPay.FAQ.CardNumber.Question',
        defaultMessage:
          "Why can't I use my card number to deposit money to my KOHO account?",
      }),
      answer: intl.formatMessage({
        id: 'BillPay.FAQ.CardNumber.Answer',
        defaultMessage:
          'Your KOHO account number stays the same, but your card number can change (like if your card expires or gets replaced). For security reasons, we only accept money sent to your account number—not your card number.',
      }),
    },
  ];

  return (
    <div>
      {FAQs.map((faq) => (
        <SubAccordion key={`${faq.key}`}>
          <AccordionTitle>{faq.question}</AccordionTitle>
          <AccordionText>{faq.answer}</AccordionText>
        </SubAccordion>
      ))}
    </div>
  );
};

function AccordionTitle({ children }: Readonly<{ children: ReactNode }>) {
  return (
    <AccordionSummary
      classes={{ root: 'p-0 m-0' }}
      expandIcon={<ArrowDropDownIcon className="text-black" />}
    >
      <ParagraphBold className="m-0 legacy:font-bold">{children}</ParagraphBold>
    </AccordionSummary>
  );
}

function AccordionText({ children }: Readonly<{ children: ReactNode }>) {
  return (
    <AccordionDetails classes={{ root: 'p-0 m-0' }}>
      <Paragraph className="mt-0">{children}</Paragraph>
    </AccordionDetails>
  );
}

function SubAccordion({
  children,
}: Readonly<{ children: NonNullable<ReactNode> }>) {
  return (
    <Accordion
      classes={{
        root: 'm-0 bg-transparent shadow-none',
        expanded: 'before:opacity-100',
      }}
    >
      {children}
    </Accordion>
  );
}
