import { useIntl } from 'react-intl';

import { AllowedCardBrand, PaymentMethod } from 'apis/easyloadApi';
import { KDSIcons } from 'assets/images/kds_icons';
import { ReactComponent as MCard } from 'assets/images/logos/mastercard.svg';
import { ReactComponent as Visa } from 'assets/images/logos/visa.svg';
import clsx from 'clsx';
import { BoxTw } from 'components/BoxTw';
import ButtonUnstyled from 'components/ButtonUnstyledTw';
import { Paragraph, SubtitleSmall, TitleMedium } from 'components/TypographyTw';

interface PaymentMethodCardProps {
  user: string;
  paymentMethod: PaymentMethod;
  checked?: boolean;
  editForm?: boolean;
  viewOnly?: boolean;
  onClick?: (arg?: PaymentMethod) => void;
}

export const PaymentMethodCard = ({
  paymentMethod,
  user,
  viewOnly,
  checked,
  editForm,
  onClick,
}: Readonly<PaymentMethodCardProps>) => {
  const intl = useIntl();

  const { card } = paymentMethod;

  return (
    <CardWrapper onClick={editForm ? undefined : onClick}>
      <BoxTw className="p-4 [box-shadow:rgba(0,0,0,0.1)_3px_3px_10px_0px]">
        <BoxTw className="flex justify-between">
          <BoxTw>
            <CardLogo brand={card.brand} />
          </BoxTw>
          <BoxTw className="flex-1 ml-2">
            <TitleMedium className="mt-0 mb-1">
              **** **** **** {card.last4}
            </TitleMedium>
            <Paragraph className="mt-0 mb-1">{user}</Paragraph>
            <SubtitleSmall>
              {intl.formatMessage(
                {
                  id: 'PaymentMethodCard.Expires',
                  defaultMessage: 'Expires {month}/{year}',
                },
                { month: card.exp_month, year: card.exp_year },
              )}
            </SubtitleSmall>
          </BoxTw>
          {viewOnly || (
            <BoxTw className="flex-initial">
              {editForm && onClick ? (
                <ButtonUnstyled
                  onClick={() => onClick(paymentMethod)}
                  className="p-2 -mt-2 -mr-2 active:bg-grey-75"
                >
                  <KDSIcons.Icons.Trash />
                </ButtonUnstyled>
              ) : (
                <Checkmark checked={checked || false} />
              )}
            </BoxTw>
          )}
        </BoxTw>
      </BoxTw>
    </CardWrapper>
  );
};

function CardWrapper({
  children,
  onClick,
}: Readonly<{
  onClick?: () => void;
  children: React.ReactNode;
}>) {
  if (onClick) {
    return (
      <ButtonUnstyled
        onClick={() => onClick()}
        className="mb-4 w-full active:bg-grey-75 active:border-grey-300 focus:hover:bg-grey-75 focus:hover:border-grey-200"
      >
        {children}
      </ButtonUnstyled>
    );
  } else {
    return (
      // styles here are to match the button styling so the transition between them is seamless
      <BoxTw className="mb-4 w-full box-border border-2 border-solid rounded border-transparent">
        {children}
      </BoxTw>
    );
  }
}

function CardLogo({ brand }: { brand: AllowedCardBrand }) {
  switch (brand) {
    case 'visa':
      return <Visa />;
    case 'mastercard':
      return <MCard />;
    default:
      return <KDSIcons.Navigation.Spending />;
  }
}

function Checkmark({ checked }: { checked: boolean }) {
  return checked ? (
    <KDSIcons.Icons.CheckmarkFilled className="text-primary-300" />
  ) : (
    <BoxTw
      className={clsx(
        'rounded-full border border-solid w-6 h-6 flex items-center justify-center',
        checked ? 'border-primary-300' : 'border-grey-300',
      )}
    />
  );
}
